import axiosInstance from '../redux/axios';

export default async function getSettings(){
    try{
        const response = await axiosInstance.get('settings')
        if(response?.data?.settings){
            const data = response?.data?.settings;
            return {
                min_withdraw: data.min_withdraw !== undefined ? data.min_withdraw : 0,
                min_deposit: data.min_deposit !== undefined ? data.min_deposit : 0,
                min_single_bet: data.min_single_bet !== undefined ? data.min_single_bet : 0,
                min_multi_bet: data.min_multi_bet !== undefined ? data.min_multi_bet : 0,
            }
        }
    }catch{
        return {
            min_withdraw: 0,
            min_deposit: 0,
            min_single_bet: 0,
            min_multi_bet: 0
        }
    }
}