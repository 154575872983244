import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import PageReducer from "./reducers/PageReducer";
import WalletReducer from "./reducers/WalletReducer";
import UserReducer from "./reducers/UserReducer";
import SportsReducer from "./reducers/SportsReducer";
import RampReducer from "./reducers/RampReducer";

const persistConfig = {
    key: "root",
    version: 1,
    storage
}

const reducer = combineReducers({
    page: PageReducer,
    wallet: WalletReducer,
    user: UserReducer,
    sports: SportsReducer,
    ramp: RampReducer
})

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
});
