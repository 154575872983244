import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [
    {id: 1, name: 'Soccer', img: '/static/sports/Soccer.svg'},
    {id: 100, name: 'Politics', img: '/static/sports/Politics.svg'},
    {id: 12, name: 'American Football', img: '/static/sports/American Football.svg'},
    {id: 16, name: 'Baseball', img: '/static/sports/Baseball.svg'},
    {id: 18, name: 'Basketball', img: '/static/sports/Basketball.svg'},
    {id: 9, name: 'Boxing', img: '/static/sports/Boxing.svg'},
    {id: 162, name: 'MMA', img: '/static/sports/MMA.svg'},
    {id: 3, name: 'Cricket', img: '/static/sports/Cricket.svg'},
    {id: 13, name: 'Tennis', img: '/static/sports/Tennis.svg'},
    {id: 91, name: 'Volleyball', img: '/static/sports/Volleyball.svg'},
    {id: 78, name: 'Handball', img: '/static/sports/Handball.svg'},
    {id: 17, name: 'Ice Hockey', img: '/static/sports/Ice Hockey.svg'},
    {id: 15, name: 'Darts', img: '/static/sports/Darts.svg'},
    {id: 36, name: 'Australian Rules', img: '/static/sports/Australian Rules.svg'},
    {id: 92, name: 'Table Tennis', img: '/static/sports/Table Tennis.svg'},
    {id: 151, name: 'E-sports', img: '/static/sports/E-sports.svg'},
    {id: 75, name: 'Gaelic Sports', img: '/static/sports/Gaelic Sports.svg'},
    {id: 14, name: 'Snooker', img: '/static/sports/Snooker.svg'},
    {id: 83, name: 'Futsal', img: '/static/sports/Futsal.svg'},
    {id: 94, name: 'Badminton', img: '/static/sports/Badminton.svg'},
    {id: 8, name: 'Rugby Union', img: '/static/sports/Rugby Union.svg'},
    {id: 19, name: 'Rugby League', img: '/static/sports/Rugby League.svg'},
    {id: 90, name: 'Floorball', img: '/static/sports/Floorball.svg'},
    {id: 95, name: 'Beach Volleyball', img: '/static/sports/Beach Volleyball.svg'},
  ],
   sports: [],
   in_play_sports: [],
   last_called: []
};

const SportsSlice = createSlice({
    name: "sports",
    initialState: initialState,
    reducers: {
        addSports: (state, action) => {
            state.sports = action.payload;
        },
        addInplay: (state, action) => {
            state.in_play_sports = action.payload;
        },
    }
});

export const {
    addSports,
    addInplay
} = SportsSlice.actions;

export default SportsSlice.reducer;