import { Modal } from 'react-bootstrap';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useState, useEffect } from 'react';
import CashierCreditsBoard from '../Profile/CashierCreditsBoard';
import UserCurrencyAmount from '../Profile/WithdrawCredits';
import UserTransferPlayer from '../Profile/UserTransferPlayer';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserDeposit from '../Profile/UserDeposit';

const steps = [
  {id: 0, name: 'Deposit'},
  {id: 1, name: 'Withdraw'},
  //{id: 2, name: 'Transfer to Player'}
]

const currencies = [
  {id: 0, amount: '0.00', value: 'BTC', symbol: '/static/currencies/Bitcoin.svg' },
  {id: 1, amount: '0.00', value: 'ETH', symbol: '/static/currencies/eth.png' },
  {id: 2, amount: '0.00', value: 'LTC', symbol: '/static/currencies/ltc.png' },
  {id: 3, amount: '0.00', value: 'USDT', symbol: '/static/currencies/usdt.png' },
  {id: 4, amount: '0.00', value: 'DOGE', symbol: '/static/currencies/doge.png' },
  {id: 5, amount: '0.00', value: 'BNB', symbol: '/static/currencies/bnb.png' },
  {id: 6, amount: '0.00', value: 'AVAX', symbol: '/static/currencies/avax.png' },
  {id: 7, amount: '0.00', value: 'MATIC', symbol: '/static/currencies/matic.png' },
  {id: 8, amount: '0.00', value: 'USDC', symbol: '/static/currencies/usdc.png' },
]

export default function CashierModal(props){
    const [activeStep, setActiveStep] = useState(0);
    const [currency, setCurrency] = useState(currencies[0])
    const defaultVal = '0.00';
    const [amount, setAmount] = useState('');
    
    const [amountCredits, setAmountCredits] = useState('');
    const [player, setPlayer] = useState('');

    const matches = useMediaQuery('(max-width:600px)');
    const onHide = props?.onHide;
    const show = props?.show;

    const reset = () => {
      setCurrency(currencies[0])
      setAmount(defaultVal)
      setAmountCredits(0)
      setPlayer('')
    }

    useEffect(() => {
      reset();
    }, [activeStep])
    
    return(
        <Modal
        tabIndex="0"
        fullscreen={matches}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-bs-theme="dark"
        onHide={onHide}
        show={show}
      >
        <Modal.Header className="header_wallet" closeButton>
          <Modal.Title id="title_header_wallet">
                <CurrencyExchangeIcon/> 
                Cashier
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body>
          <div className="display_flex_center">
            <div className="display_spacebetween chashier_steps">
              {steps.map(s =>
                <div onClick={() => {setActiveStep(s.id)}} className={activeStep === s.id ? 'active_cashier_step' : ''} key={s.id}>{s.name}</div>
              )}
            </div>
          </div>

          <div className="mt-3 mb-3">
            <CashierCreditsBoard />
          </div>

          <div>
              {activeStep === 0 &&
                <div>
                  <UserDeposit wallet={props.wallet} onHide={onHide} />
                </div>
              }
              {activeStep === 1 &&
                <div>
                  <UserCurrencyAmount onHide={onHide} currencies={currencies} currency={currency} setCurrency={setCurrency} setAmount={setAmount} amount={amount} defaultVal={defaultVal} setActiveStep={setActiveStep} />
                </div>
              }
              {activeStep === 2 &&
                <div>
                  <UserTransferPlayer amountCredits={amountCredits} setAmountCredits={setAmountCredits} player={player} setPlayer={setPlayer} setActiveStep={setActiveStep}/>
                </div>
              }
          </div>
        </Modal.Body>
      </Modal>
    )
}