import {Modal, Button} from 'react-bootstrap';
import {useState,useEffect,useCallback, useMemo} from 'react';
import {Stepper, Step, StepLabel } from '@mui/material/';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import WalletDetails from '../Profile/Wallet_Details';
import useMediaQuery from '@mui/material/useMediaQuery';

import '../../assets/css/Profile.css';
import { useDispatch, useSelector } from 'react-redux';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#bf6ecf',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#bf6ecf',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#bf6ecf',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
            <Check className="QontoStepIcon-completedIcon" />
        ) : (
            <div className="QontoStepIcon-circle" />
        )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const steps = ['','Confirm your details', 'Done'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#bf6ecf',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#bf6ecf',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
}));

  
export default function WalletSetUp(props){
    const {user} = useSelector(state => state.user);
    
    const matches = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const [activeStep, setActiveStep]  = useState(1);
    const completed = useMemo(() => {return {};}, []);
    
    const isLastStep = useCallback(() => {
        return activeStep === steps.length - 1;
    },[activeStep]);
    
    const allStepsCompleted = useCallback(() => {
        return Object.keys(completed).length === steps.length;
    },[completed]);
    const handleNext = useCallback(() => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    }, [activeStep, completed, isLastStep, allStepsCompleted]);

 
    useEffect(() => {
        if(user?.is_step_one_completed && activeStep !== 2){
            handleNext();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <Modal
        {...props}
        fullscreen={matches}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body className="modal_body_config">
            {(!user?.is_step_one_completed) &&
            <>
                <div>
                  <h5>Setup your Wallet</h5>
                  <p>
                  Confirm your email & verify your account details. This will allow you to deposit & withdraw your funds seamlessly. All information is private & secure.
                  </p>
                
                  <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                      {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                          </Step>
                      ))}
                  </Stepper>
                </div>
                {activeStep === 1 &&
                    <WalletDetails handleStep={handleNext} userDetails={user} dispatch={dispatch} />
                }
            </>
            }
            {user?.is_step_one_completed &&
                <>  
                  <div>
                    <h5>Verification Completed!</h5>
                    <p>
                    All Set! <br/>
                    Next step is to deposit USD and start your betting experience.
                    <br/><br/>
                    Enjoy access to the best providers and sports on our platform! 
                    </p>
                  </div>
                  <Button className="submit_btn full_width" onClick={() => props.onHide()}>Close</Button>
                </>
            }
            
        </Modal.Body>
      </Modal>
    )
}