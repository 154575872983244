import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';

export default function OddType2({ type2, setType2 }){
    const handleOddsChange = (index, field, value) => {
        const newType2 = [...type2]; // Copy current type1 array
    
        // Check if the index is valid
        if (newType2[index]) {
            newType2[index] = { ...newType2[index], [field]: value }; // Update the specific field
        }
    
        // Set the new state for type1
        setType2(newType2); // Update type1 with the new array
    };
    return(
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="home_team">Name</Label>
                    <Input onChange={(e) => handleOddsChange(0, 'name', e.target.value)} value={type2[0]?.name || ''}  id="home_team" placeholder="Usually score (0-1)" type="text" className="bg-gray-800 text-white" />
                </fieldset>
            </Grid>
        
            <Grid item xs={12} sm={6}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="home_team">Odd</Label>
                    <Input value={type2[0]?.OD || ''} 
                        onChange={(e) => handleOddsChange(0, 'OD', e.target.value)}  id="home_team" placeholder="ex. 1.2" type="number" className="bg-gray-800 text-white" />
                </fieldset>
            </Grid>
        </Grid>
    )
}