import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Typography} from '@mui/material';
import {Fragment} from 'react';
import dayjs from 'dayjs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
export default function ViewBetsCreated0({odds, deleteOdd}){
    const sortedData = (data) => {
        // Create a new array to avoid mutating the original
        return [...data].sort((a, b) => {
            // Handle the case for -1 first
            if (a.order === '-1' && b.order !== '-1') return -1; // a comes first
            if (b.order === '-1' && a.order !== '-1') return 1;  // b comes first
            
            // For the rest, compare by order values
            return parseInt(a.order) - parseInt(b.order);
        });
    };
    
    return(
        <div>
            <Accordion defaultExpanded className="mb-2">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="Odds Created"
                >
                    <Typography className="title_home_selected" sx={{ width: '33%', flexShrink: 0 }}>
                    Odds Created
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {odds && odds.map(item =>
                        <Accordion key={item.title} className="mb-2">
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id={item.title} 
                            >   
                            <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between'}}>
                                <div>
                                    {item.title} (Valid Unitl: {dayjs(item.valid).format('DD MMM YYYY, HH:MM')})
                                </div>
                                <div>
                                    <DeleteOutlineIcon onClick={() => {deleteOdd(item)}} style={{background: 'red', borderRadius: '4px', cursor: 'pointer'}}/>
                                </div>
                            </div>
                                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} style={{minWidth: '100%'}}>
                                {item.odds && item.type !== 6 && item.type !== 8 && item.odds.map(od =>
                                    <Fragment key={od.OD+od.order+od.name+od.title}>
                                        {item.type === 1 &&
                                            <Grid item xs={4}>
                                                <div className={!od?.OD ? "first_bets" : "live_bets"}>
                                                    <div>{od?.name}</div>
                                                    <div>{parseFloat(od?.OD).toFixed(2)}</div>
                                                </div>
                                            </Grid>
                                        }
                                        {(item.type === 3 || item.type === 2 || item.type === 7) &&
                                            <Grid item xs={4}>
                                                <div className="live_bets">
                                                    <div>{od?.name}</div>
                                                    <div>{parseFloat(od?.OD).toFixed(2)}</div>
                                                </div>
                                            </Grid>
                                        }
                                        {item.type === 4 &&
                                            <Grid item xs={item.title?.startsWith('3') ? 4 : 6}>
                                                <div className="live_bets">
                                                    <div>{od?.name}</div>
                                                    <div><span className="handicap_cal">{od?.hd ? od?.hd : ''}</span>{parseFloat(od?.OD).toFixed(2)}</div>
                                                </div>
                                            </Grid>
                                        }
                                        {item.type === 5 &&
                                            <Grid item xs={6}>
                                                <div className={!od?.OD ? "first_bets" : "live_bets"}>
                                                    <div>{od?.name}</div>
                                                    <div>{parseFloat(od?.OD).toFixed(2)}</div>
                                                </div>
                                            </Grid>
                                        }
                                         
                                    </Fragment>
                                )}
                                {item.odds && (item.type === 6 || item.type === 8) && sortedData(item.odds).map(od =>
                                    <Fragment key={od.OD+od.order+od.name+od.title}>
                                        {item.type === 6 && <>
                                            {od.title &&
                                                <Grid item xs={6}>
                                                    <div className="stats_desc">{od.title}</div>
                                                </Grid>
                                            }
                                            {!od.title &&
                                            <Grid item xs={6}>
                                                {od?.OD &&
                                                <div className={!od?.OD ? "first_bets" : "live_bets"}>
                                                    <div>{od?.name}</div>
                                                    <div>{parseFloat(od?.OD).toFixed(2)}</div>
                                                </div>}
                                            </Grid>
                                            }
                                        </>}
                                        {item.type === 8 &&
                                            <Grid item xs={3}>
                                                <div className={!od?.OD ? "first_bets" : "live_bets"}>
                                                    <div>{od?.name}</div>
                                                    <div>{od?.OD ? parseFloat(od?.OD).toFixed(2) : ''}</div>
                                                </div>
                                            </Grid>
                                        }
                                    </Fragment>
                                )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}