import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { green } from '@mui/material/colors';
import Form from 'react-bootstrap/Form';
import Button from '@mui/material/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axiosInstance from '../../redux/axios';
import { useDispatch} from 'react-redux';
import { setAccessToken, setUser } from '../../redux/reducers/UserReducer';

export default function AdminLogin(){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPass, setShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        setError('');
    }, [username, password]);

    const checkValidate = () => {
        return username !== '' && password !== '';
    }

    const login = async (e) => {
        e.preventDefault();

        if(checkValidate()){
                try{
                    const requestData = {
                        email: username,
                        password: password
                    };

                    const response = await axiosInstance.post('auth/login', requestData, {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      });
                      if(response?.data?.accessToken){
                        await dispatch(setAccessToken(response?.data?.accessToken))
                    }

                    if(response?.data?.user){
                        dispatch(setUser(response?.data?.user))
                        if(response?.data?.user?.role_id?.name === 'Admin'){
                            navigate('/admin')
                        }else if(response?.data?.user?.role_id?.name === 'Viewer'){
                            navigate('/referral')
                        }
                        else{
                            setError('You dont have admin or viewer privilege')
                        }
                    }
                }catch(err) {
                    if(err?.response?.data){
                        const errs = err?.response?.data?.errors;
                        if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                            if(Object.keys(errs).length === 0){
                                if(err.response.data?.message){
                                    setError(err.response.data?.message)
                                }else{
                                    setError(err.response?.statusText)
                                }
                            }else{
                                setError(Object.values(errs).join(' ,'))
                            }
                        }else{
                            if(err.response.data?.message){
                                setError(err.response.data?.message)
                            }else{
                                setError(err.response?.statusText)
                            }
                        }
                    }
                }
        }else{
            setError("Fill in the form")
        }
    }

    return(
        <div className="main_page pt-5">
            <Container maxWidth="sm" className="pt-5 mt-5 mb-5 pb-5">
                <Box
                display="flex"
                flexDirection="column"
                className="contact_form login_form"
                alignItems="center"
                backgroundColor="#252525"
                padding="2rem 2rem"
                borderRadius="10px"
                
                >
                    <Avatar sx={{ bgcolor: green[200] }}>
                        <LockOutlinedIcon />
                    </Avatar>

                    <Form data-bs-theme="dark" style={{width: '100%'}}>
                        <Form.Group className="mb-3" controlId="loginForm.name">
                            <Form.Label>Email <span>*</span></Form.Label>
                            <Form.Control value={username} onChange={e => setUsername(e.target.value)} type="text" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="loginForm.password">
                            <Form.Label>Password <span>*</span></Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control value={password} onChange={e => setPassword(e.target.value)} type={showPass ? "text" : "password"} placeholder="" />
                                <InputGroup.Text onClick={() => {setShow(!showPass)}} style={{cursor: 'pointer'}}>{showPass ? <VisibilityIcon/> : <VisibilityOffIcon/>}</InputGroup.Text>
                            </InputGroup>
                           
                        </Form.Group>
                        <div className="display_start">
                            <Button onClick={(e) => login(e)} type="submit" variant="contained" style={{width: '100%'}}>Log In</Button>
                        </div>
                        {error && <p className="error_p pt-2">{error}</p>}
                       
                    </Form>
                </Box>
            </Container>
        </div>
    )
}