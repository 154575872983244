'use client'
import { DotsThreeOutlineVertical, Plus } from 'phosphor-react'
import {
  Badge,
  Button,
  Dropdown,
  DropdownAction,
  DropdownContent,
  DropdownItem,
  DropdownGroup,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'keep-react'
import {useEffect, useState} from 'react'
import axiosInstance from '../../redux/axios'
import { Container } from 'react-bootstrap';
import { MagnifyingGlass } from 'phosphor-react'
import { InputIcon, Input } from 'keep-react'
import { PaginationComponent } from '../../helpers/Pagination'
import { useNavigate } from 'react-router-dom';

export default function ATopLeagues(){
    const navigate = useNavigate();

    const [activePage, setActivePage] = useState(1);
    const [pages, setPages] = useState(1);
    const [data, setData] = useState({
        banners: [],
        total: 0
    });
    const [search, setSearch] = useState('');
    const [debouncedValue, setDebouncedValue] = useState(search);

    const changeActive = (i) => {
        if(i <= pages && i >= 1){
            setActivePage(i)
            getBanners(i, search)
        }
    }

    const filterSearch = (value) => {
        setSearch(value)
    }

    useEffect(() => {
        async function banners() {
            try{
                getBanners(1)
            }catch{} 
        }
        banners()
    }, [])

    async function getBanners(page, value) {
        try{
            let query = '?page='+page;
            if(value && value !== ''){
                query+='&search='+value
            }
            const resp = await axiosInstance.get('admin/leagues'+query)
            
            if(resp?.data){
                setData(resp.data)
                setPages(resp.data.totalPages)
            }
        }catch{} 
    }

    useEffect(() => {
        if (debouncedValue) {
            setActivePage(1);
            getBanners(1, debouncedValue);
        }else{
            setActivePage(1);
            getBanners(1);
        }
    }, [debouncedValue]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(search);
        }, 2000);

        // Cleanup the timeout if the value changes before 2 seconds
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    return(
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">Top Leagues</h5>
            </div>

            <Container className="pt-5 pb-5">
                <div className="mb-3">
                    <PaginationComponent activePage={activePage} pages={pages} changeActive={changeActive} />
                </div>

                <Table className="bg-gray-800 text-white" style={{captionSide: 'top'}}>
                    <TableCaption className="bg-gray-800 text-white">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-5">
                                <h2 className="text-heading-6 font-semibold text-metal-900 dark:text-white">Top Leagues</h2>
                                <Badge color="primary">{data?.total || 0} Leagues</Badge>
                            </div>
                            <div className="flex items-center gap-5">
                                <Button onClick={() => navigate('/admin/top-leagues/new')} variant="outline" className="gap-1.5">
                                    <Plus className="size-4 fill-metal-900 dark:fill-white" />
                                    New
                                </Button>
                                <fieldset className="relative">
                                    <Input value={search} onChange={e => filterSearch(e.target.value)} className="bg-gray-800 text-white px-5" placeholder="Search ..." />
                                    <InputIcon className="ps-3">
                                        <MagnifyingGlass size={19} color="#AFBACA" />
                                    </InputIcon>
                                </fieldset>
                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>
                                <div className="w-[120px]">Name</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[40px]">Sport Id</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[60px]">Logo</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[60px]">API League Id</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[60px]">Is Visible</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[60px]">Priority</div>
                            </TableHead>
                            <TableHead>
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                    {data?.banners?.length > 0 &&
                            data?.banners?.map((item) => (
                                <TableRow key={item._id}>
                                     <TableCell>
                                        {item.name}
                                     </TableCell>
                                     <TableCell>
                                        {item.sport_id}
                                     </TableCell>
                                     <TableCell>
                                        <img style={{width: '100%', maxWidth: '30px'}} src={item.img} alt="banner" />
                                    </TableCell>
                                    <TableCell>
                                        {item.league_id}
                                    </TableCell>
                                    <TableCell>
                                        {item.is_visible ? 'true' : 'false'}
                                    </TableCell>
                                    <TableCell>
                                        {item.priority}
                                    </TableCell>
                                    <TableCell>
                                        <Dropdown>
                                            <DropdownAction asChild>
                                                <button>
                                                    <DotsThreeOutlineVertical className="size-4 fill-metal-900 dark:fill-white" />
                                                </button>
                                            </DropdownAction>
                                            <DropdownContent className="max-w-[200px] border border-metal-100 p-3 bg-gray-800 text-white">
                                            <DropdownGroup>
                                                <DropdownItem >
                                                    <div onClick={() => navigate('/admin/top-leagues/'+item._id)} style={{height: '100%', width: '100%'}}>Edit</div>
                                                </DropdownItem>
                                            </DropdownGroup>
                                            </DropdownContent>
                                        </Dropdown>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Container>
        </div>
    )
}