import React, { useState, useEffect } from 'react';

const ImageWithFallback = ({ src, fallbackSrc, alt, style }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => setImgSrc(src);  // Image exists, use it
    img.onerror = () => setImgSrc(fallbackSrc);  // Image doesn't exist, use fallback
  }, [src, fallbackSrc]);

  return <img src={imgSrc} style={style} alt={alt} />;
};

export default ImageWithFallback;