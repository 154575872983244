'use client'
import { DotsThreeOutlineVertical, Plus } from 'phosphor-react'
import {
  Badge,
  Button,
  Dropdown,
  DropdownAction,
  DropdownContent,
  DropdownItem,
  DropdownGroup,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'keep-react'
import {useEffect, useState} from 'react'
import axiosInstance from '../../redux/axios'
import { Container } from 'react-bootstrap';
import { MagnifyingGlass } from 'phosphor-react'
import { InputIcon, Input } from 'keep-react'
import { PaginationComponent } from '../../helpers/Pagination'
import { useNavigate } from 'react-router-dom';

const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
};

export default function ACustum(){
    const navigate = useNavigate();
    const [data, setData] = useState({
        games: [],
        total: 0
    });
    const [search, setSearch] = useState('');
    const [debouncedValue, setDebouncedValue] = useState(search);
    const [activePage, setActivePage] = useState(1);
    const [pages, setPages] = useState(1);

    const changeActive = (i) => {
        if(i <= pages && i >= 1){
            setActivePage(i)
            getDeposits(i, search)
        }
    }

    const filterSearch = (value) => {
        setSearch(value)
    }

    async function getDeposits(page, value) {
        try{
            let query = '?page='+page;
            if(value && value !== ''){
                query+='&search='+value
            }
            const resp = await axiosInstance.get('admin/games'+query)
       
            if(resp?.data){
                setData(resp.data)
                setPages(resp.data.pages)
            }
        }catch{} 
    }

    useEffect(() => {
        async function getDeposits() {
            try{
                const resp = await axiosInstance.get('admin/games')

                if(resp?.data){
                    setData(resp?.data)
                    setPages(resp.data.pages)
                }
            }catch{} 
        }
        getDeposits()
    }, [])

    useEffect(() => {
        if (debouncedValue) {
            setActivePage(1);
            getDeposits(1, debouncedValue);
        }else{
            setActivePage(1);
            getDeposits(1);
        }
    }, [debouncedValue]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(search);
        }, 2000);

        // Cleanup the timeout if the value changes before 2 seconds
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    return(
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">Custom Games</h5>
            </div>

            <Container className="pt-5 pb-5">
                <div className="mb-3">
                    <PaginationComponent activePage={activePage} pages={pages} changeActive={changeActive} />
                </div>

                <Table className="bg-gray-800 text-white" style={{captionSide: 'top'}}>
                    <TableCaption className="bg-gray-800 text-white">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-5">
                                <h2 className="text-heading-6 font-semibold text-metal-900 dark:text-white">Total Games</h2>
                                <Badge color="primary">{data?.total || 0} Games</Badge>
                            </div>
                            <div className="flex items-center gap-5">
                                <Button onClick={() => navigate('/admin/custom-games/new')} variant="outline" className="gap-1.5">
                                    <Plus className="size-4 fill-metal-900 dark:fill-white" />
                                    New
                                </Button>
                                <fieldset className="relative">
                                    <Input value={search} onChange={e => filterSearch(e.target.value)} className="bg-gray-800 text-white px-5" placeholder="Search ..." />
                                    <InputIcon className="ps-3">
                                        <MagnifyingGlass size={19} color="#AFBACA" />
                                    </InputIcon>
                                </fieldset>
                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>
                                <div className="w-[50px]">League</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[120px]">Teams</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[65px]">Score</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[50px]">Time Status</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Starts At</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[90px]">Ends At</div>
                            </TableHead>
                            
                            <TableHead>
                                <div className="w-[50px]">Is Visible</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[50px]">On Home Page</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[50px]">MGs</div>
                            </TableHead>
                            <TableHead>
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                    {data?.games?.length > 0 &&
                            data?.games?.map((item) => (
                                <TableRow key={item._id}>
                                     <TableCell>
                                        {item.league?.name}
                                    </TableCell>
                                    <TableCell>
                                        {item.home?.name + ' v ' + item.away?.name}
                                    </TableCell>
                                    <TableCell>
                                        {item.ss}
                                    </TableCell>
                                    <TableCell>
                                        {item.time_status}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(item.startsAt).toLocaleString('en-US', options).replace(',', '')}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(item.endsAt).toLocaleString('en-US', options).replace(',', '')}
                                    </TableCell>
                                    <TableCell>
                                        {item.is_visible ? 'true' : 'false'}
                                    </TableCell>
                                    <TableCell>
                                        {item.is_home ? 'true' : 'false'}
                                    </TableCell>
                                    <TableCell>
                                        {item.MGs?.length}
                                    </TableCell>
                                    <TableCell>
                                        <Dropdown>
                                            <DropdownAction asChild>
                                                <button>
                                                    <DotsThreeOutlineVertical className="size-4 fill-metal-900 dark:fill-white" />
                                                </button>
                                            </DropdownAction>
                                            <DropdownContent className="max-w-[200px] border border-metal-100 p-3 bg-gray-800 text-white">
                                            <DropdownGroup>
                                                <DropdownItem >
                                                    <div onClick={() => navigate('/admin/custom-games/'+item._id)} style={{height: '100%', width: '100%'}}>Edit</div>
                                                </DropdownItem>
                                            </DropdownGroup>
                                            </DropdownContent>
                                        </Dropdown>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Container>
        </div>
    )
}