import * as React from 'react';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Person4Icon from '@mui/icons-material/Person4';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { addSearchHistory } from '../redux/reducers/PageReducer';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Divider, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, InputAdornment, SwipeableDrawer, Box } from '@mui/material';
import { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import axiosInstance from '../redux/axios';
import { logOutUser } from '../redux/reducers/UserReducer';
import ImageWithFallback from '../helpers/ImageWithFallback';
import NewspaperIcon from '@mui/icons-material/Newspaper';


export default function SwipableaDrawer({mobileSwipable, setMobileSwipable, toggleDrawer, setShowSetWallet, setShowVIP, setShowVault }){
    const {searchHistory} = useSelector(state => state.page);
    const {user} = useSelector(state => state.user);
    const { categories } = useSelector(state => state.sports);

    const [expanded, setExpanded] = useState('');
    const [primeSelection, setPrimeSelection] = useState('');
    const [focusInput, setFocusInput] = useState(false);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const history = useLocation(); 

    const changePrime = (val) => {
        if(val.startsWith('sport/')){
          setPrimeSelection(val?.split('/')[1])
          setMobileSwipable(false)
          navigate('/play/sport/'+val?.split('/')[1])
        }
        else if(val !== 'support'){
          setPrimeSelection(val)
          setMobileSwipable(false)
          if(val === 'favourites' || val === 'recent'){
              navigate('/play/'+val)
          }
          else if(val === 'slots' || val === 'new-releases'){
              navigate('/play/group/'+val)
          }
          else{
              navigate(val.toLowerCase().replaceAll(' ', '-'))
          }
        }
    }

    const changeOther = (text) => {
      if(text === 'Cashier'){
        setShowVault(true)
      }
      if(text === 'Logout'){
        logOut()
      }
      setMobileSwipable(false)
      document.body.style.removeProperty('overflow')
    }

    const toggleProfile = () => {
      if(expanded === 'profile'){
        setExpanded('')
      }else{
        setExpanded('profile')
      }
    }

    /*
    const openChatWidget = () => {
      setMobileSwipable(false)
      if(window.fcWidget){
          if(!window.fcWidget?.isOpen()){
              window.fcWidget.open()
          }else{
              window.fcWidget.close()
          }
      }
    }*/

    const logOut = async () => {
      try{
        await axiosInstance.get('auth/logout');
        dispatch(logOutUser())
        
        navigate('/')
      }catch{
        dispatch(logOutUser())
        navigate('/')
      }
    }

    const changeInputSearch = (e) => {
      if(e.target.value === ''){
        setFocusInput(false)
      }else{
        setFocusInput(true)
      }
      
      if(e.target.value.length >= 3){
        let filtered = [...topGames];
        filtered = filtered.filter(x => x.title.toLowerCase().includes(e.target.value.toLowerCase()))
        
        if(filtered.length > 0){
            let index = searchHistory?.findIndex(x => x.name.toLowerCase().includes(e.target.value.toLowerCase()));
            if(index === -1){
                dispatch(addSearchHistory({id: searchHistory.length, name: filtered[0].title}))
            }
        }
      }
      setSearch(e.target.value)
    }

    useEffect(() => {
      let itemPrime = history?.pathname?.split('/')[1]
      
      if(itemPrime){
        if(itemPrime === 'play'){
          let itemP2 = history?.pathname?.split('/')[2]
          if(itemP2){
            if(itemP2 === 'sport'){
              let item3 = history?.pathname?.split('/')[3]
                if(item3){
                  setPrimeSelection(item3)
                }
            }else{
              setPrimeSelection(itemP2)
            }
            
          }else{
            setPrimeSelection('')
          }
        }else{
          setPrimeSelection(itemPrime)
        }
      }
    }, [history])

    const list = (anchor: Anchor) => (
        <Box
          sx = {{width: '100%', paddingTop: '1rem'}}
          role="presentation"
        >
          <div>
            <TextField
            onFocus={() => setFocusInput(true)}
            value={search}
            onChange={(e) => changeInputSearch(e)}
            className="search_mobile_menu"
            id="search_mobile_menu"
            sx={{ m: 1, width: '96%' }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
              endAdornment: 
              <InputAdornment position="end">
                {(search !== '' || focusInput) &&
                  <CloseIcon onClick={() => {setSearch(''); setFocusInput(false)}}/>
                }
              </InputAdornment>
            }}
            placeholder="Search for games"
            />
          </div>
          {!focusInput &&
          <>
            {user &&
            <List className="list_decor">
              <ListItem onClick={() => toggleProfile()} disablePadding className={expanded === 'profile' ? 'sub_extended' : ''} sx={{ display: 'block' }}>
                  <ListItemButton>
                    <ListItemIcon>
                      <Person4Icon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                    
                    <ListItemIcon style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              <Collapse className={expanded === 'profile' ? 'sub_extended_collapse' : ''} in={expanded === 'profile'} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                {['Cashier', 'My Bets', 'Transactions', 'Settings', 'Logout'].map((text, index) => (
                    <Fragment key={index}>
                        <ListItemButton
                        selected={primeSelection?.toString().toLowerCase() === text.toLowerCase()}
                        onClick={() => index === 1 || index === 2 || index === 3 ? changePrime(text) : changeOther(text)}
                        sx={{ pl: 4 }}>
                            <ListItemIcon>
                              {index === 0 ? <CurrencyExchangeIcon/> : index === 1 ? <NewspaperIcon/> : index === 2 ? <ReceiptLongIcon/> : index === 3 ? <SettingsIcon /> : <LogoutIcon/> }
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                        {index === 5 && <Divider />}
                    </Fragment>
                ))}
                </List>
              </Collapse>
            </List>}


          </>
          }
          <Divider /> 
          <List className="list_decor">
          <div className="all_sports_side"><PlayCircleIcon/> All Sports</div>
          {categories && categories.map((c) => (
            <ListItem onClick={() => {changePrime('sport/'+ c.name)}} key={c.id} disablePadding sx={{ display: 'block', marginBottom: '.5rem' }}>
                <ListItemButton
                    sx={{
                    minHeight: 48,
                    justifyContent: 'initial',
                    px: 2.5,
                    }}
                    selected={primeSelection.replaceAll('%20', ' ') === c.name}
                >
                    <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: 'center',
                    }}
                    >
                        <ImageWithFallback
                        src={`${c.img}`}
                        alt={c.name}
                        fallbackSrc="/static/sports/Other.svg"
                        /></ListItemIcon>
                      <ListItemText primary={c.name} sx={{ opacity: 1 }} />
                </ListItemButton>
            </ListItem>
          ))}</List>
        </Box>
      );
    //toggleDrawer('bottom', true)}
    return(
        <SwipeableDrawer
            className="swipable_drower"
            anchor='bottom'
            disableSwipeToOpen={true}
            disableDiscovery={true}
            open={mobileSwipable}
            onClose={toggleDrawer('bottom', false)}
            onOpen={toggleDrawer('bottom', true)}
            ModalProps={{
              keepMounted: true,
            }}
        >
            {list('bottom')}
        </SwipeableDrawer>
    )
}

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topGames = [
  { id: 1,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
  { id: 2,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
  { id: 3,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
  { id: 4,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
  { id: 5,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
  { id: 6,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
  { id: 7,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
  { id: 8,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
  { id: 9,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
  { id: 10,title: 'The Shawshank Redemption', year: 1994, img: '/static/test/1.avif' },
  { id: 11,title: 'The Godfather', year: 1972 , img: '/static/test/2.avif'},
  { id: 12,title: 'The Godfather: Part II', year: 1974, img: '/static/test/3.avif' },
  
];