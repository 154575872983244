
import Selectable from './Selectable';
import Grid from "@mui/material/Grid"
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import {Form} from 'react-bootstrap';
import {useState, useEffect, useRef} from 'react';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from 'react-router-dom';
import { clearAllSlips, removeBetSlip, updateBetSlip, updateUserInfo } from '../../redux/reducers/UserReducer';
import axiosInstance from '../../redux/axios';

const values = [
    {id: 0, name: 'Single'},
    {id: 1, name: 'Multi'},
]
const timeStatus = [
    {id: '0', title:'Not Started'},
    {id: '1', title:'InPlay'},
    {id: '2', title:'TO BE FIXED'},
    {id: '3', title:'Ended'},
    {id: '4', title:'Postponed'},
    {id: '5', title:'Cancelled'},
    {id: '6', title:'Walkover'},
    {id: '7', title:'Interrupted'},
    {id: '8', title:'Abandoned'},
    {id: '9', title:'Retired'},
    {id: '10', title:'Suspended'},
    {id: '11', title:'Decided by FA'},
    {id: '99', title:'Removed'},
]
export default function BetSlipComponent(){
    
    const [selected, setSelected] = useState(0);
    const {betSplips} = useSelector(state => state.user);
    const midSlipRef = useRef(null);
    const [total_bet, setTotalBet] = useState(0.00)
    const [sameAdded, setSameAdded] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        setError('')
        setSuccess('')
    }, [selected])

    useEffect(() => {
        setSameAdded(check_same_event_multi())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [betSplips])

    const est_payout = (type) => {
        if(type === 0){
            return Object.values(betSplips)
            .map(value => parseFloat(value.odd.odd) * parseFloat(value.input) || 0) 
            .reduce((acc, val) => acc + val, 0)?.toFixed(2)
        }
        if(type === 1){
            return Object.values(betSplips)
            .map(value => parseFloat(value.odd.odd) || 0) 
            .reduce((acc, val) => acc + val, 0) * parseFloat(total_bet)?.toFixed(2)
        }
    }
    const payed = () => {
        return Object.values(betSplips)
        .map(value => parseFloat(value.input) || 0) 
        .reduce((acc, val) => acc + val, 0)?.toFixed(2)
    }
    const total_odds = () => {
        return Object.values(betSplips)
        .map(value => parseFloat(value.odd.odd) || 0) 
        .reduce((acc, val) => acc + val, 0)?.toFixed(2)
    }
    const check_same_event_multi = () => {
        let temp = [...betSplips]
        const eventIdCounts = temp.reduce((counts, event) => {
            counts[event.event?.id?.split('C')[0]] = (counts[event.event?.id?.split('C')[0]] || 0) + 1;
            return counts;
          }, {});
        
          // Filter events to include only those whose event id does not have siblings
          const filteredEvents = temp.filter(event => eventIdCounts[event.event?.id?.split('C')[0]] >= 2);
        
          return filteredEvents;
    }

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if(error !== ''){
            setSuccess('')
        }
    }, [error])

    useEffect(() => {
        if(success !== ''){
            setError('')
        }
    }, [success])

    const betNow = async () => {
        setSuccess('')
        setError('')
        try{
            const formData = new FormData();
            if(selected === 0){
                formData.append('bet_type', 'Single')
                formData.append('total_bet', payed())
                formData.append('est_payout_final', parseFloat(est_payout(0)))
            }

            if(selected === 1){
                formData.append('bet_type', 'Multi')
                formData.append('total_bet', total_bet)
                formData.append('est_payout_final', parseFloat(est_payout(1)))
            }

            formData.append('bets', JSON.stringify(betSplips))
            formData.append('total_odds', total_odds())
            const response = await axiosInstance.post('betslip/create', formData)
            if(response.data.betSlip && response.data.user){
                dispatch(updateUserInfo(response.data.user))
                setSuccess('Bet slip created successfully')
                dispatch(clearAllSlips())

                setTimeout(() => {
                    setSuccess('')
                }, 2000)
            }
        }catch(err) {
            if(err?.response?.data){
                const errs = err?.response?.data?.errors;
                if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                    if(Object.keys(errs).length === 0){
                        if(err.response.data?.message){
                            setError(err.response.data?.message)
                        }else{
                            setError(err.response?.statusText)
                        }
                    }else{
                        setError(Object.values(errs).join(' ,'))
                    }
                }else{
                    if(err.response.data?.message){
                        setError(err.response.data?.message)
                    }else{
                        setError(err.response?.statusText)
                    }
                }
            }
        }
    }

    const removeSlip = (item) => {
        dispatch(removeBetSlip(item))
    }
    const clearAll = () => {
        dispatch(clearAllSlips())
    }

    const updateInput = (value, id) => {
        dispatch(updateBetSlip({id: id, value: value}))
    }
    useEffect(() => {
        if (midSlipRef.current) {
            midSlipRef.current.scrollTo({
              top: midSlipRef.current.scrollHeight,
              behavior: 'smooth',
            });
          }
    }, [betSplips])
    
    return(
        <div className="display_center" style={{flexDirection: 'column', overflow: 'hidden'}}>
        <div className="first_part">
            <div className="ps-2 pe-2 pt-3 pb-3">
                <Selectable values={values} selected={selected} setSelected={setSelected} />
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <div className="clear_all" onClick={() => clearAll()} style={{textAlign: 'end'}}>Clear All</div>
                </Grid>
            </Grid>
        </div>

        {/*MAIN */}
        <div ref={midSlipRef} className="mid_betPop" style={{flex: 1}}>
            {betSplips?.length === 0 &&
                <div className="display_center" style={{flexDirection: 'column'}}>
                    <NewspaperIcon style={{color: 'gray', width: '3rem', height: '3rem'}} />
                    <div style={{color: 'gray', fontSize: '.9rem'}}>Bet Slip is Empty</div>
                    <div onClick={() => navigate('/play/sport/Soccer')} style={{fontSize: '1.1rem', fontWeight: '600', cursor: 'pointer'}}>Start Betting Now!</div>
                </div>
            }
            {betSplips?.length > 0  &&
            <>
                <div style={selected === 0 ? {marginTop: '1rem'} : {}}  className="slip_bet">
                    <div className={sameAdded.some(event => event?.event?.id === betSplips[0]?.event?.id) && selected === 1 ? "grid_slip_bet_red" : "grid_slip_bet"}>
                        <Grid className="grid_slip" sx={{padding: '.4rem'}} container spacing={1}>
                            <Grid item xs={9}>
                                <div style={{flexShrink: 0 , display: 'flex', alignItems: 'center', flexWrap: 'nowrap', width: '100%' }}>
                                    <span className="is_live me-2">
                                        <span className="pulse-circle me-1"/>
                                        {timeStatus.find(x => {return x.id === betSplips[0]?.event?.time_status || x.id === betSplips[0]?.event?.is_live})?.title} 
                                        </span> <span style={{fontWeight: '600', fontSize: '1rem', color: 'white'}}>{betSplips[0]?.event?.league?.name} </span>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div style={{textAlign: 'end'}}><CloseIcon onClick={() => removeSlip(betSplips[0])} /></div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="slip_bet_body">
                        <div className="pt-2 ps-2">
                            <div className="pb-1">{betSplips[0]?.event?.home?.name ? betSplips[0]?.event?.home?.name + ' vs ' + betSplips[0]?.event?.away?.name : 
                            betSplips[0]?.event?.teams ? betSplips[0]?.event?.teams : ''
                            }</div>
                            <div className="pb-1">{betSplips[0]?.type}</div>
                            <div className="display_flex">
                                <div className="clear_all" style={{width: '100%'}}>{betSplips[0]?.team?.name}</div>
                                <div className="odd_slips">{betSplips[0]?.odd?.odd}</div>
                            </div>
                        </div>
                        {selected === 0 &&
                        <Grid sx={{padding: '.4rem'}} container spacing={1}>
                            <Grid item xs={8}>
                                <Form.Group data-bs-theme="dark" className="mt-2" controlId="auth.email">
                                    <Form.Control  
                                        className="input_settings" 
                                        type="number" 
                                        value={betSplips[0]?.input}
                                        onChange={e => updateInput(e.target.value, betSplips[0]?.id)} 
                                    />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="pt-2" style={{textAlign: 'end'}}>Est. Payout</div>
                                <div style={{textAlign: 'end'}}>{betSplips[0]?.est_payout}</div>
                            </Grid>
                        </Grid>}
                    </div>
                </div>

                {selected === 0 && betSplips?.length > 1 &&
                    <div style={{color: '#cfcfcf'}} className="mt-4">
                        Repeat Bet
                    </div>
                }

                {betSplips?.length > 0 &&
                    betSplips.slice(1).map(x => 
                        <div key={x?.team?.name + x?.event?.id + x?.type} style={selected === 0 ? {marginTop: '1rem'} : {}} className="slip_bet">
                            <div className={sameAdded.some(event => event?.event?.id === x?.event?.id) && selected === 1 ? "grid_slip_bet_red" : "grid_slip_bet"}>
                                <Grid sx={{padding: '.4rem'}} container spacing={1}>
                                    <Grid item xs={9}>
                                        <div style={{flexShrink: 0 , display: 'flex', alignItems: 'center', flexWrap: 'nowrap', width: '100%' }}>
                                            <span className="is_live me-2">
                                                <span className="pulse-circle me-1"/>
                                                {timeStatus.find(y => {return y.id === x?.event?.time_status || y.id === x?.event?.is_live })?.title} 
                                                </span> <span style={{fontWeight: '600', fontSize: '1rem', color: 'white'}}>{x?.event?.league?.name} </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div style={{textAlign: 'end'}}><CloseIcon onClick={() => removeSlip(x)} /></div>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className="slip_bet_body">
                                <div className="pt-2 ps-2">
                                    <div className="pb-1">{x?.event?.home?.name ? x?.event?.home?.name + ' vs ' + x?.event?.away?.name : 
                                    x?.event?.teams ? x?.event?.teams : ''
                                    }</div>
                                    <div className="pb-1">{x?.type}</div>
                                    <div className="display_flex">
                                        <div className="clear_all" style={{width: '100%'}}>{x?.team?.name}</div>
                                        <div className="odd_slips">{x?.odd?.odd}</div>
                                    </div>
                                </div>
                                {selected === 0 &&
                                <Grid sx={{padding: '.4rem'}} container spacing={1}>
                                    <Grid item xs={8}>
                                        <Form.Group data-bs-theme="dark" className="mt-2" controlId="auth.email">
                                            <Form.Control  
                                                className="input_settings" 
                                                type="number" 
                                                value={x?.input}
                                                onChange={e => updateInput(e.target.value, x?.id)} 
                                            />
                                        </Form.Group>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="pt-2" style={{textAlign: 'end'}}>Est. Payout</div>
                                        <div style={{textAlign: 'end'}}>{x?.est_payout}</div>
                                    </Grid>
                                </Grid>}
                            </div>
                        </div>
                    )
                    
                }
            </>
            }
        </div>

        <div className="footer_betPop">
            {selected === 0 &&
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    Total Bet 
                </Grid>
                <Grid item xs={6}>
                    <div className="clear_all" style={{textAlign: 'end'}}>
                        {selected === 0 && Object.values(betSplips)
                            .map(value => parseFloat(value.input) || 0) 
                            .reduce((acc, val) => acc + val, 0)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        }
                    </div>
                </Grid>
            </Grid>
            }
            {selected === 1 &&
            <>
            <Form.Group data-bs-theme="dark" className="mb-3" controlId="auth.email">
                <Form.Control  
                    className="input_settings" 
                    type="number" 
                    value={total_bet}
                    onChange={e => setTotalBet(e.target.value)}
                />
            </Form.Group>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    Total Odds 
                </Grid>
                <Grid item xs={6}>
                    <div className="odd_slips" style={{textAlign: 'end'}}>
                        {selected === 1 && Object.values(betSplips)
                            .map(value => parseFloat(value.odd.odd) || 0) 
                            .reduce((acc, val) => acc + val, 0)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        }
                    </div>
                </Grid>
            </Grid>
            </>
            }
            <Grid className="pb-3" container spacing={2}>
                <Grid item xs={6}>
                    Est. Payout
                </Grid>
                <Grid item xs={6}>
                    <div className="clear_all" style={{textAlign: 'end'}}> 
                        {selected === 0 && 
                        parseFloat(Object.values(betSplips)
                            .map(value => parseFloat(value.odd.odd) * parseFloat(value.input) || 0) 
                            .reduce((acc, val) => acc + val, 0))?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        }
                        {selected === 1 &&
                        parseFloat( Object.values(betSplips)
                        .map(value => parseFloat(value.odd.odd) || 0) 
                        .reduce((acc, val) => acc + val, 0) * parseFloat(total_bet))?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        }
                    </div>
                </Grid>
            </Grid>
            {error !== '' &&
                <div className="bet_slip_error">
                    {error}
                </div>
            }
            {success !== '' &&
                <div className="bet_slip_success">
                    {success}
                </div>
            }
            {sameAdded?.length > 0 && selected === 1 &&
                <div className="bet_slip_error">
                    Multiple selections from same event cannot be combined into a Multi bet.
                </div>
            }
            {/* || (user?.balance?.$numberDecimal <= 0)  */}
            <Button className="mt-2" disabled={betSplips?.length === 0 || (sameAdded?.length > 0 && selected === 1)} onClick={() => betNow()} variant="contained" style={{width: '100%'}}>
                Bet Now
            </Button>
        </div>
    </div>
    )
}