import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';

export default function OddType6({ type6, setType6 }) {
    const handleChange = (index, field) => (event) => {
        const updatedType6 = [...type6];
        updatedType6[index][field] = event.target.value;
        setType6(updatedType6);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="title_0">Title</Label>
                    <Input 
                        id="title_0" 
                        placeholder="Usually home team" 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type6[0]?.title || ''} 
                        onChange={handleChange(0, 'title')} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="name_0">Name</Label>
                    <Input 
                        id="name_0" 
                        placeholder="Usually over, odd, yes ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type6[1]?.name || ''} 
                        onChange={handleChange(1, 'name')} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="name_1">Name</Label>
                    <Input 
                        id="name_1" 
                        placeholder="Usually under, even, no ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type6[2]?.name || ''} 
                        onChange={handleChange(2, 'name')} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="od_0">Odd</Label>
                    <Input 
                        id="od_0" 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        value={type6[1]?.OD || ''} 
                        onChange={handleChange(1, 'OD')} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="od_1">Odd</Label>
                    <Input 
                        id="od_1" 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        value={type6[2]?.OD || ''} 
                        onChange={handleChange(2, 'OD')} 
                    />
                </fieldset>
            </Grid>

            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="title_1">Title</Label>
                    <Input 
                        id="title_1" 
                        placeholder="Usually away team" 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type6[3]?.title || ''} 
                        onChange={handleChange(3, 'title')} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="name_2">Name</Label>
                    <Input 
                        id="name_2" 
                        placeholder="Usually over, odd, yes ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type6[4]?.name || ''} 
                        onChange={handleChange(4, 'name')} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="name_3">Name</Label>
                    <Input 
                        id="name_3" 
                        placeholder="Usually under, even, no ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type6[5]?.name || ''} 
                        onChange={handleChange(5, 'name')} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="od_2">Odd</Label>
                    <Input 
                        id="od_2" 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        value={type6[4]?.OD || ''} 
                        onChange={handleChange(4, 'OD')} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="od_3">Odd</Label>
                    <Input 
                        id="od_3" 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        value={type6[5]?.OD || ''} 
                        onChange={handleChange(5, 'OD')} 
                    />
                </fieldset>
            </Grid>
        </Grid>
    );
}