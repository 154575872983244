'use client'
import {
  Badge,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'keep-react'
import {useEffect, useState} from 'react'
import axiosInstance from '../../redux/axios'
import { Container } from 'react-bootstrap';
import { PaginationComponent } from '../../helpers/Pagination'
import { MagnifyingGlass } from 'phosphor-react'
import { InputIcon, Input } from 'keep-react'

const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
};

export default function RUsers(){
    const [data, setData] = useState({
        users: [],
        total: 0
    })
    const [activePage, setActivePage] = useState(1);
    const [pages, setPages] = useState(1);

    const [search, setSearch] = useState('');
    const [debouncedValue, setDebouncedValue] = useState(search);
    
    const changeActive = (i) => {
        if(i <= pages && i >= 1){
            setActivePage(i)
            getUsers(i, search)
        }
    }

    async function getUsers(page, value) {
        try{
            let query = '?page='+page+'&ref=false';
            if(value && value !== ''){
                query+='&search='+value
            }
            const resp = await axiosInstance.get('admin/referrals/users'+query)

            if(resp?.data){
                setData(resp.data)
                setPages(resp.data.pages)
            }
        }catch{} 
    }

    useEffect(() => {
        async function getUsers() {
            try{
                const resp = await axiosInstance.get('admin/referrals/users?ref=false')

                if(resp?.data){
                    setData(resp.data)
                    setPages(resp.data.pages)
                }
            }catch{} 
        }
        getUsers()
    }, [])

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(search);
        }, 2000);

        // Cleanup the timeout if the value changes before 2 seconds
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        if (debouncedValue) {
            setActivePage(1);
            getUsers(1, debouncedValue);
        }else{
            setActivePage(1);
            getUsers(1);
        }
    }, [debouncedValue]);

    const filterSearch = (value) => {
        setSearch(value)
    }
    
    return(
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">My Users</h5>
            </div>

            <Container className="pt-5 pb-5">
                <div className="mb-3">
                    <PaginationComponent activePage={activePage} pages={pages} changeActive={changeActive} />
                </div>

                <Table className="bg-gray-800 text-white" style={{captionSide: 'top'}}>
                    <TableCaption className="bg-gray-800 text-white">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-5">
                                <h2 className="text-heading-6 font-semibold text-metal-900 dark:text-white">Total Users</h2>
                                <Badge color="primary">{data.total} Members</Badge>
                            </div>
                            <div className="flex items-center gap-5">
                                <fieldset className="relative">
                                    <Input value={search} onChange={e => filterSearch(e.target.value)} className="bg-gray-800 text-white px-5" placeholder="Search ..." />
                                    <InputIcon className="ps-3">
                                        <MagnifyingGlass size={19} color="#AFBACA" />
                                    </InputIcon>
                                </fieldset>
                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>
                                <div className="w-[190px]">Full Name & Wallet</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[190px]">Level</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[90px]">Deposits</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Role</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[90px]">Joined At</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[90px]">Status</div>
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {data?.users?.length > 0 &&
                            data?.users.map((item) => (
                                <TableRow key={item.user?._id}>
                                    <TableCell>
                                        <div>{item.user?.first_name} {item.user?.last_name}</div>
                                        <div>{item.user?.username.toLowerCase()}</div>
                                    </TableCell>
                                    <TableCell>
                                        Level {item.level}
                                    </TableCell>
                                    <TableCell>
                                        ${parseFloat(item.user?.totalDepositAmount?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                                    </TableCell>
                                    <TableCell>
                                        {item.user?.role_id?.name}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(item.user?.createdAt).toLocaleString('en-US', options).replace(',', '')}
                                    </TableCell>
                                    
                                    <TableCell>
                                        <Badge
                                            className="text-body-4"
                                            color={item.user?.active  ? 'success' : 'error'}>
                                            {item.user?.active ? 'Active' : 'Banned'}
                                        </Badge>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>

            </Container>
        </div>
    )
}