
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Form } from 'react-bootstrap';
import {Grid} from '@mui/material';
import axiosInstance from '../../../redux/axios';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AFeeWithdrawal({openDetails, setOpenDetails, selected}){
    const matches = useMediaQuery('(max-width:600px)');

    const [set, setSet] = useState(false);
    const [err, setErr] = useState('');
    const [succ, setSucc] = useState('');
    const [newF, setNew] = useState({
        description: '',
        funds: '',
        tax: '',
        total: '',
    });
    const[withdraw, setWithdraw]=useState({
        show_normal: false,
        show_custom: false
    })

    useEffect(() => {
        if(selected?.fee_cal && !set){
            setSet(true);
            setTotal(selected.fee_cal);
        }
        if(selected){
            setWithdraw({
                show_normal: selected?.show_normal,
                show_custom: selected?.show_custom
            })
        }
    }, [selected, set])

    const [total, setTotal] = useState([]);

    useEffect(() => {
        if(err !== ''){
            setSucc('')
        }
    }, [err])
    useEffect(() => {
        if(succ !== ''){
            setErr('')
        }
    }, [succ])

    const changeStatus = async () => {
        setErr('')
        setSucc('')
        try{
            const response = await axiosInstance.post(`withdraw/fee-add/${selected?._id}`, {fee_cal: total, show_custom: withdraw.show_custom, show_normal: withdraw.show_normal}, { timeout: 10000 })
       
            if(response.status === 200){
                setSucc('Fees saved successfully')
            }
        }catch(err){
            if(err?.response?.data){
                const errs = err?.response?.data?.errors;
                if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                    if(Object.keys(errs).length === 0){
                        if(err.response.data?.message){
                            setErr(err.response.data?.message);
                        }else{
                            setErr(err.response?.statusText);
                        }
                    }else{
                        setErr(Object.values(errs).join(' ,'));
                    }
                }else{
                    if(err.response.data?.message){
                        setErr(err.response.data?.message);
                    }else{
                        setErr(err.response?.statusText);
                    }
                }
            }
        }
    }

    const validateInputs = () => {
        const newErrors = {};
        if (!newF.description.trim()) newErrors.description = 'Description cannot be empty.';
        if (parseFloat(newF.funds) <= 0) newErrors.funds = 'Funds must be greater than 0.';
        if (parseFloat(newF.tax) <= 0) newErrors.tax = 'Tax must be greater than 0.';
        if (parseFloat(newF.total) <= 0) newErrors.total = 'Total must be greater than 0.';

        return Object.keys(newErrors).length === 0;
    };

    const addToArray = async () => {
        if (validateInputs()) {
            const exists = total.some(item => item.description === newF.description);
            if(!exists){
                setTotal((prev) => ([
                    ...prev,
                    newF
                ]));
                setNew({
                    description: '',
                    funds: '',
                    tax: '',
                    total: '',
                })
            }else{
                setErr('Description already exists')
            }
        }else{
            setErr('Fill in the form')
        }
    }

    const removeFromArray = (t) => {
        setTotal((prev) => prev.filter(item => item.description !== t.description));
        setErr('');
    }

    const closeModel = () => {
        setOpenDetails(false)
        setErr('')
        setSucc('')
        setTotal([])
        setSet(false);
    }

    return (
        <Modal
        className="admin_modal"
        show={openDetails}
        onHide={() => closeModel()}
        backdrop="static"
        keyboard={false}
        centered
        data-bs-theme="dark"
        fullscreen={matches}
      >
        <Modal.Header closeButton>
            Modify Fees
        </Modal.Header>
        <Modal.Body>
            <div>
                <fieldset className="max-w-md space-y-1">
                    <FormControlLabel control={<Checkbox checked={withdraw.show_normal} onChange={e => setWithdraw({...withdraw, show_normal : e.target.checked})} />} label="Show Taxes" />
                    <FormControlLabel control={<Checkbox checked={withdraw.show_custom} onChange={e => setWithdraw({...withdraw, show_custom : e.target.checked})} />} label="Show Custom" />
                </fieldset>
            </div>
           {selected?.fee_cal ?
            <div>
                <p className="mb-0">To save changes click Modify in the footer.</p>
                <p>Withdraw amount: ${parseFloat(selected?.amount?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>

                {err !== '' && <div className="error_p">{err}</div>}
                {succ !== '' && <div className="success_p">{succ}</div>}

                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control style={{fontSize: '.8rem'}} placeholder="ex. Gross Withdrawal Fee, Platform Fee, Commission, Network Fee ..." type="text" value={newF.description} onChange={e => setNew({...newF, description: e.target.value})} />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={6}>
                            <Form.Group>
                                <Form.Label>Funds</Form.Label>
                                <Form.Control style={{fontSize: '.8rem'}} type="number" placeholder="ex. Users withdraw amount" value={newF.funds} onChange={e => setNew({...newF, funds: e.target.value})} />
                            </Form.Group>
                        </Grid>

                        <Grid item xs={6}>
                            <Form.Group>
                                <Form.Label>Tax</Form.Label>
                                <Form.Control style={{fontSize: '.8rem'}} type="number" placeholder="ex. 10%" value={newF.tax} onChange={e => setNew({...newF, tax: e.target.value})} />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={6}>
                            <Form.Group>
                                <Form.Label>Total</Form.Label>
                                <Form.Control style={{fontSize: '.8rem'}} type="number" placeholder="Total funds * (tax / 100)" value={newF.total} onChange={e => setNew({...newF, total: e.target.value})} />
                            </Form.Group>
                        </Grid>
                    </Grid>

                    <Button onClick={() => addToArray()} style={{fontSize: '.8rem'}} className="mt-3">Add New</Button>
                </div>
                {total?.length > 0 &&
                    <div>
                        <table className="sum_table mt-4">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Funds</th>
                                    <th>TAX</th>
                                    <th>TOTAL, USD</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {total.map(t => 
                                    <tr key={t.description}>
                                        <td>{t.description}</td>
                                        <td>{t.funds?.$numberDecimal ? parseFloat(t.funds.$numberDecimal).toFixed(2) : parseFloat(t.funds).toFixed(2)}</td>
                                        <td>{t.tax?.$numberDecimal ? parseFloat(t.tax.$numberDecimal).toFixed(2) : parseFloat(t.tax).toFixed(2)}%</td>
                                        <td>{t.total?.$numberDecimal ? parseFloat(t.total.$numberDecimal).toFixed(2) : parseFloat(t.total).toFixed(2)}</td>
                                        <td><DeleteIcon onClick={() => removeFromArray(t)} style={{width: '1rem', margin: '.1rem 0rem', padding: '0rem', backgroundColor: 'red', cursor: 'pointer', borderRadius: '2px'}}/></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        
                    </div>
                }
            </div>
            :
            <>No fees</>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => changeStatus()}>
            Modify
          </Button>
          <Button variant="secondary" onClick={() => closeModel()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}