import React, { createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setRef } from '../redux/reducers/UserReducer';

const ReferralContext = createContext();

export const ReferralProvider = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const currentRef = useSelector((state) => state.user.ref);
    
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref');

    useEffect(() => {
        if (ref && (currentRef === null || currentRef !== ref)) {
            dispatch(setRef(ref));
        }
    }, [ref, currentRef, dispatch]);

    return (
        <ReferralContext.Provider value={ref}>
            {children}
        </ReferralContext.Provider>
    );
};

export const useReferral = () => {
    return useContext(ReferralContext);
};