import { Modal } from 'react-bootstrap';
import useMediaQuery from '@mui/material/useMediaQuery';
import BetSlipComponent from '../Sports/BetSlipComponent.js';
import NewspaperIcon from '@mui/icons-material/Newspaper';

export default function BetSlipMobile(props){
    const matches = useMediaQuery('(max-width:600px)');
    const onHide = props?.onHide;
    const show = props?.show;

    return(
        <Modal
        fullscreen={matches}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-bs-theme="dark"
        onHide={onHide}
        show={show}
      >
        <Modal.Header className="header_wallet" closeButton>
          <Modal.Title id="title_header_wallet">
                <NewspaperIcon/> 
                Bet Slip
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body style={{padding: 0}}>
          <BetSlipComponent />
        </Modal.Body>
      </Modal>
    )
}