import { Modal, Button } from 'react-bootstrap';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid } from '@mui/material';
import {useEffect, useState} from 'react';
import logo from '../../assets/img/logo.png';
import dayjs, { getUserTimezone } from '../../helpers/UserTimestamp';
import { useSelector } from 'react-redux';
import axiosInstance from "../../redux/axios";

export default function FeesCommission({details, setDetails}){
    const userTimezone = getUserTimezone();
    const {user} = useSelector(state => state.user);

    const matches = useMediaQuery('(max-width:600px)');
    const show = details !== null;
    const onHide = () => {
        setDetails(null)
    }
    const [total, setTotal] = useState(0.00);
    

    useEffect(() => {
        if(!details?.payed_taxes && details?.show_normal){
            setTotal(parseFloat(details?.amount?.$numberDecimal).toFixed(2) * (15/100))
        }
        else if(details?.show_custom && details?.fee_cal){
            const totalSum = details.fee_cal.reduce((acc, item) => parseFloat(acc) + parseFloat(item.total?.$numberDecimal), 0);

            // Update the state with the calculated total
            setTotal(totalSum);
        }
    }, [details])

    const generateLink = async () => {
        try{
            if(total > 0 && total > 10){
                const response = await axiosInstance.post('/deposits/kassify/h2h/'+user._id+'?fee='+details._id+'&type='+(details?.payed_taxes ? 'tax' : 'other'), {paysys: 'kassify', amount: total, currency: 'USD'})
            
                if(response.data){
                window.open(response.data, "_self")
                }
            }
        }catch{}
    }
    
    return(
        <Modal
        fullscreen={matches}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        data-bs-theme="dark"
        onHide={onHide}
        show={show}
        >
        <Modal.Header className="header_wallet" closeButton>
          <Modal.Title id="title_header_wallet">
             Fees & Commission Details
          </Modal.Title>
          
        </Modal.Header>
        <Modal.Body style={{padding: '1rem'}}>
            <div className="pdf_view">
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <div className="img_invoice">
                            <img style={{width: '100%'}} src={logo} alt="logo" />
                        </div>
                    </Grid>
                    <Grid item xs={8}>
                        <h6 className="title_invoice">{details?.payed_taxes ? 'Fees and Commission Invoice' : 'Tax Invoice'}</h6>
                        <div className="sub_invoice">Invoice no.: <div>{details?._id?.toUpperCase()}</div></div>
                        <div className="sub_invoice">Invoice date: <div>{dayjs(details?.createdAt).tz(userTimezone).format('MMM D, YYYY')}</div></div>
                        <div className="sub_invoice">Due: <div>{dayjs(details?.createdAt).tz(userTimezone).add(7, 'day').format('MMM D, YYYY')}</div></div>
                    </Grid>

                    
                    <Grid item xs={6}>
                        <h6 className="sub_invoice1">
                            <div>From</div>
                        </h6>
                        <h6 className="title_invoice1">
                            <div>
                            {details?.user_id?.first_name} {details?.user_id?.last_name}
                            </div>
                        </h6>
                        <p className="text_inv mb-0">{details?.user_id?.address}, <br/> {details?.user_id?.city}</p>
                        <p className="text_inv">{details?.user_id?.postal_code}, {details?.user_id?.country}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <h6 className="sub_invoice1 text-end ">
                            <div></div>
                            <div>To</div></h6>
                        <h6 className="title_invoice1 text-end ">
                        <div></div>
                        <div>Almiron Bet</div></h6>
                        <p className="text_inv text-end  mb-0">61 Republic Street Valletta, <br/>VLT </p>
                        <p className="text_inv text-end ">1117, Malta</p>
                    </Grid>
                </Grid>

                <table className="sum_table mt-4">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Funds</th>
                            <th>TAX</th>
                            <th>TOTAL, USD</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!details?.payed_taxes && details?.show_normal &&
                         <tr>
                            <td>Tax Fee</td>
                            <td>USD ${parseFloat(details?.amount?.$numberDecimal).toFixed(2)}</td>
                            <td>15%</td>
                            <td>USD ${parseFloat(details?.amount?.$numberDecimal).toFixed(2) * (15/100)}</td>
                        </tr>
                        }
                        {details?.show_custom && details?.fee_cal?.length > 0 &&
                            details?.fee_cal.map(f =>
                                <tr key={f.description}>
                                    <td>{f.description}</td>
                                    <td>USD ${parseFloat(f?.funds?.$numberDecimal).toFixed(2)}</td>
                                    <td>{parseFloat(f?.tax?.$numberDecimal).toFixed(2)}%</td>
                                    <td>USD ${parseFloat(f?.total?.$numberDecimal).toFixed(2)}</td>
                                </tr>
                            )     
                        }
                    </tbody>
                </table>

                <div className="pt-5">
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div className="sub_invoice mb-2">
                                <div>Payment instruction</div>
                            </div>
                            <p className="text_inv mb-0">Kassify Payment</p>
                            <p onClick={() => generateLink()} className="text_inv text_inv_link">Pay Here</p>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="sub_invoice text-end">
                                <div>Subtotal:</div>
                                <div className="text-end">${total.toFixed(2)} USD</div>
                            </div>
                            <div className="sub_invoice">
                                <div>Total:</div>
                                <div>${total.toFixed(2)} USD</div>
                            </div>
                            <div className="sub_invoice">
                                <div>Amount Paid:</div>
                                <div>${details?.payed_taxes ? total.toFixed(2) : '0.00'} USD</div>
                            </div>
                            <div className="due_invoice sub_invoice">
                                <div>
                                    Balance Due: 
                                </div>
                                <div>
                                ${!details?.payed_taxes ? total.toFixed(2) : '0.00'} USD
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}