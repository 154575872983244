import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PercentIcon from '@mui/icons-material/Percent';

export function CircularProgressWithLabel(props) {

    const color1 = props.color1;
    const color2 = props.color2;
    const type = props.type;

    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={(theme) => ({
                color: color1,
                ...theme.applyStyles('dark', {
                    color: color1,
                }),
                })}
                size={40}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={(theme) => ({
                    color: color2,
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                    ...theme.applyStyles('dark', {
                        color: color2,
                    }),
                })}
                size={40}
                thickness={4}
                {...props}
            />
            </Box>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: 'text.secondary' }}
          >
            {type === 'attack' ?
            <ChevronRightIcon style={{maxWidth: '1.2rem'}}  className="mb-2"/> :
            type === 'dangerous_attacks' ?
            <KeyboardDoubleArrowRightIcon style={{maxWidth: '1.2rem'}}  className="mb-2"/> :
            type === 'possession_rt' ?
            <PercentIcon style={{maxWidth: '1rem'}} className="mb-2"/> :
            ""
            }
          </Typography>
        </Box>
      </Box>
    );
  }