

export default function Selectable({selected, setSelected, values}){
    return(
        <div className="selectable_center">
            <div className="selectable_flex">
                {values && values.map(v =>
                    <div onClick={() => {setSelected(v.id)}} className={selected === v.id ? 'selected_selectable' : ''} key={v.id}>{v.name}</div>
                )}
            </div>
        </div>
    )
}