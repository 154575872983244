import { useParams } from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link} from '@mui/material';
import ImageWithFallback from '../../../helpers/ImageWithFallback';
import { useState, useEffect, Fragment} from 'react';
import axiosInstance from '../../../redux/axios';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBetSlip } from '../../../redux/reducers/UserReducer';
import { addInplay } from '../../../redux/reducers/SportsReducer';

import dayjs, { getUserTimezone } from '../../../helpers/UserTimestamp.js';

import StatsAndTimeLine from '../../../components/Sports/StatsAndTimeline';
import ScoreBoard from '../../../components/Sports/ScoreBoard';
import useMediaQuery from '@mui/material/useMediaQuery';

const timeStatus = [
    {id: '0', title:'Not Started'},
    {id: '1', title:'InPlay'},
    {id: '2', title:'TO BE FIXED'},
    {id: '3', title:'Ended'},
    {id: '4', title:'Postponed'},
    {id: '5', title:'Cancelled'},
    {id: '6', title:'Walkover'},
    {id: '7', title:'Interrupted'},
    {id: '8', title:'Abandoned'},
    {id: '9', title:'Retired'},
    {id: '10', title:'Suspended'},
    {id: '11', title:'Decided by FA'},
    {id: '99', title:'Removed'},
]

export default function Event({setOpenBets}){
    const matches = useMediaQuery('(max-width:600px)');
    const userTimezone = getUserTimezone();
    const { selected_sport, id } = useParams();
    const [event, setEvents] = useState(null)
    const { categories } = useSelector(state => state.sports);
    const { user } = useSelector(state => state.user);
    const [eventDetails, setEventDetails] = useState(null);
   

    const dispatch = useDispatch();

    const [team1Color, setTeam1] = useState('#EA2636');
    const [team2Color, setTeam2] = useState('#999999');

    useEffect(() => {
     if(event){
        if(event?.teamDetails){
            if(event?.teamDetails[0]){
                setTeam1(event?.teamDetails[0].color || '#EA2636')
            }
            if(event?.teamDetails[1]){
                setTeam2(event?.teamDetails[1].color || '#999999')
            }
        }
     }
    }, [event])


    useEffect(() => {
        async function getEvents() {
            try{
                const category = categories.find(x => {return x.name.toLowerCase() === selected_sport.toLowerCase()})
                const res = await axiosInstance.get('sport/events/'+category.id)
                if(res.data.data){
                    dispatch(addInplay(res.data?.data))

                    const allEvents = Object.values(res.data?.data).flatMap(league => league.events);

                    // Find the event with the given ID
                    const foundEvent = allEvents.find(event => event.id === id);
                    
                    if(foundEvent){
                        setEventDetails(foundEvent)
                    }
                }
            }catch{}
        }

        async function getEvent() {
            try{
                const res = await axiosInstance.get('sport/event/'+ id)
                
                if(res?.data?.data){
                    setEvents(res.data.data)
                }
                if(res?.data?.details){
                    setEventDetails(res?.data?.details)
                }else{
                    getEvents()
                }
            }catch{}
        }

        getEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

   


    const calOdd = (odd) => {
        try{
        const [numerator, denominator] = odd.split("/").map(Number);
        const result = numerator / denominator;
    
        return isNaN(result) ? 0 : parseFloat(result + 1)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }catch{
            return 0;
        }
    }

    function sortByOrder(data) {
        return Object.keys(data.reduce((acc, item) => {
          acc[item.order] = acc[item.order] || [];
          acc[item.order].push(item);
          return acc;
        }, {}))
        .sort((a, b) => a - b)
        .flatMap(order => data.filter(item => item.order === order));
      }
      
    const addToSlip = (teamName, number, odd, e, type) => {
        if(odd?.OD && user){
            dispatch(toggleBetSlip({
                event: eventDetails ? eventDetails : event?.event,
                team: {
                    name: teamName,
                    number: number
                },
                odd: {
                    odd: odd.OD,
                    su: odd.SU
                },
                type: type,
                comes_from: 'event',
                input: 0
            }))
            setOpenBets(true)
        }
    }
    const findNameByOrder = (arr, order) => {
        const result = arr.find(item => item.order === order && item.name);
        return result ? result.name : null;
      };


    const calculateName = (name, title, i ,od, o, odds) => {
        let val = '';
       
        if(od !== ''){
            if(name){
                val = name;
            }else{
                if(title.startsWith('Match Time Result')){
                    
                    if(i % 4 === 1){
                        val = event.teamDetails[0]?.name
                    }else if(i % 4 === 2){
                        val = 'Draw'
                    }else{
                        val = event.teamDetails[1]?.name
                    }
                }else if(title === 'Corners Race'){
                    
                    if(i % 4 === 1){
                        val = event.teamDetails[0]?.name
                    }else if(i % 4 === 2){
                        val = event.teamDetails[1]?.name
                    }else{
                        val = 'Neither'
                    }
                }else if(title ==='Corners'){
                    i % 3 === 1 ? val = event.teamDetails[0]?.name : val= event.teamDetails[1]?.name
                }else if(title !== 'Match Corners' && title !== '1st Half Corners' && title !== '2nd Half Corners'){
                    
                    if(i % 3 === 1){
                        val = title.startsWith('Time of 1st Goal') || title.includes('Time of') ? 'Goal Before' : title === 'Result / Both Teams To Score' ? 'Yes' : 'Over'
                      
                    }else{
                        val = title.startsWith('Time of 1st Goal') || title.includes('Time of') ? 'No Goal Before' : title === 'Result / Both Teams To Score' ? 'No' : 'Under'
                    }
                   
                }else{
                    val = i % 4 === 1 ? 'Over' : i % 4 === 2 ? 'Exactly' : 'Under'
                }
            }
        }else{
            val = ''
        }
        let name1 = ''
        if(odds){
            name1 = findNameByOrder(odds, o.order)
        }
        return val + ' > ' + name1;
    }

    const calculateName1 = (name, title, i, hd) => {
        let val = '';

        if(name){
            val = name
        }else{
            if(title === '3-Way Handicap' || title.startsWith('1st Half - Handicap') || title.startsWith('2nd Half - Handicap')){
               val = (i%3 === 0 ?
                event.teamDetails[0]?.name + (title.startsWith('Asian Handicap') ? '' : ' Reservers')
                :
                i % 3 === 1 ? 'Draw' :
                event.teamDetails[1]?.name + (title.startsWith('Asian Handicap') ? '' : ' Reservers'))
            }else if(i%2 === 0){
                val = event.teamDetails[0]?.name + (title.startsWith('Asian Handicap') ? '' : ' Reservers')
            }else{
                val = event.teamDetails[1]?.name + (title.startsWith('Asian Handicap') ? '' : ' Reservers')
            }
        }

        return val + '>' + hd;
    }

    const calClean = (score, title, i, name) => {
        let val = ''

        if(name){
            val = name;
        }else{
            if(i%2 === 0){
                val = 'Yes'
            }else{
                val = 'No'
            }
    }

        if(i > 2 && score?.includes('-0')){
            return val + ' > ' + event.teamDetails[1]?.name
        }else if (i < 2 && score?.includes('0-')){
            return val + ' < ' + event.teamDetails[0]?.name
        }

        return val;
    }

    
    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div className="event_t"> 
                        <ImageWithFallback 
                        style={{width: '1.4rem', marginRight: '.5rem', marginBottom: '.3rem'}}
                        src={`/static/sports/${selected_sport}.svg`}
                        alt={selected_sport}
                        fallbackSrc="/static/sports/Other.svg"
                        />
                       {event ? event.teamDetails[0]?.name + ' vs ' +  event.teamDetails[1]?.name  : 'Game'}
                    </div>
                    <div className="pb-2" style={{fontSize: '.9rem', color: 'gray'}}>
                    {eventDetails &&
                        <>{dayjs.unix(eventDetails?.time).tz(userTimezone).format('ddd, MMM D, HH:mm')}</>
                    }
                    </div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play/sport'}>
                            Home
                        </Link> 
                        <Link underline="hover" color="inherit" href={'/play/sport/'+selected_sport}>
                            {selected_sport}
                        </Link> 
                        <Typography color="text.primary"> {eventDetails ? eventDetails.league?.name : 'League'}</Typography>
                    </Breadcrumbs>
                </Container>
            </div>

            <Container style={{padding: '1rem', paddingTop: '2rem', paddingBottom: '2rem'}}>
                {!matches &&
                <div className="grid_ev1 mb-1 mt-1">
                    <ScoreBoard eventDetails={eventDetails} team1Color={team1Color} team2Color={team2Color} event={event} timeStatus={timeStatus} />
                </div>
                }

                {eventDetails &&
                    <StatsAndTimeLine eventDetails={eventDetails} event={event} timeStatus={timeStatus} />
                }
                {event?.currentMGs?.map(x =>
                    <Fragment key={x.title}>
                        {x.title !== 'Goalscorers' && x.title !== 'Multi Scorers'  && x.title !== 'Number of Cards' &&
                        <Accordion defaultExpanded className="acc_events mb-2">
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id={x.title}
                        >
                        <Typography className="title_home_selected" sx={{ width: '33%', flexShrink: 0 }}>
                            {x.title}
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {(x.title !== 'Match Goals' && x.title !== 'Match Corners' && x.title !== 'Alternative Match Goals') && !x.title.startsWith('Asian Handicap') && x.title !== '1st Half Goals' &&
                            !x.title.startsWith('Goal Line') && !x.title.startsWith('1st Half Goal Line') && x.title !== 'Result / Both Teams To Score' && x.title !== 'Method of Victory' && !x.title.startsWith('3-Way Handicap') &&
                            x.title !== '2-Way Corners' && x.title !== 'Asian Corners' && x.title !== 'Corners Race' && x.title !== 'Corners' && x.title !== 'Team Clean Sheet' &&
                            x.title !== event.teamDetails[0]?.name + ' Goals' && x.title !== event.teamDetails[1]?.name + ' Goals' && x.title !== 'Team to Score in 2nd Half' &&
                            x.title !== '1st Half Corners' && x.title !== '2nd Half Corners' && !x.title.startsWith('1st Half Asian Handicap') && !x.title.startsWith('2st Half Asian Handicap') && x.title !== '1st Half Asian Corners' &&
                            x.title !== 'Team to Score in Both Halfs' && x.title !== 'Team to Score in 1st Half' && !x.title.startsWith('Next 10 Minutes') && !x.title.startsWith('Match Time Result') && !x.title.startsWith('Time of 1st Goal') &&
                            !x.title.startsWith('1st Half - Handicap')  && !x.title.includes('Time of') && !x.title.includes('Goalscorers') && x.title !== 'Penalties Converted in Shootout Over/Under' && !x.title.includes('Converted in Shootout') &&
                            x.title !== 'Total Penalties Taken in Shootout' && x.title !== 'Team to Score in Both Halves' &&
                            <Grid container spacing={2} style={{minWidth: '100%'}}>
                                {x?.odds && x.odds?.length > 0 && x.odds.map(o =>
                                        <Grid key={o.odd+'i'+o.name+'k'+o.id} item xs={x.odds.length < 6 ? (12/x.odds.length): 4}>
                                            <div onClick={() => addToSlip(o.name, (o.name?.toLowerCase().includes(event.teamDetails[1]?.name?.toLowerCase()) ? '2' :o.name?.toLowerCase().includes(event.teamDetails[0]?.name?.toLowerCase()) ? '1' : '0'), o, event, x.title)} className="live_bets">
                                                <div>{o?.name}</div>
                                                <div>{o?.OD ? calOdd(o?.OD) : ''}</div>
                                            </div>
                                        </Grid>
                                
                                )}
                            </Grid>
                            }
                            {(x.title === 'Match Goals' || x.title === 'Match Corners' || x.title === 'Alternative Match Goals' || x.title === '1st Half Goals' || x.title.startsWith('Goal Line')
                            || x.title.startsWith('1st Half Goal Line') || x.title === 'Result / Both Teams To Score' || x.title === 'Method of Victory' || x.title === '2-Way Corners' ||
                            x.title === 'Asian Corners' || x.title === 'Corners Race' || x.title === 'Corners' ||  x.title === event.teamDetails[0]?.name + ' Goals' ||  x.title === event.teamDetails[1]?.name + ' Goals' ||
                            x.title === '1st Half Corners' || x.title === '2nd Half Corners' || x.title === '1st Half Asian Corners' || x.title.startsWith('Next 10 Minutes') || x.title.startsWith('Match Time Result') ||
                            x.title.startsWith('Time of 1st Goal') || x.title.includes('Time of') || x.title === 'Penalties Converted in Shootout Over/Under' || x.title.includes('Converted in Shootout') || x.title === 'Total Penalties Taken in Shootout')  &&  
                            <Grid container spacing={2} style={{minWidth: '100%'}}>
                                {x?.odds && x.odds?.length > 0 && sortByOrder(x.odds).map((o,i) =>
                                        <Grid key={o.odd+'i'+o.name+'k'+o.id} item xs={x.title === 'Match Corners' || x.title === 'Corners Race' || x.title === '1st Half Corners' || x.title === '2nd Half Corners' || x.title.startsWith('Match Time Result') ? 3 :(4)}>
                                            <div 
                                            onClick = {() => addToSlip(calculateName(o.name, x.title, i, o?.OD, o, x.odds), 
                                                calculateName(o.name, x.title, i, o?.OD, o, x.odds), 
                                                o, event, x.title)}
                                            className={(o.id === '' || !o?.OD) ? "first_bets" : "live_bets"}>
                                                <div>{o?.OD !== '' ? o?.name ? o?.name : 
                                                    x.title.startsWith('Match Time Result') ? 
                                                    i % 4 === 1 ? event.teamDetails[0]?.name : i % 4 === 2 ? 'Draw' : event.teamDetails[1]?.name
                                                    :
                                                    x.title === 'Corners Race' ? 
                                                    i % 4 === 1 ? event.teamDetails[0]?.name : i % 4 === 2 ? event.teamDetails[1]?.name : 'Neither'
                                                    :
                                                    x.title ==='Corners' ? 
                                                    i % 3 === 1 ? event.teamDetails[0]?.name : event.teamDetails[1]?.name
                                                    :
                                                    x.title !== 'Match Corners' && x.title !== '1st Half Corners' && x.title !== '2nd Half Corners' ?
                                                    i % 3 === 1 ?
                                                    x.title.startsWith('Time of 1st Goal') || x.title.includes('Time of') ? 'Goal Before' : x.title === 'Result / Both Teams To Score' ? 'Yes' : 'Over'
                                                    :
                                                    x.title.startsWith('Time of 1st Goal') || x.title.includes('Time of') ? 'No Goal Before' : x.title === 'Result / Both Teams To Score' ? 'No' : 'Under'
                                                    : i % 4 === 1 ? 'Over' : i % 4 === 2 ? 'Exactly' : 'Under' : ''
                                                    }</div>
                                                <div>{o?.OD ? calOdd(o?.OD) : ''}</div>
                                            </div>
                                        </Grid>
                                )}
                            </Grid>
                            }

                            {(x.title.startsWith('Asian Handicap') ||x.title === '3-Way Handicap' || x.title.startsWith('1st Half Asian Handicap') || x.title.startsWith('2st Half Asian Handicap') || x.title.startsWith('1st Half - Handicap')) &&
                                <Grid container spacing={2} style={{minWidth: '100%'}}>
                                    {x?.odds && x.odds?.length > 0 && sortByOrder(x.odds).map((o,i) =>
                                            <Grid key={o.odd+'i'+o.name+'k'+o.id} item xs={(x.title === '3-Way Handicap' || x.title.startsWith('1st Half - Handicap') || x.title.startsWith('2nd Half - Handicap') ? 4 : 6)}>
                                                <div
                                                    onClick = {() => addToSlip(calculateName1(o.name, x.title, i, o?.hd), 
                                                    calculateName1(o.name, x.title, i, o?.hd), 
                                                    o, event, x.title)}
                                                className={o.id === '' ? "first_bets" : "live_bets"}>
                                                    <div>{o?.name ? o?.name : 
                                                        (x.title === '3-Way Handicap' || x.title.startsWith('1st Half - Handicap') || x.title.startsWith('2nd Half - Handicap')) ?
                                                        i%3 === 0 ?
                                                            event.teamDetails[0]?.name + (x.title.startsWith('Asian Handicap') ? '' : ' Reservers')
                                                            :
                                                            i % 3 === 1 ? 'Draw' :
                                                            event.teamDetails[1]?.name + (x.title.startsWith('Asian Handicap') ? '' : ' Reservers')
                                                        :
                                                        i%2 === 0 ?
                                                        event.teamDetails[0]?.name + (x.title.startsWith('Asian Handicap') ? '' : ' Reservers')
                                                        :
                                                        event.teamDetails[1]?.name + (x.title.startsWith('Asian Handicap') ? '' : ' Reservers')
                                                        }</div>
                                                    <div><span className="handicap_cal">{o?.hd ? o?.hd : ''}</span>{o?.OD ? calOdd(o?.OD) : ''}</div>
                                                </div>
                                            </Grid>
                                    )}
                                </Grid>
                            }

                            
                            {(x.title === 'Team Clean Sheet' || x.title === 'Team to Score in Both Halves') &&
                            <>
                                <Grid container spacing={2} >
                                    <Grid item xs={6}>
                                        <div className="stats_desc">
                                        {event.teamDetails[0]?.name}</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="stats_desc">
                                        {event.teamDetails[1]?.name}</div>
                                    </Grid>
                                </Grid>
                            
                            
                                <Grid container spacing={2} style={{minWidth: '100%'}}>
                                    {x?.odds && x.odds?.length > 0 && x.odds.map((o,i) =>
                                            <Grid key={o.odd+'i'+o.name+'k'+o.id} item xs={3}>
                                                <div onClick = {() => addToSlip(calClean(event.event?.score, x.title, i, o?.name),
                                                calClean(event.event?.score, x.title, i, o?.name),
                                                o, event, x.title)}
                                                className="live_bets">
                                                    <div>{o?.name ? o.name :
                                                        
                                                        i%2 === 0 ? 'Yes' : 'No'
                                                        
                                                        }</div>
                                                    <div>{o?.OD ? calOdd(o?.OD) : ''}</div>
                                                </div>
                                            </Grid>
                                    
                                    )}
                                </Grid>
                            </>
                            }

                            {(x.title === 'Team to Score in 2nd Half' || x.title === 'Team to Score in Both Halfs' || x.title === 'Team to Score in 1st Half') &&
                            <>
                            
                                <Grid container spacing={2} style={{minWidth: '100%'}}>
                                    <Grid className="pt-0" item xs={6}>
                                    <div className="stats_desc">{event.teamDetails[0]?.name}</div></Grid>
                                    <Grid className="pt-0" item xs={6}>
                                    <div className="stats_desc">{event.teamDetails[1]?.name}</div></Grid>
                                </Grid>
                            
                                <Grid container spacing={2} style={{minWidth: '100%'}}>
                                    {x?.odds && x.odds?.length > 0 && x.odds.map((o,i) =>
                                            <Grid key={o.odd+'i'+o.name+'k'+o.id} item xs={3}>
                                                <div onClick = {() => addToSlip(calClean(event.event?.score, x.title, i, o?.name),
                                                calClean(event.event?.score, x.title, i, o?.name),
                                                o, event, x.title)}
                                                className="live_bets">
                                                    <div>{o?.name ? o.name : 
                                                        i%2 === 0 ? 'Yes' : 'No'
                                                        }</div>
                                                    <div>{o?.OD ? calOdd(o?.OD) : ''}</div>
                                                </div>
                                            </Grid>
                                    
                                    )}
                                </Grid>
                            </>
                            }
                        </AccordionDetails>
                    </Accordion>
                    }
                </Fragment>
                    
                )}

                {!event &&
                    <div className="display_search_autocomplete display_nonefound_mobile"> No data to display for this event </div>
                }
            </Container>
        </div>
    )
}