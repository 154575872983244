import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {FormControl, OutlinedInput,InputAdornment } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState, useEffect } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import axiosInstance from '../../redux/axios';
import FormHelperText from '@mui/material/FormHelperText';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import { updateUserInfo } from '../../redux/reducers/UserReducer';

import BuyFiatEmbed from './BuyFiatEmbed';

export default function UserDeposit({onHide, wallet}){
    const {user} = useSelector(state => state.user);
    const [copied, setCopied] = useState(false);
    const [txHash, setTxHash] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [deposit_options, setDepositOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState({
        method: 'Pay with Credit Card',
        network: 'Thirdweb',
        receiver_address: '0x6da165C21b6D4C579b42d3EC0EFF890faFA92f64',
        token: 'USDbC',
        token_address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
        _id: '-1'
    });
    

    const dispatch = useDispatch();
    const copyAddress = (val) => {
        try{
            const textarea = document.createElement("textarea");
            textarea.value = val;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
  
            setCopied(true);
  
            setTimeout(() => {
                setCopied(false);
            }, 1000)
        }catch{}
    }

    useEffect(() => {
        const getDepositOptions =  async () => {
              try{
                  const response = await axiosInstance.get('deposits/options');
                    
                  if(response.data?.options){
                    setDepositOptions(response.data.options)
                  }
              }catch{}
        }
        getDepositOptions()
    }, [])

    useEffect(() => {
        setSuccess('')
        setError('')
    }, [txHash])

    useEffect(() => {
        if(error!== ''){
            setSuccess('')
        }
    }, [error])

    useEffect(() => {
        if(success!== ''){
            setError('')
        }
    }, [success])

    const deposit = async () => {
        if(txHash !== '' && selectedOption){
            const data ={
                transactionHash: txHash,
                option_id: selectedOption?._id
            }
            try{
                const response = await axiosInstance.post('deposits', data);
                
                if(response.data?.user && response.data?.data){
                    setSuccess(`Funds of $${response.data?.data.amount?.$numberDecimal} USD added successfully`);
                    dispatch(updateUserInfo(response.data?.user))
                }
            }catch(err) {
                if(err?.response?.data){
                    const errs = err?.response?.data?.errors;
                    if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                        if(Object.keys(errs).length === 0){
                            if(err.response.data?.message){
                                setError(err.response.data?.message)
                            }else{
                                setError(err.response?.statusText)
                            }
                        }else{
                            setError(Object.values(errs).join(' ,'))
                        }
                    }else{
                        if(err.response.data?.message){
                            setError(err.response.data?.message)
                        }else{
                            setError(err.response?.statusText)
                        }
                    }
                }
            }
        }else{
            setError('Fill in the form.')
        }
    }

    return(
        <div>
             <Form.Group className="mb-3" controlId="withdrawForm.gateway">
                <Form.Label>Method</Form.Label>
                <ThemeProvider bsPrefix="super-select">
                <Form.Select className="select_deposit" value={selectedOption ? selectedOption._id : ""}  onChange={e => {
                        const selectedId = e.target.value;
                        const selectedObj = deposit_options.find(option => option._id === selectedId);
                        if(selectedId === '-1'){
                            setSelectedOption({
                                method: 'Pay with Credit Card',
                                network: 'Thirdweb',
                                receiver_address: '0x6da165C21b6D4C579b42d3EC0EFF890faFA92f64',
                                token: 'USDbC',
                                token_address: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
                                _id: selectedId
                            })
                        }else{
                            setSelectedOption(selectedObj);
                        }
                    }}  aria-label="countries">

                    <option value="">Select Getaway</option>
                    <option value="-1">Pay with Credit Card</option>
                    {user?.wallet_address &&
                        deposit_options.map(m =>
                            <option key={m._id} value={m._id}>{m.method}</option>
                        )
                    }
                </Form.Select>
                </ThemeProvider>
            </Form.Group>
            
            {selectedOption && selectedOption._id !== '-1' && 
            <>
                <Form.Group data-bs-theme="dark"  className="mt-4" controlId="deposit.receiver">
                    <Form.Label>Receiver Address</Form.Label>
                    <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                        <OutlinedInput
                        className="input_modal"
                        type="text"
                        value={selectedOption ? selectedOption.receiver_address : '0x00'}
                        disabled={true}
                        endAdornment={
                        <InputAdornment position="end">
                            {copied ? 
                            <DoneIcon style={{color: 'green'}}/> :
                            <ContentCopyIcon onClick={() => copyAddress(selectedOption ? selectedOption.receiver_address : '0x00')} className="copy_icon"/>
                            }
                        </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        />
                    </FormControl>
                    <FormHelperText sx={{marginLeft: '.5rem'}} id="component-helper-text">
                        <span style={{fontSize: '0.65rem'}}>
                        Send the amount funds to this address. Make sure the address you send from is the same as your profile wallet address
                        </span>
                    </FormHelperText>
                </Form.Group>

                <Form.Group data-bs-theme="dark"  className="mb-1 mt-2" controlId="deposit.tx">
                    <Form.Label>TxHash</Form.Label>
                    <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                        <OutlinedInput
                        className="input_modal"
                        type="text"
                        value={txHash}
                        onChange={e => setTxHash(e.target.value)}
                        disabled={!user?.is_step_one_completed && !user?.wallet_address}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        />
                    </FormControl>
                    
                    {error !== '' && <div className="error_p pt-2">{error}</div>}
                    {success !== '' && <div className="success_p pt-2">{success}</div>}
                </Form.Group>

                <Button onClick={() => deposit()} className="submit_btn full_width mb-3">Deposit</Button>
            </>
            }
            {selectedOption && selectedOption._id  === '-1' &&
                <>  
                <BuyFiatEmbed wallet={wallet} selectedOption={selectedOption}/>
                {/** 
                    <RampNetwork selectedOption={selectedOption}/>
                    */}
                    
                </>
            }
           
        </div>
    )
}