import { Form } from 'react-bootstrap';
import {FormControl, OutlinedInput,InputAdornment, ButtonGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import axiosInstance from '../../redux/axios';
import { updateUserInfo } from '../../redux/reducers/UserReducer';
import { Button } from 'react-bootstrap';

const defaultVal = 0;
export default function UserTransferPlayer({amountCredits, setAmountCredits, player, setPlayer, setActiveStep}){
    const {user} = useSelector(state => state.user);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        setSuccess('')
        setError('')
    }, [player, amountCredits])

    useEffect(() => {
        if(error!== ''){
            setSuccess('')
        }
    }, [error])

    useEffect(() => {
        if(success!== ''){
            setError('')
        }
    }, [success])

    const transferToPlayer = async () => {
        if(player !== '' && amountCredits !== ''){
            if(parseFloat(amountCredits) > 0){
                if(parseFloat(amountCredits) <= user?.balance.$numberDecimal){
                    try{
                        const response = await axiosInstance.post('deposits/transfer-player/'+player, {amount: amountCredits});
                
                        if(response.data?.user){
                            setSuccess(`Funds of $${amountCredits} USD send to ${player}`);
                            dispatch(updateUserInfo(response.data?.user))
                        }
                    }catch(err) {
                        if(err?.response?.data){
                            const errs = err?.response?.data?.errors;
                            if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                                if(Object.keys(errs).length === 0){
                                    if(err.response.data?.message){
                                        setError(err.response.data?.message)
                                    }else{
                                        setError(err.response?.statusText)
                                    }
                                }else{
                                    setError(Object.values(errs).join(' ,'))
                                }
                            }else{
                                if(err.response.data?.message){
                                    setError(err.response.data?.message)
                                }else{
                                    setError(err.response?.statusText)
                                }
                            }
                        }
                    }
                }else{
                    setError('Insufficient balance');
                }
            }else{
                setError('Amount must be greater than 0')
            }
        }else{
            setError('Fill in the form.')
        }
    }
    
    return(
        <div>
            <div className="display_column full_width pb-3 pt-3">
                <Form.Label>
                    <div className="display_spacebetween">
                    <div>ERC20 Wallet Address</div>
                    <div></div>
                    </div>
                </Form.Label>   
                <ButtonGroup aria-label="Basic example">
                    <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="text"
                        value={player}
                        onChange={(e) => setPlayer(e.target.value)}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                        placeholder="wallet address"
                    />
                    </FormControl>
                </ButtonGroup>
            </div>
            <div className="display_column full_width pb-3">
                <Form.Label>
                    <div className="display_spacebetween">
                    <div>Amount</div>
                    <div>${parseFloat(user?.balance?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </div>
                </Form.Label>   
                <ButtonGroup aria-label="Basic example">
                    <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="number"
                        value={amountCredits}
                        onChange={(e) => setAmountCredits(e.target.value)}
                        min={defaultVal}
                        endAdornment={<InputAdornment position="end">USD</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                    </FormControl>
                    <Button className="modal_btn" onClick={()=> setAmountCredits(parseFloat(user?.balance?.$numberDecimal).toFixed(2))} variant="contained">MAX</Button>
                </ButtonGroup>
            </div>

            {error !== '' && <div className="error_p pt-2">{error}</div>}
            {success !== '' && <div className="success_p pt-2">{success}</div>}

            <Button className="submit_btn full_width" style={{color: 'white'}} onClick={() => transferToPlayer()}>Transfer USD</Button>
            <p className="helper_modal_p">Not enough USD for transfer? <span onClick={() => {setActiveStep(0)}}>Get USD</span></p>
        </div>
    )
}