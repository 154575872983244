import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import BootstrapContainer from 'react-bootstrap/Container';

import {Breadcrumbs, Typography, Link} from '@mui/material';
import ImageWithFallback from '../../../helpers/ImageWithFallback';
import { useState, useEffect, Fragment} from 'react';
import axiosInstance from '../../../redux/axios';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBetSlip } from '../../../redux/reducers/UserReducer';

import dayjs, { getUserTimezone } from '../../../helpers/UserTimestamp.js';
import YouTubeEmbed from '../../../helpers/YoutubeEmbeded';

const timeStatus = [
    {id: '0', title:'Not Started'},
    {id: '1', title:'InPlay'},
    {id: '2', title:'TO BE FIXED'},
    {id: '3', title:'Ended'},
    {id: '4', title:'Postponed'},
    {id: '5', title:'Cancelled'},
    {id: '6', title:'Walkover'},
    {id: '7', title:'Interrupted'},
    {id: '8', title:'Abandoned'},
    {id: '9', title:'Retired'},
    {id: '10', title:'Suspended'},
    {id: '11', title:'Decided by FA'},
    {id: '99', title:'Removed'},
]


export default function CEvent({setOpenBets}){
    const userTimezone = getUserTimezone();

    const { selected_sport, id } = useParams();
    const [event, setEvent] = useState(null);
    const { user } = useSelector(state => state.user);
    console.log(selected_sport)
    const [timeElapsed, setTimeElapsed] = useState(1);
    const [status, setStatus] = useState('');
    const [halfTimeRemaining, setHalfTimeRemaining] = useState(15 * 60);
    const [timeToStart, setTimeToStart] = useState(null);

    const [videoSeconds, setVideoSeconds] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        async function getEvent() {
            try{
                const res = await axiosInstance.get('sport/cevent/'+ id)
               
                if(res?.data?.data){
                    setEvent(res.data.data)
                }
            }catch{}
        }

        getEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sortedData = (data) => {
        // Create a new array to avoid mutating the original
        return [...data].sort((a, b) => {
            // Handle the case for -1 first
            if (a.order === '-1' && b.order !== '-1') return -1; // a comes first
            if (b.order === '-1' && a.order !== '-1') return 1;  // b comes first
            
            // For the rest, compare by order values
            return parseInt(a.order) - parseInt(b.order);
        });
    };
      
    const addToSlip = (teamName, number, odd, e, type) => {
        
        if(odd && user){
            const tempe = {...event}
            tempe.time_status = event.time_status.toString()
            tempe.league = {
                name: tempe.name || tempe.league?.name
            }
            dispatch(toggleBetSlip({
                event: tempe,
                team: {
                    name: teamName,
                    number: number
                },
                odd: {
                    odd: odd,
                    su: '1'
                },
                type: type,
                comes_from: 'cevent',
                input: 0
            }))
            setOpenBets(true)
        }
    }
    const firstHalfDuration = parseFloat(event?.first_half_duration?.$numberDecimal) * 60 || 45 * 60; // 45 minutes in seconds
    const halfTimeDuration = parseFloat(event?.timeout_duration?.$numberDecimal) * 60 || 15 * 60; // 15 minutes in seconds
    const secondHalfDuration = parseFloat(event?.second_half_duration?.$numberDecimal) * 60 || 45 * 60; // 45 minutes in seconds
    

    useEffect(() => {
        if (!event?.startsAt) return;  // Ensure event start time is available

        const eventStart = dayjs(event?.startsAt).tz(userTimezone);  // Get the start time of the event

        const calculateElapsedTime = () => {
            const now = dayjs();  // Get the current time
            const totalElapsedSeconds = now.diff(eventStart, 'seconds');  // Time difference in seconds
            const timeUntilStart = eventStart.diff(now, 'seconds');


            if (totalElapsedSeconds < 0) {
                // Event hasn't started yet
                setStatus('UPCOMING');
                setTimeToStart(timeUntilStart);
                setVideoSeconds(-1)
            } else if (totalElapsedSeconds < firstHalfDuration) {
                // First half
                setStatus('INPLAY');
                setTimeElapsed(totalElapsedSeconds);
                setVideoSeconds(totalElapsedSeconds);
            } else if (totalElapsedSeconds < firstHalfDuration + halfTimeDuration) {
                // Halftime countdown
                setStatus('HALF-TIME');
                const timePassedSinceFirstHalf = totalElapsedSeconds - firstHalfDuration;
                setHalfTimeRemaining(halfTimeDuration - timePassedSinceFirstHalf);
                setTimeElapsed(firstHalfDuration);  // Pause time at 45 minutes during halftime
                
                setVideoSeconds(totalElapsedSeconds)
            } else if (totalElapsedSeconds < firstHalfDuration + halfTimeDuration + secondHalfDuration) {
                // Second half
                setStatus('INPLAY');
                setTimeElapsed(totalElapsedSeconds - halfTimeDuration);  // Continue counting from 45 minutes after halftime
                
                setVideoSeconds(totalElapsedSeconds)
            } else {
                // Event finished
                setStatus('COMPLETED');
                setTimeElapsed(firstHalfDuration + secondHalfDuration);

                
                setVideoSeconds(-1)
            }
        };

        const interval = setInterval(calculateElapsedTime, 1000);  // Update every second

        return () => clearInterval(interval);  // Cleanup interval on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, userTimezone]); 

    const padWithZero = (num) => {
        return num < 10 ? `0${num}` : `${num}`;
    };

    const minutes = padWithZero(parseFloat(Math.floor(timeElapsed / 60)).toFixed(0));
    const seconds = padWithZero(parseFloat(timeElapsed % 60).toFixed(0));

    const halfTimeMinutes = padWithZero(parseFloat(Math.floor(halfTimeRemaining / 60)).toFixed(0));
    const halfTimeSeconds = padWithZero(parseFloat(halfTimeRemaining % 60).toFixed(0));

    const timeToStartDays = padWithZero(parseFloat(Math.floor(timeToStart / (60 * 60 * 24))).toFixed(0));
    const timeToStartHours = padWithZero(parseFloat(Math.floor((timeToStart % (60 * 60 * 24)) / (60 * 60))).toFixed(0));
    const timeToStartMinutes = padWithZero(parseFloat(Math.floor((timeToStart % (60 * 60)) / 60)).toFixed(0));
    const timeToStartSeconds = padWithZero(parseFloat(timeToStart % 60).toFixed(0));

    function hasTimePassed(targetTime) {
        const targetDate = dayjs(targetTime).tz(userTimezone);
        const now = dayjs();
        return now.diff(targetDate, 'seconds') >= 0; // Returns true if the target time has passed
    }

    return(
        <div className="main_page">
            {event &&
            <>
            <div className="header_page">
                <BootstrapContainer>
                   <div className="event_t"> 
                        <ImageWithFallback 
                        style={{width: '1.4rem', marginRight: '.5rem', marginBottom: '.3rem'}}
                        src={`/static/sports/${selected_sport}.svg`}
                        alt={selected_sport}
                        fallbackSrc="/static/sports/Other.svg"
                        />
                       {event ? event.home?.name + ' vs ' +  event.away?.name  : 'Game'}
                    </div>
                    <div className="pb-2" style={{fontSize: '.9rem', color: 'gray'}}>
                    {event &&
                        <>{dayjs(event.startsAt).tz(userTimezone).format('ddd, MMM D, HH:mm')}</>
                    }
                    </div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play/sport'}>
                            Home
                        </Link> 
                        <Link underline="hover" color="inherit" href={'/play/sport/'+selected_sport}>
                            {selected_sport}
                        </Link> 
                        <Typography color="text.primary"> {event ? event.league?.name : 'League'}</Typography>
                    </Breadcrumbs>
                </BootstrapContainer>
            </div>

            <BootstrapContainer style={{padding: '1rem', paddingTop: '2rem', paddingBottom: '2rem'}}>
                    {event &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Container maxWidth="md">
                                <div className="grid_ev1 mb-5 mt-1">
                                    <Grid style={{minWidth: '100%'}} container spacing={2}>
                                            <Grid item xs={4}>
                                                <div className="event_home">
                                                    {event.home?.name}
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="event_score">
                                                    <div style={{textAlign: 'center'}}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                                            {/* INPLAY or HALF-TIME */}
                                                            {event && timeStatus.find(x => x.id === event.time_status?.toString())?.title === 'InPlay' &&
                                                                <>
                                                                    {status === 'INPLAY' && (
                                                                        <div>
                                                                            <div>InPlay</div>
                                                                            <div style={{ textAlign: 'center' }}>{`${minutes}m : ${seconds}s`}</div>
                                                                        </div>
                                                                    )}
                                                                    {status === 'HALF-TIME' && (
                                                                        <div>
                                                                            <div>Half-Time</div>
                                                                            <div style={{ textAlign: 'center' }}>{`${halfTimeMinutes}m : ${halfTimeSeconds}s`}</div>
                                                                        </div>
                                                                    )}
                                                                    {status !== 'INPLAY' && status!== 'HALF-TIME' &&
                                                                        <div>
                                                                            <div>Ended</div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }

                                                            {/* Not Started - Countdown */}
                                                            {event && timeStatus.find(x => x.id === event.time_status?.toString())?.title === 'Not Started' &&
                                                                <div>
                                                                    <div>Not Started</div>
                                                                    <div>{`${timeToStartDays > 0 ? timeToStartDays + 'd :' : ''} ${timeToStartHours > 0 ? timeToStartHours + 'h :' : ''} ${timeToStartMinutes}m : ${timeToStartSeconds}s`}</div>
                                                                </div>
                                                            }

                                                            {/* Else case - Show time status title */}
                                                            {event && !['InPlay', 'Not Started'].includes(timeStatus.find(x => x.id === event.time_status?.toString())?.title) &&
                                                                <div>
                                                                    <div>{timeStatus.find(x => x.id === event.time_status?.toString())?.title}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>{event ? event.ss.replace('-', ' : ') : ''} </div>
                                                    <div></div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className="event_away">
                                                    {event.away?.name}
                                                </div>
                                            </Grid>
                                    </Grid>
                                </div>
                            </Container>
                           
                            <Container maxWidth="md">
                                {event?.time_status === 1 && event?.videoId && videoSeconds >= 0 &&
                                    <div className="mb-3">
                                        <YouTubeEmbed videoId={event?.videoId} timeElapsed={videoSeconds} />
                                    </div>
                                }
                            </Container>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {event?.MGs && event?.MGs?.map(item =>
                            <Fragment key={item.title}>
                                {(!hasTimePassed(item.valid)) && item.title !== 'Goalscorers' && item.title !== 'Multi Scorers'  && item.title !== 'Number of Cards' &&
                                <Accordion className="acc_events mb-2" defaultExpanded>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id={item.title}
                                >
                                <Typography className="title_home_selected" sx={{ width: '33%', flexShrink: 0 }}>
                                    {item.title}
                                </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                        <Grid container spacing={2} style={{minWidth: '100%'}}>
                                        {item.odds && item.type !== 6 && item.type !== 8 && item.odds.map((od,i) =>
                                            <Fragment key={od.OD+od.order+od.name+od.title}>
                                                {item.type === 1 &&
                                                od?.OD !== "0" &&
                                                    <Grid item xs={selected_sport === 'Politics' ? 12 / item.odds.filter(x => {return x.OD !== "0"}).length : 4}>
                                                        <div onClick={() => od?.OD ? addToSlip(od?.name + '>' + item.odds[0]?.name, i, parseFloat(od?.OD).toFixed(2), event, item.title) : {}} className={!od?.OD ? "first_bets" : "live_bets"}>
                                                            <div>{od?.name}</div>
                                                            <div>{od?.OD ? parseFloat(od?.OD).toFixed(2) : ''}</div>
                                                        </div>
                                                    </Grid>
                                                }
                                                {(item.type === 3 || item.type === 2 || item.type === 7) &&
                                                od?.OD !== "0" &&
                                                    <Grid item xs={selected_sport === 'Politics' ? 12 / item.odds.filter(x => {return x.OD !== "0"}).length : 4}>
                                                        <div onClick={() => od?.OD ? addToSlip(od?.name, i, parseFloat(od?.OD).toFixed(2), event, item.title) : {}} className="live_bets">
                                                            <div>{od?.name}</div>
                                                            <div>{parseFloat(od?.OD).toFixed(2)}</div>
                                                        </div>
                                                    </Grid>
                                                }
                                                {item.type === 4 &&
                                                    <Grid item xs={item.title?.startsWith('3') ? 4 : 6}>
                                                        <div onClick={() => od?.OD ? addToSlip(od?.name, i, parseFloat(od?.OD).toFixed(2), event, item.title) : {}} className="live_bets">
                                                            <div>{od?.name}</div>
                                                            <div><span className="handicap_cal">{od?.hd ? od?.hd : ''}</span>{parseFloat(od?.OD).toFixed(2)}</div>
                                                        </div>
                                                    </Grid>
                                                }
                                                {item.type === 5 &&
                                                    <Grid onClick={() => od?.OD ? addToSlip(od?.name, i, parseFloat(od?.OD).toFixed(2), event, item.title) : {}} item xs={6}>
                                                        <div className={!od?.OD ? "first_bets" : "live_bets"}>
                                                            <div>{od?.name}</div>
                                                            <div>{parseFloat(od?.OD).toFixed(2)}</div>
                                                        </div>
                                                    </Grid>
                                                }
                                                
                                            </Fragment>
                                        )}
                                        {item.odds && (item.type === 6 || item.type === 8) && sortedData(item.odds).map((od,i) =>
                                            <Fragment key={od.OD+od.order+od.name+od.title}>
                                                {item.type === 6 && <>
                                                    {od.title &&
                                                        <Grid item xs={6}>
                                                            <div className="stats_desc">{od.title}</div>
                                                        </Grid>
                                                    }
                                                    {!od.title &&
                                                    <Grid item xs={6}>
                                                        {od?.OD &&
                                                        <div onClick={() => od?.OD ? addToSlip(od?.name, i, parseFloat(od?.OD).toFixed(2), event, item.title) : {}} className={!od?.OD ? "first_bets" : "live_bets"}>
                                                            <div>{od?.name}</div>
                                                            <div>{parseFloat(od?.OD).toFixed(2)}</div>
                                                        </div>}
                                                    </Grid>
                                                    }
                                                </>}
                                                {item.type === 8 &&
                                                    <Grid item xs={3}>
                                                        <div onClick={() => od?.OD ? addToSlip(od?.name, i, parseFloat(od?.OD).toFixed(2), event, item.title) : {}} className={!od?.OD ? "first_bets" : "live_bets"}>
                                                            <div>{od?.name}</div>
                                                            <div>{od?.OD ? parseFloat(od?.OD).toFixed(2) : ''}</div>
                                                        </div>
                                                    </Grid>
                                                }
                                            </Fragment>
                                        )}
                                        </Grid>
                                    </AccordionDetails>
                            </Accordion>
                            }
                        </Fragment>
                            
                        )}
                        </Grid>
                    </Grid>
                    }
                    

                    {!event &&
                        <div className="display_search_autocomplete display_nonefound_mobile"> No data to display for this event </div>
                    }
            </BootstrapContainer>
            </>
            }
        </div>
    )
}