import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Typography} from '@mui/material';

const timeStatus = [
    {id: '0', title:'Not Started'},
    {id: '1', title:'InPlay'},
    {id: '2', title:'TO BE FIXED'},
    {id: '3', title:'Ended'},
    {id: '4', title:'Postponed'},
    {id: '5', title:'Cancelled'},
    {id: '6', title:'Walkover'},
    {id: '7', title:'Interrupted'},
    {id: '8', title:'Abandoned'},
    {id: '9', title:'Retired'},
    {id: '10', title:'Suspended'},
    {id: '11', title:'Decided by FA'},
    {id: '99', title:'Removed'},
]

const categories = [
    {id: 1, name: 'Soccer', img: '/static/sports/Soccer.svg'},
    {id: 12, name: 'American Football', img: '/static/sports/American Football.svg'},
    {id: 16, name: 'Baseball', img: '/static/sports/Baseball.svg'},
    {id: 18, name: 'Basketball', img: '/static/sports/Basketball.svg'},
    {id: 9, name: 'Boxing', img: '/static/sports/Boxing.svg'},
    {id: 162, name: 'MMA', img: '/static/sports/MMA.svg'},
    {id: 3, name: 'Cricket', img: '/static/sports/Cricket.svg'},
    {id: 13, name: 'Tennis', img: '/static/sports/Tennis.svg'},
    {id: 91, name: 'Volleyball', img: '/static/sports/Volleyball.svg'},
    {id: 78, name: 'Handball', img: '/static/sports/Handball.svg'},
    {id: 17, name: 'Ice Hockey', img: '/static/sports/Ice Hockey.svg'},
    {id: 15, name: 'Darts', img: '/static/sports/Darts.svg'},
    {id: 36, name: 'Australian Rules', img: '/static/sports/Australian Rules.svg'},
    {id: 92, name: 'Table Tennis', img: '/static/sports/Table Tennis.svg'},
    {id: 151, name: 'E-sports', img: '/static/sports/E-sports.svg'},
    {id: 75, name: 'Gaelic Sports', img: '/static/sports/Gaelic Sports.svg'},
    {id: 14, name: 'Snooker', img: '/static/sports/Snooker.svg'},
    {id: 83, name: 'Futsal', img: '/static/sports/Futsal.svg'},
    {id: 94, name: 'Badminton', img: '/static/sports/Badminton.svg'},
    {id: 8, name: 'Rugby Union', img: '/static/sports/Rugby Union.svg'},
    {id: 19, name: 'Rugby League', img: '/static/sports/Rugby League.svg'},
    {id: 90, name: 'Floorball', img: '/static/sports/Floorball.svg'},
    {id: 95, name: 'Beach Volleyball', img: '/static/sports/Beach Volleyball.svg'},
  ]

export default function RulesCustum(){
    return(
        <div className="pb-3">
         <Accordion className="mb-2">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="Sport IDs"
                >
                    <Typography className="title_home_selected" sx={{ width: '33%', flexShrink: 0 }}>
                        Sport IDs
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {categories.map(cat => 
                        <div style={{fontSize: '.7rem', display: 'flex', alignItems: 'center'}} key={cat.id}>
                           <img className="me-2" src={cat.img} alt="sport" style={{width: '.7rem'}} /> {cat.id} -> {cat.name}
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion className="mb-2">
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="Time Status"
                >
                    <Typography className="title_home_selected" sx={{ width: '33%', flexShrink: 0 }}>
                        Time Status
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className="pt-0 mt-0">
                    {timeStatus.map(time => 
                        <div style={{fontSize: '.7rem'}} key={time.id}>
                            {time.id} -> {time.title}
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}