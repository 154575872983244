import Grid from "@mui/material/Grid";
import {useState, useEffect, useRef} from 'react';
import dayjs, { getUserTimezone } from '../../helpers/UserTimestamp.js';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

export default function ScoreBoard({eventDetails, team1Color, team2Color, event, timeStatus}) {
    const userTimezone = getUserTimezone();

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const [minStart, setMinStart] = useState(0);
    const [secStart, setSecStart] = useState(0);
    const [hoursStart, setHoursStart] = useState(0);
    const [daysStart, setDaysStart] = useState(0);
    const timer = useRef(null);

    const padWithZero = (num) => {
        return num < 10 ? `0${num}` : `${num}`;
    };

    const minutesPassedAndUntil = (date) => {
        const currentDate = new Date();
        const givenDate = new Date(date);
       
        // Calculate the difference in milliseconds
        const differenceInMilliseconds = givenDate - currentDate;
        
        // Convert milliseconds to total seconds
        const totalSeconds = Math.floor(Math.abs(differenceInMilliseconds) / 1000);
      
        // Calculate days, hours, minutes, and seconds
        const days = Math.floor(totalSeconds / (60 * 60 * 24)); // Total days
        const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60)); // Remaining hours
        const minutes = Math.floor((totalSeconds % (60 * 60)) / 60); // Remaining minutes
        const seconds = totalSeconds % 60; // Remaining seconds
    
        if (differenceInMilliseconds >= 0) {
            // If the givenDate is in the future
            setMinStart(padWithZero(minutes)); // Set minutes until
            setSecStart(padWithZero(seconds)); // Set remaining seconds
            setHoursStart(padWithZero(hours)); // Set remaining hours
            setDaysStart(padWithZero(days)); // Set remaining days
        }else{
            const totalMinutesPassed = Math.floor(Math.abs(differenceInMilliseconds) / (1000 * 60)); // Total minutes passed
            // If the givenDate has passed
            setMinutes(eventDetails?.sport_id === "1" ? totalMinutesPassed > 105 ? padWithZero(totalMinutesPassed - 18) : padWithZero(totalMinutesPassed) : padWithZero(totalMinutesPassed)); // Set minutes passed if more than 100, set to 0
            setSeconds(padWithZero(seconds)); // Set seconds passed
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            try {
                if (eventDetails?.timer?.tm > 0 || eventDetails?.timer?.ts > 0) {
                    if(!timer?.current){
                        const { tm, ts, ta } = eventDetails?.timer;
                        const totalMinutes = tm + ta;
                        const totalSeconds = ts;
        
                        // Get the current time
                        const now = dayjs();
        
                        // Subtract the given time from the current time to calculate the event time
                        const eventTime = now.subtract(totalMinutes, 'minute').subtract(totalSeconds, 'second');
                        timer.current = eventTime;
                        minutesPassedAndUntil(dayjs(eventTime).tz(userTimezone));
                    }else{
                        minutesPassedAndUntil(dayjs(timer.current).tz(userTimezone));
                    }
                } else {
                    // Fallback when timer details are missing
                    minutesPassedAndUntil(dayjs.unix(eventDetails?.time).tz(userTimezone));
                }
            } catch{}
        }, 1000); // Update every second
    
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return(
    <Grid style={{minWidth: '100%'}} container spacing={2}>
        <Grid item xs={4}>
            <div className="event_home">
                {eventDetails && eventDetails?.home?.image_id !== 0 && eventDetails?.home?.image_id !== null ?
                    <img src={`https://assets.b365api.com/images/team/m/${eventDetails?.home?.image_id}.png`} alt={eventDetails.home?.name} /> :
                    <div>
                        <SportsSoccerIcon style={{color: team1Color}} />
                    </div>
                }
                {eventDetails ? eventDetails.home?.name : 'TBD'}
            </div>
        </Grid>
        <Grid item xs={4}>
            <div className="event_score">
                <div style={{textAlign: 'center'}}>
                    {eventDetails ? timeStatus.find(x => {return x.id === eventDetails.time_status})?.title : 'TBD'}
                    <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                    {
                        eventDetails && eventDetails.time_status === '1' && (minutes > 0 || seconds > 0) &&
                        <div>{minutes + 'm : ' + seconds + 's'}</div>
                    }
                    {
                        eventDetails && eventDetails.time_status === '0' && minStart > 0 &&
                        <div>{`${daysStart > 0 ? daysStart + 'd ' : ''}${hoursStart > 0 ? hoursStart+'h ' : ''} ${minStart}m ${secStart}s`}</div>
                    }
                    </div>
                </div>
                <div>
                    <span style={{color: team1Color}}>{event?.event ? event.event?.score.split('-')[0] : eventDetails?.ss ? 
                    eventDetails?.ss?.includes(',') ? 
                    eventDetails?.points?.split('-')[0]
                    :
                    eventDetails?.ss?.split('-')[0] : 0}</span>
                    <span className="ps-2 pe-2">:</span>
                    <span style={{color: team2Color}}>{event?.event ? event.event?.score.split('-')[1] : eventDetails?.ss ? 
                    eventDetails?.ss?.includes(',') ? 
                    eventDetails?.points?.split('-')[1]
                    :
                    eventDetails?.ss?.split('-')[1] : 0}</span>
                </div>
                <div></div>
            </div>
        </Grid>
        <Grid item xs={4}>
        <div className="event_away">
                {eventDetails && eventDetails?.away?.image_id !== 0 && eventDetails?.away?.image_id !== null ?       
                    <img src={`https://assets.b365api.com/images/team/m/${eventDetails?.away?.image_id}.png`} alt={eventDetails.away?.name} />
                    :
                    <div>
                        <SportsSoccerIcon style={{color: team2Color}} />
                    </div>
                }
                {eventDetails ? eventDetails.away?.name : 'TBD'}
            </div>
        </Grid>
    </Grid>
    )
}