
import './assets/css/App.css';
import { createBrowserHistory } from "history";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from './pages/Home';
import NavBar from './layouts/NavBar';
import SideDrawer from './layouts/SideDrawer';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {useState, useEffect, useRef} from  'react';

import MuiAppBar from '@mui/material/AppBar';
import SimpleBottomNavigation from './layouts/BottomNav';
import Footer from './layouts/Footer';
import MyBets from './pages/MyBets';
import WalletSetUp from './components/Modals/WalletSetUp';
import CashierModal from './components/Modals/Cashier';
import Transactions from './pages/Transactions';
import Settings from './pages/Settings';
import SwipableaDrawer from './layouts/SwipableDrawer';
import Policies from './pages/Policies';
import Licenses from './pages/Licenses';
import ProvidersCollection from './pages/Play/Providers/ProvidersCollection';
import Sport from './pages/Play/Sports/Sport';
import Event from './pages/Play/Sports/Event';
import BetPopUp from './components/Modals/BetPopUp';
import {  useSelector, useDispatch } from 'react-redux';
import BetSlipMobile from './components/Modals/BetSlipMobile';
import EventUpcoming from './pages/Play/Sports/EventUpcoming';
import OtherEvents from './pages/Play/Sports/OtherEvents';
import { useLocation } from 'react-router-dom';
import AdminLayout from './layouts/AdminLayout';
import ADashboard from './pages/Admin/ADashboard';
import AdminLogin from './pages/Admin/AdminLogin';
import AUsers from './pages/Admin/AUsers';
import ADeposits from './pages/Admin/ADeposits';
import { ThemeProvider } from './helpers/DarkModeKeep';
import ASlips from './pages/Admin/ASlips';
import ACustum from './pages/Admin/ACustum';
import ACustumEdit from './pages/Admin/ACustumEdit';
import CEvent from './pages/Play/Sports/CEvent';

import {
  inAppWallet,
} from "thirdweb/wallets/in-app";
import { client } from "./helpers/ThirdwebClient";
import ABanners from './pages/Admin/ABanners';
import ABannersCRUD from './pages/Admin/ABannersCRUD';
import League from './pages/Play/Sports/League';
import ATopLeagues from './pages/Admin/ATopLeagues';
import ATopLeaguesCRUD from './pages/Admin/ATopLeaguesCRUD.js';
import socket from './helpers/Socket';
import axiosInstance from './redux/axios';
import { updateUserBalance } from './redux/reducers/UserReducer';
import AWithdraws from './pages/Admin/AWithdraws';
import AReferrals from './pages/Admin/AReferrals';
import AReferralsCRUD from './pages/Admin/AReferralsCRUD';
import ReferralLayout from './layouts/ReferralLayout';
import RDashboard from './pages/Referrals/RDashboard';
import RUsers from './pages/Referrals/RUsers';
import RSettings from './pages/Referrals/RSettings';
import ASettings from './pages/Admin/ASettings';

const wallet = inAppWallet();

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerHeaderBets = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
    }),
);

const DrawerBets = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flex: '0',
    anchor: 'right',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px `,
      transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      }),
  }),
}));

function App() {
  const {user} = useSelector(state => state.user);
  const history = createBrowserHistory()
  const [open, setOpen] = useState(false);
  const [openBets, setOpenBets] = useState(false);
  const [openSlip, setOpenSlip] = useState(false);

  const [mobileSwipable, setMobileSwipable] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const isAdminRoute = location.pathname.startsWith('/admin') || location.pathname.startsWith('/referral');


  useEffect(() => {
    async function auto() {
      try{
        await wallet.autoConnect({ client });
      }catch{}
    }
    auto()
  }, [])

  //modals
  const [showSetWallet, setShowSetWallet] = useState(false);
  const [showVault, setShowVaultFinal] = useState(false);
  const hasEmitted = useRef(false);
  const [notifications, setNotifications] = useState([]);


  const setShowVault = (value) => {
    if(user?.is_step_one_completed){
      setShowVaultFinal(value)
    }else{
      setShowSetWallet(value)
    }
  }
  const hideAllModals = () => {
    setShowVaultFinal(false)
    setShowSetWallet(false)
    setOpenSlip(false)
  }

  const toggleSwipableDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event).key === 'Tab' ||
        (event).key === 'Shift')
    ) {
      return;
    }

    setMobileSwipable(open);
  };

  useEffect(() => {
    async function getMyBalance() {
      try{
        const response = await axiosInstance.get('/users/mybalance')

        const balance = response?.data?.balance
        
        if(balance?.$numberDecimal){
          dispatch(updateUserBalance(balance.$numberDecimal))
        }
      }catch{}
    }
    async function getNotifications() {
      try{
        const response = await axiosInstance.get('/users/notifications')
        
        if(response?.data?.notifications){
          setNotifications(response?.data?.notifications)
        }
      }catch{}
    }
    if (user && !hasEmitted.current) {
        socket.emit('userLogin', user._id);
        getNotifications()
        hasEmitted.current = true;
    }
    
    if(user){
      getMyBalance()
    }

    const handleDepositSuccess = (data) => {
        if(data.updatedBalance){
          dispatch(updateUserBalance(data.updatedBalance?.$numberDecimal))
        }
        if(data.type){
          setNotifications((prevNotifications) => [data, ...prevNotifications]);
        }
    };

    socket.on('depositSuccess', handleDepositSuccess);
    socket.on('feeSuccess', handleDepositSuccess);

    return () => {
        socket.off('depositSuccess', handleDepositSuccess);
        socket.off('feeSuccess', handleDepositSuccess);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <div className="App">
        <BetSlipMobile onHide={() => setOpenSlip(false)} show={openSlip} />

        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {!isAdminRoute &&
          <SideDrawer 
          Drawer={Drawer} 
          DrawerHeader={DrawerHeader} 
          open={open} 
          setOpen={setOpen} 
          setShowVault={setShowVault} />
          }
          
          {user && !isAdminRoute &&
            <BetPopUp 
            Drawer={DrawerBets} 
            DrawerHeader={DrawerHeaderBets} 
            open={openBets} 
            setOpen={setOpenBets} 
            />
          }

          <Box className="main_root" component="main" sx={{ flexGrow: 1, p: 3 }}>
            {!isAdminRoute && 
            <NavBar 
            notifications={notifications}
            setNotifications={setNotifications}
            AppBar={AppBar} 
            open={open} 
            wallet={wallet}
            setOpen={setOpen} 
            setShowVault={setShowVault}
            />}
            <Routes history={history}>
                <Route
                  path='/*'
                  element={
                    <Navigate to="/play"/>
                  } />
                  <Route
                    path='/play/*'
                    element={
                      <Routes>
                        <Route path="/league/:id" element={<League setOpenBets={setOpenBets}/>} />
                        <Route path="/sport/:selected_sport" element={<Sport setOpenBets={setOpenBets}/>} />
                        <Route path="/sport/:selected_sport/events/:id" element={<Event setOpenBets={setOpenBets}/>} />
                        <Route path="/sport/:selected_sport/cevents/:id" element={<CEvent setOpenBets={setOpenBets}/>} />
                        <Route path="/sport/:selected_sport/events_other/:id" element={<OtherEvents setOpenBets={setOpenBets}/>} />
                        <Route path="/sport/:selected_sport/upcoming/:id" element={<EventUpcoming setOpenBets={setOpenBets}/>} />

                        <Route path="/*" element={<Navigate to='/play'/>} />
                        <Route path="/" element={<Home setOpenBets={setOpenBets}/>} />
                        <Route path="/slots/*" element={
                          <Routes>
                            <Route path="/*" element={<Navigate to='/play'/>} />
                          </Routes>
                        } />
                       <Route path="/providers/*" element={
                          <Routes>
                            <Route path="/*" element={<Navigate to='/play/providers'/>} />
                            <Route path="/" element={<ProvidersCollection/>} />
                          </Routes>
                        } />
                      </Routes>
                  } />   
                  <Route path="/my-bets" element={<MyBets/>} />
                  <Route 
                    path='/transactions/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/transactions'/>} />
                        <Route path="/" element={<Transactions/>} />
                        <Route path="/deposits" element={<Transactions/>} />
                        <Route path="/withdrawals" element={<Transactions/>} />
                        <Route path="/other" element={<Transactions/>} />
                      </Routes>
                    }
                  />
                  <Route 
                    path='/settings/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/settings'/>} />
                        <Route path="/" element={<Settings/>} />
                        <Route path="/general" element={<Settings/>} />
                        <Route path="/security" element={<Settings/>} />
                        <Route path="/preferences" element={<Settings/>} />
                        <Route path="/ignored-users" element={<Settings/>} />
                        <Route path="/verify" element={<Settings/>} />
                        <Route path="/offers" element={<Settings/>} />
                      </Routes>
                    }
                  />
                  <Route 
                    path='/policies/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/policies'/>} />
                        <Route path="/" element={<Policies/>} />
                        <Route path="/terms-of-service" element={<Policies/>} />
                        <Route path="/privacy" element={<Policies/>} />
                        <Route path="/licenses" element={<Policies/>} />
                      </Routes>
                    }
                  />
                  <Route 
                    path='/licenses/*'
                    element={
                      <Routes>
                        <Route path="/*" element={<Navigate to='/licenses'/>} />
                        <Route path="/" element={<Licenses/>} />
                      </Routes>
                    }
                  />

                  <Route path="/admin/*" element={user?.role_id?.name === 'Admin' ? (
                    <AdminLayout>
                      <ThemeProvider attribute="class"
                      defaultTheme="dark"
                      enableSystem
                      disableTransitionOnChange>
                        <Routes>
                          <Route path="/" element={<ADashboard />} />
                          <Route path="/dashboard" element={<ADashboard />} />
                          <Route path="/users" element={<AUsers />} />
                          <Route path="/withdraws" element={<AWithdraws />} />
                          <Route path="/deposits" element={<ADeposits />} />
                          <Route path="/bet-slips" element={<ASlips />} />
                          <Route path="/custom-games" element={<ACustum />} />
                          <Route path="/custom-games/:id" element={<ACustumEdit />} />
                          <Route path="/banners" element={<ABanners />} />
                          <Route path="/banners/:id" element={<ABannersCRUD />} />
                          <Route path="/top-leagues" element={<ATopLeagues />} />
                          <Route path="/top-leagues/:id" element={<ATopLeaguesCRUD />} />
                          
                          <Route path="/referrals" element={<AReferrals />} />
                          <Route path="/referrals/:id" element={<AReferralsCRUD />} />

                          <Route path="/settings" element={<ASettings />} />
                        </Routes>
                      </ThemeProvider>
                    </AdminLayout>
                  ) : (
                    <Navigate to="/admin/login" />
                  )} />

                  <Route path="/referral/*" element={user?.role_id?.name === 'Viewer' ? (
                    <ReferralLayout>
                      <ThemeProvider attribute="class"
                      defaultTheme="dark"
                      enableSystem
                      disableTransitionOnChange>
                        <Routes>
                          <Route path="/" element={<RDashboard />} />
                          <Route path="/dashboard" element={<RDashboard />} />
                          <Route path="/users" element={<RUsers />} />
                          <Route path="/settings" element={<RSettings />} />
                        </Routes>
                      </ThemeProvider>
                    </ReferralLayout>
                  ) : (
                    <Navigate to="/referral/login" />
                  )} />

                  <Route path="/admin/login" element={<AdminLogin />} />
                  <Route path="/referral/login" element={<AdminLogin />} />
              </Routes>
              {!isAdminRoute && <Footer />}
          </Box>
          
          {/* MODALS */}
          <WalletSetUp show={showSetWallet} onHide={() => setShowVault(false)}  />
          <CashierModal wallet={wallet} show={showVault} onHide={() => {setShowVault(false)}}  setShowSetWallet={setShowSetWallet}  />
          
          {/* MOBILE DRAWER */}

          {!isAdminRoute &&
          <>
          <SwipableaDrawer 
          toggleDrawer={toggleSwipableDrawer} 
          mobileSwipable={mobileSwipable} 
          setMobileSwipable={setMobileSwipable}
          setShowVault={setShowVault}
           />
          <SimpleBottomNavigation showVault={user?.is_step_one_completed ? showVault : showSetWallet} hideAllModals={hideAllModals} openSlip={openSlip} setOpenSlip={setOpenSlip} mobileSwipable={mobileSwipable} setMobileSwipable={setMobileSwipable} toggleDrawer={toggleSwipableDrawer} setShowVault={setShowVault} />
          </>
          }
        </Box>  
        
      </div>
    </>
  );
}

export default App;
