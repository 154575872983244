import { Container } from 'react-bootstrap';
import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';
import {useState, useEffect} from 'react';

import { Button } from 'keep-react'


import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axiosInstance from '../../redux/axios';
import {useNavigate, useParams} from 'react-router-dom';
import { useSnackbar } from 'notistack';

export default function ABannersCRUD(){
    const {id} = useParams();
    const [banner, setBanner] = useState(null);
    const [mainInfo, setMainInfo] = useState({
        url: '',
        file: null,
        priority: '',
        is_visible: false
    })
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function getBanner() {
            try{
                const response = await axiosInstance.get('admin/banners/'+id)
             
                if(response.data.banner){
                    const banner = response.data.banner
                    setBanner(banner)
                    setMainInfo({
                        url: banner.url,
                        file: null,
                        priority: banner.priority,
                        is_visible: banner.is_visible
                    })
                }
            }catch{}
        }
        if(id && id !== 'new'){
            getBanner();
        }
    }, [id])

    const validateMainInfo = () => {
        if(id === 'new'){
            return (mainInfo.priority && mainInfo.file)
        }else{
            return (mainInfo.priority)
        }
    };

    const createBanner = async () => {
        if(validateMainInfo()){
            try{
                let data = new FormData();
                data.append('file', mainInfo.file);
                data.append('priority', mainInfo.priority);
                data.append('is_visible', mainInfo.is_visible);
                const response = await axiosInstance.post('admin/banners/create', data);

                if(response.status === 201){
                    enqueueSnackbar('Banner Created Successfully', { variant: "success" });

                    setTimeout(() => {
                        navigate('/admin/banners')
                    }, 2000)
                }
            }catch(err){
                if(err?.response?.data){
                    const errs = err?.response?.data?.errors;
                    if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                        if(Object.keys(errs).length === 0){
                            if(err.response.data?.message){
                                enqueueSnackbar(err.response.data?.message, { variant: "error" });
                            }else{
                                enqueueSnackbar(err.response?.statusText, { variant: "error" });
                            }
                        }else{
                            enqueueSnackbar(Object.values(errs).join(' ,'), { variant: "error" });
                        }
                    }else{
                        if(err.response.data?.message){
                            enqueueSnackbar(err.response.data?.message, { variant: "error" });
                        }else{
                            enqueueSnackbar(err.response?.statusText, { variant: "error" });
                        }
                    }
                }
            }
        }else {
            enqueueSnackbar('Fill the form to create the banner', { variant: "error" });
        }
    }

    const editBanner = async () => {
        if(validateMainInfo()){
            try{
                let data = new FormData();
                if(mainInfo.file){
                    data.append('file', mainInfo.file);
                }
                data.append('priority', mainInfo.priority);
                data.append('is_visible', mainInfo.is_visible);
                const response = await axiosInstance.post('admin/banners/'+id, data);

                if(response.status === 200){
                    enqueueSnackbar('Banner Edited Successfully', { variant: "success" });
                    
                    if(response.data){
                        setBanner(response.data)
                    }
                }
            }catch(err){
                if(err?.response?.data){
                    const errs = err?.response?.data?.errors;
                    if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                        if(Object.keys(errs).length === 0){
                            if(err.response.data?.message){
                                enqueueSnackbar(err.response.data?.message, { variant: "error" });
                            }else{
                                enqueueSnackbar(err.response?.statusText, { variant: "error" });
                            }
                        }else{
                            enqueueSnackbar(Object.values(errs).join(' ,'), { variant: "error" });
                        }
                    }else{
                        if(err.response.data?.message){
                            enqueueSnackbar(err.response.data?.message, { variant: "error" });
                        }else{
                            enqueueSnackbar(err.response?.statusText, { variant: "error" });
                        }
                    }
                }
            }
        }else {
            enqueueSnackbar('Fill the form to create the banner', { variant: "error" });
        }
    }

    const deleteBanner = async () => {
        try{
            const response = await axiosInstance.delete('admin/banners/'+id);

            if(response.status === 200){
                enqueueSnackbar('Banner Deleted Successfully', { variant: "success" });
                
                setTimeout(() => {
                    navigate('/admin/banners')
                }, 2000)
            }
        }catch(err){
            if(err?.response?.data){
                const errs = err?.response?.data?.errors;
                if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                    if(Object.keys(errs).length === 0){
                        if(err.response.data?.message){
                            enqueueSnackbar(err.response.data?.message, { variant: "error" });
                        }else{
                            enqueueSnackbar(err.response?.statusText, { variant: "error" });
                        }
                    }else{
                        enqueueSnackbar(Object.values(errs).join(' ,'), { variant: "error" });
                    }
                }else{
                    if(err.response.data?.message){
                        enqueueSnackbar(err.response.data?.message, { variant: "error" });
                    }else{
                        enqueueSnackbar(err.response?.statusText, { variant: "error" });
                    }
                }
            }
        }
    }

    return(
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">{id === 'new' ? 'Create Banner' : 'Edit Banner'}</h5>
            </div>

            {(banner || id === 'new') && 
             <Container className="pt-5 pb-5">
                <h6>Main details</h6>

                {id !== 'new' && banner &&
                    <div className="pb-3">
                        <img style={{maxWidth: '250px', width: '100%'}} src={banner.url} alt="banner" />
                    </div>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <fieldset className="max-w-md space-y-1">
                            <FormControlLabel control={<Checkbox checked={mainInfo.is_visible} onChange={e => setMainInfo({...mainInfo, is_visible : e.target.checked})} />} label="Is Visible" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="sport_id">Image</Label>
                            <Input onChange={e => setMainInfo({...mainInfo, file: e.target.files[0]})} id="image" placeholder="Choose banner" type="file" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="sport_id">Priority</Label>
                            <Input value={mainInfo.priority} onChange={e => setMainInfo({...mainInfo, priority: e.target.value})} id="priority" placeholder="Enter priority" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                </Grid>

                <div className="pt-3">     
                    <Button onClick={() => {id==='new' ? createBanner() : editBanner()}} className="me-3 mt-2"  color="primary">{id==='new' ? 'Create ' : 'Update '}Banner</Button>
                
                    {id !== 'new' &&
                    <Button onClick={() => {deleteBanner()}} className="me-3 mt-2"  color="error">Delete Banner</Button>
                    }
               </div>
             </Container>
            }
            {(!banner && id !== 'new') &&
                <Container className="pt-5 pb-5">
                    <div style={{textAlign: 'center', width: '100%'}}>Banner not found.</div>
                </Container>
            }
        </div>
    )
}