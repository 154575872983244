import { useParams } from 'react-router-dom';
import { useState , useEffect } from 'react';

import {Container} from 'react-bootstrap';
import ImageWithFallback from '../../../helpers/ImageWithFallback';
import {Breadcrumbs, Typography, Link} from '@mui/material';
import axiosInstance from '../../../redux/axios';
import dayjs, { getUserTimezone } from '../../../helpers/UserTimestamp.js';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Grid} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useNavigate} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function League({setOpenBets}){
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');
  
    // Adjust width and height based on screen size
    const width = isSmallScreen ? 350 : isMediumScreen ? 690 : 1300;
    const height = isSmallScreen ? 100 : isMediumScreen ? 180 : 150;

    const userTimezone = getUserTimezone();
    const navigate = useNavigate();

    const { id } = useParams();
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [inplay, setInPlay] = useState([]);
    const [upcoming, setUpcoming] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function  getLeague() {
            try{
                const reponse = await axiosInstance.get(`sport/league/${id}`)
                
                if(reponse.data?.league){
                    setSelectedLeague(reponse.data?.league)
                    setInPlay(reponse.data?.inplay)
                    setUpcoming(reponse.data?.upcoming)
                }
                setLoading(false)
            }catch{
                setLoading(false)
            }
        }
        getLeague()
        setLoading(true)
        return () => {
            setSelectedLeague(null)
        }
    }, [id])

    const splitTeams = (teams) => {
        if (!teams) return [];
      
        // Split on 'v ' if it exists, otherwise split on '@'
        const delimiter = teams?.includes('v ') ? 'v ' : teams?.includes('@ ') ? '@' : 'vs';
        return teams.split(delimiter);
      };
    
    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div className="event_t"> 
                        {loading ? 
                        <Skeleton variant="circular" style={{marginRight: '.5rem', marginBottom: '.1rem'}} width={40} height={40}/>
                        :
                        <ImageWithFallback 
                        style={{width: '1.8rem', marginRight: '.5rem', marginBottom: '.1rem'}}
                        src={selectedLeague?.img}
                        alt={selectedLeague?.name}
                        fallbackSrc="/static/sports/Other.svg"
                        />
                        }
                       {loading ? 
                       <Skeleton variant="text" width={100} height={40}/>
                       : selectedLeague?.name || 'League'}
                    </div>

                    <div className="pb-2" style={{fontSize: '.9rem', color: 'gray'}}>
                    </div>

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play/sport'}>
                            Home
                        </Link> 
                        <Typography color="text.primary"> 
                        {loading ? 
                        <Skeleton variant="text" width={100} height={40}/>
                        : selectedLeague?.name || 'League'}
                        </Typography>
                    </Breadcrumbs>
                </Container>
            </div>

            {!loading &&
            <Container style={{padding: '1rem', paddingTop: '2rem', paddingBottom: '2rem'}}>
                {inplay?.length > 0 &&
                    <Accordion className="mb-2" defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={"top_leagues"}
                        className="acc_country"
                        id="top_leagues"
                        >
                        <Typography className="title_home_selected" sx={{flexShrink: 0 , display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
                            InPlay {selectedLeague?.name}
                        </Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{background: '#EDF2FF', color: 'black', padding: '.8rem 0rem'}}>
                            {inplay.map(e=>
                                <div key={e.id || e._id}>
                                    <div style={{borderBottom: '1px solid #0000003d'}} className="live_grid pt-0">
                                        <Grid style={{minWidth: '100%'}} container spacing={1}>
                                        
                                        <Grid className="pt-2" item xs={5} sm={3}>
                                                    <div className="date_home ps-0">
                                                        {dayjs.unix(e.time).tz(userTimezone).format('DD MMM YYYY, HH:mm')}
                                                    </div>
                                                    <div className="display_spacebetween">
                                                        <div className="teams_sport pt-1">{e.teams ? splitTeams(e.teams)[0] : e.home?.name}</div>
                                                        <div className="results_sport pt-1">
                                                            <span>{e.ss? e.ss?.split('-')[0] : 0}</span>
                                                        </div>
                                                    </div>

                                                    <div className="display_spacebetween">
                                                        <div className="teams_sport pt-1">{e.teams ? splitTeams(e.teams)[1] : e.away?.name}</div>
                                                        <div className="results_sport pt-1">
                                                            <span>{e.ss? e.ss?.split('-')[1] : 0}</span>
                                                        </div>
                                                    </div>
                                        </Grid>
                                            
                                        <Grid item xs={6} sm={8}>
                                        </Grid>
                                                    
                                        <Grid item xs={1}>
                                            <div onClick={() => navigate(e._id ? `/play/sport/Soccer/cevents/${e._id}` : `/play/sport/Soccer/upcoming/${e.id?.split('C')[0]}`)} className="display_center">
                                                <ArrowForwardIosIcon />
                                            </div>
                                        </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            )}
                        </AccordionDetails>
                    </Accordion>
                }

                {upcoming?.length > 0 &&
                    <Accordion className="mb-2" defaultExpanded>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={"top_leagues"}
                        className="acc_country"
                        id="top_leagues"
                        >
                        <Typography className="title_home_selected" sx={{flexShrink: 0 , display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
                            Prematch {selectedLeague?.name}
                        </Typography>
                        </AccordionSummary>

                        <AccordionDetails style={{background: '#EDF2FF', color: 'black', padding: '.8rem 0rem'}}>
                            {upcoming.map(e=>
                                <div key={e.id || e._id}>
                                    <div style={{borderBottom: '1px solid #0000003d'}} className="live_grid pt-0">
                                        <Grid style={{minWidth: '100%'}} container spacing={1}>
                                        
                                        <Grid className="pt-2" item xs={5} sm={3}>
                                                    <div className="date_home ps-0">
                                                        {dayjs.unix(e.time).tz(userTimezone).format('DD MMM YYYY, HH:mm')}
                                                    </div>
                                                    <div className="display_spacebetween">
                                                        <div className="teams_sport pt-1">{e.teams ? splitTeams(e.teams)[0] : e.home?.name}</div>
                                                        <div className="results_sport pt-1">
                                                            <span>{e.ss? e.ss?.split('-')[0] : 0}</span>
                                                        </div>
                                                    </div>

                                                    <div className="display_spacebetween">
                                                        <div className="teams_sport pt-1">{e.teams ? splitTeams(e.teams)[1] : e.away?.name}</div>
                                                        <div className="results_sport pt-1">
                                                            <span>{e.ss? e.ss?.split('-')[1] : 0}</span>
                                                        </div>
                                                    </div>
                                        </Grid>
                                            
                                        <Grid item xs={6} sm={8}>
                                        </Grid>
                                                    
                                        <Grid item xs={1}>
                                            <div onClick={() => navigate(e._id ? `/play/sport/Soccer/cevents/${e._id}` : `/play/sport/Soccer/upcoming/${e.id?.split('C')[0]}`)} className="display_center">
                                                <ArrowForwardIosIcon />
                                            </div>
                                        </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            )}
                        </AccordionDetails>
                    </Accordion>
                }
            </Container>
            }

            {loading &&
            <Container style={{padding: '1rem', paddingTop: '2rem', overflowX: 'hidden', paddingBottom: '2rem'}}>
                <>
                    <Skeleton variant="text" width={width} height={height}/>
                    <Skeleton variant="text" width={width} height={height}/>
                    <Skeleton variant="text" width={width} height={height}/>
                </>
            </Container>
            }
        </div>
    )
}