import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { CircularProgressWithLabel } from '../../helpers/CircularProgressWithLabe;l';
import { BorderLinearProgress } from '../../helpers/BorderlineProgress';

import hockey_goal from '../../assets/img/stats/hockey_goal.svg'
import hockey_net from '../../assets/img/stats/hockey_net.svg'
import hockey_power from '../../assets/img/stats/hockey_power.svg'
import handball from '../../assets/img/stats/handball.svg'
import corner from '../../assets/img/stats/corner_flag.svg';
import red_card from '../../assets/img/stats/red_card.svg';
import yellow_card from '../../assets/img/stats/yellow_card.svg';
import goal from '../../assets/img/stats/goal.svg';
import offside from '../../assets/img/stats/offside.svg';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Grid from "@mui/material/Grid";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScoreBoard from './ScoreBoard';
import Flag from 'react-world-flags';
import res from '../../helpers/GroupSports.js';

const countryToISO = res.countryToISO;

const customFlags = {
    e1: '/static/flags/esports.png',      // Custom image path for Esports
    e2: '/static/flags/international.png', // Custom image path for International Clubs
    e3: '/static/flags/africa.png',        // Custom image for Africa-based competitions
};

function getFlagSrc(code) {
    return customFlags[code] || null;
}

export default function StatsAndTimeLine({eventDetails, event, timeStatus}){
    const matches = useMediaQuery('(max-width:600px)');
    const [hidden, setHidden] = React.useState(matches ? true : false);
    const [hidden1, setHidden1] = React.useState(matches ? true : false);
    const [value, setValue] = React.useState(matches ? 0 : eventDetails?.time_status === 0 ? 4 : 1);
    const [team1Color, setTeam1] = React.useState('#EA2636');
    const [team2Color, setTeam2] = React.useState('#999999');
    const [showMore, setShowMore] =  React.useState(matches ? false : true);
    const [showTotals, setShowTotals] =  React.useState(false);
    const timelineRef = React.useRef(null); 
    const extraRef = React.useRef(null); 

    React.useEffect(() => {
     if(event){
        if(event?.teamDetails){
            if(event?.teamDetails[0]){
                setTeam1(event?.teamDetails[0].color || '#EA2636')
            }
            if(event?.teamDetails[1]){
                setTeam2(event?.teamDetails[1].color || '#999999')
            }
        }
     }
    }, [event])

    React.useEffect(() => {
        if(hidden){
            if (timelineRef.current) {
                timelineRef.current.style.height = '200px';
                timelineRef.current.style.overflow = 'hidden';
            }
        }else{
            if (timelineRef.current) {
                timelineRef.current.style.height = '100%';
                timelineRef.current.style.overflow = 'auto';
            }
        }
    }, [hidden])

    React.useEffect(() => {
        if(hidden1){
            if (extraRef.current) {
                extraRef.current.style.height = '200px';
                extraRef.current.style.overflow = 'hidden';
            }
        }else{
            if (extraRef.current) {
                extraRef.current.style.height = '100%';
                extraRef.current.style.overflow = 'auto';
            }
        }
    }, [hidden1])

    React.useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth > 600){
                setValue(1)
                if (timelineRef.current) {
                    timelineRef.current.style.height = '100%';
                    timelineRef.current.style.overflow = 'auto'; // Add overflow if needed
                }
                setHidden(false)
                if (extraRef.current) {
                    extraRef.current.style.height = '100%';
                    extraRef.current.style.overflow = 'auto'; // Add overflow if needed
                }
                setHidden1(false)
               
            }else{
                setValue(2)
                if (timelineRef.current) {
                    timelineRef.current.style.height = '200px';
                    timelineRef.current.style.overflow = 'hidden'; // Add overflow if needed
                }
                setHidden(true)

                if (extraRef.current) {
                    extraRef.current.style.height = '200px';
                    extraRef.current.style.overflow = 'hidden'; // Add overflow if needed
                }
                setHidden1(true)
                
                setValue(0)
            }
        };

        // Add the event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function haveSameWord(string1, stringTry1, string2) {
        if(string1 && string2){
            
            let wordsTotal = stringTry1 ? stringTry1.toLowerCase().replaceAll('(', '').replaceAll(')', '').replaceAll('~', '').split(' ').filter(word => word.trim() !== '' && word !== 'team' && word !== 'football' && word !== 'women') : [];
            let words1 = string1.toLowerCase().replaceAll('(', '').replaceAll(')', '').replaceAll('~', '').split(' ').filter(word => word.trim() !== '' && word !== 'team' && word !== 'football' && word !== 'women');
            
            wordsTotal = [...new Set([...wordsTotal, ...words1])];
           
            const words2 = string2.toLowerCase().split(' ').filter(word => word.trim() !== '' && word !== 'team' && word !== 'football' && word !== 'women');
        
            return words2.some(word => wordsTotal.includes(word));
        }else{
            return false
        }
    }

    function calBorders(int1, int2){
        const i1 = parseFloat(int1)
        const i2 = parseFloat(int2)

        try{
            let diff = parseFloat(i1/i2);

            if(int1 > int2){
                diff = parseFloat(i2/i1);
            }

            if(int1 === int2 || Math.abs(int1-int2) <= 1){
                diff = 0.5
            }

            if(int2 > int1){
                return parseFloat(diff) * 100
            }else{
                return 100 - (parseFloat(diff) * 100)
            }
        }catch{
            return 50;
        }
    }

    return(
        <div className="stats_container">
            <div className="full_width display_center" style={{borderBottom: '1px solid #80808052'}}>
                <Tabs 
                variant={matches ? "scrollable" : ""}
                scrollButtons="auto"
                value={value} 
                onChange={handleChange} 
                 aria-label="full width stats timeline">
                    <Tab style={!matches ? {display: 'none'} : {}} label="Score" />
                    <Tab style={(eventDetails?.time_status === '0' || eventDetails?.timeline) ? {display: 'none'} : {}} label="Stats" />
                    <Tab style={(eventDetails?.time_status === '0' || !eventDetails?.events?.length > 0) ? {display: 'none'} : {}} label="Timeline" />
                    <Tab style={(eventDetails?.extra?.away_manager || eventDetails?.extra?.home_manager || eventDetails?.extra?.referee || eventDetails?.extra?.stadium_data) ? {} : {display: 'none'}} label="Extras" />
                    
                    {eventDetails?.timeline && eventDetails?.scores &&
                        Object.keys(eventDetails.scores).map((s,i) => 
                            <Tab key={i} label={"Game"+(i+1)} />
                        )
                    }
                </Tabs>
            </div>
            {/* STATS */}
            {value === 1 && eventDetails?.time_status !== '0' &&
            <div className="stats_container1">
                {/* TABLE */}
                {(parseInt(eventDetails?.sport_id) === 13 || parseInt(eventDetails?.sport_id) === 16 || parseInt(eventDetails?.sport_id) === 18 || parseInt(eventDetails?.sport_id) === 91 ||
                parseInt(eventDetails?.sport_id) === 78 || parseInt(eventDetails?.sport_id) === 151 ||
                parseInt(eventDetails?.sport_id) === 12 || parseInt(eventDetails?.sport_id) === 17)  && eventDetails?.scores &&
                    <div className="custom-grid" style={{gridTemplateColumns: `repeat(${Object.keys(eventDetails?.scores)?.length + (parseInt(eventDetails?.sport_id) === 13 ? 2 : 1) }, 1fr)`}}>
                        <div className="c-grid-item"></div>
                            {parseInt(eventDetails?.sport_id) === 13 &&
                            <div className="c-grid-item">
                                Sets 
                            </div>
                            }
                            {Object.keys(eventDetails?.scores)?.map((s,i) =>
                                <div key={i} className="c-grid-item">
                                    {parseInt(eventDetails?.sport_id) === 18 && s === '3' ? 'Half': 
                                    parseInt(eventDetails?.sport_id) === 18 && s === '7' ? 'Total': 
                                    (parseInt(eventDetails?.sport_id) === 78)  && s === '4' ? 'Total': 
                                    (parseInt(eventDetails?.sport_id) === 151)  && s === '4' ? 'Maps': 
                                    parseInt(eventDetails?.sport_id) === 12 && s === '3' ? 'Half': 
                                    parseInt(eventDetails?.sport_id) === 12 && s === '7' ? 'Total': 
                                    parseInt(eventDetails?.sport_id) === 17 && s === '5' ? 'Total': 
                                    s?.toUpperCase()}
                                </div>
                            )}
                            <div className="c-grid-item">
                                {eventDetails?.home?.cc && (
                                    <Flag
                                        code={eventDetails?.home?.cc?.toUpperCase()}
                                        alt={`${eventDetails?.home?.cc} flag`}
                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                    />
                                    
                                )}
                                {eventDetails?.home?.name}
                            </div>
                           
                            {parseInt(eventDetails?.sport_id) === 13 &&
                            <div className="c-grid-item">
                               {eventDetails?.scores &&
                                   Object.values(eventDetails?.scores)?.filter((set,index) => {
                                    const homeWon = parseInt(set.home) >= 6 && (parseInt(set.home) - parseInt(set.away) >= 2);
  
                                    // Check if the set is ongoing (not the last index)
                                    const isOngoing = index === Object.values(eventDetails.scores).length - 1;
                                  
                                    // If the home player won the set, return true
                                    if (homeWon) return true;
                                    
                                    // If the set is ongoing, add a point to the home player if their score is higher
                                    if (isOngoing && (parseInt(set.home) > parseInt(set.away)) && (parseInt(set.home) - parseInt(set.away) < 2)) {
                                      return true; // Add point for the home player
                                    }
                                  
                                    return false;
                                  }).length
                                }
                            </div>
                            }

                            {Object.values(eventDetails?.scores)?.map((s,i) =>
                                <div key={i} className="c-grid-item">
                                    {s.home || 0}
                                </div>
                            )}

                            <div className="c-grid-item">
                                {eventDetails?.away?.cc && (
                                    <Flag
                                        code={eventDetails?.away?.cc?.toUpperCase()}
                                        alt={`${eventDetails?.away?.cc} flag`}
                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                    />
                                    
                                )}
                                {eventDetails?.away?.name}
                            </div>
                            {parseInt(eventDetails?.sport_id) === 13 &&
                            <div className="c-grid-item">
                                {eventDetails?.scores &&
                                   // eslint-disable-next-line array-callback-return
                                   Object.values(eventDetails?.scores)?.filter((set,index) => {
                                    const awayWon = parseInt(set.away) >= 6 && (parseInt(set.away) - parseInt(set.home) >= 2);
  
                                    // Check if the set is ongoing (not the last index)
                                    const isOngoing = index === Object.values(eventDetails.scores).length - 1;

                                    // If the away player won the set, return true
                                    if (awayWon) return true;
                                    
                                    // If the set is ongoing, add a point to the away player if their score is higher
                                    if (isOngoing && (parseInt(set.away) > parseInt(set.home)) && (parseInt(set.away) - parseInt(set.home) < 2)) {
                                        return true; // Add point for the away player
                                    }
                                  }).length
                                }
                            </div>
                            }
                            {Object.values(eventDetails?.scores)?.map((s,i) =>
                                <div key={i} className="c-grid-item">
                                    {s.away || 0}
                                </div>
                            )}
                    </div>
                }
                {eventDetails?.stats?.xg &&
                <div className="text-center pb-2 pt-2">
                    <span className="text_stats">{eventDetails?.stats?.xg[0]}</span> 
                    <span className="stats_headings ps-1 pe-1">xG</span>
                    <span className="text_stats">{eventDetails?.stats?.xg[1]}</span>
                </div>
                }

                <div className="display-attacks">
                    {eventDetails?.stats?.attacks && 
                    <div>
                        <div className="display_center">
                            
                                <div>
                                    <div className="stats_headings">Attacks</div>
                                    <div className="display_center">
                                        <span className="pe-2 text_stats">{eventDetails?.stats?.attacks[0]}</span>
                                        <CircularProgressWithLabel type="attack" color1={team2Color} color2={team1Color} style={parseInt(eventDetails?.stats?.attacks[0]) > parseInt(eventDetails?.stats?.attacks[1]) ? {transform: 'rotate(-60deg)'} : {transform: 'rotate(135deg)'}} color="error" size="2rem" thickness="7" variant="determinate" 
                                        value={calBorders(parseInt(eventDetails?.stats?.attacks[0]), parseInt(eventDetails?.stats?.attacks[1]))
                                        }/>
                                        <span className="ps-2 text_stats">{eventDetails?.stats?.attacks[1]}</span>
                                    </div>
                                </div>
                            
                        </div>
                    </div>}
                    {eventDetails?.stats?.dangerous_attacks && 
                    <div>
                        <div className="display_center">
                            <div>
                                <div className="stats_headings">Dangerous Attacks</div>
                                <div className="display_center">
                                    <span className="pe-2 text_stats">{eventDetails?.stats?.dangerous_attacks[0]}</span>
                                    <CircularProgressWithLabel type="dangerous_attacks" color1={team1Color} color2={team2Color} style={parseInt(eventDetails?.stats?.dangerous_attacks[0]) > parseInt(eventDetails?.stats?.dangerous_attacks[1]) ? {transform: 'rotate(-60deg)'} : {transform: 'rotate(135deg)'}} color="error" size="2rem" thickness="7" variant="determinate" 
                                    value={
                                        calBorders(parseInt(eventDetails?.stats?.dangerous_attacks[0]), parseInt(eventDetails?.stats?.dangerous_attacks[1]))
                                    } />
                                    <span className="ps-2 text_stats">{eventDetails?.stats?.dangerous_attacks[1]}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {eventDetails?.stats?.possession_rt && 
                    <div className="display-attacks">
                        <div>
                            <div className="display_center">
                                    <div>
                                        <div className="stats_headings">Possession %</div>
                                        <div className="display_center">
                                            <span className="pe-2 text_stats">{eventDetails?.stats?.possession_rt[0]}</span>
                                            <CircularProgressWithLabel type="possession_rt" color1={team1Color} color2={team2Color}  style={parseInt(eventDetails?.stats?.possession_rt[0]) > parseInt(eventDetails?.stats?.possession_rt[1]) ? {transform: 'rotate(-60deg)'} : {transform: 'rotate(135deg)'}} color="error" size="2rem" thickness="7" variant="determinate" 
                                            value={
                                                calBorders(parseInt(eventDetails?.stats?.possession_rt[0]), parseInt(eventDetails?.stats?.possession_rt[1]))
                                            }/>
                                            <span className="ps-2 text_stats">{eventDetails?.stats?.possession_rt[1]}</span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>}

                    {eventDetails?.stats?.longest_streak &&<div className="display-attacks">
                        <div>
                            <div className="display_center">
                                    <div>
                                        <div className="stats_headings">Longest Streak</div>
                                        <div className="display_center">
                                            <span className="pe-2 text_stats">{eventDetails?.stats?.longest_streak[0]}</span>
                                            <CircularProgressWithLabel type="longest_streak" color1={team1Color} color2={team2Color}  style={parseInt(eventDetails?.stats?.longest_streak[0]) > parseInt(eventDetails?.stats?.longest_streak[1]) ? {transform: 'rotate(-60deg)'} : {transform: 'rotate(135deg)'}} color="error" size="2rem" thickness="7" variant="determinate" 
                                            value={
                                                calBorders(parseInt(eventDetails?.stats?.longest_streak[0]), parseInt(eventDetails?.stats?.longest_streak[1]))
                                            }/>
                                            <span className="ps-2 text_stats">{eventDetails?.stats?.longest_streak[1]}</span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>}

                    {eventDetails?.stats?.points_won_on_serve &&<div className="display-attacks">
                        <div>
                            <div className="display_center">
                                    <div>
                                        <div className="stats_headings">Points Won On Serve</div>
                                        <div className="display_center">
                                            <span className="pe-2 text_stats">{eventDetails?.stats?.points_won_on_serve[0]}</span>
                                            <CircularProgressWithLabel type="points_won_on_serve" color1={team1Color} color2={team2Color}  style={parseInt(eventDetails?.stats?.points_won_on_serve[0]) > parseInt(eventDetails?.stats?.points_won_on_serve[1]) ? {transform: 'rotate(-60deg)'} : {transform: 'rotate(135deg)'}} color="error" size="2rem" thickness="7" variant="determinate" 
                                            value={
                                                calBorders(parseInt(eventDetails?.stats?.points_won_on_serve[0]), parseInt(eventDetails?.stats?.points_won_on_serve[1]))
                                            }/>
                                            <span className="ps-2 text_stats">{eventDetails?.stats?.points_won_on_serve[1]}</span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>}
                    {eventDetails?.stats?.possession && 
                    <div className="display-attacks">
                        <div>
                            <div className="display_center">
                                    <div>
                                        <div className="stats_headings">Possession %</div>
                                        <div className="display_center">
                                            <span className="pe-2 text_stats">{eventDetails?.stats?.possession[0]}</span>
                                            <CircularProgressWithLabel type="possession_rt" color1={team1Color} color2={team2Color}  style={parseInt(eventDetails?.stats?.possession[0]) > parseInt(eventDetails?.stats?.possession[1]) ? {transform: 'rotate(-60deg)'} : {transform: 'rotate(135deg)'}} color="error" size="2rem" thickness="7" variant="determinate" 
                                            value={
                                                calBorders(parseInt(eventDetails?.stats?.possession[0]), parseInt(eventDetails?.stats?.possession[1]))
                                            }/>
                                            <span className="ps-2 text_stats">{eventDetails?.stats?.possession[1]}</span>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>}
                </div>

                <Grid className="pt-2" container spacing={5}>
                    <Grid item xs={3}>
                        <div>
                        {eventDetails?.stats?.aces &&
                            <div className="display_center me-4">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.aces[0]}</div>
                                    <BorderLinearProgress color1={team1Color} color2={team1Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Aces</div>
                                </div>
                            </div>
                        }
                        {eventDetails?.stats?.corners && 
                            <div className="display_center me-4">
                                <div className="display_stats">
                                    <div className="corners_stars"/>
                                    {eventDetails?.stats?.corners ? eventDetails?.stats?.corners[0] : 0}
                                </div>
                                <div className="display_stats">
                                    <div className="red_stars"/>
                                    <span className="ps-1">{eventDetails?.stats?.redcards ? eventDetails?.stats?.redcards[0] : 0}</span>
                                </div>
                                <div className="display_stats">
                                    <div className="yellow_stars"/>
                                    <span className="ps-1">{eventDetails?.stats?.yellowcards ? eventDetails?.stats?.yellowcards[0] : 0}</span>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="full_width">
                            {eventDetails?.stats?.win_1st_serve &&
                                <div className="display_center full_width" style={{flexDirection: 'column'}}>
                                    <div className="display_center full_width">
                                        <div className="display_center full_width me-1">
                                            <div className="text-center full_width">
                                                <div>{eventDetails?.stats?.win_1st_serve[0]}%</div>
                                                <BorderLinearProgress color1={'#99999938'} color2={team1Color} style={{width: '100%'}} variant="determinate" value={eventDetails?.stats?.win_1st_serve[0]} />
                                            </div>
                                        </div>
                                        <div className="display_center full_width">
                                            <div className="text-center full_width">
                                                <div>{eventDetails?.stats?.win_1st_serve[1]}%</div>
                                                <BorderLinearProgress color1={'#99999938'} color2={team2Color} style={{width: '100%'}} variant="determinate" value={eventDetails?.stats?.win_1st_serve[1]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="stats_headings text-center">Win 1st Serve</div>
                                    </div>
                                </div>
                            }
                            {eventDetails?.stats?.off_target &&
                            <div className="display_center full_width" style={{flexDirection: 'column'}}>
                                <div className="stats_headings pb-2">Shots /On Target</div>
                                <div className="full_width">
                                    <div className="display_center" style={{gap: '.6rem'}}>
                                        <div className="full_width" style={{minWidth: '3rem'}}>
                                            <div className="display_center full_width text_stats">{eventDetails?.stats?.off_target ? (parseInt(eventDetails?.stats?.off_target[0]) + parseInt(eventDetails?.stats?.on_target[0])) : 0} / {eventDetails?.stats?.on_target ? eventDetails?.stats?.on_target[0] : 0}</div>
                                        </div>
                                        <div>
                                            <div className="shots_progress">
                                                <BorderLinearProgress color1={team2Color} color2={team1Color} style={{width: '100%', minWidth: '20vw'}} variant="determinate" 
                                                value={
                                                    calBorders((parseInt(eventDetails?.stats?.off_target[0]) + parseInt(eventDetails?.stats?.on_target[0])), parseInt(eventDetails?.stats?.on_target[0]))
                                                }/>
                                                <BorderLinearProgress color1={team2Color} color2={team1Color} style={{width: '50%'}} variant="determinate" 
                                                value={
                                                    calBorders((parseInt(eventDetails?.stats?.off_target[1]) + parseInt(eventDetails?.stats?.on_target[1])), eventDetails?.stats?.on_target[1])
                                                }/>
                                            </div>
                                        </div>
                                        <div className="full_width" style={{minWidth: '3rem'}}>
                                            <div className="display_center full_width text_stats">{eventDetails?.stats?.off_target ? (parseInt(eventDetails?.stats?.off_target[1]) + parseInt(eventDetails?.stats?.on_target[1])) : 0} / {eventDetails?.stats?.on_target ? eventDetails?.stats?.on_target[1] : 0}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div>
                        {eventDetails?.stats?.aces &&
                            <div className="display_center ms-4">
                                 <div className="text-center">
                                    <div>{eventDetails?.stats?.aces[1]}</div>
                                    <BorderLinearProgress color1={team2Color} color2={team2Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Aces</div>
                                </div>
                            </div>
                        }
                        {eventDetails?.stats?.corners && 
                            <div className="display_center ms-4">
                                <div className="display_stats">
                                    <div className="yellow_stars"/>
                                    <span className="ps-1">{eventDetails?.stats?.yellowcards ? eventDetails?.stats?.yellowcards[1] : 0}</span>
                                </div>
                                <div className="display_stats">
                                    <div className="red_stars"/>
                                    <span className="ps-1">{eventDetails?.stats?.redcards ? eventDetails?.stats?.redcards[1] : 0}</span>
                                </div>
                                <div className="display_stats">
                                    <div className="corners_stars"/>
                                    {eventDetails?.stats?.corners ? eventDetails?.stats?.corners[1] : 0}
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                </Grid>

                {eventDetails?.stats?.double_faults &&  parseInt(eventDetails?.sport_id) !== 1  &&
                    <Grid className="pt-2" container spacing={5}>
                    <Grid item xs={3}>
                        <div>
                        {eventDetails?.stats?.double_faults &&
                            <div className="display_center me-4">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.double_faults[0]}</div>
                                    <BorderLinearProgress color1={team1Color} color2={team1Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Double Faults</div>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="full_width">
                            {eventDetails?.stats?.break_point_conversions &&
                                <div className="display_center full_width" style={{flexDirection: 'column'}}>
                                    <div className="display_center full_width">
                                        <div className="display_center full_width me-1">
                                            <div className="text-center full_width">
                                                <div>{eventDetails?.stats?.break_point_conversions[0]}%</div>
                                                <BorderLinearProgress color1={'#99999938'} color2={team1Color} style={{width: '100%'}} variant="determinate" value={eventDetails?.stats?.break_point_conversions[0]} />
                                            </div>
                                        </div>
                                        <div className="display_center full_width">
                                            <div className="text-center full_width">
                                                <div>{eventDetails?.stats?.break_point_conversions[1]}%</div>
                                                <BorderLinearProgress color1={'#99999938'} color2={team2Color} style={{width: '100%'}} variant="determinate" value={eventDetails?.stats?.break_point_conversions[1]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="stats_headings text-center">Break Point Conversions</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div>
                        {eventDetails?.stats?.double_faults &&
                            <div className="display_center ms-4">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.double_faults[1]}</div>
                                    <BorderLinearProgress color1={team1Color} color2={team1Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Double Faults</div>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                </Grid>
                }

                {eventDetails?.stats?.fouls &&  parseInt(eventDetails?.sport_id) !== 1  &&
                    <Grid className="pt-2" container spacing={5}>
                    <Grid item xs={5} sm={4}>
                        <div style={{gap: '.5rem'}} className="display_center full_width">
                        {eventDetails?.stats?.fouls &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.fouls[0]}</div>
                                    <BorderLinearProgress color1={team1Color} color2={team1Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Fouls</div>
                                </div>
                            </div>
                        }
                        {eventDetails?.stats['2points'] &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats['2points'][0]}</div>
                                    <BorderLinearProgress color1={team1Color} color2={team1Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">2 Pts</div>
                                </div>
                            </div>
                        }
                        {eventDetails?.stats['3points'] &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats['3points'][0]}</div>
                                    <BorderLinearProgress color1={team1Color} color2={team1Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">3 Pts</div>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={3}>
                        <div className="full_width">
                          
                        </div>
                    </Grid>
                    <Grid item xs={5} sm={4}>
                        <div style={{gap: '.5rem'}} className="display_center full_width">
                        {eventDetails?.stats?.fouls &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.fouls[1]}</div>
                                    <BorderLinearProgress color1={team2Color} color2={team2Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Fouls</div>
                                </div>
                            </div>
                        }
                        {eventDetails?.stats['2points'] &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats['2points'][1]}</div>
                                    <BorderLinearProgress color1={team2Color} color2={team2Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">2 Pts</div>
                                </div>
                            </div>
                        }
                        {eventDetails?.stats['3points'] &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats['3points'][1]}</div>
                                    <BorderLinearProgress color1={team2Color} color2={team2Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">3 Pts</div>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                </Grid>
                }

                {(eventDetails?.stats?.free_throws || eventDetails?.stats?.time_outs) &&
                    <Grid className="pt-2" container spacing={5}>
                    <Grid item xs={3}>
                        <div style={{gap: '.5rem'}} className="display_center full_width">
                        {eventDetails?.stats?.time_outs &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.time_outs[0]}</div>
                                    <BorderLinearProgress color1={team1Color} color2={team1Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Time Outs</div>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="full_width">
                            {eventDetails?.stats?.free_throws && eventDetails?.stats?.free_throws_rate &&
                                <div className="display_center full_width" style={{flexDirection: 'column'}}>
                                    <div className="display_center full_width">
                                        <div className="display_center full_width me-1">
                                            <div className="text-center full_width">
                                                <div>{eventDetails?.stats?.free_throws[0]} <span className="stats_headings">{eventDetails?.stats?.free_throws_rate[0]}%</span></div>
                                                <BorderLinearProgress color1={'#99999938'} color2={team1Color} style={{width: '100%'}} variant="determinate" value={eventDetails?.stats?.free_throws_rate[0]} />
                                            </div>
                                        </div>
                                        <div className="display_center full_width">
                                            <div className="text-center full_width">
                                                <div>{eventDetails?.stats?.free_throws[1]} <span className="stats_headings">{eventDetails?.stats?.free_throws_rate[0]}%</span></div>
                                                <BorderLinearProgress color1={'#99999938'} color2={team2Color} style={{width: '100%'}} variant="determinate" value={eventDetails?.stats?.free_throws_rate[1]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="stats_headings text-center">Free Throws</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{gap: '.5rem'}} className="display_center full_width">
                        {eventDetails?.stats?.time_outs &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.time_outs[1]}</div>
                                    <BorderLinearProgress color1={team2Color} color2={team2Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Time Outs</div>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                </Grid>
                }
                {(eventDetails?.stats?.penalties) && parseInt(eventDetails?.sport_id) !== 1 &&
                    <Grid className="pt-2" container spacing={5}>
                    <Grid item xs={3}>
                        <div style={{gap: '.5rem'}} className="display_center full_width">
                        {eventDetails?.stats?.penalties &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.penalties[0]}</div>
                                    <BorderLinearProgress color1={team1Color} color2={team1Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Penalties</div>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="full_width">
                            {eventDetails?.stats?.goals_on_power_play && eventDetails?.stats?.s7 &&

                                <div className="display_center full_width" style={{flexDirection: 'column'}}>
                                    <div className="display_center full_width">
                                        <div className="display_center full_width me-1">
                                            <div className="text-center full_width">
                                                <div>{eventDetails?.stats?.s7[0]} <span className="stats_headings">{eventDetails?.stats?.goals_on_power_play[0]}%</span></div>
                                                <BorderLinearProgress color1={'#99999938'} color2={team1Color} style={{width: '100%'}} variant="determinate" value={eventDetails?.stats?.goals_on_power_play[0]} />
                                            </div>
                                        </div>
                                        <div className="display_center full_width">
                                            <div className="text-center full_width">
                                                <div>{eventDetails?.stats?.s7[1]} <span className="stats_headings">{eventDetails?.stats?.goals_on_power_play[1]}%</span></div>
                                                <BorderLinearProgress color1={'#99999938'} color2={team2Color} style={{width: '100%'}} variant="determinate" value={eventDetails?.stats?.goals_on_power_play[1]} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="stats_headings text-center">Goals on Power Play</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{gap: '.5rem'}} className="display_center full_width">
                        {eventDetails?.stats?.penalties &&
                            <div className="display_center">
                                <div className="text-center">
                                    <div>{eventDetails?.stats?.penalties[1]}</div>
                                    <BorderLinearProgress color1={team2Color} color2={team2Color} style={{width: '100%'}} variant="determinate" value={100} />
                                    <div className="stats_headings text-center">Penalties</div>
                                </div>
                            </div>
                        }
                        </div>
                    </Grid>
                </Grid>
                }
                
                {showMore &&
                <div>
                    {eventDetails?.stats?.action_areas && 
                        <div className='pt-4 pb-3'>
                            <div className="stats_headings text-center">Action Areas</div>

                            <div>
                                <div className="action_areas">
                                    <div>
                                        <div>{parseFloat(eventDetails?.stats?.action_areas[0]).toFixed(0)}%</div>
                                    </div>
                                    <div>
                                        <div>{100 - Math.abs(parseFloat(eventDetails?.stats?.action_areas[1]) + parseFloat(eventDetails?.stats?.action_areas[0])).toFixed(0)}%</div>
                                    </div>
                                    <div>
                                        <div>{parseFloat(eventDetails?.stats?.action_areas[1]).toFixed(0)}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {(eventDetails?.stats?.key_passes || eventDetails?.stats?.passing_accuracy || eventDetails?.stats?.crosses) &&
                        <div className="display_center full_width" style={{flexDirection: 'column', gap: '.5rem'}}>
                            {eventDetails?.stats?.key_passes &&
                                <div className="display_center full_width" style={{flexDirection: 'column'}}>
                                    <div className="stats_headings pb-2">Key Passes</div>
                                    <div className="full_width">
                                        <div className="display_center" style={{gap: '.6rem'}}>
                                            <div className="full_width" style={{minWidth: '3rem'}}>
                                                <div className="display_center full_width text_stats">{eventDetails?.stats?.key_passes[0] || 0}</div>
                                            </div>
                                            <div>
                                                <div className="shots_progress">
                                                    <BorderLinearProgress color1={team2Color} color2={team1Color} style={{width: '100%', minWidth: '20vw'}} variant="determinate" value={
                                                        calBorders(parseInt(eventDetails?.stats?.key_passes[0]),parseInt(eventDetails?.stats?.key_passes[1]))
                                                    } />
                                                </div>
                                            </div>
                                            <div className="full_width" style={{minWidth: '3rem'}}>
                                                <div className="display_center full_width text_stats">{eventDetails?.stats?.key_passes[1] || 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {eventDetails?.stats?.passing_accuracy &&
                                <div className="display_center full_width" style={{flexDirection: 'column'}}>
                                    <div className="stats_headings pb-2">Passing Accuracy</div>
                                    <div className="full_width">
                                        <div className="display_center" style={{gap: '.6rem'}}>
                                            <div className="full_width" style={{minWidth: '3rem'}}>
                                                <div className="display_center full_width text_stats">{parseFloat(eventDetails?.stats?.passing_accuracy[0] * 100).toFixed(0) || 0}%</div>
                                            </div>
                                            <div>
                                                <div className="shots_progress">
                                                    <BorderLinearProgress color1={team2Color} color2={team1Color} style={{width: '100%', minWidth: '20vw'}} variant="determinate" value={
                                                        calBorders(parseFloat(eventDetails?.stats?.passing_accuracy[0]) * 100,parseFloat(eventDetails?.stats?.passing_accuracy[1])* 100)
                                                    } />
                                                </div>
                                            </div>
                                            <div className="full_width" style={{minWidth: '3rem'}}>
                                                <div className="display_center full_width text_stats">{parseFloat(eventDetails?.stats?.passing_accuracy[1] * 100).toFixed(0)  || 0}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {eventDetails?.stats?.crosses &&
                                <div className="display_center full_width" style={{flexDirection: 'column'}}>
                                    <div className="stats_headings pb-2">Crosses</div>
                                    <div className="full_width">
                                        <div className="display_center" style={{gap: '.6rem'}}>
                                            <div className="full_width" style={{minWidth: '3rem'}}>
                                                <div className="display_center full_width text_stats">{eventDetails?.stats?.crosses[0] || 0}</div>
                                            </div>
                                            <div>
                                                <div className="shots_progress">
                                                    <BorderLinearProgress color1={team2Color} color2={team1Color} style={{width: '100%', minWidth: '20vw'}} variant="determinate" value={
                                                        calBorders(parseInt(eventDetails?.stats?.crosses[1]),parseInt(eventDetails?.stats?.crosses[0]))
                                                    } />
                                                </div>
                                            </div>
                                            <div className="full_width" style={{minWidth: '3rem'}}>
                                                <div className="display_center full_width text_stats">{eventDetails?.stats?.crosses[1] || 0}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                }
                {(eventDetails?.stats?.action_areas || (eventDetails?.stats?.key_passes || eventDetails?.stats?.passing_accuracy || eventDetails?.stats?.crosses)) &&
                <div className="link_stats_a">
                    <span onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : 'Show More'}</span>
                </div>
                }
            </div>}
            
            {/* TIMELINE */}
            <div style={value === 2 && eventDetails?.time_status !== '0' ? {} : {display: 'none'}} className="full_width">
                {eventDetails && eventDetails?.events &&
                <div ref={timelineRef} className="stats_container1 mt-2">
                    {(parseInt(eventDetails?.sport_id) === 1 || parseInt(eventDetails?.sport_id) === 78 || parseInt(eventDetails?.sport_id) === 17 ) &&
                    
                    <>
                    {parseInt(eventDetails?.sport_id) === 1 &&
                        <>
                        <div className="link_stats_a">
                            <span onClick={() => setShowTotals(!showTotals)}>{showTotals ? 'Hide Match Totals' : 'Show Match Totals'}</span>
                        </div>
                        {showTotals &&
                        <div>
                            <div className="custom-grid">
                                <div className="c-grid-item"></div>
                                <div className="c-grid-item">
                                    <img src={corner} alt="corner" />
                                </div>
                                <div className="c-grid-item">
                                    <img src={yellow_card} alt="yellow_card" />
                                </div>
                                <div className="c-grid-item">
                                    <img src={red_card} alt="red_card" />
                                </div>
                                <div className="c-grid-item">
                                    {parseInt(eventDetails?.sport_id) === 1 ? <img src={goal} alt="goal" /> :
                                    parseInt(eventDetails?.sport_id) === 78 ? <img src={handball} alt="handball" /> : ''}
                                </div>
                                <div className="c-grid-item">
                                    {eventDetails?.home?.image_id && eventDetails?.home?.image_id !== 0 ?
                                        <div>
                                            <img src={`https://assets.b365api.com/images/team/m/${eventDetails?.home?.image_id}.png`} alt={eventDetails?.away?.name} />
                                        </div>
                                        :
                                        <div>
                                            <SportsSoccerIcon style={{color: team1Color}} />
                                        </div>
                                    }
                                </div>
                            {/* CORNERS, YELLOW, RED, GOALS HOME */}
                            <div className="c-grid-item">
                            {eventDetails?.events?.filter(event => {
                                const eventText = event?.text?.toLowerCase();
                                const homeTeam = eventDetails?.home?.name?.toLowerCase().split(' ');
                                return eventText.includes('corner') && !eventText.includes('race') && homeTeam.some(h => {return eventText.includes(h)})
                            }).length}
                            </div>
                            <div className="c-grid-item">
                                {eventDetails?.events?.filter(event => {
                                    const eventText = event?.text?.toLowerCase();
                                    const homeTeam = eventDetails?.home?.name?.toLowerCase().split(' ');
                                    return eventText.includes('yellow card') && homeTeam.some(h => {return eventText.includes(h)})
                                }).length}
                            </div>
                            <div className="c-grid-item">
                                {eventDetails?.events?.filter(event => {
                                    const eventText = event?.text?.toLowerCase();
                                    const homeTeam = eventDetails?.home?.name?.toLowerCase().split(' ');
                                    return eventText.includes('red card') && homeTeam.some(h => {return eventText.includes(h)})
                                }).length}
                            </div>
                            <div className="c-grid-item">
                                {eventDetails?.events?.filter(event => {
                                    const eventText = event?.text?.toLowerCase();
                                    const homeTeam = eventDetails?.home?.name?.toLowerCase().split(' ');
                                    return eventText.includes('goal') && homeTeam.some(h => {return eventText.includes(h)})
                                }).length}
                            </div>

                            <div className="c-grid-item">
                                {eventDetails?.away?.image_id && eventDetails?.away?.image_id !== 0 ?
                                    <div>
                                        <img src={`https://assets.b365api.com/images/team/m/${eventDetails?.away?.image_id}.png`} alt={eventDetails?.away?.name} />
                                    </div>
                                    :
                                    <div>
                                        <SportsSoccerIcon style={{color: team2Color}} />
                                    </div>
                                }
                            </div>

                            {/* CORNERS, YELLOW, RED, GOALS AWAY */}
                            <div className="c-grid-item">
                            {eventDetails?.events?.filter(event => {
                                const eventText = event?.text?.toLowerCase();
                                const awayTeam = eventDetails?.away?.name?.toLowerCase().split(' ');
                                return eventText.includes('corner') && !eventText.includes('race') && awayTeam.some(h => {return eventText.includes(h)})
                            }).length}
                            </div>
                            <div className="c-grid-item">
                                {eventDetails?.events?.filter(event => {
                                    const eventText = event?.text?.toLowerCase();
                                    const awayTeam = eventDetails?.away?.name?.toLowerCase().split(' ');
                                    return eventText.includes('yellow card') && awayTeam.some(h => {return eventText.includes(h)})
                                }).length}
                            </div>
                            <div className="c-grid-item">
                                {eventDetails?.events?.filter(event => {
                                    const eventText = event?.text?.toLowerCase();
                                    const awayTeam = eventDetails?.away?.name?.toLowerCase().split(' ');
                                    return eventText.includes('red card') && awayTeam.some(h => {return eventText.includes(h)})
                                }).length}
                            </div>
                            <div className="c-grid-item">
                                {eventDetails?.events?.filter(event => {
                                    const eventText = event?.text?.toLowerCase();
                                    const awayTeam = eventDetails?.away?.name?.toLowerCase().split(' ');
                                    return eventText.includes('goal') && awayTeam.some(h => {return eventText.includes(h)})
                                }).length}
                            </div>
                            </div>
                        </div>
                        }
                        </>
                    }

                    <Timeline position="right">
                    {
                        eventDetails?.events?.slice().reverse().map(e =>
                            <React.Fragment key={e.id}>
                            {!e.text.includes('Shot Off Target') && !e.text.includes('Shot On Target') && !e.text.includes('Assist') &&  !e.text.includes('Substitution ') &&
                                <TimelineItem>
                                    <TimelineContent 
                                    sx={{ py: '12px', px: 2, m: 'auto 0' }}
                                    variant="body2"
                                    color="text.secondary"
                                    >
                                        {e.text.includes('Yellow Card') || e.text?.split('~')[1]?.includes('Red Card') ?
                                            <>
                                                {haveSameWord(e.text.split('~')[2], e.text.split('~')[3], eventDetails?.home?.name) &&
                                                    <div className="display_end text_center">
                                                        {e.text?.split('~')[1]?.includes('Yellow Card') ? <img src={yellow_card} alt="yellow_card" /> :
                                                        e.text?.split('~')[1]?.includes('Red Card') ? <img src={red_card} alt="red_card" /> :  ''}
                                                        <span className="ps-2">{e.text.split('~')[1]}</span>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                            {!e.text.includes('Score') ? 
                                            <>
                                                {haveSameWord(e.text.split('-')[2],e.text.split('-')[3], eventDetails?.home?.name) &&
                                                <div className="display_end text_center">
                                                    {e.text?.split('-')[1]?.includes('Corner') ? <img src={corner} alt="corner" /> : 
                                                    e.text?.split('-')[1]?.includes('Goal') ? 
                                                    parseInt(eventDetails?.sport_id) === 1 ? <img src={goal} alt="goal" /> :
                                                    parseInt(eventDetails?.sport_id) === 78 ? <img src={handball} alt="handball" /> :  
                                                    parseInt(eventDetails?.sport_id) === 17 ? <img src={hockey_goal} alt="hockey_goal" /> : '' 
                                                    : 
                                                    e.text?.split('-')[1]?.includes('Offside') ? <img src={offside} alt="offside" /> : 
                                                    e.text?.split('-')[1]?.includes('Power') ? <img src={hockey_power} alt="hockey_power" /> :
                                                    e.text?.split('-')[1]?.includes('Empty Net') ? <img src={hockey_net} alt="hockey_net" /> :
                                                    ''}
                                                    <span className="ps-2">{e.text.split('-')[1]}</span>
                                                </div>
                                                }
                                            </>
                                            :
                                            <>
                                            </>
                                            }
                                            </>
                                        }
                                    </TimelineContent>
                                

                                    <TimelineSeparator sx={e.text.includes('Score') ? {minWidth: '60%'} : 
                                    haveSameWord(e.text.split('-')[2], e.text.split('-')[3], eventDetails?.away?.name) ? {borderColor: team1Color} : {borderColor: team2Color}}
                                        className={!e.text.includes('Score') ? haveSameWord(e.text.split('-')[2], e.text.split('-')[3], eventDetails?.away?.name)  ? 'line-right-timeline' : 'line-left-timeline' : 'line-score-time'}
                                    >
                                        <TimelineConnector />
                                        <div className={e.text.includes('Score') ? 'text-with-lines-light full_width' : ''}>
                                            {e.text.includes('Yellow Card') || e.text?.split('~')[1]?.includes('Red Card') ?
                                                <>
                                                    {e.text?.split('~')[0]}
                                                </>
                                                :
                                                <>
                                                {!e.text.includes('Score') ? 
                                                <>
                                                    {e.text?.split('-')[0]}

                                                    
                                                </>
                                                :
                                                <>
                                                {e.text.split('-')[0]} {'  ' + e.text.split('-')[1] + ' : ' + e.text.split('-')[2] || ''}
                                                </>
                                                }
                                                </>
                                            }
                                        </div>
                                        <TimelineConnector />
                                    </TimelineSeparator>

                                    <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    variant="body2"
                                    color="text.secondary"
                                    >
                                        {e.text.includes('Yellow Card') || e.text?.split('~')[1]?.includes('Red Card') ?
                                            <>
                                                {haveSameWord(e.text.split('~')[2], e.text.split('~')[3], eventDetails?.away?.name) &&
                                                    <div className="display_start text_center">
                                                        {e.text?.split('~')[1]?.includes('Yellow Card') ? <img src={yellow_card} alt="yellow_card" /> :
                                                        e.text?.split('~')[1]?.includes('Red Card') ? <img src={red_card} alt="red_card" /> :  ''}
                                                        <span className="ps-2">{e.text.split('~')[1]}</span>
                                                    </div>
                                                }
                                            </>
                                            :
                                            <>
                                            {!e.text.includes('Score') ? 
                                            <>
                                                {haveSameWord(e.text.split('-')[2], e.text.split('-')[3], eventDetails?.away?.name) &&
                                                <div className="display_start text_center">
                                                    {e.text?.split('-')[1]?.includes('Corner') ? <img src={corner} alt="corner" /> : 
                                                    e.text?.split('-')[1]?.includes('Goal') || e.text?.split('-')[1]?.includes('Race') ? 
                                                    parseInt(eventDetails?.sport_id) === 1 ? <img src={goal} alt="goal" /> :
                                                    parseInt(eventDetails?.sport_id) === 78 ? <img src={handball} alt="handball" /> : 
                                                    parseInt(eventDetails?.sport_id) === 17 ? <img src={hockey_goal} alt="hockey_goal" /> : ''
                                                    :
                                                    e.text?.split('-')[1]?.includes('Offside') ? <img src={offside} alt="offside" /> : 
                                                    e.text?.split('-')[1]?.includes('Power') ? <img src={hockey_power} alt="hockey_power" /> :
                                                    e.text?.split('-')[1]?.includes('Empty Net') ? <img src={hockey_net} alt="hockey_net" /> :
                                                    ''}
                                                    <span className="ps-2">{e.text.split('-')[1]}</span>
                                                </div>
                                                }
                                            </>
                                            :
                                            <>
                                            </>
                                            }
                                            </>
                                        }
                                    </TimelineOppositeContent>
                                </TimelineItem>
                            }
                            </React.Fragment>
                            
                        )
                    }
                    </Timeline>
                     </>
                    }

                    {(parseInt(eventDetails?.sport_id) === 12 || parseInt(eventDetails?.sport_id) === 13 || parseInt(eventDetails?.sport_id) === 16 || parseInt(eventDetails?.sport_id) === 91) && eventDetails?.events &&
                    <div>
                        {eventDetails?.events?.slice().reverse().map(e =>
                            <div className="stats_headings text-center" key={e.id}>
                                {e.text}
                            </div>
                        )}
                    </div>
                    }
                </div>
                }
            </div>

            {value === 2 && matches &&
                <div className="link_stats_a">
                    <span onClick={() => setHidden(!hidden)}>{hidden ? 'Show More' : 'Show Less'}</span>
                </div>
            }

            {/*SCOREBOARD */}
            {value === 0 &&
            <div className="grid_ev1 mb-1 mt-1">
            <ScoreBoard eventDetails={eventDetails} team1Color={team1Color} team2Color={team2Color} event={event} timeStatus={timeStatus} />
            </div>}

            {/* EXTRAS */}
            <div ref={extraRef} style={value === 3 ? {} : {display: 'none'}} className="full_width extras_container">
                {eventDetails?.extra?.ground &&
                    <div className="full_width">
                            <div className="text-with-lines-light full_width">Ground</div>
                            <div>{eventDetails?.extra?.ground}</div>
                    </div>
                }
                {eventDetails?.extra?.home_manager &&
                    <div className="full_width">
                        <div className="text-with-lines-light full_width">{eventDetails?.home?.name + ' Manager'}</div>

                        <div>
                        {eventDetails?.extra?.home_manager?.cc && (
                            (
                                <Flag
                                    code={eventDetails?.extra?.home_manager?.cc.toUpperCase()}  // Use the ISO code for standard countries
                                    alt={`${eventDetails?.extra?.home_manager?.cc.toUpperCase()} flag`}
                                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                />
                            )
                        )}
                        {eventDetails?.extra?.home_manager?.name}
                        </div>
                    </div>
                }
                {eventDetails?.extra?.away_manager &&
                    <div className="full_width">
                        <div className="text-with-lines-light full_width">{eventDetails?.away?.name + ' Manager'}</div>
                        
                        <div>
                        {eventDetails?.extra?.away_manager?.cc && (
                            (
                                <Flag
                                    code={eventDetails?.extra?.away_manager?.cc.toUpperCase()}  // Use the ISO code for standard countries
                                    alt={`${eventDetails?.extra?.away_manager?.cc.toUpperCase()} flag`}
                                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                />
                            )
                        )}
                        {eventDetails?.extra?.away_manager?.name}
                        </div>
                    </div>
                }
                {eventDetails?.extra?.referee &&
                    <div className="full_width">
                        <div className="text-with-lines-light full_width">Referee</div>
                        <div>
                        {eventDetails?.extra?.referee?.cc.toUpperCase() && (
                            (
                                <Flag
                                    code={eventDetails?.extra?.referee?.cc.toUpperCase()}  // Use the ISO code for standard countries
                                    alt={`${eventDetails?.extra?.referee?.cc.toUpperCase()} flag`}
                                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                />
                            )
                        )}
                        {eventDetails?.extra?.referee?.name}
                        </div>
                    </div>
                }
                {eventDetails?.extra?.stadium_data &&
                    <div className="full_width">
                        <div className="text-with-lines-light full_width">Stadium</div>
                        <div>
                            <div>
                                <div className="display_center">
                                    {eventDetails?.extra?.stadium_data?.country && (
                                        countryToISO[eventDetails?.extra?.stadium_data?.country] && (
                                            countryToISO[eventDetails?.extra?.stadium_data?.country].startsWith('e') ? (
                                                // Render custom image for custom flags
                                                <img
                                                    src={getFlagSrc(countryToISO[eventDetails?.extra?.stadium_data?.country])}  // Use the custom flag source
                                                    alt={`${eventDetails?.extra?.stadium_data?.country} flag`}
                                                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                />
                                            ) : (
                                                // Render standard country flags using the Flag component
                                                <Flag
                                                    code={countryToISO[eventDetails?.extra?.stadium_data?.country]}  // Use the ISO code for standard countries
                                                    alt={`${eventDetails?.extra?.stadium_data?.country} flag`}
                                                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                />
                                            )
                                        )
                                    )}
                                    {eventDetails?.extra?.stadium_data?.country}
                                </div>
                                <div>
                                    <span className="stats_headings"> Name: </span> {eventDetails?.extra?.stadium_data?.name || 'TBD'}
                                </div>
                                <div>
                                    <span className="stats_headings"> City: </span> {eventDetails?.extra?.stadium_data?.city || 'TBD'}
                                </div>
                                <div>
                                    <span className="stats_headings"> Capacity: </span> {eventDetails?.extra?.stadium_data?.capacity || 'TBD'}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            

            {/* GAMES */}
            <div ref={extraRef} style={value > 3 && eventDetails?.timeline && eventDetails.scores ? {} : {display: 'none'}} className="full_width extras_container">
                <Timeline position="right">
                    {
                    eventDetails?.timeline?.filter(x => {return parseInt(x.gm) === (value - 3)}).slice().reverse().map((e,i) => 
                        <TimelineItem key={e.id}>
                            <TimelineContent 
                            sx={{ py: '12px', px: 2, m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                            >
                                <div className="display_end text_center">
                                    {e?.te === '0' ? `Point ${(eventDetails?.timeline?.filter(x => {return parseInt(x.gm) === (value - 3)}).length) - (i)} Winner` : ''} 
                                </div>
                            </TimelineContent>

                            <TimelineSeparator className={e?.te === '1' ? 'line-right-timeline' : 'line-left-timeline'} 
                                sx={e?.te === '1' ? {borderColor: team2Color} : {borderColor: team1Color}}
                            >
                                <TimelineConnector />
                                <div>{e.ss}</div>
                                <TimelineConnector />
                            </TimelineSeparator>

                            <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            variant="body2"
                            color="text.secondary"
                            >
                                <div className="display_start text_center">
                                    {e?.te === '1' ? `Point ${(eventDetails?.timeline?.filter(x => {return parseInt(x.gm) === (value - 3)}).length) - (i)} Winner` : ''} 
                                </div>
                            </TimelineOppositeContent>
                        </TimelineItem>
                    )}
                </Timeline>
            </div>

            {value >= 3 && matches &&
                <div className="link_stats_a">
                    <span onClick={() => setHidden1(!hidden1)}>{hidden1 ? 'Show More' : 'Show Less'}</span>
                </div>
            }
        </div>
    )
}