
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import Form from 'react-bootstrap/Form';
import axiosInstance from '../../../redux/axios';
import dayjs from '../../../helpers/UserTimestamp';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Input } from 'keep-react';
import {Grid} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export default function ScheduleScore({choosen, open, changeOpen}){
    const matches = useMediaQuery('(max-width:600px)');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [data, setData] = useState({
        ss: '',
        date: dayjs().utc()
    })

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
      setError('')
      setSuccess('')
      setData({
        ss: '',
        date: dayjs().utc()
    })
    }, [choosen, open])

    useEffect(() => {
        async function getJobs() {
            try{
                const res = await axiosInstance.get('/admin/jobs/'+choosen._id)
                
                if(res.data?.jobs){
                    setJobs(res.data?.jobs)
                }
            }catch{}
        }
        if(choosen){
            getJobs()
        }
        setJobs([])
    }, [choosen, open])
    
    const action = async () => {
        try{
            const response = await axiosInstance.post('/admin/schedule/score/'+choosen._id, {
                ss: data.ss,
                date: data.date
            })

            if(response?.data?.job){
                setJobs(prevJobs => [...prevJobs, response.data.job]);
            }
        }catch(err){
            if(err?.response?.data){
                const errs = err?.response?.data?.errors;
                if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                    if(Object.keys(errs).length === 0){
                        if(err.response.data?.message){
                            setError(err.response.data?.message)
                        }else{
                            setError(err.response?.statusText)
                        }
                    }else{
                        setError(Object.values(errs).join(' ,'))
                    }
                }else{
                    if(err.response.data?.message){
                        setError(err.response.data?.message)
                    }else{
                        setError(err.response?.statusText)
                    }
                }
            }  
        }
    }

    const deleteJ = async (id) => {
        try{
            const res = await axiosInstance.delete('/admin/jobs/'+id)
           
            if(res.data?.result === id){
                setJobs((prevJobs) => prevJobs.filter((job) => job._id !== id));
            }
        }catch{}
    }
    
    return (
        <Modal
        size="lg"
        className="admin_modal"
        show={open}
        onHide={() => changeOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
        data-bs-theme="dark"
        fullscreen={matches}
      >
        <Modal.Header closeButton>
            Schedule Score
        </Modal.Header>
        <Modal.Body>
            {choosen ? 
            <>
                <Form.Group className="mt-2" controlId="auth.email">
                    <Form.Label>Score <span className="form_required">*</span></Form.Label>
                    <Input value={data.ss} onChange={e => setData({...data, ss: e.target.value})} placeholder="format. 0-0" type="text" className="bg-gray-800 text-white" />
                </Form.Group>
                <Form.Group className="mt-2" controlId="auth.email">
                    <Form.Label>At Time (UTC) <span className="form_required">*</span></Form.Label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateTimePicker']}>
                            <DateTimePicker value={data.date} onChange={e => setData({...data, date: e})} className="datetime_pic"/>
                        </DemoContainer>
                    </LocalizationProvider>
                </Form.Group>
                {error !== '' && <div className="error_p">{error}</div>}
                {success !== '' && <div className="success_p">{success}</div>}
                <Button className="mt-3" onClick={action} variant="success">
                    Schedule
                </Button>


                {jobs?.length > 0 &&
                <>
                <div className="mt-2">Jobs</div>
                <Grid className="mb-2" container spacing={2}>
                    {jobs && jobs.map(j =>
                        <Grid item key={j._id} xs={12} sm={6}>
                            <div className="sc_jb">
                                <div className="schedule_job">
                                    <span>Name:</span> Update Score <br/>
                                    <span>Runs At:</span> {dayjs(j.nextRunAt).format('YYYY MMM DD, HH:mm')} <br/>
                                    <span>New Score:</span> {j.data?.ss}
                                </div>
                                <div>
                                    <DeleteOutlineIcon onClick={() => deleteJ(j._id)} style={{background: 'red', borderRadius: '4px', cursor: 'pointer'}}/>
                                </div>
                            </div>
                        </Grid>
                    )}
                </Grid>
                </>}
            </>
            :
            <>No game selected</>
            }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => changeOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}