import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export const BorderLinearProgress = styled(LinearProgress)(({ theme, color1, color2 }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: color1,
      ...theme.applyStyles('dark', {
        backgroundColor: color1,
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: color2,
      ...theme.applyStyles('dark', {
        backgroundColor: color2,
      }),
    },
  }));