import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';

export default function OddType5({ type5, setType5 }){
    const handleChange = (index, field) => (event) => {
        const updatedType3 = [...type5];
        updatedType3[index][field] = event.target.value;
        setType5(updatedType3);
    };

    return(
        <Grid container spacing={2}>
        {type5.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor={`name_${index}`}>Name</Label>
                    <Input 
                        value={item.name || ''} 
                        id={`name_${index}`} 
                        placeholder="Enter name" 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        onChange={handleChange(index, 'name')} 
                    />
                </fieldset>
            </Grid>
        ))}
        {type5.map((item, index) => (
            <Grid item xs={12} sm={6} key={`odd_${index}`}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor={`odd_${index}`}>Odd</Label>
                    <Input 
                        value={item.OD || ''} 
                        id={`odd_${index}`} 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        onChange={handleChange(index, 'OD')} 
                    />
                </fieldset>
            </Grid>
        ))}
    </Grid>
    )
}