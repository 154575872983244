import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';

export default function OddType8({ type8, setType8 }){

    const handleOddsChange = (index, field, value) => {
        const newType8 = [...type8]; // Copy current type1 array
    
        // Check if the index is valid
        if (newType8[index]) {
            newType8[index] = { ...newType8[index], [field]: value }; // Update the specific field
        }
    
        // Set the new state for type1
        setType8(newType8); // Update type1 with the new array
    };

    return(
       <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="title">Title</Label>
                    <Input 
                        id="title" 
                        placeholder="Usually number" 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type8[0]?.name} 
                        onChange={(e) => handleOddsChange(0, 'name', e.target.value)} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={3}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="name_1">Name</Label>
                    <Input 
                        id="name_1" 
                        placeholder="Usually over, odd, yes ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type8[1]?.name} 
                        onChange={(e) => handleOddsChange(1, 'name', e.target.value)} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={3}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="name_2">Name</Label>
                    <Input 
                        id="name_2" 
                        placeholder="Usually exactly ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type8[2]?.name} 
                        onChange={(e) => handleOddsChange(2, 'name', e.target.value)} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={3}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="name_3">Name</Label>
                    <Input 
                        id="name_3" 
                        placeholder="Usually under, even, no ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type8[3]?.name} 
                        onChange={(e) => handleOddsChange(3, 'name', e.target.value)} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={3}></Grid>
            <Grid item xs={12} sm={3}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="odd_1">Odd</Label>
                    <Input 
                        id="odd_1" 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        value={type8[1]?.OD} 
                        onChange={(e) => handleOddsChange(1, 'OD', e.target.value)} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={3}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="odd_2">Odd</Label>
                    <Input 
                        id="odd_2" 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        value={type8[2]?.OD} 
                        onChange={(e) => handleOddsChange(2, 'OD', e.target.value)} 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={3}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="odd_3">Odd</Label>
                    <Input 
                        id="odd_3" 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        value={type8[3]?.OD} 
                        onChange={(e) => handleOddsChange(3, 'OD', e.target.value)} 
                    />
                </fieldset>
            </Grid>
        </Grid>
    )
}