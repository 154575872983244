import axios from 'axios';
import { store } from './store';

//'http://localhost:3000/api/'
//'https://almiron.bet/api/'
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_ENV === 'dev' ? 'http://localhost:3000/api/' : 'https://almiron.bet/api/'
})

axiosInstance.interceptors.request.use((config) => {
    const token = store.getState().user.accessToken;
  
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

export default axiosInstance;