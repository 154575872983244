import { ConnectButton } from "thirdweb/react";
import { darkTheme } from "thirdweb/react";
import { inAppWallet } from "thirdweb/wallets";
import { avalanche } from "thirdweb/chains";
import { client } from "./ThirdwebClient";
import { AutoConnect } from "thirdweb/react";
import axiosInstance from "../redux/axios";
import { setAccessToken, setUser } from '../redux/reducers/UserReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useDisconnect, useActiveWallet } from "thirdweb/react";

const wallets = [
  inAppWallet({
    auth: {
      options: [
        "google",
        "discord",
        "telegram",
        "email",
        "phone",
        "x",
      ],
    },
  }),
];

export default function TWButton() {
   const dispatch = useDispatch();
   const { user } = useSelector(state => state.user)
   const currentRef = useSelector((state) => state.user.ref);
  
   const { disconnect } = useDisconnect();
   const wallet = useActiveWallet();

   const isLoggedIn = async () => {
    return user;
   }

   const login = async (params) => {
    try{
        let link = 'auth/tw-login';
        if(currentRef){
          link+='?ref='+currentRef
        }
        const res = await axiosInstance.post(link, { 
            params: params})
        if(res.status === 200){
            dispatch(setUser(res.data?.user))
            
            dispatch(setAccessToken(res.data?.token))
        }
    }catch{}
   }
   
   const generatePayload = async ({ address }) => {
    try{
        const res = await axiosInstance.get('auth/tw-login', { 
            params: {
                address: address,
                chainId: avalanche.id.toString(),
            }
        })
        return res.data;
    }catch{}
   }
   const logout = async () => {
        disconnect(wallet)
        try{
            await axiosInstance.get('auth/tw-logout')
        }catch{}
   }

   return (
    <>
    <AutoConnect
      wallets={wallets}
      client={client}
    />
    <ConnectButton
      client={client}
      wallets={wallets}
      theme={darkTheme({
        colors: {
          accentText: "#1e6015",
          modalBg: "#06090c",
          primaryButtonBg: "#3892cc",
          primaryButtonText: "#ffffff",
        },
      })}
      connectButton={{ label: "Sign in / Register" }}
      connectModal={{
        size: "compact",
        title: "Sign in to AlmironBet",
        titleIcon: '/logo.png',
        showThirdwebBranding: false,
      }}
      //accountAbstraction={{
      //  chain: base,
      //  factoryAddress: '0xc7A2b48FE9C77Fc1B1e7b672AE5448bbEA7ddD26',
      //  sponsorGas: true,
      //}}
      auth={{
        isLoggedIn: async () => {
            const authResult = await isLoggedIn();
            if (!authResult) return false;
            return true;
          },
          doLogin: async (params) => await login(params),
          getLoginPayload: async ({ address }) =>
            generatePayload({ address }),
          doLogout: async () => await logout(),
      }}
    />
    </>
    
  );
}