const inferCountry = (league_name) => {
    if (league_name.includes('Brazil') || league_name.includes('Teresina')) return 'Brazil';
    if (league_name.includes('Spain') || league_name.includes('Madrid')) return 'Spain';
    if (league_name.includes('England') || league_name.includes('UK') || league_name.includes('Wales') || league_name.includes('British')) return 'United Kingdom';
    if (league_name.includes('Germany') || league_name.includes('German')) return 'Germany';
    if (league_name.includes('Italy') || league_name.includes('San Marino') || league_name.includes('Italia')) return 'Italy';
    if (league_name.includes('France') || league_name.includes('French')) return 'France';
    if (league_name.includes('Argentina')) return 'Argentina';
    if (league_name.includes('Portugal')) return 'Portugal';
    if (league_name.includes('Mexico')) return 'Mexico';
    if (league_name.includes('Colombia')) return 'Colombia';
    if (league_name.includes('Belgium')) return 'Belgium';
    if (league_name.includes('Netherlands')) return 'Netherlands';
    if (league_name.includes('Turkey')) return 'Turkey';
    if (league_name.includes('Scotland')) return 'Scotland';
    if (league_name.includes('Uruguay')) return 'Uruguay';
    if (league_name.includes('Peru')) return 'Peru';
    if (league_name.includes('Chile')) return 'Chile';
    if (league_name.includes('Ireland')) return 'Ireland';
    if (league_name.includes('Paraguay')) return 'Paraguay';
    if (league_name.includes('Czech Republic') || league_name.includes('Czech')) return 'Czech Republic';
    if (league_name.includes('Austria')) return 'Austria';
    if (league_name.includes('Poland')) return 'Poland';
    if (league_name.includes('Sweden') || league_name.includes('Swedish')) return 'Sweden';
    if (league_name.includes('Switzerland')) return 'Switzerland';
    if (league_name.includes('Denmark') || league_name.includes('Danish')) return 'Denmark';
    if (league_name.includes('Iceland')) return 'Iceland';
    if (league_name.includes('Norway')) return 'Norway';
    if (league_name.includes('Finland')) return 'Finland';
    if (league_name.includes('Saudi Arabia')) return 'Saudi Arabia';
    if (league_name.includes('Qatar')) return 'Qatar';
    if (league_name.includes('Iran')) return 'Iran';
    if (league_name.includes('Iraq')) return 'Iraq';
    if (league_name.includes('Israel')) return 'Israel';
    if (league_name.includes('Bahrain')) return 'Bahrain';
    if (league_name.includes('Oman')) return 'Oman';
    if (league_name.includes('Lithuania')) return 'Lithuania';
    if (league_name.includes('Malta')) return 'Malta';
    if (league_name.includes('Macedonia') || league_name.includes('Macedonian') || league_name.includes('North Macedonia')) return 'Macedonia';
    if (league_name.includes('Slovakia')) return 'Slovakia';
    if (league_name.includes('Slovenia')) return 'Slovenia';
    if (league_name.includes('Azerbaijan')) return 'Azerbaijan';
    if (league_name.includes('Uzbekistan')) return 'Uzbekistan';
    if (league_name.includes('India') || league_name.includes('Indian')) return 'India';
    if (league_name.includes('Georgia')) return 'Georgia';
    if (league_name.includes('Kosovo')) return 'Kosovo';
    if (league_name.includes('Indonesia')) return 'Indonesia';
    if (league_name.includes('Mongolia')) return 'Mongolia';
    if (league_name.includes('Rwanda') || league_name.includes('Uganda') || league_name.includes('South Africa') || league_name.includes('Ethiopia') || league_name.includes('Algeria') || league_name.includes('Tunisia') || league_name.includes('Cosafa') || league_name.includes('Mauritania') || league_name.includes('Morocco') || league_name.includes('Nigeria') || league_name.includes('Tanzania') || league_name.includes('Ghana') || league_name.includes('Angola')) return 'Africa';
    if (league_name.includes('Vietnam')) return 'Vietnam';
    if (league_name.includes('Belgrad') || league_name.includes('Serbia')) return 'Serbia';
    if (league_name.includes('Esports') || league_name.includes('Esoccer') || league_name.includes('Ebasketball')) return 'Esports';
    if (league_name.includes('Costa Rica')) return 'Costa Rica';
    if (league_name.includes('Bolivia')) return 'Bolivia'; 
    if (league_name.includes('Ecuador')) return 'Ecuador'; 
    if (league_name.includes('Venezuela')) return 'Venezuela'; 
    if (league_name.includes('Panama')) return 'Panama'; 
    if (league_name.includes('USA') || league_name.includes('El Salvador') || league_name.includes('Honduras')) return 'USA'; 
    if (league_name.includes('Aruba')) return 'Aruba'; 
    if (league_name.includes('New Zealand')) return 'New Zealand'; 
    if (league_name.includes('Solomon')) return 'Solomon'; 
    if (league_name.includes('Albania')) return 'Albania'; 
    if (league_name.includes('Greece')) return 'Greece'; 
    if (league_name.includes('Bulgaria')) return 'Bulgaria'; 
    if (league_name.includes('Croatia')) return 'Croatia'; 
    if (league_name.includes('Cyprus')) return 'Cyprus'; 
    if (league_name.includes('Estonia')) return 'Estonia'; 
    if (league_name.includes('Faroe')) return 'Faroe'; 
    if (league_name.includes('Guatemala')) return 'Guatemala'; 
    if (league_name.includes('Hungary')) return 'Hungary'; 
    if (league_name.includes('Jordan')) return 'Jordan'; 
    if (league_name.includes('Kuwait')) return 'Kuwait'; 
    if (league_name.includes('Andorra')) return 'Andorra'; 
    if (league_name.includes('Armenia')) return 'Armenia'; 
    if (league_name.includes('Montenegro')) return 'Montenegro';
    if (league_name.includes('Latvia')) return 'Latvia';
    if (league_name.includes('Japan') || league_name.includes('Tokyo')) return 'Japan'; 

    return 'International Clubs';
}

const countryToISO = {
    Czechia: 'CZ',
    Ukraine: 'UA',
    Canada: 'CA',
    Australia: 'AU',
    Jamaica: 'JM',
    "Ghana": "GH",
    "Austria": "AT",
    "Angola": "AO",
    "Latvia": "LV",
    "Tanzania": "TZ",
    "Montenegro": "ME",
    "Armenia": "AM",
    "Bulgaria": "BG",
    "Croatia": "HR",
    "Cyprus": "CY",
    "Estonia": "EE",
    "Faroe": "FO", // Faroe Islands
    "Gibraltar": "GI",
    "Guatemala": "GT",
    "Hungary": "HU",
    "Jordan": "JO",
    "Kuwait": "KW",
    "Mauritania": "MR",
    "Morocco": "MA",
    "Nigeria": "NG",
    "Romania": "RO",
    "Andorra": "AD",
    Albania: 'AL',
    Solomon: 'SB',
    'New Zealand': 'NZ',
    Aruba: 'AW',
    USA: 'US',
    Panama: 'PA',
    Venezuela: 'VE',
    Belgium: 'BE',       // ISO code for Belgium
    Colombia: 'CO',      // ISO code for Colombia
    Mexico: 'MX',        // ISO code for Mexico
    Ireland: 'IE',       // ISO code for Ireland
    Portugal: 'PT',      // ISO code for Portugal
    Scotland: 'GB-SCT',  // ISO code for Scotland (part of the UK)
    Paraguay: 'PY',      // ISO code for Paraguay
    Malta: 'MT',         // ISO code for Malta
    'Costa Rica': 'CR',    // ISO code for Costa Rica
    'Saudi Arabia': 'SA',   // ISO code for Saudi Arabia
    Japan: 'JP',
    Ecuador: 'EC',
    Macedonia: 'MK',
    Vietnam: 'VN',
    India: 'IN', 
    Peru: 'PE',          // ISO code for Peru
    Bolivia: 'BO',       // ISO code for Bolivia
    'United Kingdom': 'GB',
    Turkey: 'TR',      // ISO code for Turkey
    Iceland: 'IS',     // ISO code for Iceland
    Switzerland: 'CH',  // ISO code for Switzerland
    France: 'FR',       // ISO code for France
    Germany: 'DE',      // ISO code for Germany
    Sweden: 'SE',       // ISO code for Sweden
    Finland: 'FI',
    Spain: 'ES',  
    Oman: 'OM', 
    Italy: 'IT',
    Azerbaijan: 'AZ',   // ISO code for Azerbaijan
    Serbia: 'RS',       // ISO code for Serbia
    Esports: 'e1',      
    Georgia: 'GE',      // ISO code for Georgia
    Greece: 'GR',       // ISO code for Greece
    Indonesia: 'ID',    // ISO code for Indonesia
    Kosovo: 'XK',       // ISO code for Kosovo
    Mongolia: 'MN',     // ISO code for Mongolia
    Norway: 'NO',       // ISO code for Norway
    Poland: 'PL',       // ISO code for Poland
    Rwanda: 'RW',       // ISO code for Rwanda
    Brazil: 'BR',       // ISO code for Brazil
    Uganda: 'UG',       // ISO code for Uganda
    Ethiopia: 'ET',     // ISO code for Ethiopia
    Argentina: 'AR',    // ISO code for Argentina
    Uruguay: 'UY',      // ISO code for Uruguay
    Slovakia: 'SK',     // ISO code for Slovakia
    Uzbekistan: 'UZ',   // ISO code for Uzbekistan
    Denmark: 'DK',      // ISO code for Denmark
    Chile: 'CL',        // ISO code for Chile
    Iraq: 'IQ',         // ISO code for Iraq
    'Czech Republic': 'CZ', // ISO code for Czech Republic
    Lithuania: 'LT',    // ISO code for Lithuania
    Qatar: 'QA',        // ISO code for Qatar
    Iran: 'IR',         // ISO code for Iran
    Slovenia: 'SI',     // ISO code for Slovenia
    Israel: 'IL',       // ISO code for Israel
    Bahrain: 'BH',      // ISO code for Bahrain    
    Africa: 'e3',       
    'International Clubs': 'e2',  
};
const res = {inferCountry, countryToISO}
export default res;