import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useEffect, useState } from 'react';
import axiosInstance from '../../redux/axios';
import dayjs, { getUserTimezone } from '../../helpers/UserTimestamp';
import { PaginationComponent } from '../../helpers/Pagination';
import {Button} from '@mui/material';
import FeesCommission from './Fees&Commission';
import { useSelector } from 'react-redux';

export default function TransactionDetails({menuItems, selected, steps, activeStep, setActiveStep}){
    const [display, setDisplay] = useState([]);
    const {user} = useSelector(state => state.user);
    const [filtered, setFiltered] = useState([]);
    const userTimezone = getUserTimezone();

    const per_page = 10;
    const [totalPages, setPages] = useState(1)
    const [activePage, setActivePage] = useState(1)

    const indexOfLastItem = activePage * per_page;
    const indexOfFirstItem = indexOfLastItem - per_page;
    const [details, setDetails] = useState(null);

    useEffect(() => {
        if(filtered?.length > 0){
            setPages(Math.ceil(filtered?.length / per_page))
        }else{
            setPages(1)
            setActivePage(1)
        }
    }, [filtered])

    useEffect(() => {
        if(selected === 1 && activeStep !== 0){
            setFiltered(display ? display.filter(x => {
                return x.status === (activeStep === 1 ? 'Pending' : activeStep === 2 ? 'Accepted' : 'Declined')
            }) : [])
        }
        if(selected === 1 && activeStep === 0){
            setFiltered(display)
        }
    }, [activeStep, selected, display])

    useEffect(() => {
        async function getDisplay() {
            try{
                const response = await axiosInstance.get(selected === 1 ? 'withdraw' : selected === 0 ? 'deposits' : '')
            
                if(response.data){
                    setDisplay(response.data)
                    setFiltered(response.data)
                }
            }catch{}
        }
        getDisplay()
        setDisplay([])
        return () => {
            setDisplay([])
        }
    }, [selected])

    const changeActive = (page) => {
        setActivePage(page)
    }

    const setPay = async (d) => {
        try{
            let total = 0;

            if(!d?.payed_taxes && d.show_normal){
                total = parseFloat(d?.amount?.$numberDecimal).toFixed(2) * (15/100)
            }
            if(d.show_custom && d.fee_cal?.length > 0){
                d.fee_cal.reduce((acc, item) => parseFloat(acc) + parseFloat(item.total?.$numberDecimal), 0)
            }
        
            if(total > 0 && total > 10){
                const response = await axiosInstance.post('/deposits/kassify/h2h/'+user._id+'?fee='+d._id+'&type='+(d?.payed_taxes ? 'tax' : 'other'), {paysys: 'kassify', amount: total, currency: 'USD'})
            
                if(response.data){
                window.open(response.data, "_self")
                }
            }
        }catch{}
    }
    /*
    const hasFiveDaysPassed = (date) => {
        const inputDate = new Date(date);
        const currentDate = new Date();
        const differenceInTime = currentDate - inputDate;
    
        const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
        return differenceInDays >= 5;
    }
    */
    return(
        <div className="transaction_sidemenu_table">
            <div className="display_spacebetween">
            {steps?.length > 0 &&
                <div className="display_spacebetween chashier_steps">
                {steps && steps.map(s =>
                    <div onClick={() => {setActiveStep(s.id)}} className={activeStep === s.id ? 'active_cashier_step' : ''} key={s.id}>{s.name}</div>
                )}
                </div>
            }
            </div>
            {(filtered?.length === 0 || !Array.isArray(filtered)) &&
                <div className="main_container_transactions">
                    <div>
                        <QueryStatsIcon className="icon_transactions"/>
                        <div className="pt-2">No {selected !== 0 ? steps[activeStep]?.name + ' ' + menuItems[selected]?.name : 'Deposits'}</div>
                    </div>
                </div>
            }
            {filtered?.length > 0 && Array.isArray(filtered) &&
            <div>
                <PaginationComponent pages={totalPages} activePage={activePage} changeActive={changeActive} />
                <table className="styled-table">
                    <thead className="header_transactions">
                        {/* DEPOSIT */}
                        {selected === 0 && <>
                        <th>Type</th>
                        <th>Initiated by</th>
                        <th>Amount</th>
                        <th>Created At</th>
                        <th>Hash</th>
                        </>}
                        {/* WITHDRAW */}
                        {selected === 1 && <>
                        <th>Method</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th>Tax Payed</th>
                        <th>Actions</th>
                        </>}
                    </thead>
                    <tbody>
                    {filtered?.slice(indexOfFirstItem, indexOfLastItem).map(d =>
                        <tr key={d._id}>
                            {selected === 0 && <>
                            <td>{d.type}</td>
                            <td>{d.initiated_by ? d.initiated_by?.first_name + ' ' + d.initiated_by?.last_name : ''}</td>
                            <td>{parseFloat(d.amount?.$numberDecimal)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0} USD</td>
                            <td>{dayjs(d.createdAt).tz(userTimezone).format('ddd, MMM D, HH:mm')}</td>
                            <td>{d.transactionHash}</td>
                            </>}
                            {selected === 1 && <>
                            <td>{d.method}</td>
                            <td>{parseFloat(d.amount?.$numberDecimal)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0} USD</td>
                            <td>
                                <div className={d.status === 'Pending' ? 'pending_p' : d.status === 'Accepted' ? 'accepted_p' : 'declined_p'}>{d.status}</div>
                            </td>
                            <td>{dayjs(d.createdAt).tz(userTimezone).format('ddd, MMM D, HH:mm')}</td>
                            <td>{!d.show_normal && !d.show_custom ? 'true' : d.show_normal ? (d.payed_taxes ? 'true' : 'false') : d.show_custom ? (d.payed_other ? 'true' : 'false') : 'false'}</td>
                            <td>
                                <div style={{display: 'flex', gap: '.7rem'}}>
                                    {((d.show_normal && !d.payed_taxes) || (d.show_custom && !d.payed_other)) &&
                                        <>
                                        <Button variant="contained" onClick={() => setPay(d)} color="warning" style={{fontSize: '.7rem', padding: '.1rem'}}>Pay now</Button>
                                        <Button variant="contained" onClick={() => setDetails(d)} color="primary" style={{fontSize: '.7rem', padding: '.1rem'}}>Details</Button>
                                        </>
                                    }
                                </div>
                            </td>
                            </>}
                        </tr>
                    )}
                    </tbody>

                    <FeesCommission setDetails={(val) => setDetails(val)} details={details} />
                </table>
            </div>
            }
        </div>
    )
}