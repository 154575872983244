import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link,Grid} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import { useState, useEffect } from 'react';
import {useLocation, useNavigate} from "react-router-dom";

const menuItems= [
    {id: 0, name: 'Terms of Service'},
    {id: 2, name: 'Privacy'},
]

export default function Policies() {
    const [selected, setSelected] = useState(menuItems[0].id);
    const history = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(history?.pathname?.split('/').length > 2){
            let item = history?.pathname?.split('/')[2]
            let index = menuItems.findIndex(x => {return x.name.toLowerCase() === item.toLowerCase().replaceAll('-', ' ')});
            if(index !== -1){
                setSelected(menuItems[index].name)
            }
        }else{
            navigate('/policies')
            setSelected(menuItems[0]?.name)
        }
    }, [history, navigate])
    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div> <SettingsIcon className="faded_icon"/> Policies </div>
                    <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Home
                        </Link> 
                        <Typography color="text.primary">Policies</Typography>
                    </Breadcrumbs>
                </Container>
            </div>

            <Container className="pt-5">
                <Grid container spacing={{ xs: 2, sm: 0, md: 0 }}>
                    <Grid item xs={12} sm={history.pathname !== '/policies' ? 3 : 12} md={history.pathname !== '/policies' ? 3 : 12}>
                        <div className="transaction_sidemenu_cont">
                            {menuItems.map(m => 
                                <div 
                                onClick={() => {setSelected(m.name); navigate('/policies/'+m.name.toLowerCase().replaceAll(' ', '-'))}} 
                                className={selected === m.name ? "el_trans_sidemenu_selected" : "el_trans_sidemenu"} 
                                key={m.id}>
                                    {m.name}
                                </div>
                            )}
                        </div>
                    </Grid>
                    {history.pathname !== '/policies' &&
                    <Grid item xs={12} sm={9} md={9}>
                        <div className="transaction_sidemenu_table">
                            <h4>{selected}</h4>
                        </div>
                    </Grid>
                    }
                </Grid>
            </Container>
        </div>
    )
}