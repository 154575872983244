import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Grid } from '@mui/material';

const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
};


export default function ViewUser({choosen, open, changeOpen}){
    const matches = useMediaQuery('(max-width:600px)');

    return (
        <Modal
        className="admin_modal"
        show={open}
        onHide={() => changeOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
        data-bs-theme="dark"
        fullscreen={matches}
      >
        <Modal.Header closeButton>
            User Details
        </Modal.Header>
        <Modal.Body>
            {choosen ? 
            <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.first_name !== '' ? choosen.first_name + ' ' + choosen.last_name : '-'} secondary="Full Name" />
                    </ListItem>
                </Grid>
                <Grid item xs={6}>
                    <ListItem style={{overflow: 'hidden'}}>
                        <ListItemText sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }} primary={choosen.username || '-'} secondary="ERC20 Wallet Address" />
                    </ListItem>
                </Grid>
                
                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={new Date(choosen.createdAt).toLocaleString('en-US', options).replace(',', '')} secondary="Joined At" />
                    </ListItem>
                </Grid>
                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.email || '-'} secondary="Email" />
                    </ListItem>
                </Grid>

                <Grid item xs={12}>
                    <ListItem>
                        <ListItemText primary={'$' + parseFloat(choosen.balance?.$numberDecimal)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' USD' || '-'} secondary="Balance" />
                    </ListItem>
                </Grid>

                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.address || '-'} secondary="Address" />
                    </ListItem>
                </Grid>
                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.city || '-'} secondary="City" />
                    </ListItem>
                </Grid>
                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.country || '-'} secondary="Country" />
                    </ListItem>
                </Grid>
                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.state || '-'} secondary="State" />
                    </ListItem>
                </Grid>

                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.phone_number || '-'} secondary="Phone Number" />
                    </ListItem>
                </Grid>

                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.role_id?.name  || '-'} secondary="Role" />
                    </ListItem>
                </Grid>

                <Grid item xs={12}>
                    <ListItem>
                        <ListItemText primary={choosen.wallet_address  || '-'} secondary="TRC20 Wallet Address" />
                    </ListItem>
                </Grid>

                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.active ? 'Active' : 'Banned'} secondary="Status" />
                    </ListItem>
                </Grid>

                <Grid item xs={6}>
                    <ListItem>
                        <ListItemText primary={choosen.ban_reason || 'none'} secondary="Ban Reason" />
                    </ListItem>
                </Grid>
            </Grid>
            </>
            :
            <>No user selected</>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => changeOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}