import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   accessToken: '',
   email_address: '',
   user: null,
   transaction: null,
   billing: {
        addressLine: "",
        city: "",
        countryCode: '',
        firstName: '',
        lastName: "",
        state: "",
        postCode: "",
        type: "BILLING",
        id: null,
        userId: null
   },
   set_billing: false,
   card_details: {
    month: "",
    year: "",
    number: "",
    verification_value: "",
   },
   set_card: false
};

const RampSlice = createSlice({
    name: "ramp",
    initialState: initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email_address = action.payload;
        },
        setToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setVerified: (state, action) => {
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken;
        },
        setBillingRamp: (state, action) => {
            state.billing = action.payload;
            state.set_billing = true;
        },
        setCard: (state, action) => {
            
        }
    }
});

export const {
    setEmail, setToken, setVerified, setBillingRamp
} = RampSlice.actions;

export default RampSlice.reducer;