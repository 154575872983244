import { Container } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axiosInstance from '../../redux/axios';

export default function RSettings() {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (error !== '') {
            setSuccess('');
        }
    }, [error]);

    useEffect(() => {
        if (success !== '') {
            setError('');
        }
    }, [success]);

    const changePassword = async () => {
        setSuccess('');
        setError('');
        try {
            if(oldPassword.trim() !== '' && newPassword.trim() !== ''){
                const response = await axiosInstance.post('users/change-password', {oldPassword: oldPassword, newPassword: newPassword})
               
                if(response.data.status === 'OK'){
                    setSuccess('Password changes successfully')
                }
            }else{
                setError('Fill in the form')
            }
        } catch (e) {
            if (e.response?.data?.message) {
                setError(e.response?.data?.message);
            }
            if (e.response?.data?.error) {
                setError(e.response?.data?.error);
            }
        }
    };

    return (
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">Settings</h5>
            </div>

            <Container className="pt-5 pb-5">
                <div className="card_settings">
                    <div className="card_main_settings">
                        <h5> Change Password </h5>

                        <Form data-bs-theme="dark" noValidate>
                            <Form.Group className="mt-4" controlId="setting.old_password">
                                <Form.Label>
                                    Old Password <span className="form_required">*</span>
                                </Form.Label>
                                <div className="input-group">
                                    <Form.Control
                                        className="card_main_settings_input"
                                        style={{ width: '60%' }}
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                        This field is required.
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>

                            <Form.Group className="mt-4" controlId="setting.new_password">
                                <Form.Label>
                                    New Password <span className="form_required">*</span>
                                </Form.Label>
                                <div className="input-group">
                                    <Form.Control
                                        className="card_main_settings_input"
                                        style={{ width: '60%' }}
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                        This field is required.
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                        </Form>

                        {error && <div className="error_p pt-2">{error}</div>}
                        {success && <div className="success_p pt-2">{success}</div>}
                    </div>

                    <div className="card_footer_settings">
                        <Button className="contained_btn" onClick={changePassword} variant="contained">
                            Change Password
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}