import {Container} from 'react-bootstrap';
import "../assets/css/Home.css";
import {Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import ChooseSport from '../components/Home/ChooseSport';
import { useEffect, useState, Fragment } from 'react';
import axiosInstance from '../redux/axios';
import ImageWithFallback from '../helpers/ImageWithFallback';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Typography} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector, useDispatch } from 'react-redux';
import { toggleBetSlip } from '../redux/reducers/UserReducer';
import res from '../helpers/GroupSports.js';
import Flag from 'react-world-flags';
import CarouselHome from '../components/Home/Carousel';
import Selectable from '../components/Sports/Selectable';
import dayjs, { getUserTimezone } from '../helpers/UserTimestamp.js';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';

const inferCountry = res.inferCountry;
const countryToISO = res.countryToISO;

const customFlags = {
    e1: '/static/flags/esports.png',      // Custom image path for Esports
    e2: '/static/flags/international.png', // Custom image path for International Clubs
    e3: '/static/flags/africa.png',        // Custom image for Africa-based competitions
};

function getFlagSrc(code) {
    return customFlags[code] || null;
}

const values = [
    {id: 0, name: 'Prematch'},
    {id: 1, name: 'Live'},
]

export default function Home({setOpenBets}){
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');
  
    // Adjust width and height based on screen size
    const width = isSmallScreen ? 520 : isMediumScreen ? 690 : 1300;
    const height = isSmallScreen ? 100 : isMediumScreen ? 180 : 150;
  
    const userTimezone = getUserTimezone();
    const navigate = useNavigate();
    const [categories1, setCategories] = useState(null)
    const [topLeagues, settopLeagues] = useState(null)
    const [selected, setSelected] = useState(0);
    const [prematch, setPreMatch] = useState(null)
    const [banners, setBanners] = useState([]);
    const [loadingBanners, setLoadingBanners] = useState(true);
    const [loadingEvents, setLoadingEvents]= useState(true);

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    
    useEffect(() => {
        async function getHomePage() {
            try{
                const response1 = await axiosInstance.get('sport/home-other')

                if(response1.data){
                    settopLeagues(response1.data?.topLeagues)
                    setBanners(response1.data.banners)
                    setLoadingBanners(false)
                }

                const response = await axiosInstance.get('sport/home')

                if(response.data?.eventsLeague){
                    setPreMatch(response.data?.eventsLeague)
                }
                if(response.data?.formatted?.categories){
                    setCategories(filterData(response.data?.formatted?.categories))
                }

                setLoadingEvents(false)
            }catch{
                setLoadingBanners(false)
                setLoadingEvents(false)
            }
        }
        setLoadingEvents(true);
        setLoadingBanners(true);
        getHomePage()
    }, [])

    const calOdd = (odd) => {
        try{
        const [numerator, denominator] = odd.split("/").map(Number);
        const result = numerator / denominator;
    
        return isNaN(result) ? 0 : parseFloat(result + 1)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }catch{
            return 0;
        }
    }

    const filterData = (data) => {
        // Define the allowed category titles
        const allowedCategories = ['Politics', 'Soccer', 'Tennis', 'Basketball', 'Handball'];
      
        // Filter and map the data
        return data
          .filter(category => allowedCategories.includes(category.title)) // Filter by specific category titles
          .map(category => ({
            ...category,
            leagues: category.leagues
              .filter(league => league.events.length > 0) // Filter leagues with events
              .map(league => ({
                ...league,
                events: league.events
                  .filter(event => event?.currentMGs?.length > 0 || event?.MGs?.length > 0) // Filter events with non-empty currentMGs
              }))
            }))
          .filter(category => category.leagues.length > 0); // Filter categories with leagues
      };


      const splitTeams = (teams) => {
        if (!teams) return [];
      
        // Split on 'v ' if it exists, otherwise split on '@'
        const delimiter = teams?.includes('v ') ? 'v ' : teams?.includes('@ ') ? '@' : 'vs';
        return teams.split(delimiter);
      };

      const addToSlip = (teamName, number, odd, e, type) => {
        if(odd?.odds && user){
            let tempe = {...e}
            tempe.time_status = "1"
            tempe.league = {
                name: tempe.name || tempe.league?.name
            }
            dispatch(toggleBetSlip({
                event: tempe,
                team: {
                    name: teamName,
                    number: odd.name
                },
                odd: {
                    odd: odd.odds,
                    su: '1'
                },
                type: type,
                comes_from: e._id ? 'cevent' : 'other',
                input: 0
            }))
            setOpenBets(true)
        }
    }

    const groupedByCountry = (leaguesArray) => {
        if(leaguesArray){
            return leaguesArray.reduce((acc, league) => {
                const country = inferCountry(league.title);  // Infer country dynamically
                if (!acc[country]) {
                acc[country] = [];
                }
                acc[country].push(league);
                return acc;
            }, {});
        }else{
            return {}
        } 
    }
    const reorderedGroupedByCountry = (groupedByCountry) => {
        return Object.keys(groupedByCountry)
            .sort((a, b) => {
                // Ensure "International Clubs" is always first
                if (a === 'International Clubs') return -1; // a comes first
                if (b === 'International Clubs') return 1;  // b comes first
    
                // Check if there are any custom events in the leagues for country a and b
                const aHasCustomEvents = groupedByCountry[a].some(league => 
                    league.events && league.events.some(event => event.is_custom)
                );
                const bHasCustomEvents = groupedByCountry[b].some(league => 
                    league.events && league.events.some(event => event.is_custom)
                );
    
                // Prioritize entries with is_custom: true
                if (aHasCustomEvents && !bHasCustomEvents) return -1; // a comes first
                if (!aHasCustomEvents && bHasCustomEvents) return 1;  // b comes first
    
                // Show Esports last
                if (a === 'Esports') return 1; // a comes last
                if (b === 'Esports') return -1; // b comes last
    
                // Maintain alphabetical order for other keys
                return a.localeCompare(b);
            })
            .reduce((acc, key) => {
                acc[key] = groupedByCountry[key];
                return acc;
            }, {});
    };

    return (
        <div className="main_page pb-5">
            <div></div>
            <Container style={{paddingTop: '3rem'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <CarouselHome banners={banners} loadingBanners={loadingBanners} />
                    </Grid>
                    <Grid item xs={12} md={loadingBanners ? 4 : banners?.length > 0 ? 4 : 12}>
                        <Accordion className="mb-2 accordion_other" defaultExpanded>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={"top_leagues"}
                            id="top_leagues"
                            >
                            <Typography className="title_home_selected" sx={{flexShrink: 0 , display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
                                Top Leagues
                            </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Grid container spacing={2}>
                                {topLeagues && topLeagues.map(t => 
                                    <Grid key={t._id} item xs={4} sm={6} md={4}>
                                        <div onClick={() => navigate('/play/league/'+t._id)} className="top_home_league">
                                            <img src={t.img} alt={t.name} />
                                            <div>
                                                <div>{t.name}</div>
                                                <div className="sub_leag_h">{t.sport_id === 1 ? 'Soccer' : ''}</div>
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Container>
            <Container style={{paddingTop: '3rem'}}>
                <Accordion className="mb-2 accordion_other" defaultExpanded>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={"top_leagues"}
                    id="top_leagues"
                    >
                    <Typography className="title_home_selected" sx={{flexShrink: 0 , display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
                        All Sports
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ChooseSport/>
                    </AccordionDetails>
                </Accordion>
            </Container>

            <Container style={{paddingTop: '2rem', overflowX: 'hidden'}}>
                <Selectable values={values} selected={selected} setSelected={setSelected}/>
                
                {!loadingEvents &&
                    <div className="mt-4">
                    {selected === 0 && prematch &&
                        prematch.map(p =>
                            <div key={p?._doc?._id}>
                                {p.events?.length > 0 &&
                                <Accordion key={p?._doc?._id} className="mb-2 mt-2 accordion_other" defaultExpanded>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={"top_leagues"}
                                    id="top_leagues"
                                    className="acc_country"
                                    >
                                    <Typography className="title_home_selected" sx={{flexShrink: 0 , display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
                                            <ImageWithFallback
                                                src={`${p?._doc?.img}`}
                                                alt={p?._doc?.name}
                                                style={{maxWidth: '1rem', height: '1rem', marginTop: '.2rem'}}
                                                fallbackSrc="/static/sports/Other.svg"
                                                /> <span className="ps-2">{p?._doc?.name}</span>
                                    </Typography>
                                    </AccordionSummary>

                                    <AccordionDetails style={{background: '#EDF2FF', color: 'black', padding: '.5rem 0rem'}}>
                                    {p.events && p.events?.map(e =>
                                            <div key={e.id || e._id}>
                                                <div style={{borderBottom: '1px solid #0000003d'}} className="live_grid pt-0">
                                                    <Grid style={{minWidth: '100%'}} container spacing={1}>
                                                    
                                                    <Grid className="pt-2" item xs={5} sm={3}>
                                                                <div className="date_home ps-0">
                                                                    {dayjs.unix(e.time).tz(userTimezone).format('DD MMM YYYY, HH:mm')}
                                                                </div>
                                                                <div className="display_spacebetween">
                                                                    <div className="teams_sport pt-1">{e.teams ? splitTeams(e.teams)[0] : e.home?.name}</div>
                                                                    <div className="results_sport pt-1">
                                                                        <span>{e.ss? e.ss?.split('-')[0] : 0}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="display_spacebetween">
                                                                    <div className="teams_sport pt-1">{e.teams ? splitTeams(e.teams)[1] : e.away?.name}</div>
                                                                    <div className="results_sport pt-1">
                                                                        <span>{e.ss? e.ss?.split('-')[1] : 0}</span>
                                                                    </div>
                                                                </div>
                                                    </Grid>
                                                        
                                                    <Grid item xs={6} sm={8}>
                                                    </Grid>
                                                                
                                                    <Grid item xs={1}>
                                                        <div onClick={() => navigate(e._id ? `/play/sport/Soccer/cevents/${e._id}` : `/play/sport/Soccer/upcoming/${e.id?.split('C')[0]}`)} className="display_center">
                                                            <ArrowForwardIosIcon />
                                                        </div>
                                                    </Grid>
                                                    </Grid>
                                                </div>
                                            </div>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                                }
                            </div>
                        )
                    }          
                    {selected === 1 && categories1 && categories1?.map((category) => (
                            <Accordion key={category.title} className="mb-2 mt-2 accordion_other" defaultExpanded>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"top_leagues"}
                                id="top_leagues"
                                >
                                <Typography className="title_home_selected" sx={{flexShrink: 0 , display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
                                        <ImageWithFallback
                                            src={`/static/sports/${category.title}.svg`}
                                            alt={category.name}
                                            fallbackSrc="/static/sports/Other.svg"
                                            /> <span className="ps-2">{category.title}</span>
                                </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                <div>
                                    {category?.leagues && 
                                    Object.entries(reorderedGroupedByCountry(groupedByCountry(Object.values(category.leagues)))).map(([key,value]) =>
                                        <Fragment key={key}>
                                        {key !== 'Esports' &&
                                            <Accordion className="mb-2" defaultExpanded id={key} >
                                            <AccordionSummary
                                            className="acc_country"
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id={key}
                                            >
                                                <div className="acc_count_flex">
                                                    <div style={{justifyContent: 'flex-start'}} className="acc_count_flex">
                                                        {countryToISO[key] && (
                                                            countryToISO[key].startsWith('e') ? (
                                                                // Render custom image for custom flags
                                                                <img
                                                                    src={getFlagSrc(countryToISO[key])}  // Use the custom flag source
                                                                    alt={`${key} flag`}
                                                                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                                />
                                                            ) : (
                                                                // Render standard country flags using the Flag component
                                                                <Flag
                                                                    code={countryToISO[key]}  // Use the ISO code for standard countries
                                                                    alt={`${key} flag`}
                                                                    style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                                />
                                                            )
                                                        )}
                                                        {key}
                                                    </div>
                                                    <div>{value?.length || 0}</div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails className="acc_details_country">
                                                {value && value.map(league => 
                                                    <Accordion className="mb-2" id={league.title} key={league.title} >
                                                    <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={league.title}
                                                    id={league.title}
                                                    >
                                                    <Typography className="title_home_selected" sx={{flexShrink: 0 , display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
                                                        {league.title}
                                                    </Typography>
                                                    </AccordionSummary>
                        
                                                    <AccordionDetails>
                                                        {league.events && league.events?.map(e =>
                                                            <div key={e.id || e._id}>
                                                                <div className="live_date">
                                                                <span className="is_live me-2"><span className="pulse-circle me-1"/>InPlay </span>
                                                                </div>
                                                                <div className="live_grid pb-4 pt-2">
                                                                    <div className="text-with-lines">{e.currentMGs ? e.currentMGs[0]?.name : '1x2'}</div>
                                                                    <Grid style={{minWidth: '100%'}} container spacing={1}>
                                                                    <Grid className="pt-2" item xs={12} sm={3}>
                                                                        <div className="display_spacebetween">
                                                                            <div className="teams_sport pt-1">{e.teams ? splitTeams(e.teams)[0] : e.home?.name}</div>
                                                                            <div className="results_sport pt-1">
                                                                                <div>{e.ss? e.ss?.split('-')[0] : 0}</div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="display_spacebetween">
                                                                            <div className="teams_sport pt-1">{e.teams ? splitTeams(e.teams)[1] : e.away?.name}</div>
                                                                            <div className="results_sport pt-1">
                                                                                <div>{e.ss? e.ss?.split('-')[1] : 0}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                        
                                                                    <Grid item xs={11} sm={8}>
                                                                        {e.currentMGs &&
                                                                        <>
                                                                        <Grid className="ps-2 pe-2" style={{minWidth: '100%'}} container spacing={2}>
                                                                            {
                                                                                e.currentMGs[0] && 
                                                                                e.currentMGs[0]?.pas.map((b,i) =>
                                                                                    <Fragment key={i}>
                                                                                        <Grid item xs={12/e.currentMGs[0]?.pas.length}>
                                                                                            <div onClick={() => addToSlip((i === 0 ? splitTeams(e.teams)[0] : i === 2 ? splitTeams(e.teams)[1] : i === 1 && i === e.currentMGs[0]?.pas.length-1 ? splitTeams(e.teams)[1] : 'Draw'), i, b, e, e.currentMGs[0]?.name)} className="live_bets">
                                                                                                <div>{i === 0 ? splitTeams(e.teams)[0] : i === 2 ? splitTeams(e.teams)[1] : i === 1 && i === e.currentMGs[0]?.pas.length-1 ? splitTeams(e.teams)[1] : 'Draw'}</div>
                                                                                                <div>{calOdd(b?.odds)}</div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Fragment>
                                                                                )
                                                                            }
                                                                        </Grid>
                                                                        </>}
                                                                        {e.MGs &&
                                                                            e.MGs.filter(x => {return x.title === 'Fulltime Result'}).map((mg,i) =>
                                                                                <Fragment key={mg.title}>
                                                                                    <div className="text-with-lines">{mg?.title}</div>
                                                                                    <Grid className="ps-2 pe-2" style={{minWidth: '100%'}} container spacing={2}>
                                                                                    {mg.odds.map(od =>
                                                                                        <Fragment key={od.name+od.OD}>
                                                                                            <Grid item xs={4}>
                                                                                                <div onClick={() => addToSlip(od.name, i, {odds : parseFloat(od.OD).toFixed(2)}, e, mg?.title)} className="live_bets">
                                                                                                    <div>{od.name}</div>
                                                                                                    <div>{parseFloat(od.OD).toFixed(2)}</div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                        </Fragment>
                                                                                    )}
                                                                                    </Grid>
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                                
                                                                    <Grid item xs={1}>
                                                                        <div onClick={() => navigate(e._id ? `/play/sport/${category.title}/cevents/${e._id}` : `/play/sport/${category.title}/${category.title === 'Soccer' ? 'events' : 'events_other'}/${e.id?.split('C')[0]}`)} className="display_center">
                                                                            <ArrowForwardIosIcon />
                                                                        </div>
                                                                    </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                                )}
                                            </AccordionDetails>
                                            </Accordion>}
                                        </Fragment>
                                    )}   
                                </div>
                                </AccordionDetails>
                            </Accordion>
                    ))}
                    </div>
                }
                {loadingEvents &&
                    <>
                        <Skeleton variant="text" width={width} height={height}/>
                        <Skeleton variant="text" width={width} height={height}/>
                        <Skeleton variant="text" width={width} height={height}/>
                    </>
                }
            </Container>
        </div>
    )
}

