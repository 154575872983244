'use client'
import { DotsThreeOutlineVertical } from 'phosphor-react'
import {
  Badge,
  Dropdown,
  DropdownAction,
  DropdownContent,
  DropdownItem,
  DropdownGroup,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'keep-react'
import {useEffect, useState} from 'react'
import axiosInstance from '../../redux/axios'
import { Container } from 'react-bootstrap';
import ViewUser from './components/ViewUser'
import BanUser from './components/BanModel'
import { PaginationComponent } from '../../helpers/Pagination'
import { MagnifyingGlass } from 'phosphor-react'
import { InputIcon, Input } from 'keep-react'
import AddBalance from './components/AddBalance'
const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
};

export default function AUsers(){
    const [data, setData] = useState({
        users: [],
        total: 0
    })
    const [choosen, setChoosen] = useState(null)
    const [open, changeOpen] = useState(false);
    const [ban, setBan] = useState(false);
    const [addBalance, setAddBalance] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [pages, setPages] = useState(1);

    const [search, setSearch] = useState('');
    const [debouncedValue, setDebouncedValue] = useState(search);
    
    const changeActive = (i) => {
        if(i <= pages && i >= 1){
            setActivePage(i)
            getUsers(i, search)
        }
    }

    async function getUsers(page, value) {
        try{
            let query = '?page='+page+'&ref=false';
            if(value && value !== ''){
                query+='&search='+value
            }
            const resp = await axiosInstance.get('admin/users'+query)

            if(resp?.data){
                setData(resp.data)
                setPages(resp.data.pages)
            }
        }catch{} 
    }

    useEffect(() => {
        async function getUsers() {
            try{
                const resp = await axiosInstance.get('admin/users?ref=false')

                if(resp?.data){
                    setData(resp.data)
                    setPages(resp.data.pages)
                }
            }catch{} 
        }
        getUsers()
    }, [])

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(search);
        }, 2000);

        // Cleanup the timeout if the value changes before 2 seconds
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        if (debouncedValue) {
            setActivePage(1);
            getUsers(1, debouncedValue);
        }else{
            setActivePage(1);
            getUsers(1);
        }
    }, [debouncedValue]);

    const filterSearch = (value) => {
        setSearch(value)
    }

    const updateUser = (user) => {
        if(user){
            setData((prevData) => {
                const updatedUsers = prevData.users.map(u => 
                    u._id === user._id ? { ...u, ...user } : u // Update the matching user
                );
                return {
                    ...prevData, // Spread the previous data
                    users: updatedUsers, // Update the users array
                };
            });
        }
    }
    
    return(
        <div className="main_page pt-0">
            <AddBalance choosen={choosen} open={addBalance} changeOpen={setAddBalance} updateUser={updateUser} />
            <BanUser choosen={choosen} open={ban} changeOpen={setBan} updateUser={updateUser} />
            <ViewUser choosen={choosen} open={open} changeOpen={changeOpen} />
            <div className="dashboard_admin">
                <h5 className="mb-0">Users</h5>
            </div>

            <Container className="pt-5 pb-5">
                <div className="mb-3">
                    <PaginationComponent activePage={activePage} pages={pages} changeActive={changeActive} />
                </div>

                <Table className="bg-gray-800 text-white" style={{captionSide: 'top'}}>
                    <TableCaption className="bg-gray-800 text-white">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-5">
                                <h2 className="text-heading-6 font-semibold text-metal-900 dark:text-white">Total Users</h2>
                                <Badge color="primary">{data.total} Members</Badge>
                            </div>
                            <div className="flex items-center gap-5">
                                <fieldset className="relative">
                                    <Input value={search} onChange={e => filterSearch(e.target.value)} className="bg-gray-800 text-white px-5" placeholder="Search ..." />
                                    <InputIcon className="ps-3">
                                        <MagnifyingGlass size={19} color="#AFBACA" />
                                    </InputIcon>
                                </fieldset>
                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>
                                <div className="w-[190px]">Full Name & Wallet</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[65px]">Balance</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Role</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[90px]">Joined At</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[90px]">Verified</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[90px]">Status</div>
                            </TableHead>
                            <TableHead></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {data?.users?.length > 0 &&
                            data?.users.map((item) => (
                                <TableRow key={item._id}>
                                    <TableCell>
                                        <div>{item.first_name} {item.last_name}</div>
                                        <div>{item.username.toLowerCase()}</div>
                                    </TableCell>
                                    <TableCell>
                                        ${parseFloat(item.balance?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                                    </TableCell>
                                    <TableCell>
                                        {item.role_id?.name}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(item.createdAt).toLocaleString('en-US', options).replace(',', '')}
                                    </TableCell>
                                    <TableCell>
                                        <Badge
                                            className="text-body-4"
                                            color={item.is_step_one_completed  ? 'success' : 'error'}>
                                            {item.is_step_one_completed ? 'True' : 'False'}
                                        </Badge>
                                    </TableCell>
                                    <TableCell>
                                        <Badge
                                            className="text-body-4"
                                            color={item.active  ? 'success' : 'error'}>
                                            {item.active ? 'Active' : 'Banned'}
                                        </Badge>
                                    </TableCell>
                                    <TableCell>
                                        <Dropdown>
                                            <DropdownAction asChild>
                                                <button>
                                                    <DotsThreeOutlineVertical className="size-4 fill-metal-900 dark:fill-white" />
                                                </button>
                                            </DropdownAction>
                                            <DropdownContent className="max-w-[200px] border border-metal-100 p-3 bg-gray-800 text-white">
                                            <DropdownGroup>
                                                <DropdownItem >
                                                    <div style={{height: '100%', width: '100%'}} onClick={() => {setAddBalance(true); setChoosen(item)}}>Add Balance</div>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <div style={{height: '100%', width: '100%'}} onClick={() => {changeOpen(true); setChoosen(item)}}>View</div>
                                                </DropdownItem>
                                                <DropdownItem>
                                                <div style={{height: '100%', width: '100%'}} onClick={() => {setBan(true); setChoosen(item)}}>{item.active  ? 'Ban' : 'Unban'}</div>
                                                </DropdownItem>
                                            </DropdownGroup>
                                            </DropdownContent>
                                        </Dropdown>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>

            </Container>
        </div>
    )
}