import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link} from '@mui/material';
import {useState, useEffect} from 'react';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import {useNavigate} from 'react-router-dom';
import axiosInstance from '../redux/axios';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import {Form} from 'react-bootstrap';
import dayjs, { getUserTimezone } from '../helpers/UserTimestamp.js';


export default function MyBets(){
    const userTimezone = getUserTimezone();

    const {user} = useSelector(state => state.user);

    const navigate = useNavigate();
    const [bets, setBets] = useState([]);

    useEffect(() => {
        async function getMyBets() {
            try{
                const response = await axiosInstance.get('betslip/'+user?._id)
               
                if(response.data){
                    setBets(response.data.data)
                }
            }catch{}
        }
        getMyBets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div> My Bets </div>
                    <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Home
                        </Link> 
                        <Typography color="text.primary">My Bets</Typography>
                    </Breadcrumbs>
                </Container>
            </div>
            <Container style={{padding: '1rem', paddingTop: '2rem'}}>
               
               <div >
                {bets?.length === 0 && 
                     <div className="display_center" style={{flexDirection: 'column', width: '100%', minHeight: '30rem'}}>
                        <NewspaperIcon style={{color: 'gray', width: '3rem', height: '3rem'}} />
                        <div style={{color: 'gray', fontSize: '.9rem'}}>Bet Slip is Empty</div>
                        <div onClick={() => navigate('/play/sport/Soccer')} style={{fontSize: '1.1rem', fontWeight: '600', cursor: 'pointer'}}>Start Betting Now!</div>
                    </div>
                }
                {bets.length > 0 && <div style={{minHeight: '30rem'}}>
                    <Grid container spacing={4}>
                        {bets.map(b => 
                            <Grid item xs={12} sm={6} md={4} key={b._id}>
                                <div style={{marginTop: '1rem', background: '#121212', justifyContent: 'space-between', border: '1px solid #ffffff6e', height: '25rem'}}  className="slip_bet no-after">
                                    <div className="grid_slip_bet">
                                        <Grid sx={{padding: '.4rem'}} container spacing={1}>
                                            <Grid item xs={6}>
                                                <div style={{flexShrink: 0 , display: 'flex', alignItems: 'center', flexWrap: 'nowrap', width: '100%', fontWeight: '600' }}>
                                                   <span className={b.status === 'Pending' ? 'pending_status' : b.status === 'Win' ? 'win_status' : 'loss_status'}>{b.status === 'Pending' ? 'Bet Accepted' : b.status}</span> 
                                                   {b.bet_type}
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div style={{textAlign: 'end'}}>
                                                    {dayjs(b.createdAt).tz(userTimezone).format('DD MMM YYYY, HH:MM')}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div style={{flex: 1, overflowY: 'auto', height: "100%"}} className="slip_bet_body">
                                        {b.bets && b.bets.map((x,i) => 
                                            <div key={i} style={b.bet_type === 'Single' || i === 0 ? {marginTop: '1rem'} : {}} className="slip_bet">
                                                <div className="grid_slip_bet">
                                                    <Grid sx={{padding: '.4rem'}} container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <div className="pb-1 text-white">{x.event?.league?.name}</div>
                                                        </Grid>
                                                    </Grid>
                                                </div>

                                                <div className="slip_bet_body">
                                                    <div className="pt-2 ps-2">
                                                        {x?.event?.home?.name ? x?.event?.home?.name + ' vs ' + x?.event?.away?.name : 
                                                        x?.event?.teams ? x?.event?.teams : ''
                                                        }
                                                    </div>
                                                    <div className="pt-2 ps-2">
                                                        <div className="display_flex" style={{width: '100%'}}>
                                                            <div className="clear_all" style={{width: '100%'}}>{x?.type}: {x?.team?.name}</div>
                                                            <div className="odd_slips">{x?.odd?.odd}</div>
                                                        </div>
                                                    </div>
                                                    {b.bet_type === 'Single' &&
                                                    <Grid sx={{padding: '.4rem'}} container spacing={1}>
                                                        <Grid item xs={8}>
                                                            <Form.Group data-bs-theme="dark" className="mt-2" controlId={`test.${x?.input?.$numberDecimal}`}>
                                                                <Form.Control 
                                                                    disabled={true}
                                                                    className="input_settings" 
                                                                    type="string" 
                                                                    value={'$' + parseFloat(x?.input?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                />
                                                            </Form.Group>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <div className="pt-2" style={{textAlign: 'end'}}>Est. Payout</div>
                                                            <div style={{textAlign: 'end'}}>${parseFloat(x?.est_payout?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                                                        </Grid>
                                                    </Grid>}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="footer_betPop" style={{padding: '.9rem'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                Total Bet 
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="clear_all" style={{textAlign: 'end'}}>
                                                    ${parseFloat(b.total_bet?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {b.bet_type === 'Multi' &&
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    Total Odds 
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="odd_slips" style={{textAlign: 'end'}}>
                                                        {parseFloat(b.total_odds?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid className="pb-3" container spacing={2}>
                                            <Grid item xs={6}>
                                                Est. Payout
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div className="clear_all" style={{textAlign: 'end'}}> 
                                                    ${parseFloat(b.est_payout_final?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>}
               </div>
            </Container>
        </div>
    )
}