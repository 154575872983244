import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Form } from 'react-bootstrap';
import axiosInstance from '../../../redux/axios';

const selectedStatus = ['accept', 'decline']

export default function ViewWithdrawDetails({openDetails, setOpenDetails, selected, updateWithdraw}){
    const matches = useMediaQuery('(max-width:600px)');
    const [status, setStatus] = useState('accept');
    const [hash, setHash] = useState(selected?.hash || '');
    
    const [err, setErr] = useState('');
    const [succ, setSucc] = useState('');
   

    useEffect(() => {
        if(err !== ''){
            setSucc('')
        }
    }, [err])
    useEffect(() => {
        if(succ !== ''){
            setErr('')
        }
    }, [succ])

    const changeStatus = async () => {
        setErr('')
        setSucc('')
        try{
            if(hash !== '' && status !== ''){
                const resp = await axiosInstance.post(`withdraw/confirm/${selected?._id}/${status}`, {hash: hash})
      
                if(resp?.data?.withdrawal){
                    setSucc('Withdraw complete')
                    updateWithdraw(resp?.data?.withdrawal)
                }
            }else{
                setErr('Fill in the form')
            }
        }catch(err){
            if(err?.response?.data){
                const errs = err?.response?.data?.errors;
                if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                    if(Object.keys(errs).length === 0){
                        if(err.response.data?.message){
                            setErr(err.response.data?.message);
                        }else{
                            setErr(err.response?.statusText);
                        }
                    }else{
                        setErr(Object.values(errs).join(' ,'));
                    }
                }else{
                    if(err.response.data?.message){
                        setErr(err.response.data?.message);
                    }else{
                        setErr(err.response?.statusText);
                    }
                }
            }
        }
    }

    return (
        <Modal
        className="admin_modal"
        show={openDetails}
        onHide={() => setOpenDetails(false)}
        backdrop="static"
        keyboard={false}
        centered
        data-bs-theme="dark"
        fullscreen={matches}
      >
        <Modal.Header closeButton>
            Withdraw Details
        </Modal.Header>
        <Modal.Body>
            {selected?.other_info ? 
            <>
            <Grid container spacing={2}>
                {Object.entries(selected?.other_info).map(([key, value]) => (
                    <Grid key={key} item xs={6}>
                        <ListItem>
                            <ListItemText primary={value} secondary={key} />
                        </ListItem>
                    </Grid>
                ))}
            </Grid>
            </>
            :
            <>No details</>}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ListItem>
                        <ListItemText primary={
                            <FormControl sx={{ m: 0, minWidth: '100%' }} size="small">
                                <Form.Select value={status} onChange={(e) => {setStatus(e.target.value)}} aria-label="Default select example">
                                    {selectedStatus.map((t) => (
                                        <option key={t} value={t}>{t}</option>
                                    ))}
                                </Form.Select> 
                            </FormControl>
                        } secondary="Status" />
                    </ListItem>
                </Grid>
                <Grid item xs={12}>
                    <ListItem>
                        <ListItemText primary={
                           <Form.Control type="text" placeholder="" value={hash} onChange={e => setHash(e.target.value)} />
                        } secondary="Hash/Reason" />
                    </ListItem>
                </Grid>
            </Grid>
            {err !== '' && <div className="error_p">{err}</div>}
            {succ !== '' && <div className="success_p">{succ}</div>}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => changeStatus()}>
            Change status
          </Button>
          <Button variant="secondary" onClick={() => setOpenDetails(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}