import { Form, Button } from 'react-bootstrap';
import { Grid } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCountries } from '../../redux/reducers/PageReducer';
import * as formik from 'formik';
import * as yup from 'yup';
import axiosInstance from '../../redux/axios';
import { setUser } from '../../redux/reducers/UserReducer';


const months = ["January","February","March","April","May","June","July",
    "August","September","October","November","December"];
    
function getMonthNumber(monthName) {
    const monthMap = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12'
    };
  
    const monthNumber = monthMap[monthName];
  
    if (monthNumber) {
      return monthNumber;
    } else {
      return 'Invalid month name';
    }
}

function getMonthName(monthNumberStr) {
    // Convert the string to a number
    const monthNumber = parseInt(monthNumberStr, 10);
  
    if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
      return "";
    }
  
    return months[monthNumber - 1];
  }

export default function WalletDetails({handleStep, userDetails, settings}){
    const { user } = useSelector(state => state.user)
    const { Formik } = formik;
    const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
    const [error, setError] = useState('');

    const validationSchema =  yup.object().shape({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        country: yup.string().required(),
        day: yup.number().required().positive().integer().min(1).max(31),
        month: yup.string().required().notOneOf(["Month"], "This field is required.").when('selectedValue', {
            is: val => val && val !== 'Month', // Only require if value is not "Month" and is not empty
            then: yup.string().required('Please select a value'),
        }),
        year: yup.number().required().positive().integer().min(1900).max(parseInt(new Date().getFullYear())-17),
        address: yup.string().required(),
        city: yup.string().required(),
        postal_code: yup.string().required()
    })

    const {countries} = useSelector(state => state.page);
    const dispatch = useDispatch();

    const getCountries = useCallback(
      async () => {
        try{
            const response = await fetch("https://restcountries.com/v2/all");
            const c = await response.json();
            dispatch(setCountries(c));
        }catch{}
      },
      [dispatch],
    )
    
    useEffect(() => {
      if(!countries?.length){
        getCountries()
      }
    }, [countries, getCountries])
    
    function convertNullValues(obj) {
        const result = {};
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            result[key] = obj[key] === null || obj[key] === undefined ? '' : obj[key];
          }
        }
      
        return result;
      }

    return(
        <>
        {user &&
        <Formik
        validationSchema={validationSchema}
        validateOnChange={validateAfterSubmit}
        validateOnBlur={validateAfterSubmit}
        onSubmit={async (values) => {
            if(validationSchema.isValidSync(values)){
                if(!user?.is_step_one_completed){
                    try{
                        const formData = new FormData();
                        formData.append('first_name', values.first_name);
                        formData.append('last_name', values.last_name)
                        formData.append('country', values.country)
                        formData.append('address', values.address)
                        formData.append('date_of_birth', JSON.stringify({year: values.year.toString(), month: getMonthNumber(values.month), day: values.day}))
                        formData.append('city', values.city)
                        formData.append('postal_code', values.postal_code)
                      
                        const response = await axiosInstance.post('auth/step-one/'+user?._id,formData, {
                            headers: {
                              'Content-Type': 'multipart/form-data'
                            }
                          })
                        if(response?.data?.data){
                            setError('')
                            dispatch(setUser(response?.data?.data))
                        }
                    }catch(e){
                        if(e.response?.data?.message){
                            setError(e.response?.data?.message);
                        }
                        if(e.response?.data?.error){
                            setError(e.response?.data?.error);
                        }  
                    }
                }
            }else{
                setError('Invalid schema')
            }
        }}
        
        initialValues={{...convertNullValues(userDetails), day: userDetails?.date_of_birth?.day ?? '', month: getMonthName(userDetails?.date_of_birth?.month) || '', year:  userDetails?.date_of_birth?.year ?? ''}}
        >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
                setError('')
            }, [values]);

            return (
                <>
                {userDetails &&
                
                <Form data-bs-theme="dark" noValidate onSubmit={handleSubmit}>
                    <Grid className="form_modal_in mt-4" container spacing={2}>
                        <Grid item xs={6}>
                            <Form.Group controlId="details.first_name">
                                <Form.Label>First Name <span className="form_required">*</span></Form.Label>
                                <Form.Control  
                                    disabled={userDetails.is_step_one_completed}
                                    className="input_settings" 
                                    type="text" 
                                    name="first_name"
                                    value={values.first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.first_name && !errors.first_name}
                                    isInvalid={!!errors.first_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={6}>
                            <Form.Group controlId="details.last_name">
                                <Form.Label>Last Name <span className="form_required">*</span></Form.Label>
                                <Form.Control   
                                    disabled={userDetails.is_step_one_completed}
                                    type="text" 
                                    name="last_name"
                                    value={values.last_name}
                                    onChange={handleChange}
                                    isValid={touched.last_name && !errors.last_name}
                                    isInvalid={!!errors.last_name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12}>
                            <Form.Group controlId="details.country">
                                <Form.Label>Country <span className="form_required">*</span></Form.Label>
                                <Form.Select 
                                disabled={userDetails.is_step_one_completed}
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                                isValid={touched.country && !errors.country}
                                isInvalid={!!errors.country}
                                aria-label="Country"
                                >
                                    <option></option>
                                    {countries && countries.length > 0 &&
                                    countries.map(c => 
                                        <option value={c.name} key={c.name}>{c.name}</option>
                                    )}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Grid>

                        <Grid item xs={12}>
                            <Form.Group controlId="details.date_of_birth">
                                <Form.Label>Date of Birth <span className="form_required">*</span></Form.Label>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Form.Control   
                                            disabled={userDetails.is_step_one_completed}
                                            type="number" 
                                            name="day"
                                            value={values.day}
                                            onChange={handleChange}
                                            isValid={touched.day && !errors.day}
                                            placeholder="DD"
                                            isInvalid={!!errors.day}
                                            step="any"
                                            min="1"
                                            max="31"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.day}
                                        </Form.Control.Feedback>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Form.Select 
                                        name="month"
                                        disabled={userDetails.is_step_one_completed}
                                        value={values.month}
                                        onChange={handleChange}
                                        isValid={touched.month && !errors.month}
                                        isInvalid={!!errors.month}
                                        placeholder="Month" 
                                        aria-label="Month">
                                            <option>Month</option>
                                            {months.map(m =>  
                                                <option key={m} value={m}>{m}</option>
                                            )}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            This field is required.
                                        </Form.Control.Feedback>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Form.Control   
                                            type="number" 
                                            name="year"
                                            disabled={userDetails.is_step_one_completed}
                                            value={values.year}
                                            onChange={handleChange}
                                            isValid={touched.year && !errors.year}
                                            placeholder="YYYY"
                                            isInvalid={!!errors.year}
                                            step="any"
                                            min="1900"
                                            max={parseInt(new Date().getFullYear())-17}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.year}
                                        </Form.Control.Feedback>
                                    </Grid>
                                </Grid>
                            </Form.Group>
                        </Grid>

                        <Grid item xs={12}>
                            <Form.Group controlId="details.address">
                                <Form.Label>Address <span className="form_required">*</span></Form.Label>
                                <Form.Control   
                                    type="text" 
                                    disabled={userDetails.is_step_one_completed}
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    isValid={touched.address && !errors.address}
                                    isInvalid={!!errors.address}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Grid>

                        <Grid item xs={6}>
                            <Form.Group controlId="details.city">
                                <Form.Label>City <span className="form_required">*</span></Form.Label>
                                <Form.Control   
                                    type="text" 
                                    name="city"
                                    disabled={userDetails.is_step_one_completed}
                                    value={values.city}
                                    onChange={handleChange}
                                    isValid={touched.city && !errors.city}
                                    isInvalid={!!errors.city}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Grid>
                        <Grid item xs={6}>
                            <Form.Group controlId="details.postal_code">
                                <Form.Label>Postal Code <span className="form_required">*</span></Form.Label>
                                <Form.Control   
                                    type="text" 
                                    name="postal_code"
                                    disabled={userDetails.is_step_one_completed}
                                    value={values.postal_code}
                                    onChange={handleChange}
                                    isValid={touched.postal_code && !errors.postal_code}
                                    isInvalid={!!errors.postal_code}
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Grid>
                    </Grid>

                    <Button disabled={userDetails?.is_step_one_completed && settings} className="submit_btn full_width" onClick={() => {
                        setValidateAfterSubmit(true)
                    }} type="submit">Submit</Button>
                    {error !== '' && <div className="error_p pt-2">{error}</div>}
                </Form>
                }
            </>
        )}}
        </Formik>
        }
        </>
    )
}