import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Input, Label } from 'keep-react';

export default function OddType7({ mainInfo, type7, setType7 }) {

    useEffect(() => {
        setType7([
            {
                name: `${mainInfo.home?.name} - ${mainInfo.home?.name}`,
                OD: "",
                order: "0",
            },
            {
                name: `${mainInfo.home?.name} - Draw`,
                OD: "",
                order: "1",
            },
            {
                name: `${mainInfo.home?.name} - ${mainInfo.away?.name}`,
                OD: "",
                order: "2",
            },
            {
                name: `Draw - ${mainInfo.home?.name}`,
                OD: "",
                order: "3",
            },
            {
                name: `Draw - Draw`,
                OD: "",
                order: "4",
            },
            {
                name: `Draw - ${mainInfo.away?.name}`,
                OD: "",
                order: "5",
            },
            {
                name: `${mainInfo.away?.name} - ${mainInfo.home?.name}`,
                OD: "",
                order: "6",
            },
            {
                name: `${mainInfo.away?.name} - Draw`,
                OD: "",
                order: "7",
            },
            {
                name: `${mainInfo.away?.name} - ${mainInfo.away?.name}`,
                OD: "",
                order: "8",
            }
        ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainInfo]);

    const handleChange = (index, field) => (event) => {
        const updatedType7 = [...type7];
        updatedType7[index][field] = event.target.value;
        setType7(updatedType7);
    };

    return (
        <Grid container spacing={2}>
            {type7.map((odd, index) => (
                <Grid item xs={12} sm={4} key={index}>
                    <fieldset className="max-w-md space-y-1">
                        <Label htmlFor={`odd_${index}`}>{odd.name} (odd):</Label>
                        <Input 
                            id={`odd_${index}`} 
                            placeholder="ex. 1.2" 
                            type="number" 
                            className="bg-gray-800 text-white" 
                            value={odd.OD} 
                            onChange={handleChange(index, 'OD')} 
                        />
                    </fieldset>
                </Grid>
            ))}
        </Grid>
    );
}