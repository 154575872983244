//icons
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CloseIcon from '@mui/icons-material/Close';
import {
    Divider,
    IconButton,
} from '@mui/material';
import {useState, useEffect} from 'react';
import {Fragment} from 'react';
import { useSelector } from 'react-redux';
import BetSlipComponent from '../Sports/BetSlipComponent.js';
import Badge from '@mui/material/Badge';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

export default function BetPopUp({DrawerHeader, Drawer, open, setOpen, setShowSetWallet,setShowVIP}){
    const {betSplips} = useSelector(state => state.user);
    const [oldBets, setOldBets] = useState(false);

    useEffect(() => {
        setOldBets(betSplips?.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    
    return(
            <ClickAwayListener onClickAway={() => {
                if(betSplips?.length !== oldBets){
                    setOpen(true)
                    setOldBets(betSplips?.length)
                }else{
                    setOpen(false)
                }
            }}>
                <Drawer 
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    overflow: 'hidden'
                }}
                variant="permanent" 
                open={open}
                anchor="right"
                className="side_drawer_desk"
                >
                    <DrawerHeader sx={{width: '100%', justifyContent: 'space-between'}}>
                        {open ? 
                            <div className="relative_play_comp pe-5" style={{fontSize: '.9rem'}}>
                               <Badge badgeContent={betSplips?.length || 0} color="primary"> <NewspaperIcon className="mb-1" style={{width: '1rem'}} /> </Badge>
                               <span className="ms-3">Bet Slip</span>
                            </div>
                        : <></>}
                        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                            {open ? 
                            <CloseIcon style={{width: '1rem', height: '1rem'}} /> : <Badge badgeContent={betSplips?.length || 0} color="primary"><NewspaperIcon /></Badge>}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    
                    {open &&
                        <BetSlipComponent />
                    }
                </Drawer>
            </ClickAwayListener>
        )
    }