import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

export default function CashierCreditsBoard(){
    const {user} = useSelector(state => state.user);
    return(
        <div className="display_flex_center">
            <div style={{gap: '7rem'}} className="full_width darker_bg">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className="display_spacebetween">
                            <Avatar alt="User Name" src="" />
                            <div>
                                <div className="ps-2">{user?.first_name !== "" ? (user?.first_name + ' ' + user?.last_name) : user?.username }</div>
                                <div style={{fontSize: '.6rem'}} className="ps-2">{user ? user.username : ''}</div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="end-credits">
                            {parseFloat(user?.balance?.$numberDecimal)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' USD'}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}