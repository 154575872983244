
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import Form from 'react-bootstrap/Form';
import axiosInstance from '../../../redux/axios';

export default function AddBalance({choosen, open, changeOpen, updateUser}){
    const matches = useMediaQuery('(max-width:600px)');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [reason, setReason] = useState('');
    const [amount, setAmount] = useState('');

    useEffect(() => {
      setError('')
      setReason('')
      setSuccess('')
    }, [choosen, open])
    
    const action = async () => {
        setError('')
        setSuccess('')
        if(reason !== '' && parseFloat(amount) > 0){
            try{
                let body={}
                if(reason !== ''){
                    body={
                        amount: amount,
                        remark: reason
                    }
                }
                const response = await axiosInstance.post('/deposits/add-balance/'+choosen._id, body)
                
                if(response.status === 201){
                    setSuccess('Balance of $' + amount + ' USD added successfully')
                   
                    updateUser(response.data.user)
                }
            }catch(err){
                if(err?.response?.data){
                    const errs = err?.response?.data?.errors;
                    if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                        if(Object.keys(errs).length === 0){
                            if(err.response.data?.message){
                                setError(err.response.data?.message)
                            }else{
                                setError(err.response?.statusText)
                            }
                        }else{
                            setError(Object.values(errs).join(' ,'))
                        }
                    }else{
                        if(err.response.data?.message){
                            setError(err.response.data?.message)
                        }else{
                            setError(err.response?.statusText)
                        }
                    }
                }
            }
        }else{
            setError('Amount must be greater than 0')
        }
    }
    
    return (
        <Modal
        className="admin_modal"
        show={open}
        onHide={() => changeOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
        data-bs-theme="dark"
        fullscreen={matches}
      >
        <Modal.Header closeButton>
            Add Balance
        </Modal.Header>
        <Modal.Body>
            {choosen ? 
            <>
                Enter the amount you want to add to {choosen.username} {choosen.first_name ? `(${choosen.first_name} ${choosen.last_name})` : ''}'s balance
                {choosen.active &&
                <>
                    <Form.Control value={amount} onChange={e => setAmount(e.target.value)} className="mt-3 mb-3" placeholder="Amount" type="number" />
                    <Form.Control value={reason} onChange={e => setReason(e.target.value)} className="mt-3 mb-3" placeholder="Remark ..." type="number" as="textarea" rows={3} />
                </>
                }
                {error !== '' && <div className="error_p">{error}</div>}
                {success !== '' && <div className="success_p">{success}</div>}
            </>
            :
            <>No user selected</>
            }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => changeOpen(false)}>
            Close
          </Button>
          <Button onClick={action} variant={!choosen?.active ? "success" : "warning"}>
            Add Balance
          </Button>
        </Modal.Footer>
      </Modal>
    )
}