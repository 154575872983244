import { Grid } from '@mui/material';
import { Input, Label } from 'keep-react';

export default function OddType1({ type1, setType1 }) {
    const handleOddsChange = (index, field, value) => {
        const newType1 = [...type1]; // Copy current type1 array
    
        // Check if the index is valid
        if (newType1[index]) {
            newType1[index] = { ...newType1[index], [field]: value }; // Update the specific field
        }
    
        // Set the new state for type1
        setType1(newType1); // Update type1 with the new array
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor={`title-${type1?.title}`}>Title</Label>
                    <Input 
                        value={type1[0]?.name || ''} 
                        onChange={(e) => handleOddsChange(0, 'name', e.target.value)} 
                        id={`title-${type1?.title}`} 
                        placeholder="Usually number" 
                        type="text" 
                        className="bg-gray-800 text-white" 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor={`odd-name-1-${type1.title}`}>Name</Label>
                    <Input 
                        value={type1[1]?.name || ''} 
                        onChange={(e) => handleOddsChange(1, 'name', e.target.value)} 
                        id={`odd-name-1-${type1?.title}`} 
                        placeholder="Usually over, odd, yes ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor={`odd-name-2-${type1?.title}`}>Name</Label>
                    <Input 
                        value={type1[2]?.name || ''} 
                        onChange={(e) => handleOddsChange(2, 'name', e.target.value)} 
                        id={`odd-name-2-${type1?.title}`} 
                        placeholder="Usually under, even, no ..." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor={`odd-1-${type1.title}`}>Odd</Label>
                    <Input 
                        value={type1[1]?.OD || ''} 
                        onChange={(e) => handleOddsChange(1, 'OD', e.target.value)} 
                        id={`odd-1-${type1?.title}`} 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                    />
                </fieldset>
            </Grid>
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor={`odd-2-${type1.title}`}>Odd</Label>
                    <Input 
                        value={type1[2]?.OD || ''} 
                        onChange={(e) => handleOddsChange(2, 'OD', e.target.value)} 
                        id={`odd-2-${type1?.title}`} 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                    />
                </fieldset>
            </Grid>
        </Grid>
    );
}