import { Container } from 'react-bootstrap';
import logo from '../assets/img/logo-text1.png';
import plus18 from '../assets/img/icons/18.png';
import mc from '../assets/img/icons/mc.png';
import visa from '../assets/img/icons/visa.png';

export default function Footer(){
    return(
        <div className="footer_content">
            <Container>
                <div>
                </div>
                <div className="display_footer">
                    <div style={{marginBottom: '1rem'}}>
                        <img src={logo} style={{width: '6.4rem'}} className="me-2" alt="logo" />
                    </div>
                    <p>© 2024 Almiron.bet | All Rights Reserved.</p>
                    <p>61 Republic Street Valletta, VLT <span className="text_white">1117, Malta</span></p>
                    
                    <div className="icons_footer">
                        <a href="https://kassify.pro/?ref=gitsr333">
                            <img alt="kassify" src="https://kassify.pro/promo/kassify.png"/>
                        </a>
                        <a href="/#"><img src={plus18} alt="kassify" /></a>
                        <a href="/#"><img src={mc} alt="kassify" /></a>
                        <a href="/#"><img src={visa} alt="kassify" /></a>
                    </div>
                    <p className="pt-2"> Contact <span className="text_white">support@almiron.bet</span></p>
                </div>
            </Container>
        </div>
    )
}