import { Form } from 'react-bootstrap';
import {FormControl, OutlinedInput,InputAdornment, ButtonGroup, Grid } from '@mui/material';
import axiosInstance from '../../redux/axios';
import { Button } from 'react-bootstrap';

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import { updateUserBalance } from '../../redux/reducers/UserReducer';

const gates = [
    {id: 0, name: 'Visa/MasterCard Bank Transfer'},
    {id: 1, name: 'USDT TRC20 Transfer'},
    {id: 2, name: 'USDC ERC20 Transfer'},
    {id: 3, name: 'Payeer Transfer'},
    {id: 4, name: 'Qiwi Transfer'},
    {id: 5, name: 'WebMoney WMR Transfer'},
]

export default function UserCurrencyAmount({currencies, currency, setCurrency, setAmount, amount, defaultVal, setActiveStep, onHide}){
    const navigate = useNavigate()
    const {user} = useSelector(state => state.user);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [gateway, setGateway] = useState(0);
    const dispatch = useDispatch();

    const [bankTransfer, setBankTransfer] = useState({
        first_name: '',
        last_name: '',
        address: '',
        bank_name: '',
        iban: ''
    })

    const [crypto, setCrypto] = useState({
        wallet_address: ''
    })

    const [other, setOther] = useState({
        account_id: ''
    })

    useEffect(() => {
        setCrypto({wallet_address: ''})
        setOther({account_id: ''})
    }, [gateway])

    useEffect(() => {
        setSuccess('')
        setError('')
    }, [amount])

    useEffect(() => {
        if(error!== ''){
            setSuccess('')
        }
    }, [error])

    useEffect(() => {
      if(success!== ''){
          setError('')
      }
    }, [success])

    const withdraw = async () => {
        if(amount !== ''){
          const data ={
            method: gates[gateway].name,
            amount: amount,
            wallet_address: user?.username,
            other_info: gateway === 0 ? bankTransfer : (gateway === 1 || gateway === 2) ? crypto : other
          }
          try{
              const response = await axiosInstance.post('withdraw', data);
              
              if(response.data?.data){
                  setSuccess(`Congratulations! Withdraw is successfully requested. After 3-5 business days the funds will be transfered to your account.`);
              }

              if(response.data?.user){
                dispatch(updateUserBalance(response.data?.user?.balance?.$numberDecimal))
              }

          }catch(err) {
              if(err?.response?.data){
                  const errs = err?.response?.data?.errors;
                  if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                      if(Object.keys(errs).length === 0){
                          if(err.response.data?.message){
                              setError(err.response.data?.message)
                          }else{
                              setError(err.response?.statusText)
                          }
                      }else{
                          setError(Object.values(errs).join(' ,'))
                      }
                  }else{
                      if(err.response.data?.message){
                          setError(err.response.data?.message)
                      }else{
                          setError(err.response?.statusText)
                      }
                  }
              }
          }
      }else{
          setError('Fill in the form.')
      }
    }

    return(
      <>
        {error !== '' && <div className="error_p pt-2 pb-2">{error}</div>}
        {success !== '' && <div className="success_p pt-2 pb-2">{success}</div>}
        <div className="display_flex_center">
            <div className="display_column full_width pb-3">
                <Form.Label>
                    <div className="display_spacebetween">
                    <div>Amount</div>
                    <div>${parseFloat(user?.balance?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </div>
                </Form.Label>   

                <ButtonGroup aria-label="Basic example">
                    <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        min={defaultVal}
                        endAdornment={<InputAdornment position="end">USD</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                    </FormControl>
                    <Button className="modal_btn" onClick={()=> setAmount(parseFloat(user?.balance?.$numberDecimal).toFixed(2))} variant="contained">MAX</Button>
                </ButtonGroup>
            </div>
        </div>

        <Form.Group className="mb-3" controlId="withdrawForm.gateway">
            <Form.Select className="select_deposit" value={gateway} onChange={e => setGateway(parseInt(e.target.value))}  aria-label="countries">
                {gates.map(g =>
                    <option key={g.id} value={g.id}>{g.name}</option>
                )}
            </Form.Select>
        </Form.Group>

        {(gateway === 0) &&
        <div className="container_withdraws">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                className="input_modal"
                                type="text"
                                value={bankTransfer.first_name}
                                onChange={(e) => setBankTransfer({...bankTransfer, first_name: e.target.value})}
                                min={defaultVal}
                                placeholder="Enter your first name"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </Form.Group>
                </Grid>
                <Grid item xs={6}>
                    <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                className="input_modal"
                                type="text"
                                value={bankTransfer.last_name}
                                onChange={(e) => setBankTransfer({...bankTransfer, last_name: e.target.value})}
                                min={defaultVal}
                                placeholder="Enter your last name"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </Form.Group>
                </Grid>
                <Grid item xs={12}>
                    <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                className="input_modal"
                                type="text"
                                value={bankTransfer.address}
                                onChange={(e) => setBankTransfer({...bankTransfer, address: e.target.value})}
                                min={defaultVal}
                                placeholder="Enter your address"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </Form.Group>
                </Grid>
                <Grid item xs={6}>
                    <Form.Group>
                        <Form.Label>Bank Name</Form.Label>
                        <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                className="input_modal"
                                type="text"
                                value={bankTransfer.bank_name}
                                onChange={(e) => setBankTransfer({...bankTransfer, bank_name: e.target.value})}
                                min={defaultVal}
                                placeholder="Enter the bank name"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </Form.Group>
                </Grid>
                <Grid item xs={6}>
                    <Form.Group>
                        <Form.Label>IBAN/Account Number</Form.Label>
                        <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                            <OutlinedInput
                                className="input_modal"
                                type="text"
                                value={bankTransfer.iban}
                                onChange={(e) => setBankTransfer({...bankTransfer, iban: e.target.value})}
                                min={defaultVal}
                                placeholder="Enter the IBAN/Account Number"
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </Form.Group>
                </Grid>
            </Grid>
        </div>
        }

        {gateway === 3 &&
        <div className="container_withdraws">
            <Form.Group>
                <Form.Label>Payeer Account ID</Form.Label>
                <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="text"
                        value={other.account_id}
                        onChange={(e) => setOther({...other, account_id: e.target.value})}
                        min={defaultVal}
                        placeholder="Enter your payeer account ID"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                </FormControl>
            </Form.Group>
        </div>
        }

        {gateway === 4 &&
        <div className="container_withdraws">
            <Form.Group>
                <Form.Label>Qiwi Wallet Phone Number</Form.Label>
                <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="text"
                        value={other.account_id}
                        onChange={(e) => setOther({...other, account_id: e.target.value})}
                        min={defaultVal}
                        placeholder="Enter your Qiwi wallet phone number"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                </FormControl>
            </Form.Group>
        </div>
        }

        {gateway === 5 &&
        <div className="container_withdraws">
            <Form.Group>
                <Form.Label>WMR Wallet Number</Form.Label>
                <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="text"
                        value={other.account_id}
                        onChange={(e) => setOther({...other, account_id: e.target.value})}
                        min={defaultVal}
                        placeholder="Enter your WMR wallet number"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                </FormControl>
            </Form.Group>
        </div>
        }

        {(gateway === 1 || gateway === 2) && 
        <div className="container_withdraws">
            <Form.Group>
                <Form.Label>Wallet Address</Form.Label>
                <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                    <OutlinedInput
                        className="input_modal"
                        type="text"
                        value={crypto.wallet_address}
                        onChange={(e) => setCrypto({...crypto, wallet_address: e.target.value})}
                        min={defaultVal}
                        placeholder="Enter your wallet address"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                </FormControl>
            </Form.Group>
        </div>
        }

        <Button className="submit_btn full_width" onClick={() => withdraw()}>Withdraw</Button>
        <p className="helper_modal_p">Fees and commission section? <span onClick={() => {navigate('/transactions/withdrawals'); onHide()}}>Go to withdraws</span></p>
      </>
    )
}