import React, { useEffect, useRef, useState } from 'react';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';

const YouTubePlayer = ({ videoId, timeElapsed }) => {
    const playerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true); // State to track loading
    const [updated, setUpdated] = useState(false);
    const [isMuted, setIsMuted] = useState(true); // New state to track if the video is muted

    // Track the script loading state to avoid reloading it
    const scriptLoaded = useRef(false);

    useEffect(() => {
        // Load YouTube Player API script if it's not loaded yet
        if (!scriptLoaded.current) {
            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            scriptLoaded.current = true;
        }

        // Initialize YouTube player when API is ready
        window.onYouTubeIframeAPIReady = () => {
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: videoId,
                playerVars: {
                    autoplay: 1,
                    controls: 0,
                    modestbranding: 1,
                    rel: 0,
                    showinfo: 0,
                    mute: 1, // Start muted to allow autoplay
                },
                events: {
                    onReady: (event) => {

                        event.target.seekTo(timeElapsed);
                        event.target.playVideo();

                        setTimeout(() => {
                            setIsLoading(false);
                        }, 4000);
                    },
                },
            });
        };

        return () => {
            // Clean up when the component unmounts
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoId]); // Re-run only when the videoId changes

    // Seek to the correct time when timeElapsed changes
    useEffect(() => {
        if (playerRef.current && timeElapsed > 0 && !updated) {
            playerRef?.current?.seekTo(timeElapsed);
            setUpdated(true);
        }
    }, [timeElapsed, updated]); // Watch for changes in timeElapsed

    // Function to handle unmuting the video
    const handleUnmute = () => {
        if (playerRef.current) {
            playerRef.current.unMute();
            setIsMuted(false);  // Update state to indicate the video is unmuted
        }
    };

    return (
        <div className="video_out" style={{ position: 'relative', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div className="event_score" style={{ minHeight: '1rem' }}>
                <div>Live Match</div>
            </div>
            <div className="video_cont">
                <div id="youtube-player" style={{ width: '100%', height: '100%' }}></div>

                {/* Show loading overlay */}
                {isLoading && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.99)', // Semi-transparent overlay
                        zIndex: 2, // Make sure it's above the iframe
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white'
                    }}>
                        Loading...
                    </div>
                )}

                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent overlay
                    zIndex: 1 // Make sure it's above the iframe
                }}></div>

                {/* Show unmute button when the video is muted */}
                {isMuted && (
                    <button 
                        onClick={handleUnmute}
                        style={{
                            position: 'absolute',
                            bottom: '10px',
                            left: '7px',
                            zIndex: 3,
                            background: 'red',
                            color: 'white',
                            padding: '2px',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        <VolumeMuteIcon/>
                    </button>
                )}
            </div>
        </div>
    );
};

export default YouTubePlayer;