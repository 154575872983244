import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import {FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import { Button } from 'react-bootstrap';
import axiosInstance from "../../redux/axios";
import {Grid} from '@mui/material';
import getSettings from "../../helpers/GetSettings";

const currencies = [
  {id: 0, name: 'USD', options: [
    {id: 1, name: 'VisaUSD', img: '/static/card/master-card.png'},
    {id: 2, name: 'VisaUSD_v2', img: '/static/card/master-card.png'},
    {id: 3, name: 'VisaUSD_v3', img: '/static/card/master-card.png'},
    {id: 4, name: 'Aple_pay', img: '/static/card/aplpal.png'},
    {id: 5, name: 'google_pay', img: '/static/card/google.png'},
    {id: 6, name: 'kassify', img: 'https://kassify.pro/promo/kassify.png'},
    {id: 7, name: 'crypto', img: '/static/card/crypto.png'},
  ]},
  {id: 1, name: 'EUR', options: [
    {id: 1, name: 'VisaEUR', img: '/static/card/master-card.png'},
    {id: 2, name: 'VisaEUR_V2', img: '/static/card/master-card.png'},
    {id: 3, name: 'VisaEUR_V3', img: '/static/card/master-card.png'},
    {id: 4, name: 'VisaEUR_V4', img: '/static/card/master-card.png'},
    {id: 5, name: 'Aple_pay', img: '/static/card/aplpal.png'},
    {id: 6, name: 'google_pay', img: '/static/card/google.png'},
    {id: 7, name: 'kassify', img: 'https://kassify.pro/promo/kassify.png'},
    {id: 8, name: 'crypto', img: '/static/card/crypto.png'},
  ]},
  {id: 2, name: 'GBP', options: [
    {id: 1, name: 'VisaGBP', img: '/static/card/master-card.png'},
    {id: 2, name: 'VisaGBP_v2', img: '/static/card/master-card.png'},
    {id: 3, name: 'VisaGBP_v3', img: '/static/card/master-card.png'},
    {id: 4, name: 'Aple_pay', img: '/static/card/aplpal.png'},
    {id: 5, name: 'google_pay', img: '/static/card/google.png'},
    {id: 6, name: 'kassify', img: 'https://kassify.pro/promo/kassify.png'},
    {id: 7, name: 'crypto', img: '/static/card/crypto.png'},
  ]},
  /*
  {id: 3, name: 'GHS',options: [
    {id: 1, name: 'VisaGHS', img: '/static/card/master-card.png'},
    {id: 2, name: 'Aple_pay', img: '/static/card/aplpal.png'},
    {id: 3, name: 'google_pay', img: '/static/card/google.png'},
    {id: 4, name: 'kassify', img: 'https://kassify.pro/promo/kassify.png'},
    {id: 5, name: 'crypto', img: '/static/card/crypto.png'},
  ]},
  {id: 4, name: 'UAH',options: [
    {id: 1, name: 'VisaUAH', img: '/static/card/master-card.png'},
    {id: 2, name: 'Aple_pay', img: '/static/card/aplpal.png'},
    {id: 3, name: 'google_pay', img: '/static/card/google.png'},
    {id: 4, name: 'kassify', img: 'https://kassify.pro/promo/kassify.png'},
    {id: 5, name: 'crypto', img: '/static/card/crypto.png'},
  ]},
  {id: 5, name: 'MXN',options: [
    {id: 1, name: 'VisaMXN', img: '/static/card/master-card.png'},
    {id: 2, name: 'VisaMXN2', img: '/static/card/master-card.png'},
    {id: 3, name: 'VisaMXN3', img: '/static/card/master-card.png'},
    {id: 4, name: 'Aple_pay', img: '/static/card/aplpal.png'},
    {id: 5, name: 'google_pay', img: '/static/card/google.png'},
    {id: 6, name: 'kassify', img: 'https://kassify.pro/promo/kassify.png'},
    {id: 7, name: 'crypto', img: '/static/card/crypto.png'},
  ]},*/
]

export default function BuyFiatEmbed({selectedOption}){
    const {user} = useSelector(state => state.user);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState(0);
    const [minDeposit, setMinDeposit] = useState(0);

    useEffect(() => {
      async function getMin() {
        const {min_deposit} = await getSettings();
        setMinDeposit(min_deposit)
      }
      getMin()
    }, [])
    useEffect(() => {
      setSuccess('')
      setError('')
      }, [amount])
      useEffect(() => {
          if(error!== ''){
              setSuccess('')
          }
      }, [error])
      useEffect(() => {
          if(success!== ''){
              setError('')
          }
    }, [success])


    const GetLink = async (name) => {
      setError('')
      setSuccess('')
      if((minDeposit !== undefined ? parseInt(amount) >= parseFloat(minDeposit) : true) && amount !== ''){
        try{
          const response = await axiosInstance.post('/deposits/kassify/h2h/'+user._id, {paysys: name, amount: amount, currency: currencies[currency]?.name})
         
          if(response.data){
            window.open(response.data, "_self")
          }
        }catch(err){
          if(err?.response?.data){
            const errs = err?.response?.data?.errors;
            if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                if(Object.keys(errs).length === 0){
                    if(err.response.data?.message){
                        setError(err.response.data?.message);
                    }else{
                        setError(err.response?.statusText);
                    }
                }else{
                    setError(Object.values(errs).join(' ,'));
                }
            }else{
                if(err.response.data?.message){
                    setError(err.response.data?.message);
                }else{
                    setError(err.response?.statusText);
                }
            }
        }
        }
      }else{
        setError(`Deposit amount must be more than $${minDeposit} ${currencies[currency]?.name || 'USD'}`)
      }
    }
    return(
        <>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Form.Group data-bs-theme="dark"  className="mb-1 mt-2" controlId="deposit.tx">
                    <Form.Label>Amount</Form.Label>
                    <FormControl size="small" sx={{ width: '100%' }} variant="outlined">
                        <OutlinedInput
                        className="input_modal"
                        type="number"
                        endAdornment={
                          <InputAdornment position="end">
                              {currencies[currency]?.name}
                          </InputAdornment>
                          }
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        />
                    </FormControl>
                </Form.Group>
              </Grid>
              <Grid item xs={4}>
                <Form.Group data-bs-theme="dark"  className="mb-1 mt-2" controlId="deposit.tx">
                          <Form.Label>Currency</Form.Label>
                          <Form.Select className="select_deposit" value={currency} onChange={e => setCurrency(e.target.value)}  aria-label="countries">
                          {
                              currencies.map(m =>
                                  <option key={m.id} value={m.id}>{m.name}</option>
                              )
                          }
                      </Form.Select>
                  </Form.Group>
              </Grid>
            </Grid>

            {error !== '' && <div className="error_p pt-2">{error}</div>}
            {success !== '' && <div className="success_p pt-2">{success}</div>}

            <Grid className="mt-3 mb-3" container spacing={2}>
            {currency !== -1 &&
              currencies[currency]?.options?.length > 0 &&
              currencies[currency]?.options.map(o => 
                <Grid key={o.id} item xs={4}>
                  <div className="container_kassify">
                    <div>
                      <img src={o.img} alt={o.name} />
                      <div>{o.name?.includes('Visa') ? o.name : ''}</div>
                    </div>
                    <div>
                     <Button onClick={() => GetLink(o.name)} className="submit_btn full_width">Deposit</Button>
                    </div>
                  </div>
                </Grid>
              )
            }
            </Grid>
            
          </div>
          {/*step === 2 &&
          <>
            <Button onClick={() => restart()} className="mb-2" style={{fontSize: '.8rem'}}>{"<"} Restart</Button>
            <AutoConnect
            client={client}
            timeout={10000}
            />

            <PayEmbed client={client} 
            theme='dark'
            payOptions={{
              metadata: {
                  name: "Pay with Credit Card",
              },
              onPurchaseSuccess: async (info) => {
               
                if(info.type === 'fiat' && info.status?.status === "ON_RAMP_TRANSFER_COMPLETED"){
                  try{
                    const res = await axiosInstance.post('deposits/verify/'+info.status.intentId)
                    if(res.data.user){
                      dispatch(updateUserInfo(res.data?.user))
                    }
                  }catch{}
                }
              },
              mode: "direct_payment",
              paymentInfo: {
                  amount: avaxAmount.toString(),
                  chain: avalanche,
                  token: getDefaultToken(avalanche, "AVAX"),
                  sellerAddress: selectedOption.receiver_address,
              },
              purchaseData: {
                to: user.username
              },
              buyWithCrypto: false,
              buyWithFiat: {
                  testMode: false,
                  prefillSource: {
                    currency: 'USD'
                  }
              },
            }}
            connectOptions={{
              wallets: [],
              chain: avalanche,
              connectModal: {
                  size: "compact",
                },
            }}
            />
          </>
          */}
        </>
    )
}