import '../../assets/css/Admin.css';
import { Grid } from '@mui/material';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import cash from "../../assets/img/3d/cash.png";
import user1 from "../../assets/img/3d/user.png";
import wallet from "../../assets/img/3d/wallet.png";
import axiosInstance from '../../redux/axios';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';

var contrastColor = '#B9B8CE';
var backgroundColor = '#100C2A';
var colorPalette = [
    '#b349ff',
    '#7cffb2',
    '#fddd60',
    '#ff6e76',
    '#58d9f9',
    '#05c091',
    '#ff8a45',
    '#8d48e3',
    '#dd79ff'
];
var axisCommon = function () {
    return {
        axisLine: {
            lineStyle: {
                color: contrastColor
            }
        },
        splitLine: {
            lineStyle: {
                color: '#484753'
            }
        },
        splitArea: {
            areaStyle: {
                color: ['rgba(255,255,255,0.02)', 'rgba(255,255,255,0.05)']
            }
        },
        minorSplitLine: {
            lineStyle: {
                color: '#20203B'
            }
        }
    };
};
echarts.registerTheme('my_theme', {
    backgroundColor: 'transparent',
    color: colorPalette,
    axisPointer: {
        lineStyle: {
            color: '#817f91'
        },
        crossStyle: {
            color: '#817f91'
        },
        label: {
            // TODO Contrast of label backgorundColor
            color: '#fff'
        }
    },
    legend: {
        textStyle: {
            color: contrastColor
        }
    },
    textStyle: {
        color: contrastColor
    },
    title: {
        textStyle: {
            color: '#EEF1FA'
        },
        subtextStyle: {
            color: '#B9B8CE'
        }
    },
    toolbox: {
        iconStyle: {
            borderColor: contrastColor
        }
    },
    dataZoom: {
        borderColor: '#71708A',
        textStyle: {
            color: contrastColor
        },
        brushStyle: {
            color: 'rgba(135,163,206,0.3)'
        },
        handleStyle: {
            color: '#353450',
            borderColor: '#C5CBE3'
        },
        moveHandleStyle: {
            color: '#B0B6C3',
            opacity: 0.3
        },
        fillerColor: 'rgba(135,163,206,0.2)',
        emphasis: {
            handleStyle: {
                borderColor: '#91B7F2',
                color: '#4D587D'
            },
            moveHandleStyle: {
                color: '#636D9A',
                opacity: 0.7
            }
        },
        dataBackground: {
            lineStyle: {
                color: '#71708A',
                width: 1
            },
            areaStyle: {
                color: '#71708A'
            }
        },
        selectedDataBackground: {
            lineStyle: {
                color: '#87A3CE'
            },
            areaStyle: {
                color: '#87A3CE'
            }
        }
    },
    visualMap: {
        textStyle: {
            color: contrastColor
        }
    },
    timeline: {
        lineStyle: {
            color: contrastColor
        },
        label: {
            color: contrastColor
        },
        controlStyle: {
            color: contrastColor,
            borderColor: contrastColor
        }
    },
    calendar: {
        itemStyle: {
            color: backgroundColor
        },
        dayLabel: {
            color: contrastColor
        },
        monthLabel: {
            color: contrastColor
        },
        yearLabel: {
            color: contrastColor
        }
    },
    timeAxis: axisCommon(),
    logAxis: axisCommon(),
    valueAxis: axisCommon(),
    categoryAxis: axisCommon(),

    line: {
        symbol: 'circle'
    },
    graph: {
        color: colorPalette
    },
    gauge: {
        title: {
            color: contrastColor
        }
    },
    candlestick: {
        itemStyle: {
            color: '#FD1050',
            color0: '#0CF49B',
            borderColor: '#FD1050',
            borderColor0: '#0CF49B'
        }
    }
});

echarts.registerTheme('my_theme1', {
    backgroundColor: 'transparent',
    color: '#7cffb2',
    axisPointer: {
        lineStyle: {
            color: '#817f91'
        },
        crossStyle: {
            color: '#817f91'
        },
        label: {
            // TODO Contrast of label backgorundColor
            color: '#fff'
        }
    },
    legend: {
        textStyle: {
            color: contrastColor
        }
    },
    textStyle: {
        color: contrastColor
    },
    title: {
        textStyle: {
            color: '#EEF1FA'
        },
        subtextStyle: {
            color: '#B9B8CE'
        }
    },
    toolbox: {
        iconStyle: {
            borderColor: contrastColor
        }
    },
    dataZoom: {
        borderColor: '#71708A',
        textStyle: {
            color: contrastColor
        },
        brushStyle: {
            color: 'rgba(135,163,206,0.3)'
        },
        handleStyle: {
            color: '#353450',
            borderColor: '#C5CBE3'
        },
        moveHandleStyle: {
            color: '#B0B6C3',
            opacity: 0.3
        },
        fillerColor: 'rgba(135,163,206,0.2)',
        emphasis: {
            handleStyle: {
                borderColor: '#91B7F2',
                color: '#4D587D'
            },
            moveHandleStyle: {
                color: '#636D9A',
                opacity: 0.7
            }
        },
        dataBackground: {
            lineStyle: {
                color: '#71708A',
                width: 1
            },
            areaStyle: {
                color: '#71708A'
            }
        },
        selectedDataBackground: {
            lineStyle: {
                color: '#87A3CE'
            },
            areaStyle: {
                color: '#87A3CE'
            }
        }
    },
    visualMap: {
        textStyle: {
            color: contrastColor
        }
    },
    timeline: {
        lineStyle: {
            color: contrastColor
        },
        label: {
            color: contrastColor
        },
        controlStyle: {
            color: contrastColor,
            borderColor: contrastColor
        }
    },
    calendar: {
        itemStyle: {
            color: backgroundColor
        },
        dayLabel: {
            color: contrastColor
        },
        monthLabel: {
            color: contrastColor
        },
        yearLabel: {
            color: contrastColor
        }
    },
    timeAxis: axisCommon(),
    logAxis: axisCommon(),
    valueAxis: axisCommon(),
    categoryAxis: axisCommon(),

    line: {
        symbol: 'circle'
    },
    graph: {
        color: colorPalette
    },
    gauge: {
        title: {
            color: contrastColor
        }
    },
    candlestick: {
        itemStyle: {
            color: '#FD1050',
            color0: '#0CF49B',
            borderColor: '#FD1050',
            borderColor0: '#0CF49B'
        }
    }
});

export default function RDashboard(){
    const {user} = useSelector(state => state.user)
    const [data, setData] = useState({
        users: '0',
        deposits: '0',
        slips: '0',
        totalBetSum: '0'
    })
    const [copied, setCopied] = useState(false);

    const [options, setOptions] = useState({
        title: {
            text: 'Weekly Users Joined'
        },
        tooltip: {},
        xAxis: {
            data: []
        },
        yAxis: {},
        series: [{
            name: 'Users',
            type: 'bar',
            data: [],
            itemStyle: {
                borderRadius: [1000, 1000, 0, 0],
            }
        }]
    })

    const [option, setOption] = useState({
        title: {
          text: 'Users Joined Today'
        },
        toolbox: {
            feature: {
                saveAsImage: {},
                dataZoom: {},
                restore: {}
            }
        },
        tooltip: {},
        legend: {
          data:['Users']
        },
        xAxis: {
          data: []
        },
        yAxis: {},
        series: [{
          name: 'Users',
          type: 'line',
          data: []
        }]
      });

    useEffect(() => {
        async function GetDashboard(){
            try{
                const res = await axiosInstance.get('admin/referrals/dashboard')
        
                if(res?.data?.users){
                    setData(res.data)
                    setOptions({
                        ...options,
                        xAxis: {
                          ...options.xAxis, 
                          data: res?.data?.formattedChartData.map(item => item.date)
                        },
                        series: {
                            ...options.series, 
                            data: res?.data?.formattedChartData.map(item => item.totalUsers)
                        },
                        tooltip: {
                            formatter: function (params) {
                                const slip = res?.data?.formattedChartData[params.dataIndex]; // Access the corresponding slip
                                return `${slip.date}<br/>Total Users: ${slip.totalUsers}<br/>Users: ${slip.users}`;
                            } // Set custom tooltip formatter
                        },
                    });

                    if(res?.data?.slipsChart2){
                        const xAxisData = res?.data?.slipsChart2?.map(slip => slip.date);
                        const seriesData = res?.data?.slipsChart2?.map(slip => parseFloat(slip.totalUsers));
                        

                        const option = {
                            title: {
                                text: 'Users joined today'
                            },
                            toolbox: {
                                feature: {
                                    saveAsImage: {},
                                    dataZoom: {},
                                    restore: {}
                                }
                            },
                            tooltip: {
                                formatter: function (params) {
                                    const slip = res?.data?.slipsChart2[params.dataIndex]; // Access the corresponding slip
                                    return `${slip.date}<br/>Total Users: ${slip.totalUsers}<br/>Users: ${slip.users}`;
                                } // Set custom tooltip formatter
                            },
                            legend: {
                                data: ['Slips']
                            },
                            xAxis: {
                                data: xAxisData // Set xAxis data to the time values
                            },
                            yAxis: {},
                            series: [{
                                name: 'Slips',
                                type: 'line',
                                data: seriesData // Set series data to the total_bet values
                            }]
                        };

                        setOption(option)
                    }
                }

            }catch{}
        }
        GetDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const copyAddress = (val) => {
        try{
            const textarea = document.createElement("textarea");
            textarea.value = val;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
  
            setCopied(true);
  
            setTimeout(() => {
                setCopied(false);
            }, 1000)
        }catch{}
    }
    
    return(
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">Dashboard</h5>
            </div>

            <Container className="pt-5">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className="card_dash" id="card_dash1">
                            <div>
                                <div>Total Users</div>
                                <h4>{data.users}</h4>
                            </div>
                            <div>
                                <img src={user1} alt="user"/>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className="card_dash" id="card_dash2">
                            <div>
                                <div>Total Deposits</div>
                                <h4>$ {parseFloat(data.deposits?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD</h4>
                            </div>
                            <div>
                                <img src={wallet} alt="wallet"/>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid className="pt-5" container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                    <div className="card_dash" id="card_dash4">
                            <div>
                                <div>My Referral Code</div>
                                <h4>
                                    {copied ? 
                                    <DoneIcon style={{color: 'green'}}/> :
                                    <ContentCopyIcon onClick={() => copyAddress('https://almiron.bet/play?ref='+user?.ref_code)} className="copy_icon pe-1 me-1"/>
                                    }
                                    {'https://almiron.bet/play?ref='+user?.ref_code}</h4>
                            </div>
                            <div>
                                <img src={cash} alt="cash"/>
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid className="pt-3" container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <div className="card_chart">
                            <div>
                                <ReactECharts
                                    option={options}
                                    style={{ height: 400 }}
                                    opts={{ renderer: 'svg' }}
                                    theme='my_theme'
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className="card_chart">
                            <div>
                                <ReactECharts
                                    option={option}
                                    id="myChart"
                                    style={{ height: 400 }}
                                    opts={{ locale: 'FR' }}
                                    theme='my_theme1'
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                
            </Container>
        </div>
    )
}