
import Divider from '@mui/material/Divider';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Grid } from '@mui/material';
import { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import { Form } from 'react-bootstrap';
import axiosInstance from '../../../redux/axios';

const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
};
const selectedStatus = ['Pending', 'Win', 'Loss']
export default function BetDetails({choosen, open, changeOpen, updateBet}){
    const matches = useMediaQuery('(max-width:600px)');
    const [status, setStatus] = useState(choosen?.status || '');

    const changeStatus = async (value) => {
        
        const before = status + '';
        try{
            const response = await axiosInstance.post("betslip/update-status/"+choosen?._id, {status: value});
           
            if(response.status === 200){
                setStatus(value)
                updateBet(response.data.bet)
            }
        }catch{
            setStatus(before)
        }
    }
    return (
        <Modal
        className="admin_modal"
        show={open}
        onHide={() => changeOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
        data-bs-theme="dark"
        fullscreen={matches}
      >
        <Modal.Header closeButton>
            Bet Slip Details
        </Modal.Header>
        <Modal.Body>
            {choosen ? 
            <>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ListItem>
                            <ListItemText primary={choosen.initiated_by?.first_name !== '' ? choosen.initiated_by?.first_name + ' ' + choosen.initiated_by?.last_name : '-'} secondary="Initiated By" />
                        </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                        <ListItem>
                            <ListItemText primary={choosen.initiated_by?.username || '-'}  sx={{ wordBreak: 'break-word', overflowWrap: 'break-word' }} secondary="Wallet Address ERC20" />
                        </ListItem>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <ListItem>
                            <ListItemText primary={new Date(choosen.createdAt).toLocaleString('en-US', options).replace(',', '')} secondary="Created At" />
                        </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                        <ListItem>
                            <ListItemText primary={
                                <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                                    <Form.Select value={status} onChange={(e) => {changeStatus(e.target.value)}} aria-label="Default select example">
                                        {selectedStatus.map((t) => (
                                            <option key={t} value={t}>{t}</option>
                                        ))}
                                    </Form.Select> 
                                </FormControl>
                            } secondary="Status" />
                        </ListItem>
                    </Grid>

                    <Grid item xs={6}>
                        <ListItem>
                            <ListItemText primary={'$' + parseFloat(choosen.total_bet?.$numberDecimal)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' USD' || '-'} secondary="Total Bet" />
                        </ListItem>
                    </Grid>

                    <Grid item xs={6}>
                    <ListItem>
                            <ListItemText primary={'$' + parseFloat(choosen.est_payout_final?.$numberDecimal)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' USD' || '-'} secondary="Estimated Payout" />
                        </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                        <ListItem>
                            <ListItemText primary={choosen.bet_type || '-'} secondary="Bet Type" />
                        </ListItem>
                    </Grid>
                    <Grid item xs={6}>
                        <ListItem>
                            <ListItemText primary={parseFloat(choosen.total_odds?.$numberDecimal)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '-'} secondary="Total Odds" />
                        </ListItem>
                    </Grid>
                    <Grid item xs={12}>
                        <ListItem>
                            <ListItemText primary='Details'/>
                        </ListItem>

                        {choosen.bets && choosen.bets.map(b =>
                            <div className="ticket_details" key={b.id}>
                                <div>Ticket <span>#{b.id}</span></div>
                                
                                <Grid className="grid_ticket_details" container spacing={2}>
                                    <Grid item xs={6}>
                                        <ListItem>
                                            <ListItemText primary={b.type || '-'} secondary="Bet Type" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ListItem>
                                            <ListItemText primary={`${b.event?.league?.name}`} secondary="Event" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ListItem>
                                            <ListItemText primary={
                                                <>
                                                <div>{b.event?.teams ? `${b.event?.teams?.toString().split(' v ')[0]}` : `${b.event?.home?.name}`}</div>
                                                <div className="score_betdetails">{`${b.event?.ss}`}</div>
                                                <div>{b.event?.teams ? `${b.event?.teams?.toString().split(' v ')[1]}` : `${b.event?.away?.name}`}</div>
                                                </>
                                            } secondary="Score" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ListItem>
                                            <ListItemText primary={`${b.team?.name}`} secondary="Bet on" />
                                        </ListItem>
                                    </Grid>

                                    {choosen.bet_type === 'Single' && 
                                    <>
                                     <Divider />
                                     <Grid item xs={4}>
                                        <ListItem className="single_bet_stats">
                                            <ListItemText primary={`$${parseFloat(b.input?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`} secondary="Bet Placed" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ListItem className="single_bet_stats">
                                            <ListItemText primary={`$${parseFloat(b.est_payout?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD`} secondary="Est. Payout" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ListItem className="single_bet_stats">
                                            <ListItemText primary={`${(b.odd?.odd)}`} secondary="Odds" />
                                        </ListItem>
                                    </Grid>
                                    </>
                                    }
                                </Grid>
                                
                            </div>
                        )}
                    </Grid>
                </Grid>
            </>
            :
            <>No BetSlip selected</>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => changeOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
}