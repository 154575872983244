'use client'
import { Pagination, PaginationItem, PaginationList, PaginationNavigator } from 'keep-react'
import { CaretLeft, CaretRight, DotsThree } from 'phosphor-react'

export const PaginationComponent = ({ pages, activePage, changeActive }) => {
    if (pages <= 0) return null; // No pages, no need to render
  
    // Function to generate the page range dynamically
    const generatePagination = () => {
      let pagination = [];
  
      if (pages <= 5) {
        // If pages are less than or equal to 5, just show all pages
        pagination = [...Array(pages)].map((_, i) => i + 1);
      } else {
        // If more than 5 pages, show dynamic pagination
        if (activePage <= 2) {
          pagination = [1, 2, '...', pages - 1, pages];
        } else if (activePage >= pages - 1) {
          pagination = [1, 2, '...', pages - 1, pages];
        } else {
          pagination = [1, 2, '...', activePage, '...', pages - 1, pages];
        }
      }
      return pagination;
    };
  
    return (
      <Pagination style={{ width: 'fit-content' }} className="gap-1" shape="rounded">
        {/* Previous Button */}
        {activePage > 1 && (
          <PaginationNavigator
            onClick={() => changeActive(activePage - 1)}
            className="bg-gray-800"
          >
            <CaretLeft size={18} />
            Previous
          </PaginationNavigator>
        )}
  
        <PaginationList className="mb-0 pr-3 pl-3">
          {generatePagination().map((page, index) => {
            if (page === '...') {
              return (
                <PaginationItem key={index}>
                  <DotsThree size={20} />
                </PaginationItem>
              );
            }
  
            return (
              <PaginationItem
                key={index}
                onClick={() => changeActive(page)}
                active={activePage === page}
              >
                {page}
              </PaginationItem>
            );
          })}
        </PaginationList>
  
        {/* Next Button */}
        {activePage < pages && (
          <PaginationNavigator
            onClick={() => changeActive(activePage + 1)}
            className="bg-gray-800"
          >
            Next
            <CaretRight size={18} />
          </PaginationNavigator>
        )}
      </Pagination>
    );
  };