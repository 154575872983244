import { Container } from 'react-bootstrap';
import {useState, useEffect} from 'react';
import axiosInstance from '../../redux/axios';
import {useNavigate, useParams} from 'react-router-dom';
import * as formik from 'formik';
import * as yup from 'yup';
import { Form, Button } from 'react-bootstrap';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';

const months = ["January","February","March","April","May","June","July",
    "August","September","October","November","December"];
    
function getMonthNumber(monthName) {
    const monthMap = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12'
    };
  
    const monthNumber = monthMap[monthName];
  
    if (monthNumber) {
      return monthNumber;
    } else {
      return 'Invalid month name';
    }
}

function getMonthName(monthNumberStr) {
    // Convert the string to a number
    const monthNumber = parseInt(monthNumberStr, 10);
  
    if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
      return "";
    }
  
    return months[monthNumber - 1];
  }

export default function AReferralsCRUD(){
    const {id} = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const { Formik } = formik;
    const [user, setUser] = useState(null);
    
    const navigate = useNavigate();
    const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

    const validationSchema =  yup.object().shape({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        username: yup.string().required(),
        email: yup.string().required(),
        day: yup.number().required().positive().integer().min(1).max(31),
        month: yup.string().required().notOneOf(["Month"], "This field is required.").when('selectedValue', {
            is: val => val && val !== 'Month', // Only require if value is not "Month" and is not empty
            then: yup.string().required('Please select a value'),
        }),
        year: yup.number().required().positive().integer().min(1900).max(parseInt(new Date().getFullYear())-17),
    })

    useEffect(() => {
        async function getUser() {
            try{
                const response = await axiosInstance.get('admin/users/'+id)
             
                if(response.data.user){
                    const user = response.data.user
                    setUser(user)
                   
                }
            }catch{}
        }
        if(id && id !== 'new'){
            getUser();
        }
    }, [id])

    function convertNullValues(obj) {
        const result = {};
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            result[key] = obj[key] === null ? '' : obj[key];
          }
        }
      
        return result;
    }


    return(
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">{id === 'new' ? 'Create Referral' : 'Edit Referral'}</h5>
            </div>

            {(user || id === 'new') && 
             <Container className="pt-5 pb-5">
                <h6>Main details</h6>

                <Formik
                validationSchema={validationSchema}
                validateOnChange={validateAfterSubmit}
                validateOnBlur={validateAfterSubmit}
                onSubmit={async (values) => {
                    if(validationSchema.isValidSync(values)){
                        try{
                            const formData = new FormData();
                            formData.append('first_name', values.first_name);
                            formData.append('last_name', values.last_name);
                            formData.append('username', values.username);
                            formData.append('email', values.email);
                            formData.append('date_of_birth', JSON.stringify({year: values.year.toString(), month: getMonthNumber(values.month), day: values.day}))

                            const response = await axiosInstance.post(id === 'new' ? 'admin/users/create' : 'admin/users/'+user?._id, formData, {
                                headers: {
                                'Content-Type': 'multipart/form-data'
                                }
                            })
                            if(response?.status === 201){
                                enqueueSnackbar('Reffered user created successfully', { variant: "success" });
                                setTimeout(() => {
                                    navigate('/admin/referrals')
                                }, 3000)
                            }
                            if(response?.status === 200){
                                enqueueSnackbar('Reffered user edited successfully', { variant: "success" });
                                setTimeout(() => {
                                    navigate('/admin/referrals')
                                }, 3000)
                            }
                        }catch(e){
                            if(e.response?.data?.message){
                                enqueueSnackbar(e.response?.data?.message, { variant: "error" });
                            }
                            if(e.response?.data?.error){
                                enqueueSnackbar(e.response?.data?.error, { variant: "error" });
                            }  
                        }
                    }
                }}
                initialValues={{...convertNullValues(user), day: user?.date_of_birth?.day ?? '', month: getMonthName(user?.date_of_birth?.month) || '', year:  user?.date_of_birth?.year ?? ''}}
                >
                 {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    return (
                    <>
                        <Form data-bs-theme="dark" noValidate onSubmit={handleSubmit}>
                            <Grid className="form_modal_in mt-4" container spacing={2}>
                                <Grid item xs={6}>
                                    <Form.Group controlId="details.first_name">
                                        <Form.Label>First Name <span className="form_required">*</span></Form.Label>
                                        <Form.Control  
                                            className="input_settings" 
                                            type="text" 
                                            name="first_name"
                                            value={values.first_name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.first_name && !errors.first_name}
                                            isInvalid={!!errors.first_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Grid>
                                <Grid item xs={6}>
                                    <Form.Group controlId="details.last_name">
                                        <Form.Label>Last Name <span className="form_required">*</span></Form.Label>
                                        <Form.Control   
                                            type="text" 
                                            name="last_name"
                                            value={values.last_name}
                                            onChange={handleChange}
                                            isValid={touched.last_name && !errors.last_name}
                                            isInvalid={!!errors.last_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Grid>

                                <Grid item xs={6}>
                                    <Form.Group controlId="details.username">
                                        <Form.Label>Username <span className="form_required">*</span></Form.Label>
                                        <Form.Control  
                                            className="input_settings" 
                                            type="text" 
                                            name="username"
                                            disabled={id !== 'new'}
                                            value={values.username}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.username && !errors.username}
                                            isInvalid={!!errors.username}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Grid>

                                <Grid item xs={6}>
                                    <Form.Group controlId="details.email">
                                        <Form.Label>Email <span className="form_required">*</span></Form.Label>
                                        <Form.Control  
                                            className="input_settings" 
                                            type="email" 
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            This field is required.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Grid>

                                <Grid item xs={12}>
                                    <Form.Group controlId="details.date_of_birth">
                                        <Form.Label>Date of Birth <span className="form_required">*</span></Form.Label>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <Form.Control   
                                                    type="number" 
                                                    name="day"
                                                    value={values.day}
                                                    onChange={handleChange}
                                                    isValid={touched.day && !errors.day}
                                                    placeholder="DD"
                                                    isInvalid={!!errors.day}
                                                    step="any"
                                                    min="1"
                                                    max="31"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.day}
                                                </Form.Control.Feedback>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Form.Select 
                                                name="month"
                                                value={values.month}
                                                onChange={handleChange}
                                                isValid={touched.month && !errors.month}
                                                isInvalid={!!errors.month}
                                                placeholder="Month" 
                                                aria-label="Month">
                                                    <option>Month</option>
                                                    {months.map(m =>  
                                                        <option key={m} value={m}>{m}</option>
                                                    )}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    This field is required.
                                                </Form.Control.Feedback>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Form.Control   
                                                    type="number" 
                                                    name="year"
                                                    value={values.year}
                                                    onChange={handleChange}
                                                    isValid={touched.year && !errors.year}
                                                    placeholder="YYYY"
                                                    isInvalid={!!errors.year}
                                                    step="any"
                                                    min="1900"
                                                    max={parseInt(new Date().getFullYear())-17}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.year}
                                                </Form.Control.Feedback>
                                            </Grid>
                                        </Grid>
                                    </Form.Group>
                                </Grid>
                            </Grid>

                            <Button className="submit_btn full_width" onClick={() => {
                                setValidateAfterSubmit(true)
                            }} type="submit">Submit</Button>
                        </Form>
                    </>
                    )}}
                </Formik>
                
             </Container>
            }
            {(!user && id !== 'new') &&
                <Container className="pt-5 pb-5">
                    <div style={{textAlign: 'center', width: '100%'}}>User not found.</div>
                </Container>
            }
        </div>
    )
}