import {
    Toolbar,
    Box,
    Button,
    Badge,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ButtonGroup
} from '@mui/material';

import {Container} from 'react-bootstrap';
import { styled } from '@mui/material/styles';

import { useSelector } from 'react-redux';
import { useState, useEffect, Fragment, useRef } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {useNavigate} from 'react-router-dom';
//icons
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import {useLocation} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import axiosInstance from '../redux/axios';
import { logOutUser } from '../redux/reducers/UserReducer';
import { useDispatch } from 'react-redux';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import logo_text from '../assets/img/logo-text1.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import TWButton from '../helpers/TWButton';
import { useDisconnect, useActiveWallet } from "thirdweb/react";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import dayjs, { getUserTimezone } from '../helpers/UserTimestamp';
import Flag from 'react-world-flags';


const languageMap = {
    'en': { name: 'English', flag: 'GB' },
    'de': { name: 'German', flag: 'DE' },
    'fr': { name: 'French', flag: 'FR' },
    'it': { name: 'Italian', flag: 'IT' },
    'es': { name: 'Spanish', flag: 'ES' },
    'hu': { name: 'Hungarian', flag: 'HU' },
    'tr': { name: 'Turkish', flag: 'TR' },
    'ar': { name: 'Arabic', flag: 'AE' },
    'nl': { name: 'Dutch', flag: 'NL' },
    'el': { name: 'Greek', flag: 'GR' },
    'zh-CN': { name: 'Simplified Chinese', flag: 'CN' },
    'pl': { name: 'Polish', flag: 'PL' },
    'hr': { name: 'Croatian', flag: 'HR' },
    'ko': { name: 'Korean', flag: 'KR' },
    'pt': { name: 'Portuguese', flag: 'PT' }
};
const languages = Object.keys(languageMap);

const MenuTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="bottom" arrow  {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: 0
    },
}));

const NotificationTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="bottom-end" {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#131517',
        color: theme.palette.common.white,
        boxShadow: theme.shadows[3],
        fontSize: 11,
        padding: 0,
        minWidth: '20rem'
    },
}));


const MenuTitle = ({changeMenu, changeMenuLink, logOut, pathname}) => {
    return(
        <List className="list_menu_nav">
            {['Cashier', 'My Bets', 'Transactions', 'Settings', 'Logout'].map((text, index) => (
            <ListItem 
            key={index}
            disablePadding 
            sx={{ display: 'block' }}
            onClick={() => 
                index === 0 ? changeMenu('cashier') : 
                index === 1 ? changeMenuLink('my-bets') :
                index === 2 ? changeMenuLink('transactions') :
                index === 3 ? changeMenuLink('settings') :
                index === 4 ? logOut() :
                null}
            >
                <ListItemButton
                    sx={{
                    justifyContent: 'initial',
                    }}
                    className={pathname ? pathname.toLowerCase() === text.toLowerCase() ? 'selected_menu_nav' : '' : ''}
                >
                    <ListItemIcon
                    sx={{
                        minWidth: 0,
                        justifyContent: 'center',
                    }}
                    >
                        {index === 0 ? <CurrencyExchangeIcon/> : index === 1 ? <NewspaperIcon/> :   
                        index === 2 ? <ReceiptLongIcon /> :
                        index === 3 ? <SettingsIcon/> : <LogoutIcon/> }
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
            </ListItem>
            ))}
        </List>
    )
}

const NotifTitle = ({handleCloseNotif, notifications}) => {
    const userTimezone = getUserTimezone();

    return(
        <div className="container_">
            <div className="display_spacebetween model_title_nav">
                <div className="title_notif"> <NotificationsIcon className="faded_icon"/> <span> Notifications </span> </div>
                <div className="title_notif close_cursor">
                    <CloseIcon onClick={handleCloseNotif} className="faded_icon"/>
                </div>
            </div>
            <div className="modal_nav_body">
                {notifications?.length === 0 &&
                <div className="no_notif_nav">
                    <div>
                        <NotificationsActiveIcon />
                    </div>
                    <div>No Notifications Available</div>
                    <div>Your interactions will be visible here</div>
                </div>
                }
                {notifications?.length > 0 &&
                <div>
                    {notifications.map(n => 
                        <ListItemButton key={n._id ? n._id : n.message+n.updatedBalance?.$numberDecimal} component="a" href="#">
                            <ListItemIcon>
                                <Badge color="primary" badgeContent={n.is_seen ? 0 : 1} variant="dot">
                                    {n.type === 'Deposit' ? <AccountBalanceWalletIcon /> : <AccountBalanceWalletIcon/>}
                                </Badge>
                            </ListItemIcon>
                            <ListItemText className="text_norif" primary={n.title} secondary={
                            <Fragment>
                                {n.message}<br/>
                                <span>{dayjs(n.createdAt).tz(userTimezone).format('ddd, MMM D, HH:mm')}</span>
                            </Fragment>
                            } />
                        </ListItemButton>
                    )}
                </div>
                }
            </div>
        </div>
    )
}

const LanguageSelect = ({languages, handleLanguageChange, selectedLanguage}) => {
    return(
        <>
         <div className="language_container">
         <List
         style={{maxWidth: '10rem'}}>
            {languages.map(l =>
                <ListItem disablePadding key={l}>
                    <ListItemButton className="btn_languages" selected={selectedLanguage === l} onClick={() => handleLanguageChange(l)}>
                        <ListItemIcon>
                            <Flag
                                code={languageMap[l]?.flag}  // Use the ISO code for standard countries
                                alt={`${l} flag`}
                                style={{ width: '18px', height: '18px', marginRight: '8px' }}
                            />
                        </ListItemIcon>
                        <ListItemText className="text_languages" primary={languageMap[l]?.name?.toUpperCase()} />
                    </ListItemButton>
                </ListItem>
            )}
        </List>
        </div>
        </>
    )
}
export default function NavBar({AppBar, open, wallet, setOpen, setShowSetWallet,setShowVIP, setShowVault, notifications, setNotifications}){
    const {user} = useSelector(state => state.user);
    const googleRef = useRef(null);
    const dispatch = useDispatch();
    const { disconnect } = useDisconnect();
    const wallet1 = useActiveWallet();

    const matches = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const history = useLocation();
    
    const [openMenu, setOpenMenu] = useState(false);
    const [openNotif, setOpenNotif] = useState(false);
    const [openLang, setOpenLang] = useState(false);
    const [pathname, setPathName] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    

    useEffect(() => {
        if (googleRef.current && window.google?.translate) {
            new window.google.translate.TranslateElement({
                includedLanguages: 'en,de,fr,it,es,hu,tr,ar,nl,el,zh-CN,pl,hr,ko,pt',
            }, 'google_translate_element');

            // Add event listener to detect language changes
            const observer = new MutationObserver(() => {
                const selectElement = document.querySelector('.goog-te-combo');
                if (selectElement) {
                    setSelectedLanguage(selectElement.value); // Update selected language state
                }
            });

            // Observe changes in the Google Translate widget
            observer.observe(googleRef.current, { childList: true, subtree: true });

            return () => {
                observer.disconnect(); // Clean up the observer on unmount
            };
        }
    }, [googleRef]);

    const handleLanguageChange = (lang) => {
        if (window.google && window.google?.translate) {
            const translateElement = new window.google.translate.TranslateElement.getInstance();
            if (translateElement) {
                const select = document.querySelector('.skiptranslate select');
              
                if (select) {
                    select.value = lang; // Set the new language
                    setSelectedLanguage(lang)
                    select.dispatchEvent(new Event('change', { bubbles: true })); // Trigger the change event
                } else {
                    console.error('Language selector not found');
                }
            } else {
                console.error('Google Translate not initialized');
            }
        } else {
            console.error('Google Translate not available');
        }

        setOpenLang(false);
    };

    useEffect(() => {
        let itemPrime = history?.pathname?.split('/')[1]
        if(itemPrime){
           const item = itemPrime?.toString().toLowerCase().replaceAll('-', ' ')
           if(item === 'transactions' || item === 'settings' || item === 'my bets'){
                setPathName(item)
           }else{
                setPathName(null)
           }
        }
       
    }, [history])

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    const handleCloseLang = () => {
        setOpenLang(false);
    };

    const handleCloseNotif = () => {
        setOpenNotif(false);
    };

    const openChatWidget = () => {
        if(window.fcWidget){
            if(!window.fcWidget?.isOpen()){
                window.fcWidget.open()
            }else{
                window.fcWidget.close()
            }
        }
    }

    const changeMenuLink = (val) => {
        setOpenMenu(false)
        navigate(val.toLowerCase().replaceAll(' ', '-'))
    } 

    const changeMenu = (val) => {
        if(val === 'cashier'){
            setShowVault(true)
        }
        if(val === 'rank'){
            setShowVIP(true)
        }
        if(val === 'support'){
            openChatWidget()
        }

        setOpenMenu(false)
    }

    const logOut = async () => {
        try{
          await axiosInstance.get('auth/logout');
          dispatch(logOutUser())
          
          disconnect(wallet1)
          
          navigate('/')
        }catch{
          dispatch(logOutUser())
          navigate('/')
        }
      }

    const openNotifications = async () => {
        if(openNotif && notifications?.filter(x => {return !x.is_seen})?.length > 0){
           
            try{
                const seen = await axiosInstance.get('users/notifications/seen');
                
                if(seen.status === 200){
                    setNotifications(seen.data.notifications)
                }
            }catch{}
        }
        setOpenNotif(!openNotif)
    }

    return(
        <>
        <AppBar style={{backgroundColor: '#000000', backgroundImage: 'linear-gradient(rgb(0 0 0 / 9%), rgb(169 169 169 / 9%))'}} position="fixed" open={open}>
            <Container className="container_navbar">
                <Toolbar className={matches ? "ps-0 pe-0" : ""}>
                    <div style={{ flexGrow: 1 }}>
                        <img onClick={()=> navigate('/play')} src={logo_text} style={{width: '6.4rem', cursor: 'pointer'}} className="me-3 pt-2 pb-2 img_logo_nav" alt="logo" />
                        <div ref={googleRef} id="google_translate_element"></div>
                    </div>

                    {user &&
                    <Box sx={{ flexGrow: 0, paddingRight: '1rem'}}>
                        <ButtonGroup>
                            <div className="credits_display">
                                {user ? parseFloat(user?.balance?.$numberDecimal)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} <CurrencyExchangeIcon className="ms-2" style={{width: '.9rem'}}/>
                            </div>
                            <Button onClick={() => setShowVault(true)} style={{padding: '.1rem'}} variant="contained"><CurrencyExchangeIcon style={{width: '1rem'}}/></Button>
                        </ButtonGroup>
                    </Box>
                    }
                    <Box sx={{ flexGrow: 0 }}>
                        <div className="display_menu_nav">
                            {user && 
                            <>
                            <div>
                                <ClickAwayListener onClickAway={handleCloseMenu}>
                                    <div className="full-height">
                                        <MenuTooltip 
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        open={openMenu} 
                                        onClose={handleCloseMenu} 
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        title={
                                            <MenuTitle
                                                changeMenu={changeMenu}
                                                changeMenuLink={changeMenuLink}
                                                logOut={logOut}
                                                pathname={pathname}
                                                />
                                        }>
                                            <div className="lang_select">
                                                <PersonIcon onClick={() => setOpenMenu(!openMenu)} />
                                            </div>
                                        </MenuTooltip>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            <div>
                                <ClickAwayListener onClickAway={handleCloseNotif}>
                                    <div className="full-height">
                                        <NotificationTooltip
                                            open={openNotif} 
                                            onClose={handleCloseNotif} 
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={
                                            <NotifTitle
                                            notifications={notifications}
                                            handleCloseNotif={handleCloseNotif}
                                            />
                                            }>
                                            <div className="lang_select">
                                                <Badge badgeContent={notifications?.filter(x => {return !x.is_seen})?.length || 0} color="primary">
                                                    <NotificationsIcon onClick={() => openNotifications()}  />
                                                </Badge>
                                            </div>
                                        </NotificationTooltip>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            </>
                            }
                            {!user &&
                            <div>
                                <TWButton />
                            </div>
                            }
                            {/* LANGUAGE */}
                            <div>
                            <ClickAwayListener onClickAway={handleCloseLang}>
                                <div className="full-height">
                                    <MenuTooltip 
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    open={openLang} 
                                    onClose={handleCloseLang} 
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={
                                        <LanguageSelect
                                        selectedLanguage={selectedLanguage}
                                        handleLanguageChange={handleLanguageChange}
                                        languages={languages}/>
                                    }>
                                        {selectedLanguage &&
                                        <div className="lang_select" onClick={() => setOpenLang(!openLang)} >
                                            <Flag
                                                code={languageMap[selectedLanguage]?.flag}  // Use the ISO code for standard countries
                                                alt={`${selectedLanguage} flag`}
                                                style={{ width: '18px', height: '18px', marginRight: '8px' }}
                                            />
                                            <div>{selectedLanguage.toUpperCase()}</div>
                                        </div>
                                        }{
                                            !selectedLanguage &&
                                            <div className="lang_select" onClick={() => setOpenLang(!openLang)} >
                                                <Flag
                                                    code={languageMap['en']?.flag}  // Use the ISO code for standard countries
                                                    alt={`en flag`}
                                                    style={{ width: '18px', height: '18px', marginRight: '8px' }}
                                                />
                                                <div>{'en'.toUpperCase()}</div>
                                            </div>
                                        }
                                    </MenuTooltip>
                                </div>
                            </ClickAwayListener>
                            </div>
                        </div>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
        
        {/** 
        <SignIn wallet={wallet} show={signIn} onHide={() => setSignIn(false)} setRegister={setRegister} />
        <Register wallet={wallet} show={register} onHide={() => setRegister(false)} setSignIn={setSignIn}/>
        <ForgotPassword show={forgotPass} onHide={() => setForgotPass(false)} />*/}
        </>
    )
}