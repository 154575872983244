'use client'
import { DotsThreeOutlineVertical } from 'phosphor-react'
import {
  Badge,
  Dropdown,
  DropdownAction,
  DropdownContent,
  DropdownItem,
  DropdownGroup,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'keep-react'
import {useEffect, useState} from 'react'
import axiosInstance from '../../redux/axios'
import { Container } from 'react-bootstrap';
import { MagnifyingGlass } from 'phosphor-react'
import { InputIcon, Input } from 'keep-react'
import { PaginationComponent } from '../../helpers/Pagination'
import BetDetails from './components/BetDetails'

const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
};

export default function ASlips(){
    const [data, setData] = useState({
        bets: [],
        total: 0
    });
    const [search, setSearch] = useState('');
    const [debouncedValue, setDebouncedValue] = useState(search);
    const [activePage, setActivePage] = useState(1);
    const [pages, setPages] = useState(1);

    const [choosen, setChoosen] = useState(null);
    const [open, setOpen] = useState(false);

    const changeActive = (i) => {
        if(i <= pages && i >= 1){
            setActivePage(i)
            getBets(i, search)
        }
    }

    const filterSearch = (value) => {
        setSearch(value)
    }

    async function getBets(page, value) {
        try{
            let query = '?page='+page;
            if(value && value !== ''){
                query+='&search='+value
            }
            const resp = await axiosInstance.get('admin/bets'+query)
          
            if(resp?.data){
                setData(resp.data)
                setPages(resp.data.pages)
            }
        }catch{} 
    }

    useEffect(() => {
        async function getBets() {
            try{
                const resp = await axiosInstance.get('admin/bets')

                if(resp?.data){
                    setData(resp?.data)
                    setPages(resp.data.pages)
                }
            }catch{} 
        }
        getBets()
    }, [])

    useEffect(() => {
        if (debouncedValue) {
            setActivePage(1);
            getBets(1, debouncedValue);
        }else{
            setActivePage(1);
            getBets(1);
        }
    }, [debouncedValue]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(search);
        }, 2000);

        // Cleanup the timeout if the value changes before 2 seconds
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    const updateBet = (bet) => {
        const index = data.bets.findIndex(x => {return x._id === bet._id})
        if(index !== -1){
            const updatedBets = [...data.bets];  // Create a shallow copy of the bets array
            updatedBets[index] = bet;  // Replace the bet at the found index with the new bet
            
            setData(prevData => ({
                ...prevData,
                bets: updatedBets  // Update the bets array in the state
            }));
            
        }
    }

    return(
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">Bet Slips</h5>
            </div>


            <Container className="pt-5 pb-5">
                <BetDetails choosen={choosen} open={open} changeOpen={setOpen} updateBet={updateBet} />
                <div className="mb-3">
                    <PaginationComponent activePage={activePage} pages={pages} changeActive={changeActive} />
                </div>

                <Table className="bg-gray-800 text-white" style={{captionSide: 'top'}}>
                    <TableCaption className="bg-gray-800 text-white">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-5">
                                <h2 className="text-heading-6 font-semibold text-metal-900 dark:text-white">Total BetSlips</h2>
                                <Badge color="primary">{data?.total || 0} Tickets</Badge>
                            </div>
                            <div className="flex items-center gap-5">
                                <fieldset className="relative">
                                    <Input value={search} onChange={e => filterSearch(e.target.value)} className="bg-gray-800 text-white px-5" placeholder="Search ..." />
                                    <InputIcon className="ps-3">
                                        <MagnifyingGlass size={19} color="#AFBACA" />
                                    </InputIcon>
                                </fieldset>
                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>
                                <div className="w-[50px]">Bet ID</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Status</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[80px]">Initiated By</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Type</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Created At</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Total Bet</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[90px]">Est. Payout</div>
                            </TableHead>
                            <TableHead></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                    {data?.bets?.length > 0 &&
                            data?.bets?.map((item) => (
                                <TableRow key={item._id}>
                                     <TableCell>
                                        {item._id}
                                    </TableCell>
                                    <TableCell>
                                        <Badge showIcon className="text-body-4" color={item.status === 'Pending' ? 'warning' : item.status === 'Win' ? 'success' : 'error'}>
                                            {item.status}
                                        </Badge>
                                    </TableCell>
                                    <TableCell>
                                        <div>{item.initiated_by?.first_name} {item.initiated_by?.last_name}</div>
                                        <div>{item.initiated_by?.username.toLowerCase()}</div>
                                    </TableCell>
                                    <TableCell>
                                        <Badge showIcon className="text-body-4" color={item.bet_type === 'Single' ? 'primary' : 'success'}>
                                            {item.bet_type}
                                        </Badge>
                                        
                                    </TableCell>
                                    <TableCell>
                                        {new Date(item.createdAt).toLocaleString('en-US', options).replace(',', '')}
                                    </TableCell>
                                    
                                    <TableCell>
                                      ${parseFloat(item.total_bet?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                                    </TableCell>

                                    
                                    <TableCell>
                                        <div style={{overflowWrap: 'break-word', maxWidth: '100px'}}>
                                            ${parseFloat(item.est_payout_final?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <Dropdown>
                                            <DropdownAction asChild>
                                                <button>
                                                    <DotsThreeOutlineVertical className="size-4 fill-metal-900 dark:fill-white" />
                                                </button>
                                            </DropdownAction>
                                            <DropdownContent className="max-w-[200px] border border-metal-100 p-3 bg-gray-800 text-white">
                                            <DropdownGroup>
                                                <DropdownItem>
                                                    <div onClick={() => {setOpen(true); setChoosen(item)}} style={{height: '100%', width: '100%'}}>Details</div>
                                                </DropdownItem>
                                            </DropdownGroup>
                                            </DropdownContent>
                                        </Dropdown>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Container>
        </div>
    )
}