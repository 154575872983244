'use client'
import { DotsThreeOutlineVertical} from 'phosphor-react'

import {
  Badge,
  Dropdown,
  DropdownAction,
  DropdownContent,
  DropdownItem,
  DropdownGroup,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'keep-react'
import {useEffect, useState} from 'react'
import axiosInstance from '../../redux/axios'
import { Container } from 'react-bootstrap';
import { MagnifyingGlass } from 'phosphor-react'
import { InputIcon, Input } from 'keep-react'
import { PaginationComponent } from '../../helpers/Pagination'
import ViewWithdrawDetails from './components/ViewWithdrawDetails'
import AFeeWithdrawal from './components/FeeWithdraw'

const options = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
};

export default function AWithdraws(){
    const [data, setData] = useState({
        withdraws: [],
        total: 0
    });
    const [search, setSearch] = useState('');
    const [debouncedValue, setDebouncedValue] = useState(search);
    const [activePage, setActivePage] = useState(1);
    const [pages, setPages] = useState(1);
    const [selected, setSelected] = useState(null)
    const [openDetails, setOpenDetails] = useState(false);
    const [openFee, setOpenFee] = useState(false);

    const changeActive = (i) => {
        if(i <= pages && i >= 1){
            setActivePage(i)
            getWithdraws(i, search)
        }
    }

    const filterSearch = (value) => {
        setSearch(value)
    }

    async function getWithdraws(page, value) {
        try{
            let query = '?page='+page;
            if(value && value !== ''){
                query+='&search='+value
            }
            const resp = await axiosInstance.get('withdraw/all'+query)
            if(resp?.data){
                setData(resp.data)
                setPages(resp.data.pages)
            }
        }catch{} 
    }

    useEffect(() => {
        async function getWithdraws() {
            try{
                const resp = await axiosInstance.get('withdraw/all')
             
                if(resp?.data){
                    setData(resp?.data)
                    setPages(resp.data.pages)
                }
            }catch{} 
        }
        getWithdraws()
    }, [])

    useEffect(() => {
        if (debouncedValue) {
            setActivePage(1);
            getWithdraws(1, debouncedValue);
        }else{
            setActivePage(1);
            getWithdraws(1);
        }
    }, [debouncedValue]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(search);
        }, 2000);

        // Cleanup the timeout if the value changes before 2 seconds
        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    const updateWithdraw = (withdrawal) => {
        const updatedWithdraws = data.withdraws.map((item) => {
            if (item._id === withdrawal._id) {
                return { ...item, ...withdrawal }; // Merge the existing item with the new data
            }
            return item; // Return the existing item if it doesn't match
        });
        setData((prevState) => ({
            ...prevState,
            withdraws: updatedWithdraws,
        }));
    }

    return(
        <div className="main_page pt-0">
            <ViewWithdrawDetails selected={selected} setOpenDetails={setOpenDetails} openDetails={openDetails} updateWithdraw={updateWithdraw} />
            <AFeeWithdrawal selected={selected} setOpenDetails={setOpenFee} openDetails={openFee} />
            <div className="dashboard_admin">
                <h5 className="mb-0">Withdraws</h5>
            </div>

            <Container className="pt-5 pb-5">

                <div className="mb-3">
                    <PaginationComponent activePage={activePage} pages={pages} changeActive={changeActive} />
                </div>

                <Table className="bg-gray-800 text-white" style={{captionSide: 'top'}}>
                    <TableCaption className="bg-gray-800 text-white">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-5">
                                <h2 className="text-heading-6 font-semibold text-metal-900 dark:text-white">Total Withdraws</h2>
                                <Badge color="primary">{data?.total || 0} Transactions</Badge>
                            </div>
                            <div className="flex items-center gap-5">
                                <fieldset className="relative">
                                    <Input value={search} onChange={e => filterSearch(e.target.value)} className="bg-gray-800 text-white px-5" placeholder="Search ..." />
                                    <InputIcon className="ps-3">
                                        <MagnifyingGlass size={19} color="#AFBACA" />
                                    </InputIcon>
                                </fieldset>
                            </div>
                        </div>
                    </TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>
                                <div className="w-[50px]">Method</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">User</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[65px]">Amount</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Status</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[100px]">Created At</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[50px]">TrxHash</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[50px]">Tax Payed</div>
                            </TableHead>
                            <TableHead>
                                <div className="w-[50px]">Fees Payed</div>
                            </TableHead>
                            <TableHead></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                    {data?.withdraws?.length > 0 &&
                            data?.withdraws?.map((item) => (
                                <TableRow key={item._id}>
                                     <TableCell>
                                        {item.method}
                                    </TableCell>
                                    <TableCell>
                                        <div>{item.user_id?.first_name} {item.user_id?.last_name}</div>
                                        <div>{item.user_id?.email ? item.user_id?.email?.toLowerCase() : item.user_id?.username.toLowerCase()}</div>
                                    </TableCell>
                                    <TableCell>
                                        ${parseFloat(item.amount?.$numberDecimal).toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USD
                                    </TableCell>
                                    <TableCell>
                                        <div className={item.status === 'Pending' ? 'pending_p' : item.status === 'Accepted' ? 'accepted_p' : 'declined_p'}>{item.status}</div>
                                    </TableCell>
                                    <TableCell>
                                        {new Date(item.createdAt).toLocaleString('en-US', options).replace(',', '')}
                                    </TableCell>
                                    
                                    <TableCell>
                                      {item.hash}
                                    </TableCell>
                                    <TableCell>
                                      {item.payed_taxes ? 'true' : 'false'}
                                    </TableCell>
                                    <TableCell>
                                      {item.payed_other ? 'true' : 'false'}
                                    </TableCell>

                                    <TableCell>
                                        <Dropdown>
                                            <DropdownAction asChild>
                                                <button>
                                                    <DotsThreeOutlineVertical className="size-4 fill-metal-900 dark:fill-white" />
                                                </button>
                                            </DropdownAction>
                                            <DropdownContent className="max-w-[200px] border border-metal-100 p-3 bg-gray-800 text-white">
                                            <DropdownGroup>
                                                <DropdownItem>
                                                    <div style={{height: '100%', width: '100%'}} onClick={() => {setSelected(item); setOpenDetails(true)}}>Details</div>
                                                </DropdownItem>
                                            </DropdownGroup>
                                            <DropdownGroup>
                                                <DropdownItem>
                                                    <div style={{height: '100%', width: '100%'}} onClick={() => {setSelected(item); setOpenFee(true)}}>Modify Fees</div>
                                                </DropdownItem>
                                            </DropdownGroup>
                                            </DropdownContent>
                                        </Dropdown>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Container>
        </div>
    )
}