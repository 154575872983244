import { useParams } from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link} from '@mui/material';
import ImageWithFallback from '../../../helpers/ImageWithFallback';
import { useState, useEffect, Fragment} from 'react';
import axiosInstance from '../../../redux/axios';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';
import { toggleBetSlip } from '../../../redux/reducers/UserReducer';
import { addSports } from '../../../redux/reducers/SportsReducer';
import dayjs, { getUserTimezone } from '../../../helpers/UserTimestamp.js';
import ScoreBoard from '../../../components/Sports/ScoreBoard';
import useMediaQuery from '@mui/material/useMediaQuery';
import StatsAndTimeLine from '../../../components/Sports/StatsAndTimeline';

const timeStatus = [
    {id: '0', title:'Not Started'},
    {id: '1', title:'InPlay'},
    {id: '2', title:'TO BE FIXED'},
    {id: '3', title:'Ended'},
    {id: '4', title:'Postponed'},
    {id: '5', title:'Cancelled'},
    {id: '6', title:'Walkover'},
    {id: '7', title:'Interrupted'},
    {id: '8', title:'Abandoned'},
    {id: '9', title:'Retired'},
    {id: '10', title:'Suspended'},
    {id: '11', title:'Decided by FA'},
    {id: '99', title:'Removed'},
]

export default function EventUpcoming({setOpenBets}){
    const matches = useMediaQuery('(max-width:600px)');

    const userTimezone = getUserTimezone();
    const { selected_sport, id } = useParams();
    const [event, setEvents] = useState(null)
    const [eventDetails, setEventDetails] = useState(null);

    const { sports, categories } = useSelector(state => state.sports);
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [team1Color, setTeam1] = useState('#EA2636');
    const [team2Color, setTeam2] = useState('#999999');

    useEffect(() => {
        if(event){
           if(event?.teamDetails){
               if(event?.teamDetails[0]){
                   setTeam1(event?.teamDetails[0].color || '#EA2636')
               }
               if(event?.teamDetails[1]){
                   setTeam2(event?.teamDetails[1].color || '#999999')
               }
           }
        }
       }, [event])

    useEffect(() => {
        async function getEvents() {
            try{
                const category = categories.find(x => {return x.name.toLowerCase() === selected_sport.toLowerCase()})
                const res = await axiosInstance.get('sport/events/upcoming/'+category.id)
                
                if(res.data.data){
                    dispatch(addSports(res.data?.data))

                    const allEvents = Object.values(res.data?.data).flatMap(league => league.events);

                    // Find the event with the given ID
                    const foundEvent = allEvents.find(event => event.id === id);
                    
                    if(foundEvent){
                        setEventDetails(foundEvent)
                    }
                }
            }catch{}
        }
        async function getEvent() {
            try{
                const res = await axiosInstance.get('sport/upcoming/'+ id)
                
                if(res?.data?.success){
                    setEvents(res.data.result)
                    if(res.data.details){
                        setEventDetails(res.data.details)
                    }else{
                        if(sports){
                            const allEvents = Object.values(sports).flatMap(league => league.events);
        
                            // Find the event with the given ID
                            const foundEvent = allEvents.find(event => event.id === id);
                            
                            if(foundEvent){
                                setEventDetails(foundEvent)
                            }else{
                                getEvents()
                            }
                        }else{
                            getEvents()
                        }
                    }
                }
            }catch{}
        }

        getEvent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onlyObjects = (data) => {
        return Object.fromEntries(
            Object.entries(data).filter(([key, value]) => typeof value === 'object' && value !== null)
        );
    } 

    const groupedByHeader = (data) => { 
        return  data.reduce((acc, item) => {
        // Check if the header key already exists in the accumulator
        if (!acc[item.header]) {
          // Initialize an array for the header key if it doesn't exist
          acc[item.header] = [];
        }
        // Push the item into the array for the appropriate header
        acc[item.header].push(item);
        return acc;
      }, {});
    }

    const hasHeader = (array) => {
        return array.some(item => 'header' in item);
    };

    const addToSlip = (teamName, number, odd, e, type) => {
        if(odd?.odds && user){
            dispatch(toggleBetSlip({
                event: e,
                team: {
                    name: teamName,
                    number: odd.name
                },
                odd: {
                    odd: odd.odds,
                    su: '1'
                },
                type: type,
                comes_from: 'upcoming',
                input: 0
            }))
            setOpenBets(true)
        }
    }

    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div className="event_t"> 
                        <ImageWithFallback 
                        style={{width: '1.4rem', marginRight: '.5rem', marginBottom: '.3rem'}}
                        src={`/static/sports/${selected_sport}.svg`}
                        alt={selected_sport}
                        fallbackSrc="/static/sports/Other.svg"
                        />
                       {eventDetails ? eventDetails.home?.name + ' vs ' +  eventDetails.away?.name  : 'Game'}
                    </div>
                    <div className="pb-2" style={{fontSize: '.9rem', color: 'gray'}}>
                    {eventDetails &&
                        <>{dayjs.unix(eventDetails?.time).tz(userTimezone).format('ddd, MMM D, HH:mm')}</>
                    }
                    </div>
                    <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play/sport'}>
                            Home
                        </Link> 
                        <Link underline="hover" color="inherit" href={'/play/sport/'+selected_sport}>
                            {selected_sport}
                        </Link> 
                        <Typography color="text.primary"> {eventDetails ? eventDetails.league?.name : 'League'}</Typography>
                    </Breadcrumbs>
                </Container>
            </div>

            <Container style={{padding: '1rem', paddingTop: '2rem', paddingBottom: '2rem'}}>
                {!matches &&
                <div className="grid_ev1 mb-1 mt-1">
                    <ScoreBoard eventDetails={eventDetails} team1Color={team1Color} team2Color={team2Color} event={event} timeStatus={timeStatus} />
                </div>
                }

                {eventDetails && 
                    <StatsAndTimeLine eventDetails={eventDetails} event={event} timeStatus={timeStatus} />
                }

                <div>
                    {eventDetails && event && Object.entries(onlyObjects(event)).slice().reverse().map(([key,play]) =>
                        <Fragment key={play.key}>
                            {play.sp &&
                                Object.entries(play.sp).map(([key,value]) =>
                                    <Fragment key={key}>
                                        {value.name && value.odds?.length > 0 &&
                                        <Accordion className="acc_events mb-2" defaultExpanded>
                                            <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            id={value.name}
                                            >
                                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                                {value.name}
                                            </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Grid container spacing={2} style={{minWidth: '100%'}}>
                                                {value.odds && !hasHeader(value.odds) &&
                                                    value.odds.map(od =>
                                                        <Fragment key={od?.id+od?.handicap+od?.header+od?.name} >
                                                            <Grid item xs={value.odds.length < 6 ? (12/value.odds.length) : 4}>
                                                                <div onClick={() => addToSlip(od?.name === '1' ? eventDetails?.home?.name : od?.name === '2' ? eventDetails?.away?.name : od?.name, od?.name, od, eventDetails, value.name)} className="live_bets">
                                                                    <div>{od?.name === '1' ? eventDetails?.home?.name : od?.name === '2' ? eventDetails?.away?.name : od?.name}</div>
                                                                    <div>{od?.odds ? od?.odds : ''}</div>
                                                                </div>


                                                            </Grid>
                                                        </Fragment>
                                                    )
                                                }
                                                {value.odds && hasHeader(value.odds) && 
                                                <>
                                                    {[...new Set(value.odds.map(item => item.header))].map(x => 
                                                        <Fragment key={x}>
                                                            <Grid item xs={12/[...new Set(value.odds.map(item => item.header))].length}>
                                                                <div className="first_bets mb-3" style={{height: 'fit-content'}}>
                                                                    <div>{x === "1" ? eventDetails.home?.name : x === '2' ? eventDetails.away?.name : x}</div>
                                                                </div>

                                                                {groupedByHeader(value.odds)[x] && groupedByHeader(value.odds)[x].map(od =>
                                                                    <div onClick={() => addToSlip((x === '1' ? eventDetails.home?.name : x === '2' ? eventDetails.away?.name : x) 
                                                                        + ' ' + 
                                                                        (od?.name ? od?.name === '1' ? eventDetails.home?.name : od?.name === '2' ? eventDetails.away?.name : od?.name : '') + ' ' + (od?.ED ? ' > Range: ' + od.ED : '') + (od?.handicap ? ' > ' + od.handicap : '')
                                                                        , '', od, eventDetails, value.name)}
                                                                    key={x+od?.id+od?.handicap+od?.header+od?.name} className="live_bets mb-3" style={{height: 'fit-content'}}>
                                                                        <div>
                                                                            {od?.name === '1' ? eventDetails.home?.name : od?.name === '2' ? eventDetails.away?.name : od?.name ? od.name : ''} 
                                                                            {od?.team || od?.ED ? ' - ' : ''}
                                                                            {od?.team === '1' ? eventDetails.home?.name : od?.team === '2' ? eventDetails.away?.name : od?.team ? od.team : ''}
                                                                            {od?.ED ? 'Range: ' + od?.ED : ''}
                                                                        </div>
                                                                        <div><span className="handicap_cal">{od?.handicap}</span>{od?.odds ? od?.odds : ''}</div>
                                                                    </div>
                                                                )}
                                                            </Grid>
                                                        </Fragment>
                                                    )}
                                                </>
                                                }
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        }


                                    </Fragment>
                                )
                            }
                        </Fragment>
                    )}  
                </div>
                </Container>
        </div>
    )
}