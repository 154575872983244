import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';

export default function OddType4({type4, setType4, selectedName }){
    const handleChange = (index, field) => (event) => {
        const updatedType4 = [...type4];
        updatedType4[index][field] = event.target.value;
        setType4(updatedType4);
    };
    return(
        <Grid container spacing={2}>
        <Grid item xs={12} sm={selectedName?.startsWith('3') ? 4 : 6}>
            <fieldset className="max-w-md space-y-1">
                <Label htmlFor="name_0">Name</Label>
                <Input 
                    id="name_0" 
                    placeholder="Usually home team name" 
                    type="text" 
                    className="bg-gray-800 text-white" 
                    value={type4[0]?.name || ''} 
                    onChange={handleChange(0, 'name')} 
                />
            </fieldset>
        </Grid>

        {selectedName?.startsWith('3') && (
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="name_1">Name</Label>
                    <Input 
                        id="name_1" 
                        placeholder="Usually draw, no n-th goal etc.." 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type4[1]?.name || ''} 
                        onChange={handleChange(1, 'name')} 
                    />
                </fieldset>
            </Grid>
        )}

        <Grid item xs={12} sm={selectedName?.startsWith('3') ? 4 : 6}>
            <fieldset className="max-w-md space-y-1">
                <Label htmlFor="name_2">Name</Label>
                <Input 
                    id="name_2" 
                    placeholder="Usually away team name" 
                    type="text" 
                    className="bg-gray-800 text-white" 
                    value={type4[selectedName?.startsWith('3') ? 2 : 1]?.name || ''} 
                    onChange={handleChange(selectedName?.startsWith('3') ? 2 : 1, 'name')} 
                />
            </fieldset>
        </Grid>

        <Grid item xs={12} sm={selectedName?.startsWith('3') ? 4 : 6}>
            <fieldset className="max-w-md space-y-1">
                <Label htmlFor="od_0">Odd</Label>
                <Input 
                    id="od_0" 
                    placeholder="ex. 1.2" 
                    type="number" 
                    className="bg-gray-800 text-white" 
                    value={type4[0]?.OD || ''} 
                    onChange={handleChange(0, 'OD')} 
                />
            </fieldset>
        </Grid>

        <Grid item xs={12} sm={selectedName?.startsWith('3') ? 4 : 6}>
            <fieldset className="max-w-md space-y-1">
                <Label htmlFor="od_1">Odd</Label>
                <Input 
                    id="od_1" 
                    placeholder="ex. 1.2" 
                    type="number" 
                    className="bg-gray-800 text-white" 
                    value={type4[1]?.OD || ''} 
                    onChange={handleChange(1, 'OD')} 
                />
            </fieldset>
        </Grid>

        {selectedName?.startsWith('3') && (
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="od_2">Odd</Label>
                    <Input 
                        id="od_2" 
                        placeholder="ex. 1.2" 
                        type="number" 
                        className="bg-gray-800 text-white" 
                        value={type4[2]?.OD || ''} 
                        onChange={handleChange(2, 'OD')} 
                    />
                </fieldset>
            </Grid>
        )}

        <Grid item xs={12} sm={selectedName?.startsWith('3') ? 4 : 6}>
            <fieldset className="max-w-md space-y-1">
                <Label htmlFor="hd_0">HD</Label>
                <Input 
                    id="hd_0" 
                    placeholder="ex. +1" 
                    type="text" 
                    className="bg-gray-800 text-white" 
                    value={type4[0]?.hd || ''} 
                    onChange={handleChange(0, 'hd')} 
                />
            </fieldset>
        </Grid>

        <Grid item xs={12} sm={selectedName?.startsWith('3') ? 4 : 6}>
            <fieldset className="max-w-md space-y-1">
                <Label htmlFor="hd_1">HD</Label>
                <Input 
                    id="hd_1" 
                    placeholder="ex. -1" 
                    type="text" 
                    className="bg-gray-800 text-white" 
                    value={type4[1]?.hd || ''} 
                    onChange={handleChange(1, 'hd')} 
                />
            </fieldset>
        </Grid>

        {selectedName?.startsWith('3') && (
            <Grid item xs={12} sm={4}>
                <fieldset className="max-w-md space-y-1">
                    <Label htmlFor="hd_2">HD</Label>
                    <Input 
                        id="hd_2" 
                        placeholder="ex. -2" 
                        type="text" 
                        className="bg-gray-800 text-white" 
                        value={type4[2]?.hd || ''} 
                        onChange={handleChange(2, 'hd')} 
                    />
                </fieldset>
            </Grid>
        )}
    </Grid>
    )
}