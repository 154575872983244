import { Container } from 'react-bootstrap';
import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';
import {useState, useEffect} from 'react';
import { Button } from 'keep-react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axiosInstance from '../../redux/axios';
import {useNavigate, useParams} from 'react-router-dom';
import { useSnackbar } from 'notistack';

export default function ATopLeaguesCRUD(){
    const {id} = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [league, setLeague] = useState(null);
    const [mainInfo, setMainInfo] = useState({
        file: null,
        name: '',
        sport_id: '',
        img: '',
        league_id: '',
        is_visible: false,
        priority: ''
    })
    const navigate = useNavigate();

    useEffect(() => {
        async function getTopLeague() {
            try{
                const response = await axiosInstance.get('admin/leagues/'+id)
             
                if(response.data.league){
                    const league = response.data.league
                    setLeague(league)
                    setMainInfo({
                        name: league.name,
                        sport_id: league.sport_id,
                        img: league.img,
                        league_id: league.league_id,
                        is_visible: league.is_visible,
                        priority: league.priority
                    })
                }
            }catch{}
        }
        if(id && id !== 'new'){
            getTopLeague();
        }
    }, [id])

    const validateMainInfo = () => {
        if(id === 'new'){
            return (mainInfo.priority && mainInfo.file && mainInfo.name && mainInfo.sport_id && mainInfo.league_id)
        }else{
            return (mainInfo.priority && mainInfo.name && mainInfo.sport_id && mainInfo.league_id)
        }
    };

    const createLeague = async () => {
        if(validateMainInfo()){
            try{
                let data = new FormData();
                data.append('file', mainInfo.file);
                data.append('priority', mainInfo.priority);
                data.append('is_visible', mainInfo.is_visible);
                data.append('name', mainInfo.name);
                data.append('sport_id', mainInfo.sport_id);
                data.append('league_id', mainInfo.league_id);

                const response = await axiosInstance.post('admin/leagues/create', data);

                if(response.status === 201){
                    enqueueSnackbar('League Created Successfully', { variant: "success" });

                    setTimeout(() => {
                        navigate('/admin/top-leagues')
                    }, 2000)
                }
            }catch(err){
                if(err?.response?.data){
                    const errs = err?.response?.data?.errors;
                    if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                        if(Object.keys(errs).length === 0){
                            if(err.response.data?.message){
                                enqueueSnackbar(err.response.data?.message, { variant: "error" });
                            }else{
                                enqueueSnackbar(err.response?.statusText, { variant: "error" });
                            }
                        }else{
                            enqueueSnackbar(Object.values(errs).join(' ,'), { variant: "error" });
                        }
                    }else{
                        if(err.response.data?.message){
                            enqueueSnackbar(err.response.data?.message, { variant: "error" });
                        }else{
                            enqueueSnackbar(err.response?.statusText, { variant: "error" });
                        }
                    }
                }
            }
        }else {
            enqueueSnackbar('Fill the form to create the league', { variant: "error" });
        }
    }

    const editLeague = async () => {
        if(validateMainInfo()){
            try{
                let data = new FormData();
                if(mainInfo.file){
                    data.append('file', mainInfo.file);
                }
                data.append('priority', mainInfo.priority);
                data.append('is_visible', mainInfo.is_visible);
                data.append('name', mainInfo.name);
                data.append('sport_id', mainInfo.sport_id);
                data.append('league_id', mainInfo.league_id);

                const response = await axiosInstance.post('admin/leagues/'+id, data);

                if(response.status === 200){
                    enqueueSnackbar('League Edited Successfully', { variant: "success" });
                    
                    if(response.data){
                        setLeague(response.data)
                    }
                }
            }catch(err){
                if(err?.response?.data){
                    const errs = err?.response?.data?.errors;
                    if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                        if(Object.keys(errs).length === 0){
                            if(err.response.data?.message){
                                enqueueSnackbar(err.response.data?.message, { variant: "error" });
                            }else{
                                enqueueSnackbar(err.response?.statusText, { variant: "error" });
                            }
                        }else{
                            enqueueSnackbar(Object.values(errs).join(' ,'), { variant: "error" });
                        }
                    }else{
                        if(err.response.data?.message){
                            enqueueSnackbar(err.response.data?.message, { variant: "error" });
                        }else{
                            enqueueSnackbar(err.response?.statusText, { variant: "error" });
                        }
                    }
                }
            }
        }else {
            enqueueSnackbar('Fill the form to edit the league', { variant: "error" });
        }
    }

    const deleteLeague = async () => {
        try{
            const response = await axiosInstance.delete('admin/leagues/'+id);

            if(response.status === 200){
                enqueueSnackbar('League Deleted Successfully', { variant: "success" });
                
                setTimeout(() => {
                    navigate('/admin/top-leagues')
                }, 2000)
            }
        }catch(err){
            if(err?.response?.data){
                const errs = err?.response?.data?.errors;
                if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                    if(Object.keys(errs).length === 0){
                        if(err.response.data?.message){
                            enqueueSnackbar(err.response.data?.message, { variant: "error" });
                        }else{
                            enqueueSnackbar(err.response?.statusText, { variant: "error" });
                        }
                    }else{
                        enqueueSnackbar(Object.values(errs).join(' ,'), { variant: "error" });
                    }
                }else{
                    if(err.response.data?.message){
                        enqueueSnackbar(err.response.data?.message, { variant: "error" });
                    }else{
                        enqueueSnackbar(err.response?.statusText, { variant: "error" });
                    }
                }
            }
        }
    }

    return(
        <div className="main_page pt-0">
            <div className="dashboard_admin">
                <h5 className="mb-0">{id === 'new' ? 'Create League' : 'Edit League'}</h5>
            </div>

            {(league || id === 'new') && 
             <Container className="pt-5 pb-5">
                <h6>Main details</h6>

                {id !== 'new' && league &&
                    <div className="pb-3">
                        <img style={{maxWidth: '150px', width: '100%'}} src={league.img} alt="league_img" />
                    </div>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <fieldset className="max-w-md space-y-1">
                            <FormControlLabel control={<Checkbox checked={mainInfo.is_visible} onChange={e => setMainInfo({...mainInfo, is_visible : e.target.checked})} />} label="Is Visible" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="image">Image</Label>
                            <Input onChange={e => setMainInfo({...mainInfo, file: e.target.files[0]})} id="image" placeholder="Choose image" type="file" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="priority">Priority</Label>
                            <Input value={mainInfo.priority} onChange={e => setMainInfo({...mainInfo, priority: e.target.value})} id="priority" placeholder="Enter priority" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="name">Name</Label>
                            <Input value={mainInfo.name} onChange={e => setMainInfo({...mainInfo, name: e.target.value})} id="name" placeholder="Enter name" type="text" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="sport_id">Sport Id</Label>
                            <Input value={mainInfo.sport_id} onChange={e => setMainInfo({...mainInfo, sport_id: e.target.value})} id="sport_id" placeholder="Enter sport id" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="league_id">League Id</Label>
                            <Input value={mainInfo.league_id} onChange={e => setMainInfo({...mainInfo, league_id: e.target.value})} id="league_id" placeholder="Enter bets api league id" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                </Grid>

                <div className="pt-3">     
                    <Button onClick={() => {id==='new' ? createLeague() : editLeague()}} className="me-3 mt-2"  color="primary">{id==='new' ? 'Create ' : 'Update '}League</Button>
                
                    {id !== 'new' &&
                    <Button onClick={() => {deleteLeague()}} className="me-3 mt-2"  color="error">Delete League</Button>
                    }
               </div>
             </Container>
            }
            {(!league && id !== 'new') &&
                <Container className="pt-5 pb-5">
                    <div style={{textAlign: 'center', width: '100%'}}>League not found.</div>
                </Container>
            }
        </div>
    )
}