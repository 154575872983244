import {useRef} from 'react';
import ImageWithFallback from '../../helpers/ImageWithFallback';
import '../../assets/css/Sport.css';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function ChooseSport(){
    const { categories } = useSelector(state => state.sports);
   
    const gridRef = useRef(null);
    const navigate = useNavigate();

    const scrollLeft = () => {
        if (gridRef.current) {
          gridRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
      };
      
      const scrollRight = () => {
        if (gridRef.current) {
          gridRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
      };

      const openSport = (category) => {
        if(category === 'Boxing'){
            navigate('/play/sport/'+category+'_'+category);
        }else if(category === 'Mixed Martial Arts'){
            navigate('/play/sport/mma_mixed_martial_arts')
        }
        else{
            navigate('/play/sport/'+category);
        }
      }
    
    return(
        <div className="scroll-container">
            <div className="scroll_btn_container">
                <IconButton onClick={scrollLeft} className="scroll-btn left">
                    <ArrowBackIosIcon />
                </IconButton>
            </div>
            <div className="grid-wrapper" ref={gridRef}>
                <Grid container spacing={2} className="sports_menu">
                    {categories &&
                        categories.map((category) => (
                        <Grid item xs={3} sm={2} className="grid-item" key={category.id}>
                            <div onClick={() => openSport(category.name)} className="category_sport">
                                <ImageWithFallback
                                src={`${category.img}`}
                                alt={category.name}
                                fallbackSrc="/static/sports/Other.svg"
                                />
                                <h6>{category.name}</h6>
                            </div>
                        </Grid>
                        ))}
                </Grid>
            </div>
            <div className="scroll_btn_container">
                <IconButton onClick={scrollRight} className="scroll-btn right">
                    <ArrowForwardIosIcon />
                </IconButton>
            </div>
        </div>
    )
}