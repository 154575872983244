import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { store } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from "react-redux";
import { ThirdwebProvider } from "thirdweb/react";
import { SnackbarProvider } from 'notistack';

import "./assets/css/scss/_variables.scss";
import "./assets/css/responsive/responsive_home.css"
import ScrollToTop from './components/ScrollToTop';
import { client } from './helpers/ThirdwebClient';
import { ReferralProvider } from './helpers/ReferralContext';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#3e9dfa',
      main: '#0B82F7',
      dark: '#004d99',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3e9dfa',
      main: '#0B82F7',
      dark: '#004d99',
      contrastText: '#000',
    },
  },
});
const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <ThirdwebProvider client={client}>
                <BrowserRouter>
                  <ReferralProvider>
                    <ScrollToTop/>
                    <App />
                    </ReferralProvider>
                </BrowserRouter>
              </ThirdwebProvider>
            </ThemeProvider>
          </PersistGate>
      </Provider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
