import { Container } from 'react-bootstrap';
import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';
import {useState, useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Button } from 'keep-react'
import dayjs from 'dayjs';
import OddType1 from '../../components/Admin/OddType1';
import OddType2 from '../../components/Admin/OddType2';
import OddType3 from '../../components/Admin/OddType3';
import OddType4 from '../../components/Admin/OddType4';
import OddType5 from '../../components/Admin/OddType5';
import OddType6 from '../../components/Admin/OddType6';
import OddType7 from '../../components/Admin/OddType7';
import OddType8 from '../../components/Admin/OddType8';
import ViewBetsCreated from './components/ViewBetsCreated';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import axiosInstance from '../../redux/axios';
import {useNavigate, useParams} from 'react-router-dom';
import RulesCustum from './components/RulesCustum';
import ScheduleScore from './components/ScheduleScore';
import { useSnackbar } from 'notistack';

const types = [
    {id: 0, name: 'Fulltime Result', type: 3},
    {id: 1, name: 'Double Chance', type: 3},
    {id: 2, name: 'Half Time Result', type: 3},
    {id: 8, name: 'Half Time/Full Time', type: 7},
    {id: 3, name: 'N Goal', type: 3},
    {id: 21, name: 'Match Corners', type: 8},
    {id: 18, name: 'To Win 2nd Half', type: 3},
    {id: 4, name: 'Match Goals', type: 1},
    {id: 5, name: 'Alternative Match Goals', type: 1},
    {id: 7, name: 'Asian Handicap', type: 4},
    {id: 10, name: 'Final Score', type: 2},
    {id: 11, name: '3-Way Handicap', type: 4},
    {id: 13, name: 'Draw No Bet', type: 5},
    {id: 14, name: 'Last Team to Score', type: 3},
    {id: 15, name: 'Goals Odd/Even', type: 5},
    {id: 16, name: 'Result / Both Teams To Score', type: 1},
    {id: 16, name: 'Both Teams To Score', type: 5},
    {id: 23, name: 'Team to Score in 1st Half', type: 6},
    {id: 19, name: 'Team to Score in 2nd Half', type: 6},
    {id: 24, name: 'Team Clean Sheet', type: 6},
]

export default function ACustumEdit() {
    const {id} = useParams();
    const [game, setGame] = useState(null);
    const [selectedType, setSType] = useState({name: 'Select Type', type: 0})
    const [generate, setGenerate] = useState(false);
    const [mainInfo, setMainInfo] = useState({
        sport_id: '',
        ss: '',
        league: {
            name: ''
        },
        home: {
            name: ''
        },
        away: {
            name: ''
        },
        time_status: '',
        startsAt: dayjs(),
        endsAt: dayjs(),
        is_home: true,
        is_visible: true,
        videoId: '',
        first_half_duration: '',
        second_half_duration: '',
        timeout_duration: ''
    })
    const [schedule, setSchedule] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [validUntil, setValidUntil] = useState(dayjs())
    const [NoGoal, setNoGoals] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        async function getGame() {
            try{
                const response = await axiosInstance.get('admin/games/'+id)
             
                if(response.data.game){
                    const game = response.data.game
                    setGame(game)
                    setMainInfo({
                        sport_id: game.sport_id || '',
                        ss: game.ss || '',
                        league: {
                            name: game.league?.name || ''
                        },
                        home: {
                            name: game.home?.name || ''
                        },
                        away: {
                            name: game.away?.name || ''
                        },
                        time_status: game.time_status || 0,
                        startsAt: dayjs(game.startsAt) || dayjs(),
                        endsAt: dayjs(game.endsAt) || dayjs(),
                        is_home: game.is_home || false,
                        is_visible: game.is_visible || false,
                        videoId: game.videoId || '',
                        first_half_duration: game.first_half_duration?.$numberDecimal || 0,
                        second_half_duration: game.second_half_duration?.$numberDecimal || 0,
                        timeout_duration: game.timeout_duration?.$numberDecimal || 0
                    })
                    setGenerate(true)
                    setOdds(game.MGs)
                }
            }catch{}
        }
        if(id && id !== 'new'){
            getGame();
        }
    }, [id])

    const [plus1, setPlus1] = useState([[
        { name: "", order: "0" },
        { name: "", OD: "", order: "0" },
        { name: "", OD: "", order: "0" }
    ]]);
    const [plus2, setPlus2] = useState([[
        { name: "", OD: "", order: "0" },
    ]]);
    const [type3, setType3] = useState([
        { name: "", OD: "", order: "0"},
        { name: "", OD: "", order: "1"},
        { name: "", OD: "", order: "2"},
    ])
    const [plus4, setPlus4] = useState([selectedType?.name?.startsWith('3') ? [
        { name: "", OD: "", hd: "", order: "0" },
        { name: "", OD: "", hd: "", order: "1" },
        { name: "", OD: "", hd: "", order: "2" },
    ] : 
    [
        { name: "", OD: "", hd: "", order: "0" },
        { name: "", OD: "", hd: "", order: "1" },
    ]
    ]);
    const [type5, setType5] = useState([
        { name: "", OD: "", order: "0"},
        { name: "", OD: "", order: "1"},
    ])
    const [type6, setType6] = useState([
        { title: "", order: "-1"},
        { name: "", OD: "", order: "0"},
        { name: "", OD: "", order: "1"},
        { title: "", order: "-1"},
        { name: "", OD: "", order: "0"},
        { name: "", OD: "", order: "1"},
    ])
    const [type7, setType7] = useState([
        {
            name: "",
            OD: "",
            order: "0",
        },
        {
            name: "",
            OD: "",
            order: "1",
        },
        {
            name: "",
            OD: "",
            order: "2",
        },
        {
            name: "",
            OD: "",
            order: "3",
        },
        {
            name: "",
            OD: "",
            order: "4",
        },
        {
            name: "",
            OD: "",
            order: "5",
        },
        {
            name: "",
            OD: "",
            order: "6",
        },
        {
            name: "",
            OD: "",
            order: "7",
        },
        {
            name: "",
            OD: "",
            order: "8",
        }
    ])
    const [plus8, setPlus8] = useState([[
        { name: "", order: "0" },
        { name: "", OD: "", order: "0" },
        { name: "", OD: "", order: "0" },
        { name: "", OD: "", order: "0" },
    ]]);

    const [odds, setOdds] = useState([])
    
    useEffect(() => {
        setPlus1([[
            { name: "", order: "0" },
            { name: "", OD: "", order: "0" },
            { name: "", OD: "", order: "0" }
        ]])
        setPlus2([[
            { name: "", OD: "", order: "0" },
        ]])
        setType3([
            { name: "", OD: "", order: "0"},
            { name: "", OD: "", order: "1"},
            { name: "", OD: "", order: "2"},
        ])
        setPlus4([
            selectedType?.name?.startsWith('3') ? [
                { name: "", OD: "", hd: "", order: "0" },
                { name: "", OD: "", hd: "", order: "1" },
                { name: "", OD: "", hd: "", order: "2" },
            ] : 
            [
                { name: "", OD: "", hd: "", order: "0" },
                { name: "", OD: "", hd: "", order: "1" },
            ]
        ])
        setType5([
            { name: "", OD: "", order: "0"},
            { name: "", OD: "", order: "1"},
        ])
        setType6([
            { title: "", order: "-1"},
            { name: "", OD: "", order: "0"},
            { name: "", OD: "", order: "1"},
            { title: "", order: "-1"},
            { name: "", OD: "", order: "0"},
            { name: "", OD: "", order: "1"},
        ])
        setPlus8([[
            { name: "", order: "0" },
            { name: "", OD: "", order: "0" },
            { name: "", OD: "", order: "0" },
            { name: "", OD: "", order: "0" },
        ]])
        setValidUntil(dayjs())
    }, [selectedType])

    const addPlus1 = () => {
        const newOdd = [
            { name: "", order: String(plus1.length) }, 
            { name: "", OD: "", order: String(plus1.length) }, 
            { name: "", OD: "", order: String(plus1.length) } 
        ]
        setPlus1(prev => [...prev, newOdd]); // Add new odd type
    };
    const removePlus1 = (index) => {
        setPlus1(prev => prev.filter((_, i) => i !== index)); // Remove by index
    };
    const addPlus2 = () => {
        const newOdd = [
            { name: "", OD: "", order: String(plus2.length) }, 
        ]
        setPlus2(prev => [...prev, newOdd]); // Add new odd type
    };
    const removePlus2 = (index) => {
        setPlus2(prev => prev.filter((_, i) => i !== index)); // Remove by index
    };
    const addPlus4 = () => {
        const newOdd = selectedType?.name?.startsWith('3') ? [
            { name: "", OD: "", hd: "", order: "0" },
            { name: "", OD: "", hd: "", order: "1" },
            { name: "", OD: "", hd: "", order: "2" },
        ] : 
        [
            { name: "", OD: "", hd: "", order: "0" },
            { name: "", OD: "", hd: "", order: "1" },
        ]
        setPlus4(prev => [...prev, newOdd]); // Add new odd type
    };
    const removePlus4 = (index) => {
        setPlus4(prev => prev.filter((_, i) => i !== index)); // Remove by index
    };
    const addPlus8 = () => {
        const newOdd = [
            { name: "", order: String(plus8.length) }, 
            { name: "", OD: "", order: String(plus8.length) },
            { name: "", OD: "", order: String(plus8.length) }, 
            { name: "", OD: "", order: String(plus8.length) }
        ]
        setPlus8(prev => [...prev, newOdd]); // Add new odd type
    };
    const removePlus8 = (index) => {
        setPlus8(prev => prev.filter((_, i) => i !== index)); // Remove by index
    };
    const updateOddType8 = (index, updatedType) => {
        setPlus8(prev => {
            const newPlus = [...prev];
            newPlus[index] = updatedType; // Update specific index
            return newPlus;
        });
    };
    const updateOddType4 = (index, updatedType) => {
        setPlus4(prev => {
            const newPlus = [...prev];
            newPlus[index] = updatedType; // Update specific index
            return newPlus;
        });
    };
    const updateOddType2 = (index, updatedType) => {
        setPlus2(prev => {
            const newPlus = [...prev];
            newPlus[index] = updatedType; // Update specific index
            return newPlus;
        });
    };
    const updateOddType1 = (index, updatedType) => {
        setPlus1(prev => {
            const newPlus = [...prev];
            newPlus[index] = updatedType; // Update specific index
            return newPlus;
        });
    };

    const validateMainInfo = () => {
        return (mainInfo.sport_id && mainInfo.league.name && mainInfo.home.name && mainInfo.away.name)
    };
    const validateOtherMain = () => {
        return (mainInfo.ss && mainInfo.time_status !== '')
    };

    const allOddsFilled = (final) => {
        return final.title !== "" && 
            final.odds.every(odd => 
                odd.name !== "" && 
                odd.OD !== "" && 
                odd.order !== ""
            )
    }
    const allOddsOnlyTitle = (final) => {
        return final.title !== "" && 
            final.odds.every(odd => 
                odd.title !== ""
            )
    }

    const createOdd = async () => {
        if (selectedType) {
            let combinedOdds;
            let final = { title: selectedType.name === 'N Goal' ? (`${NoGoal}${(NoGoal === '1' ? 'st': NoGoal === '2' ? 'nd' : NoGoal === '3' ? 'rd' : 'th')} Goal`) : selectedType.name, type: selectedType.type, valid: validUntil, odds: [] };
    
            // Determine combined odds based on selected type
            switch (selectedType.type) {
                case 1:
                    combinedOdds = plus1.flatMap(type => type);
                    break;
                case 2:
                    combinedOdds = plus2.flatMap(type => type);
                    break;
                case 3:
                    combinedOdds = type3;
                    break;
                case 4:
                    combinedOdds = plus4.flatMap(type => type);
                    break;
                case 5:
                    combinedOdds = type5;
                    break;
                case 6:
                    combinedOdds = type6;
                    break;
                case 7:
                    combinedOdds = type7;
                    break;
                case 8:
                    combinedOdds = plus8.flatMap(type => type);
                    break;
                default:
                    return; // Handle unexpected types, if necessary
            }
    
            final.odds = combinedOdds;
    
            // Check for duplicate titles
            const existingOdds = odds.find(odd => odd.title === final.title);
            if (existingOdds) {
                enqueueSnackbar('An odd with this title already exists.', { variant: "error" });
                return;
            }
    
            // Validate odds and check using allOddsOnlyTitle for type 6
            const isValid = selectedType.type === 6 
                ? allOddsOnlyTitle(final) 
                : allOddsFilled(final);
    
            if (isValid) {
                setOdds(prevOdds => [...prevOdds, final]);
            } else {
                enqueueSnackbar('Fill all the required fields to create the odd.', { variant: "error" });
            }
        } else {
            enqueueSnackbar('Select type to add an odd.', { variant: "error" });
        }
    };
    const checkGenerate = () => {
        if(validateMainInfo()){
            setGenerate(true)
        }else{
            setGenerate(false)
            enqueueSnackbar('Fill in the Sport ID, League and Teams to generate.', { variant: "error" });
        }
    }
    const validateScore = (score) => {
        const scoreRegex = /^\d+-\d+$/; // Matches "0-0", "1-2", etc.
        return scoreRegex.test(score);
    };
    const deleteOdd = (item) => {
        if(item){
            setOdds(prevOdds => prevOdds.filter(odd => odd.title !== item.title));
        }
    }

    const updateGame = async () => {
        if(validateMainInfo() && validateOtherMain()){
            const isValidStart = mainInfo.startsAt.isValid();
            const isValidEnd = mainInfo.endsAt.isValid();
            const isStartBeforeEnd = mainInfo.startsAt.isBefore(mainInfo.endsAt);
            
            if (isValidStart && isValidEnd && isStartBeforeEnd) {
               if(validateScore(mainInfo.ss)){
                    try{
                        let data = mainInfo;
                        data.MGs = odds;
                        const response = await axiosInstance.post('admin/games/update/'+id, data);

                        if(response.status === 201){
                            enqueueSnackbar('Game Updated Successfully', { variant: "success" });
                        }
                    }catch(err){
                        if(err?.response?.data){
                            const errs = err?.response?.data?.errors;
                            if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                                if(Object.keys(errs).length === 0){
                                    if(err.response.data?.message){
                                        enqueueSnackbar(err.response.data?.message, { variant: "error" });
                                    }else{
                                        enqueueSnackbar(err.response?.statusText, { variant: "error" });
                                    }
                                }else{
                                    enqueueSnackbar(Object.values(errs).join(' ,'), { variant: "error" });
                                }
                            }else{
                                if(err.response.data?.message){
                                    enqueueSnackbar(err.response.data?.message, { variant: "error" });
                                }else{
                                    enqueueSnackbar(err.response?.statusText, { variant: "error" });
                                }
                            }
                        }
                    }
               }else{
                    enqueueSnackbar('Score is not in the correct format', { variant: "error" });
               }
            }else{
                enqueueSnackbar('The start and end time are not correct. Check if it starts before it ends.', { variant: "error" });
            }
        }else {
            enqueueSnackbar('Fill the form to update the game', { variant: "error" });
        }
    }

    const createGame = async () => {
        if(validateMainInfo() && validateOtherMain()){
            const isValidStart = mainInfo.startsAt.isValid();
            const isValidEnd = mainInfo.endsAt.isValid();
            const isStartBeforeEnd = mainInfo.startsAt.isBefore(mainInfo.endsAt);
            
            if (isValidStart && isValidEnd && isStartBeforeEnd) {
               if(validateScore(mainInfo.ss)){
                    try{
                        let data = mainInfo;
                        data.MGs = odds;
                        const response = await axiosInstance.post('admin/games/create', data);

                        if(response.status === 201){
                            enqueueSnackbar('Game Created Successfully', { variant: "success" });
                            setTimeout(() => {
                                navigate('/admin/custom-games')
                            }, 2000)
                        }
                    }catch(err){
                        if(err?.response?.data){
                            const errs = err?.response?.data?.errors;
                            if(typeof errs === 'object' && errs !== null && !Array.isArray(errs)){
                                if(Object.keys(errs).length === 0){
                                    if(err.response.data?.message){
                                        enqueueSnackbar(err.response.data?.message, { variant: "error" });
                                    }else{
                                        enqueueSnackbar(err.response?.statusText, { variant: "error" });
                                    }
                                }else{
                                    enqueueSnackbar(Object.values(errs).join(' ,'), { variant: "error" });
                                }
                            }else{
                                if(err.response.data?.message){
                                    enqueueSnackbar(err.response.data?.message, { variant: "error" });
                                }else{
                                    enqueueSnackbar(err.response?.statusText, { variant: "error" });
                                }
                            }
                        }
                    }
               }else{
                    enqueueSnackbar('Score is not in the correct format', { variant: "error" });
               }
            }else{
                enqueueSnackbar('The start and end time are not correct. Check if it starts before it ends.', { variant: "error" });
            }
        }else {
            enqueueSnackbar('Fill the form to create the game', { variant: "error" });
        }
    }

    return(
        <div className="main_page pt-0">
            <ScheduleScore open={schedule} changeOpen={setSchedule} choosen={game} />
            <div className="dashboard_admin">
                <h5 className="mb-0">Edit Game</h5>
            </div>

            {(game || id === 'new') &&
            <Container className="pt-5 pb-5">
                <RulesCustum />
                <h6>Main details</h6>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <FormControlLabel control={<Checkbox checked={mainInfo.is_visible} onChange={e => setMainInfo({...mainInfo, is_visible : e.target.checked})} />} label="Is Visible" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <FormControlLabel control={<Checkbox checked={mainInfo.is_home} onChange={e => setMainInfo({...mainInfo, is_home : e.target.checked})} />} label="Show on Home Page" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="sport_id">Sport ID</Label>
                            <Input value={mainInfo.sport_id} onChange={e => setMainInfo({...mainInfo, sport_id: e.target.value})} id="sport_id" placeholder="Enter sport ID" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="league_name">League Name</Label>
                            <Input value={mainInfo.league.name} onChange={e => setMainInfo({...mainInfo, league: {name: e.target.value}})} id="league_name" placeholder="Enter name" type="text" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="home_team">Home Team</Label>
                            <Input id="home_team" value={mainInfo.home.name} onChange={e => setMainInfo({...mainInfo, home: {name: e.target.value}})} placeholder="Enter name" type="text" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="away_team">Away Team</Label>
                            <Input id="away_team" value={mainInfo.away.name} onChange={e => setMainInfo({...mainInfo, away: {name: e.target.value}})} placeholder="Enter name" type="text" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="score">Score</Label>
                            <Input id="score" value={mainInfo.ss} onChange={e => setMainInfo({...mainInfo, ss: e.target.value})} placeholder="format. 0-0" type="text" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="time_status">Time Status</Label>
                            <Input value={mainInfo.time_status} onChange={e => setMainInfo({...mainInfo, time_status: e.target.value})} id="time_status" placeholder="Enter time status" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="starts_at">Starts at</Label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker value={mainInfo.startsAt} onChange={e => setMainInfo({...mainInfo, startsAt: e})} className="datetime_pic"/>
                                </DemoContainer>
                            </LocalizationProvider>
                        </fieldset>
                    </Grid>

                    <Grid item xs={6}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="starts_at">Ends at</Label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker value={mainInfo.endsAt} onChange={e => setMainInfo({...mainInfo, endsAt: e})} className="datetime_pic"/>
                                </DemoContainer>
                            </LocalizationProvider>
                        </fieldset>
                    </Grid>

                    <Grid item xs={12}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="starts_at">Youtube Video ID</Label>
                            <Input id="score" value={mainInfo.videoId} onChange={e => setMainInfo({...mainInfo, videoId: e.target.value})} placeholder="ex. QquwMkNtFDk?si=0hZErZz-1iIMeZMO" type="text" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={4}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="away_team">First Half Duration (minutes)</Label>
                            <Input id="away_team" value={mainInfo.first_half_duration} onChange={e => setMainInfo({...mainInfo, first_half_duration: e.target.value})} placeholder="ex. 45" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={4}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="away_team">Timeout Duration (minutes)</Label>
                            <Input id="away_team" value={mainInfo.timeout_duration} onChange={e => setMainInfo({...mainInfo, timeout_duration: e.target.value})} placeholder="ex. 15" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>

                    <Grid item xs={4}>
                        <fieldset className="max-w-md space-y-1">
                            <Label htmlFor="away_team">Second Half Duration (minutes)</Label>
                            <Input id="away_team" value={mainInfo.second_half_duration} onChange={e => setMainInfo({...mainInfo, second_half_duration: e.target.value})} placeholder="ex. 45" type="number" className="bg-gray-800 text-white" />
                        </fieldset>
                    </Grid>
                </Grid>

                <div className="pt-3">
                    <Button onClick={() => {checkGenerate()}} className="me-3 mt-2" color="success">Generate Odds</Button>
                    <Button onClick={() => {id==='new' ? createGame() : updateGame()}} className="me-3 mt-2"  color="primary">{id==='new' ? 'Create ' : 'Update '}Game</Button>
                    {id !== 'new' &&
                    <Button onClick={() => setSchedule(true)} className="me-3 mt-2"  color="warning">Schedule Scores Update</Button>
                    }
                </div>
                
                {generate && <>
                <h6 className="mt-4">Create Bet Odds</h6>

                <ViewBetsCreated deleteOdd={deleteOdd} odds={odds} />

                <Grid container spacing={2}>
                    <Grid sx={{alignItems: 'center', display: 'flex'}} item xs={2} sm={1}>
                        <Fab sx={{width: '2.38rem', height: '2.38rem'}} color="primary" aria-label="add">
                            <AddIcon onClick={() => createOdd()} />
                        </Fab>
                    </Grid>
                    <Grid item xs={10} sm={11}>
                        <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
                            <InputLabel id="demo-select-small-label">Select Type</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedType?.name || ""}
                                label="Select Type"
                                onChange={(e) => {
                                    const selected = types.find(t => t.name === e.target.value);
                                    setSType(selected); // Set the entire object if needed
                                }}
                            >
                                <MenuItem value="">
                                    <em>Select Type</em>
                                </MenuItem>
                                {types.map(t =>
                                    <MenuItem key={t.name} value={t.name}>{t.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {selectedType?.type !== 0 &&
                    <div>
                        {selectedType &&
                        <>
                        <h6 className="pt-3">Example</h6>
                        <img className="pb-3" style={{width: '25rem'}} src={'/static/admin/'+selectedType?.name?.toString().replaceAll("/","")+'.png'} alt='example' />

                        <fieldset className="max-w-md space-y-1 mb-1">
                            <Label htmlFor="starts_at">Valid Until</Label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker value={validUntil} onChange={e => setValidUntil(e)} className="datetime_pic"/>
                                </DemoContainer>
                            </LocalizationProvider>
                        </fieldset>
                        </>}
                        {selectedType?.type === 1 &&
                          <>
                            {plus1.map((type1, index) => (
                                <div className="admin_odd_wrapper" key={index}>
                                    <OddType1
                                        type1={type1}
                                        setType1={(updatedType) => updateOddType1(index, updatedType)} 
                                    />
                                    {index > 0 && (
                                        <Button onClick={() => removePlus1(index)} color="error">Remove</Button>
                                    )}
                                </div>
                            ))}
                            <Button onClick={addPlus1}>Add More</Button>
                        </>
                        }
                        {selectedType?.type === 2 &&
                        <>
                            {plus2.map((type2, index) => (
                                <div className="admin_odd_wrapper" key={index}>
                                    <OddType2
                                        type2={type2}
                                        setType2={(updatedType) => updateOddType2(index, updatedType)} 
                                    />
                                    {index > 0 && (
                                        <Button onClick={() => removePlus2(index)} color="error">Remove</Button>
                                    )}
                                </div>
                            ))}
                            <Button onClick={addPlus2}>Add More</Button>
                        </>    
                        }
                        {selectedType?.type === 3 &&
                            <>
                            {selectedType?.name === 'N Goal' &&
                             <fieldset className="max-w-md space-y-1 pb-2">
                                <Label htmlFor="home_team">No. Goal</Label>
                                <Input value={NoGoal} onChange={e => setNoGoals(e.target.value)} id="home_team" placeholder="ex. 1,2,3 ..." type="number" className="bg-gray-800 text-white" />
                            </fieldset>
                            }
                            <div className="admin_odd_wrapper">
                                <OddType3 type3={type3} setType3={setType3} />
                            </div>
                            </>
                        }
                         {selectedType?.type === 4 &&
                         <>
                            {plus4.map((type4, index) => (
                                <div className="admin_odd_wrapper" key={index}>
                                    <OddType4
                                        selectedName={selectedType.name}
                                        type4={type4}
                                        setType4={(updatedType) => updateOddType4(index, updatedType)} 
                                    />
                                    {index > 0 && (
                                        <Button onClick={() => removePlus4(index)} color="error">Remove</Button>
                                    )}
                                </div>
                            ))}
                            <Button onClick={addPlus4}>Add More</Button>
                            </>
                        }
                        {selectedType?.type === 5  &&
                             <>
                             {selectedType?.name === 'N Goal' &&
                              <fieldset className="max-w-md space-y-1 pb-2">
                                 <Label htmlFor="home_team">No. Goal</Label>
                                 <Input id="home_team" placeholder="ex. 1,2,3 ..." type="number" className="bg-gray-800 text-white" />
                             </fieldset>
                             }
                             <div className="admin_odd_wrapper">
                                 <OddType5 type5={type5} setType5={setType5} />
                             </div>
                             </>
                        }

                        {selectedType?.type === 6 &&
                          <>
                          <h6>If no odd for certain type, leave OD and name empty just fill in the title</h6>
                          <div className="admin_odd_wrapper">
                              <OddType6 type6={type6} setType6={setType6} />
                          </div>
                          </>
                        }

                        {selectedType?.type === 7 &&
                          <div className="admin_odd_wrapper">
                            <OddType7 type7={type7} setType7={setType7} mainInfo={mainInfo} />
                          </div>
                        }

                        {selectedType?.type === 8 &&
                           <>
                           {plus8.map((type8, index) => (
                               <div className="admin_odd_wrapper" key={index}>
                                   <OddType8
                                       type8={type8}
                                       setType8={(updatedType) => updateOddType8(index, updatedType)} 
                                   />
                                   {index > 0 && (
                                       <Button onClick={() => removePlus8(index)} color="error">Remove</Button>
                                   )}
                               </div>
                           ))}
                           <Button onClick={addPlus8}>Add More</Button>
                       </>
                        }
                    </div>
                }

                </>}
            </Container>
            }
            {(!game && id !== 'new') &&
                <Container className="pt-5 pb-5">
                    <div style={{textAlign: 'center', width: '100%'}}>Game not found.</div>
                </Container>
            }
        </div>
    )
}