import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';

const initialState = {
   accessToken: null,
   user: null,
   betSplips: [],
   wallet: null,
   ref: null
};

const calOdd = (odd) => {
    try{
    const [numerator, denominator] = odd.split("/").map(Number);
    const result = numerator / denominator;

    return isNaN(result) ? 0 : parseFloat(result + 1)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }catch{
        return 0;
    }
}


const UserSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setRef:(state, action) => {
            state.ref = action.payload
        },
        setWallet: (state, action) => {
            state.wallet = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        updateUserBalance: (state, action) => {
            if(state.user){
                try{
                    state.user.balance.$numberDecimal = action.payload;
                }catch{}
            }
        },
        updateUserInfo: (state, action) => {
            state.user = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        logOutUser: (state) => {
            state.accessToken = null;
            state.user = null;
        },
        toggleBetSlip: (state, action) => {
            let {event , odd, team, type, comes_from} = action.payload;
           
            const findIndex = state.betSplips.findIndex(x => {
                return x.team.name === team.name && x.event.league?.name === event.league?.name && x.type === type
            })

            if(comes_from !== 'upcoming' && comes_from !== 'cevent'){
                odd.odd = calOdd(odd.odd)
            }

            if(findIndex !== -1){
                state.betSplips.splice(findIndex, 1);
            }else{
                state.betSplips.push({
                    ...action.payload,
                    id: uuidv4(),
                    est_payout: 0.000
                });
            }
        },
        updateBetSlip: (state, action) => {
            const { id, value } = action.payload;
            const betSlip = state.betSplips.find(betSlip => betSlip.id === id);
            
            if (betSlip) {
              betSlip.input = value;
              betSlip.est_payout = parseFloat(value * betSlip.odd.odd)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        },
        removeBetSlip: (state, action) => {
            const {event, team, type} = action.payload;
            const findIndex = state.betSplips.findIndex(x => {
                return x.team.name === team.name && x.event.id === event.id && x.type === type
            })
            if(findIndex !== -1){
                state.betSplips.splice(findIndex, 1);
            }
        },
        clearAllSlips: (state) => {
            state.betSplips = []
        }
    }
});

export const {
    setUser,
    setAccessToken,
    logOutUser,
    toggleBetSlip,
    removeBetSlip,
    clearAllSlips,
    updateBetSlip,
    updateUserInfo,
    setWallet,
    updateUserBalance,
    setRef
} = UserSlice.actions;

export default UserSlice.reducer;