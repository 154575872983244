import {Container} from 'react-bootstrap';
import {Breadcrumbs, Typography, Link} from '@mui/material';
import { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ImageWithFallback from '../../../helpers/ImageWithFallback';
import { useSelector, useDispatch } from 'react-redux';
import Selectable from '../../../components/Sports/Selectable';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { OutlinedInput, InputAdornment, FormControl} from "@mui/material"
import axiosInstance from '../../../redux/axios';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { addInplay, addSports } from '../../../redux/reducers/SportsReducer';
import { toggleBetSlip } from '../../../redux/reducers/UserReducer';
import Flag from 'react-world-flags';
import res from '../../../helpers/GroupSports.js';
import dayjs, { getUserTimezone } from '../../../helpers/UserTimestamp.js';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';

const inferCountry = res.inferCountry;
const countryToISO = res.countryToISO;


const values = [
    {id: 1, name: 'Live'},
    {id: 2, name: 'Prematch'}
]

const timeStatus = [
    {id: '0', title:'Not Started'},
    {id: '1', title:'InPlay'},
    {id: '2', title:'TO BE FIXED'},
    {id: '3', title:'Ended'},
    {id: '4', title:'Postponed'},
    {id: '5', title:'Cancelled'},
    {id: '6', title:'Walkover'},
    {id: '7', title:'Interrupted'},
    {id: '8', title:'Abandoned'},
    {id: '9', title:'Retired'},
    {id: '10', title:'Suspended'},
    {id: '11', title:'Decided by FA'},
    {id: '99', title:'Removed'},
]

const customFlags = {
    e1: '/static/flags/esports.png',      // Custom image path for Esports
    e2: '/static/flags/international.png', // Custom image path for International Clubs
    e3: '/static/flags/africa.png',        // Custom image for Africa-based competitions
};

function getFlagSrc(code) {
    return customFlags[code] || null;
}

export default function Sport({setOpenBets}){
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isMediumScreen = useMediaQuery('(max-width:960px)');
  
    // Adjust width and height based on screen size
    const width = isSmallScreen ? 350 : isMediumScreen ? 690 : 1300;
    const height = isSmallScreen ? 100 : isMediumScreen ? 180 : 150;

    const userTimezone = getUserTimezone();

    const { categories } = useSelector(state => state.sports)
    const { user } = useSelector(state => state.user)
    const { selected_sport } = useParams();
 
    const sub_selected = selected_sport ? selected_sport?.toString()?.split('_')[0] : selected_sport;

    const [selected, setSelected] = useState(1)
    const navigate = useNavigate();
    const [search, doSearch] = useState('');
    const [loading, setLoading] = useState(true);

    const [events, setEvents] = useState(null);

    const dispatch = useDispatch();
    const [upcoming, setUpcoming] = useState([]);

    const clearSearch = () => {
        doSearch('')
    }

    const groupedByCountry = (leaguesArray) => {
        if(leaguesArray){
            return leaguesArray.reduce((acc, league) => {
                const country = inferCountry(league.league_name);  // Infer country dynamically
                if (!acc[country]) {
                acc[country] = [];
                }
                acc[country].push(league);
                return acc;
            }, {});
        }else{
            return {}
        } 
    }

    const reorderedGroupedByCountry = (groupedByCountry) => {
        return Object.keys(groupedByCountry)
            .sort((a, b) => {
                // Ensure "International Clubs" is always first
                if (a === 'International Clubs') return -1; // a comes first
                if (b === 'International Clubs') return 1;  // b comes first
    
                // Check if there are any custom events in the leagues for country a and b
                const aHasCustomEvents = groupedByCountry[a].some(league => 
                    league.events && league.events.some(event => event.is_custom)
                );
                const bHasCustomEvents = groupedByCountry[b].some(league => 
                    league.events && league.events.some(event => event.is_custom)
                );
    
                // Prioritize entries with is_custom: true
                if (aHasCustomEvents && !bHasCustomEvents) return -1; // a comes first
                if (!aHasCustomEvents && bHasCustomEvents) return 1;  // b comes first
    
                // Show Esports last
                if (a === 'Esports') return 1; // a comes last
                if (b === 'Esports') return -1; // b comes last
    
                // Maintain alphabetical order for other keys
                return a.localeCompare(b);
            })
            .reduce((acc, key) => {
                acc[key] = groupedByCountry[key];
                return acc;
            }, {});
    };

    useEffect(() => {
        async function getSportUpcomingEvents(category) {
            try{
                const res = await axiosInstance.get('sport/events/upcoming/'+category.id)
                if(res.data.data){
                    setUpcoming(res.data?.data)
                    dispatch(addSports(res.data?.data))
                    setLoading(false)
                }
            }catch{
                setLoading(false)
            }
        }
        async function getOddsSports(category) {
            try{
                const res = await axiosInstance.get('sport/events/'+category.id)
                if(res.data.data){
                    setEvents(res.data?.data)
                    dispatch(addInplay(res.data?.data))
                    setLoading(false)
                }
            }catch{
                setLoading(false)
            }
        }
        setUpcoming([])
        setEvents([])
        setLoading(true);
        const category = categories.find(x => {return x.name.toLowerCase() === selected_sport.toLowerCase()})
        if(category){
            getOddsSports(category)
            getSportUpcomingEvents(category)
        }else{
            setLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected_sport])


    const calOdd = (odd) => {
        try{
        const [numerator, denominator] = odd.split("/").map(Number);
        const result = numerator / denominator;
    
        return isNaN(result) ? 0 : parseFloat(result + 1)?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }catch{
            return 0;
        }
    }

    const addToSlip = (teamName, number, odd, e, type) => {
        if(user){
            let tempe = {...e}
            tempe.time_status = e.time_status?.toString()
            dispatch(toggleBetSlip({
                event: tempe,
                team: {
                    name: teamName,
                    number: number
                },
                odd: {
                    odd: odd.OD,
                    su: odd.SU
                },
                type: type,
                comes_from: e._id ? 'cevent' : 'sport',
                input: 0
            }))
            setOpenBets(true)
        }
    }

    const isEmpty = (obj) => {
        try{
            return Object.keys(obj).length === 0
        }catch{
            return true
        }
    }

    return(
        <div className="main_page">
            <div className="header_page">
                <Container>
                   <div className="event_t"> 
                        <ImageWithFallback 
                        style={{width: '1.4rem', marginRight: '.5rem', marginBottom: '.3rem'}}
                        src={`/static/sports/${sub_selected === 'mma' ? 'Mixed Martial Arts': sub_selected}.svg`}
                        alt={sub_selected}
                        fallbackSrc="/static/sports/Other.svg"
                        />
                        {sub_selected === 'mma' ? 'Mixed Martial Arts' : sub_selected} 
                    </div>
                    <Breadcrumbs  aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href={'/play'}>
                            Home
                        </Link> 
                        <Typography color="text.primary">{sub_selected === 'mma' ? 'Mixed Martial Arts' : sub_selected}</Typography>
                    </Breadcrumbs>
                </Container>
            </div>
            {!loading &&
            <Container style={{padding: '1rem', paddingTop: '2rem'}}>
               <div className="mobile_search_home">
                <FormControl sx={{zIndex: '10', position:'relative'}} fullWidth >
                    <OutlinedInput
                        sx={{zIndex: '10', position:'relative', marginBottom: '.5rem'}}
                        className="search_home"
                        startAdornment={<InputAdornment  position="start"><SearchIcon className="search_icon"/></InputAdornment>}
                        endAdornment={<InputAdornment position="start">{search !== '' ? <CloseIcon onClick={() => {clearSearch()}} className="close_icon"/> : <></>}</InputAdornment>}
                        placeholder="Search events"
                        value={search}
                        onChange={(event) => {
                            doSearch(event.target.value);
                        }}
                    />
                </FormControl>
               </div>
               <div className="pt-3">
                    <Selectable selected={selected} setSelected={setSelected} values={values} />
                    <div className="pt-4 pb-3">
                            {(selected === 1) &&
                                <>
                                {events && 
                                    <div className="mb-5">
                                        {Object.entries(reorderedGroupedByCountry(groupedByCountry(Object.values(events)))).filter(([key, value]) => {
                                            // Check if the country name matches the search term or if any event within the leagues under this country matches the search term
                                            return value.some(league => 
                                            league.league_name.toLowerCase().includes(search.toLowerCase()) || // Check if league name matches the search term
                                            league.events.some(event =>
                                                event?.home?.name?.toLowerCase().includes(search.toLowerCase()) || // Check if home team matches the search term
                                                event?.away?.name?.toLowerCase().includes(search.toLowerCase())    // Check if away team matches the search term
                                            )
                                            ) || key.toLowerCase().includes(search.toLowerCase()); // Check if country name matches the search term
                                        }).map(([key,value]) =>
                                            <Fragment key={key}>
                                                {key !== 'Esports' &&
                                                <Accordion className="mb-2" defaultExpanded id={key} >
                                                    <AccordionSummary
                                                    className="acc_country"
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id={key}
                                                    >
                                                        <div className="acc_count_flex">
                                                            <div style={{justifyContent: 'flex-start'}} className="acc_count_flex">
                                                                {countryToISO[key] && (
                                                                    countryToISO[key].startsWith('e') ? (
                                                                        // Render custom image for custom flags
                                                                        <img
                                                                            src={getFlagSrc(countryToISO[key])}  // Use the custom flag source
                                                                            alt={`${key} flag`}
                                                                            style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                                        />
                                                                    ) : (
                                                                        // Render standard country flags using the Flag component
                                                                        <Flag
                                                                            code={countryToISO[key]}  // Use the ISO code for standard countries
                                                                            alt={`${key} flag`}
                                                                            style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                                        />
                                                                    )
                                                                )}
                                                                {key}
                                                            </div>
                                                            <div>{value?.length || 0}</div>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="acc_details_country">
                                                        {value && value.map(league => 
                                                            <Accordion defaultExpanded className="mb-2" id={league.league_name} key={league.league_name} >
                                                                <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1bh-content"
                                                                id={league.league_name}
                                                                >
                                                                    {league.league_name}
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    {league.events && league.events.map(e =>
                                                                        <div key={e.id || e._id}>
                                                                            <div className="live_date">
                                                                            <span className="is_live me-2"><span className="pulse-circle me-1"/>{timeStatus.find(x => {return parseInt(x.id) === parseInt(e.time_status)})?.title} </span>
                                                                            {e.time ? dayjs.unix(e.time).tz(userTimezone).format('ddd, MMM D, HH:mm') : dayjs(e.startsAt).tz(userTimezone).format('ddd, MMM D, HH:mm')}
                                                                            </div>
                                                                            <div className="live_grid pb-4">
                                                                                <div className="text-with-lines">1x2</div>
                                                                                <Grid style={{minWidth: '100%'}} container spacing={1}>
                                                                                    <Grid className="pt-2" item xs={12} sm={3}>
                                                                                        <div className="display_spacebetween">
                                                                                            <div className="teams_sport pt-1">{e.home.name}</div>
                                                                                            <div className="results_sport pt-1">
                                                                                                <div>{e.ss? e.ss?.split('-')[0] : 0}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="display_spacebetween">
                                                                                            <div className="teams_sport pt-1">{e.away.name}</div>
                                                                                            <div className="results_sport pt-1">
                                                                                                <div>{e.ss? e.ss?.split('-')[1] : 0}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Grid>
                                                                                    
                                                                                    <Grid item xs={11} sm={8}>
                                                                                        {e.odds && e.odds[0]?.length > 0 &&
                                                                                        <>
                                                                                        <Grid className="ps-2 pe-2" style={{minWidth: '100%'}} container spacing={2}>
                                                                                            <Grid item xs={e.odds[0][1] ? 4 : 6}>
                                                                                                <div onClick={() => addToSlip(e.home.name, "1", e.odds[0][0], e, "Fulltime Results")} className="live_bets">
                                                                                                    <div>{e.home.name}</div>
                                                                                                    <div>{calOdd(e.odds[0][0]?.OD)}</div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            {e.odds[0][1] &&
                                                                                            <Grid item xs={4}>
                                                                                                <div onClick={() => addToSlip("Draw", "0", e.odds[0][1], e, "Fulltime Results")}  className="live_bets">
                                                                                                    <div>Draw</div>
                                                                                                    <div>{calOdd(e.odds[0][1]?.OD)}</div>
                                                                                                </div>
                                                                                            </Grid>}
                                                                                            <Grid item xs={e.odds[0][1] ? 4 : 6}>
                                                                                                <div  onClick={() => addToSlip(e.away.name, "2", e.odds[0][2], e, "Fulltime Results")} className="live_bets">
                                                                                                    <div>{e.away.name}</div>
                                                                                                    <div>{calOdd(e.odds[0][2]?.OD)}</div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        </>
                                                                                        }
                                                                                        {e.MGs &&
                                                                                            e.MGs.filter(x => {return x.title === 'Fulltime Result'}).map((mg,i) =>
                                                                                                <Fragment key={mg.title}>
                                                                                                    <div className="text-with-lines">1x2</div>
                                                                                                    <Grid className="ps-2 pe-2" style={{minWidth: '100%'}} container spacing={2}>
                                                                                                    {mg.odds.map(od =>
                                                                                                        <Fragment key={od.name+od.OD}>
                                                                                                            <Grid item xs={4}>
                                                                                                                <div onClick={() => addToSlip(od.name, i, {OD: parseFloat(od.OD).toFixed(2), SU: '1'}, e, mg?.title)} className="live_bets">
                                                                                                                    <div>{od.name}</div>
                                                                                                                    <div>{parseFloat(od.OD).toFixed(2)}</div>
                                                                                                                </div>
                                                                                                            </Grid>
                                                                                                        </Fragment>
                                                                                                    )}
                                                                                                    </Grid>
                                                                                                </Fragment>
                                                                                            )
                                                                                        }
                                                                                    </Grid>
                                                                                            
                                                                                    <Grid onClick={() => {navigate(e._id ? `/play/sport/${selected_sport}/cevents/${e._id}` : `/play/sport/${selected_sport}/${selected_sport === 'Soccer' ? 'events' : 'events_other'}/${e.id?.split('C')[0]}`)}}  item xs={1}>
                                                                                        <div className="display_center">
                                                                                            <ArrowForwardIosIcon />
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                                }
                                            </Fragment>
                                        )}
                                    </div>
                                }
                               
                                {(!events || events?.length === 0 || isEmpty(events)) && 
                                    <div className="display_search_autocomplete display_nonefound_mobile"> No active {selected_sport} Games to display.</div>
                                }
                                </>
                            }

                            {selected === 2 && 
                                 <>
                                 {upcoming &&
                                    <div className="mb-5">
                                        {Object.entries(reorderedGroupedByCountry(groupedByCountry(Object.values(upcoming)))).filter(([key, value]) => {
                                            // Check if the country name matches the search term or if any event within the leagues under this country matches the search term
                                            return value.some(league => 
                                            league.league_name.toLowerCase().includes(search.toLowerCase()) || // Check if league name matches the search term
                                            league.events.some(event =>
                                                event?.home?.name?.toLowerCase().includes(search.toLowerCase()) || // Check if home team matches the search term
                                                event?.away?.name?.toLowerCase().includes(search.toLowerCase())    // Check if away team matches the search term
                                            )
                                            ) || key.toLowerCase().includes(search.toLowerCase()); // Check if country name matches the search term
                                        }).map(([key,value]) => 
                                            <Accordion className="mb-2" defaultExpanded id={key} key={key} >
                                                <AccordionSummary
                                                className="acc_country"
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id={key}
                                                >
                                                    <div className="acc_count_flex">
                                                        <div style={{justifyContent: 'flex-start'}} className="acc_count_flex">
                                                            {countryToISO[key] && (
                                                                countryToISO[key].startsWith('e') ? (
                                                                    // Render custom image for custom flags
                                                                    <img
                                                                        src={getFlagSrc(countryToISO[key])}  // Use the custom flag source
                                                                        alt={`${key} flag`}
                                                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                                    />
                                                                ) : (
                                                                    // Render standard country flags using the Flag component
                                                                    <Flag
                                                                        code={countryToISO[key]}  // Use the ISO code for standard countries
                                                                        alt={`${key} flag`}
                                                                        style={{ width: '20px', height: '20px', marginRight: '8px' }}
                                                                    />
                                                                )
                                                            )}
                                                            {key}
                                                        </div>
                                                        <div>{value?.length || 0}</div>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails className="acc_details_country">
                                                    {value && value.map(league => 
                                                        <Accordion defaultExpanded className="mb-2" id={league.league_name} key={league.league_name} >
                                                            <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1bh-content"
                                                            id={league.league_name}
                                                            >
                                                                {league.league_name}
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {league.events && league.events.map(e =>
                                                                     <div key={e.id || e._id}>
                                                                     <div className="live_date">
                                                                      <span className="is_live me-2"><span className="pulse-circle me-1"/>{timeStatus.find(x => {return parseInt(x.id) === parseInt(e.time_status)})?.title} </span>
                                                                      {e.time ? dayjs.unix(e.time).tz(userTimezone).format('ddd, MMM D, HH:mm') : dayjs(e.startsAt).tz(userTimezone).format('ddd, MMM D, HH:mm')}
                                                                     </div>
                                                                     <div className="live_grid pb-4">
                                                                         <Grid style={{minWidth: '100%'}} container spacing={1}>
                                                                             <Grid className="pt-2" item xs={5} sm={3}>
                                                                                <div className="display_spacebetween">
                                                                                    <div className="teams_sport pt-1">{e.home.name}</div>
                                                                                    <div className="results_sport pt-1">
                                                                                        <span>{e.ss? e.ss?.split('-')[0] : 0}</span>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="display_spacebetween">
                                                                                    <div className="teams_sport pt-1">{e.away.name}</div>
                                                                                    <div className="results_sport pt-1">
                                                                                        <span>{e.ss? e.ss?.split('-')[1] : 0}</span>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                             </Grid>
                                                                             
                                                                             <Grid item xs={6} sm={8}>
                                                                                 {e.odds && e.odds[0]?.length > 0 &&
                                                                                 <>
                                                                                 <div className="text-with-lines">1x2</div>
                                                                                 <Grid className="ps-2 pe-2" style={{minWidth: '100%'}} container spacing={2}>
                                                                                     <Grid item xs={e.odds[0][1] ? 4 : 6}>
                                                                                         <div onClick={() => addToSlip(e.home.name, "1", e.odds[0][0], e, "Fulltime Results")} className="live_bets">
                                                                                             <div>{e.home.name}</div>
                                                                                             <div>{calOdd(e.odds[0][0]?.OD)}</div>
                                                                                         </div>
                                                                                     </Grid>
                                                                                     {e.odds[0][1] &&
                                                                                     <Grid item xs={4}>
                                                                                         <div onClick={() => addToSlip("Draw", "0", e.odds[0][1], e, "Fulltime Results")}  className="live_bets">
                                                                                             <div>Draw</div>
                                                                                             <div>{calOdd(e.odds[0][1]?.OD)}</div>
                                                                                         </div>
                                                                                     </Grid>}
                                                                                     <Grid item xs={e.odds[0][1] ? 4 : 6}>
                                                                                         <div  onClick={() => addToSlip(e.away.name, "2", e.odds[0][2], e, "Fulltime Results")} className="live_bets">
                                                                                             <div>{e.away.name}</div>
                                                                                             <div>{calOdd(e.odds[0][2]?.OD)}</div>
                                                                                         </div>
                                                                                     </Grid>
                                                                                 </Grid>
                                                                                 </>
                                                                                 }
                                                                                 {e.MGs &&
                                                                                     e.MGs.filter(x => {return x.title === 'Fulltime Result'}).map((mg,i) =>
                                                                                         <Fragment key={mg.title}>
                                                                                             <div className="text-with-lines">1x2</div>
                                                                                             <Grid className="ps-2 pe-2" style={{minWidth: '100%'}} container spacing={2}>
                                                                                             {mg.odds.map(od =>
                                                                                                 <Fragment key={od.name+od.OD}>
                                                                                                     <Grid item xs={4}>
                                                                                                         <div onClick={() => addToSlip(od.name, i, mg, e, mg?.title)} className="live_bets">
                                                                                                             <div>{od.name}</div>
                                                                                                             <div>{parseFloat(od.OD).toFixed(2)}</div>
                                                                                                         </div>
                                                                                                     </Grid>
                                                                                                 </Fragment>
                                                                                             )}
                                                                                             </Grid>
                                                                                         </Fragment>
                                                                                     )
                                                                                 }
                                                                             </Grid>
                                                                                     
                                                                             <Grid onClick={() => {navigate(e._id ? `/play/sport/${selected_sport}/cevents/${e._id}` : `/play/sport/${selected_sport}/upcoming/${e.id?.split('C')[0]}`)}}  item xs={1}>
                                                                                 <div className="display_center">
                                                                                     <ArrowForwardIosIcon />
                                                                                 </div>
                                                                             </Grid>
                                                                         </Grid>
                                                                     </div>
                                                                 </div>
                                                                )}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        )}
                                    </div>
                                 }
                                
                                 {(!upcoming || upcoming?.length === 0 || isEmpty(upcoming)) &&
                                     <div className="display_search_autocomplete display_nonefound_mobile"> No active {selected_sport} Games to display.</div>
                                 }
                                 </>
                            }
                          
                    </div>
               </div>
            </Container>
            }

            {loading &&
            <Container style={{padding: '1rem', paddingTop: '2rem', overflowX: 'hidden', paddingBottom: '2rem'}}>
                <>
                    <Skeleton variant="text" width={width} height={height}/>
                    <Skeleton variant="text" width={width} height={height}/>
                    <Skeleton variant="text" width={width} height={height}/>
                </>
            </Container>
            }
        </div>
    )
}