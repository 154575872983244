import Carousel from 'react-bootstrap/Carousel';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';

function CarouselHome({banners, loadingBanners}) {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(max-width:960px)');

  // Adjust width and height based on screen size
  const width = isSmallScreen ? 360 : isMediumScreen ? 580 : 1080;
  const height = isSmallScreen ? 200 : isMediumScreen ? 280 : 350;

  return (
    <Carousel className="carousel_home" indicators={false}>
      {banners && banners.map(b => 
        <Carousel.Item key={b._id} interval={3000}>
          <div className="display_center">
              <img style={{width: '100%'}} src={b.url} alt="test" />
          </div>
        </Carousel.Item>
      )}

      {loadingBanners && 
        <Skeleton variant="rectangular" width={width} height={height}/>
      }
    </Carousel>
  );
}

export default CarouselHome;