import { Container } from 'react-bootstrap';
import {useState, useEffect} from 'react';
import axiosInstance from '../../redux/axios';
import {Grid} from '@mui/material';
import { Input, Label } from 'keep-react';
import { Button } from 'keep-react'

export default function ASettings(){
    const [settings, setSettings] = useState({
        min_withdraw: '',
        min_deposit: '',
        min_single_bet: '',
        min_multi_bet: ''
    })
    const [err, setError] = useState('')
    const [success, setSuccess] = useState('')
    
    useEffect(() => {
        if(err !== ''){
            setSuccess('')
        }
    }, [err])
    useEffect(() => {
        if(success !== ''){
            setError('')
        }
    }, [success])

    useEffect(() => { 
        async function getSettings() {
            try{
                const resp = await axiosInstance.get('settings');
                
                if(resp?.data?.settings){
                    const data = resp?.data?.settings;
                    setSettings(({
                        min_withdraw: data.min_withdraw !== undefined ? data.min_withdraw : '',
                        min_deposit: data.min_deposit !== undefined ? data.min_deposit : '',
                        min_single_bet: data.min_single_bet !== undefined ? data.min_single_bet : '',
                        min_multi_bet: data.min_multi_bet !== undefined ? data.min_multi_bet : '',
                    }))
                }
            }catch{} 
        }
        getSettings();
    },[])

    const save = async () => {
        setError('')
        setSuccess('')
        try{
            if(settings.min_deposit !== '' && settings.min_withdraw !== '' && settings.min_single_bet !== '' && settings.min_multi_bet !== ''){
                const response = await axiosInstance.post('admin/settings', settings)
                
                if(response.status === 200){
                    setSuccess(response?.data?.message)
                }
            }else{
                setError('Fill in the form')
            }
            
        }catch(err){
            if(err.response?.data?.error){
                setError(err.response?.data?.error)
            }
        }
    }

    return(
        <div className="main_page pt-0">
             <div className="dashboard_admin">
                <h5 className="mb-0">Config Settings</h5>
            </div>

            <Container className="pt-5 pb-5">
                <div>
                    <h6>Main details</h6>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <fieldset className="max-w-md space-y-1">
                                <Label htmlFor="min_withdraw">Min Withdraw</Label>
                                <Input value={settings.min_withdraw} onChange={e => setSettings({...settings, min_withdraw: e.target.value})} id="min_withdraw" placeholder="Enter min withdraw" type="number" className="bg-gray-800 text-white" />
                            </fieldset>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <fieldset className="max-w-md space-y-1">
                                <Label htmlFor="min_deposit">Min Deposit</Label>
                                <Input value={settings.min_deposit} onChange={e => setSettings({...settings, min_deposit: e.target.value})} id="min_deposit" placeholder="Enter min deposit" type="number" className="bg-gray-800 text-white" />
                            </fieldset>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <fieldset className="max-w-md space-y-1">
                                <Label htmlFor="min_single_bet">Min Single Bet</Label>
                                <Input value={settings.min_single_bet} onChange={e => setSettings({...settings, min_single_bet: e.target.value})} id="min_single_bet" placeholder="Enter min single bet" type="number" className="bg-gray-800 text-white" />
                            </fieldset>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <fieldset className="max-w-md space-y-1">
                                <Label htmlFor="min_multi_bet">Min Multi Bet</Label>
                                <Input value={settings.min_multi_bet} onChange={e => setSettings({...settings, min_multi_bet: e.target.value})} id="min_multi_bet" placeholder="Enter min multi bet" type="number" className="bg-gray-800 text-white" />
                            </fieldset>
                        </Grid>
                    </Grid>

                    <div className="pt-3">
                        <Button onClick={() => {save()}} className="me-3 mt-2" color="success">Save Changes</Button>
                    </div>
                    {err !== '' && <p className="p_error">{err}</p>}
                    {success !== '' && <p className="p_success"> {success} </p>}
                </div>
            </Container>
        </div>
    )
}